import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { PAGES } from '../constants';
import { setPage } from '../redux/actionCreators';
import Maps from '../components/Maps';

function Map(props) {
	useEffect(() => {
		props.setPage(PAGES.map.id);
		// eslint-disable-next-line
	}, []);

	return <Maps />;
}

export default connect(null, { setPage })(Map);
