/**
 * Takes a variable amount of paths and combines them, saving you from having to worry about extra or missing slashes
 * @param {String} ...paths : Strings to be combined to form a path
 * @returns {String} : The combined path, with a leading slash and without a trailing slash
 */
function combinePaths(...paths) {
	let combination = '';
	for (const path of paths) {
		for (const part of String(path).split('/')) {
			if (part.length) combination += '/' + part;
		}
	}

	return combination;
}

export default combinePaths;
