export const SET_HASFINISHEDLOADING = 'SET_HASFINISHEDLOADING';
export const SET_USERINFO = 'SET_USERINFO';
export const SET_PAGE = 'SET_PAGE';
export const SET_TAB = 'SET_TAB';
export const SET_PROPERTY = 'SET_PROPERTY';
export const SET_SENSORS = 'ADD_SENSORS';
export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_SENSORGROUPS = 'SET_SENSORGROUPS';
export const SET_DIGITALTWINS = 'SET_DIGITALTWINS';
export const SET_DIGITALTWINTAGS = 'SET_DIGITALTWINTAGS';
export const SET_DIGITALTWINPERSPECTIVES = 'SET_DIGITALTWINPERSPECTIVES';
export const SET_COS = 'SET_COS';
export const SET_COREGISTERS = 'SET_COREGISTERS';
export const SET_COREGISTEROFFSETS = 'SET_COREGISTEROFFSETS';
export const SET_ACTIONS = 'SET_ACTIONS';
export const SET_FILERECORDS = 'SET_FILERECORDS';
export const SET_ALARMPERIODSENSORS = 'SET_ALARMPERIODSENSORS';
export const SET_DEVIATIONS = 'SET_DEVIATIONS';
export const SET_CAMERAS = 'SET_CAMERAS';
export const SET_EXTERNALCONTROLPANELS = 'SET_EXTERNALCONTROLPANELS';

export const UPDATE_SENSORS = 'UPDATE_SENSORS';
export const UPDATE_SENSORGROUPS = 'UPDATE_SENSORGROUPS';
export const UPDATE_PROPERTIES = 'UPDATE_PROPERTIES';
export const UPDATE_DIGITALTWINS = 'UPDATE_DIGITALTWINS';
export const UPDATE_DIGITALTWINTAGS = 'UPDATE_DIGITALTWINTAGS';
export const UPDATE_DIGITALTWINPERSPECTIVES = 'UPDATE_DIGITALTWINPERSPECTIVES';
export const UPDATE_COS = 'UPDATE_COS';
export const UPDATE_COREGISTERS = 'UPDATE_COREGISTERS';
export const UPDATE_COREGISTEROFFSETS = 'UPDATE_COREGISTEROFFSETS';
export const UPDATE_ACTIONS = 'UPDATE_ACTIONS';
export const UPDATE_USERINFO = 'UPDATE_USERINFO';
export const UPDATE_FILERECORDS = 'UPDATE_FILERECORDS';
export const UPDATE_ALARMPERIODSENSORS = 'UPDATE_ALARMPERIODSENSORS';
export const UPDATE_DEVIATIONS = 'UPDATE_DEVIATIONS';
export const UPDATE_CAMERAS = 'UPDATE_CAMERAS';
export const UPDATE_EXTERNALCONTROLPANELS = 'UPDATE_EXTERNALCONTROLPANELS';

export const ADD_CALLBACK = 'ADD_CALLBACK';
export const REMOVE_CALLBACK = 'REMOVE_CALLBACK';
