import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as colors from '../../colors';

export default function DeleteReportPopup({ onDelete, onClose, reportName }) {
	const [deleteName, setDeleteName] = useState(reportName);
	const [deletingReport, setDeletingReport] = useState(false);

	const { t } = useTranslation();

	const handleDelete = () => {
		setDeletingReport(true);
		onDelete();
	};
	return (
		<>
			<DialogTitle>{t('reports.deleteReportTitle')}</DialogTitle>
			<DialogContent>
				<DialogContentText>{t('reports.deleteReportText')}</DialogContentText>
				<hr />
				<div
					style={{
						fontSize: '1rem',
						fontWeight: 'bold',
						color: colors.failure,
					}}
				>
					{deleteName}
				</div>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color='primary'>
					{t('generic.cancel')}
				</Button>
				<Button
					onClick={handleDelete}
					startIcon={!deletingReport ? <Delete /> : null}
					style={{
						color: 'white',
						backgroundColor: colors.failure,
						minWidth: '8rem',
					}}
					variant='contained'
					disabled={deletingReport}
				>
					{deletingReport ? <CircularProgress color='inherit' size={'1.4rem'} /> : t('automations.delete')}
				</Button>
			</DialogActions>
		</>
	);
}
