/**
 * Calculate the percentage difference between the Y-values of the start and end points of a least squares regression line
 * @param {Array of Objects} points : The points on the value/time axis of the sensor, format: [{x: Date, y: Number}, ...]
 * @param {number} min : Minimum value to allow in the slope calculation, where 0.000...1 is realistic but 1 is more practical as it clamps values below 1 to 1 (otherwise going from 0.1 to 3 would be a 3000% increase). A higher value diminishes results produced by small point-values
 * @returns {Number} : The percentage change/trend of the calculated least squares regression line, 1 unit = 1 percent
 */
function getPointTrend(points, min = 0.5) {
	// Filter and transform input
	const xyPoints = points
		.filter(point => !isNaN(point.y) && point.y !== null)
		.map(point => {
			return { x: new Date(point.x).getTime(), y: point.y };
		});

	// Two or more points required to calculate a trend
	if (xyPoints.length < 2) return 0;

	const xSum = xyPoints.reduce((sum, cur) => sum + cur.x, 0);
	const ySum = xyPoints.reduce((sum, cur) => sum + cur.y, 0);
	const xySum = xyPoints.reduce((sum, cur) => sum + cur.x * cur.y, 0);
	const xSquaredSum = xyPoints.reduce((sum, cur) => sum + cur.x * cur.x, 0);
	const n = xyPoints.length;

	// y = mx + b
	const m = (n * xySum - xSum * ySum) / (n * xSquaredSum - Math.pow(xSum, 2));
	const b = (ySum - m * xSum) / n;

	// `Math.max(min, min + ...)` smooths out results but makes the formula less accurate
	const firstY = Math.max(min, min + m * xyPoints[0].x + b);
	const lastY = Math.max(min, min + m * xyPoints[xyPoints.length - 1].x + b);

	// Percentage difference between first and last points, converted from 0-1 to 0-100%
	// '|| 0' as a substitute for NaN after dividing by zero
	return ((lastY - firstY) / firstY) * 100 || 0;
}

export { getPointTrend };
