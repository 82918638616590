import React, { useState, useEffect } from 'react';
import { Dialog, TextField, Button } from '@material-ui/core';
import { gql, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { CheckCircle as CheckIcon, Edit as EditIcon } from '@material-ui/icons';
import { useTranslation, Trans } from 'react-i18next';

import { getDecimals } from '../utility-functions';
import { updateSensors } from '../redux/actionCreators';
import { STORE, getStateVariables } from '../redux/selectors';
import { SensorGraph } from './SensorGraph';
import DialogWrapper from './DialogWrapper';
import AlarmPeriodEditor from './AlarmPeriodEditor';
import SliderWrapper from './SliderWrapper';

const SET_SENSORS = gql`
	mutation ($sensorids: [ID]!, $lowerthreshold: Float, $upperthreshold: Float, $alarmgraceminutes: Int) {
		setSensors(
			sensorids: $sensorids
			lowerthreshold: $lowerthreshold
			upperthreshold: $upperthreshold
			alarmgraceminutes: $alarmgraceminutes
		) {
			sensorid
			multiplier
			lowerthreshold
			upperthreshold
			alarmgraceminutes
		}
	}
`;

const apIconProps = {
	style: { color: '#124', width: '1.1rem', height: '1.1rem', marginRight: '0.3rem' },
};

/**
 * Popup-window that allows setting sensor threshold values through visual sliders with sensor-history data in the background
 * @param {Object} sensor : The sensor, with its accompanying properties, of which the thresholds are being edited
 * @param {Boolean} open : Whether or not the popup should show
 * @param {() => void} onExit : Called when the popup signals it wants to close
 */
function AlarmEditor(props) {
	const [thresholds, setThresholds] = useState([]);
	const [tmpThresholds, setTmpThresholds] = useState([]);
	const [dataRange, setDataRange] = useState([]);
	const [graphHasLoaded, setGraphHasLoaded] = useState(false);
	const [agm, setAgm] = useState();
	const [showApPopup, setShowApPopup] = useState(false);
	const [apCb, setApCb] = useState();

	const { t } = useTranslation();

	useEffect(() => {
		setAgm(props.sensor.alarmgraceminutes);
	}, [props.sensor.alarmgraceminutes]);

	useEffect(() => {
		if (props.open) {
			setThresholds([props.sensor?.lowerthreshold, props.sensor?.upperthreshold]);
		} else {
			setThresholds([]);
			setDataRange([]);
			setGraphHasLoaded(false);
		}
	}, [props.sensor, props.open]);

	const [setSensors] = useMutation(SET_SENSORS, {
		onCompleted: ({ setSensors }) => {
			if (!setSensors) return;
			props.updateSensors(
				setSensors.map(sen => ({
					...sen,
					lowerthreshold: sen.lowerthreshold && sen.lowerthreshold * (sen.multiplier || 1),
					upperthreshold: sen.upperthreshold && sen.upperthreshold * (sen.multiplier || 1),
				}))
			);
		},
		onError: console.warn,
	});

	const hasActiveAp = props.alarmPeriodSensors.map(sen => sen.sensorid).includes(props.sensor.sensorid);
	const bounds = [
		thresholds.length && dataRange.length
			? Math.min(thresholds[0], dataRange[0])
			: thresholds.length
				? thresholds[0]
				: dataRange.length
					? dataRange[0]
					: 0,
		thresholds.length && dataRange.length
			? Math.max(thresholds[1], dataRange[1])
			: thresholds.length
				? thresholds[1]
				: dataRange.length
					? dataRange[1]
					: 100,
	];
	const offsetBounds = [bounds[0] - (bounds[1] - bounds[0]) * 0.25, bounds[1] + (bounds[1] - bounds[0]) * 0.25];
	const newThresholds = [
		tmpThresholds[0] !== undefined ? tmpThresholds[0] : thresholds[0] !== undefined ? thresholds[0] : props.sensor.lowerthreshold,
		tmpThresholds[1] !== undefined ? tmpThresholds[1] : thresholds[1] !== undefined ? thresholds[1] : props.sensor.upperthreshold,
	];

	return (
		<Dialog open={Boolean(props.open)} onClose={() => props.onExit()} PaperProps={{ style: { padding: '1.4rem', minWidth: '50rem' } }}>
			<h1 style={{ display: 'flex', fontSize: '148%', fontWeight: '400', color: '#000b', margin: '0 0 0.7rem 0.1rem' }}>
				<Trans
					i18nKey='alarmEditor.editThresholdsTitle'
					values={{ sensorName: props.sensor.name }}
					components={[<div style={{ marginLeft: '0.5rem', color: '#000d' }} />]}
				/>
			</h1>

			<div style={{ display: 'flex' }}>
				<div style={{ background: '#f0f0f0', padding: '1rem', borderRadius: '0.7rem', width: '100%' }}>
					<SensorGraph
						isVisible={Boolean(props.open)}
						sensorInfo={{
							...props.sensor,
							lowerthreshold: newThresholds[0],
							upperthreshold: newThresholds[1],
						}}
						updateDataRange={newRange => setDataRange(newRange)}
						onLoaded={() => setGraphHasLoaded(true)}
						yRange={offsetBounds}
					/>
				</div>

				{graphHasLoaded && (
					<SliderWrapper
						isVertical
						sliderProps={{
							defaultValue: [props.sensor.lowerthreshold, props.sensor.upperthreshold],
							onChange: (e, newVals) => {
								// Slider-ticks will try to change from null to 0 even when they are not touched
								setTmpThresholds([
									newVals[0] === 0 && thresholds[0] === null ? null : getDecimals(newVals[0], 1),
									newVals[1] === 0 && thresholds[1] === null ? null : getDecimals(newVals[1], 1),
								]);
							},
							onChangeCommitted: (e, newVals) => {
								// Slider-ticks will try to change from null to 0 even when they are not touched
								setThresholds([
									newVals[0] === 0 && thresholds[0] === null ? null : getDecimals(newVals[0], 1),
									newVals[1] === 0 && thresholds[1] === null ? null : getDecimals(newVals[1], 1),
								]);
								setTmpThresholds([]);
							},
							orientation: 'vertical',
							min: offsetBounds[0],
							max: offsetBounds[1],
							step: (offsetBounds[1] - offsetBounds[0]) / 100,
						}}
						style={{ background: '#f0f0f0', padding: '1rem 0.3rem 1rem 0.08rem', marginLeft: '0.5rem', borderRadius: '0.7rem' }}
					/>
				)}
			</div>

			<div style={{ display: 'flex', margin: '1.2rem 0 0 0' }}>
				<TextField
					value={newThresholds[0] === null ? '' : Number(newThresholds[0]).toFixed(2)}
					onChange={e => setThresholds([e.target.value ? Number(e.target.value) : null, thresholds[1]])}
					label={t('alarmEditor.lowerThresholdAbb')}
					variant='outlined'
					type='Number'
					size='small'
					style={{ width: '6rem', margin: '0 0 0 0.2rem' }}
				/>
				<TextField
					value={newThresholds[1] === null ? '' : Number(newThresholds[1]).toFixed(2)}
					onChange={e => setThresholds([thresholds[0], e.target.value ? Number(e.target.value) : null])}
					label={t('alarmEditor.upperThresholdAbb')}
					variant='outlined'
					type='Number'
					size='small'
					style={{ width: '6rem', margin: '0 0 0 0.7rem' }}
				/>
				<TextField
					value={typeof agm === 'number' ? agm : ''}
					onChange={({ target }) =>
						!isNaN(target.value) &&
						Number(target.value) % 1 === 0 &&
						Number(target.value) >= 0 &&
						setAgm(target.value ? Number(target.value) : null)
					}
					label={t('alarmEditor.graceMinsAbb')}
					variant='outlined'
					type='Number'
					size='small'
					style={{ width: '7rem', margin: '0 0 0 0.7rem' }}
				/>
				<div
					onClick={() => setShowApPopup(true)}
					style={{
						display: 'flex',
						alignItems: 'center',
						padding: '0.3rem 0.5rem',
						marginLeft: '0.7rem',
						background: apCb ? '#afb' : hasActiveAp ? '#eef' : '#eee',
						borderRadius: '4px',
						cursor: 'pointer',
					}}
				>
					{apCb ? <EditIcon {...apIconProps} /> : hasActiveAp && <CheckIcon {...apIconProps} />}
					<div style={{ fontWeight: 500, color: '#333', marginRight: '0.3rem' }}>{t('generic.alarmPeriod_other')}:</div>
					{apCb
						? t('alarmEditor.unsaved_other')
						: hasActiveAp
							? t('alarmEditor.activated_other')
							: t('alarmEditor.noneSpecified')}
				</div>

				<div style={{ margin: 'auto 0 auto auto' }}>
					<Button
						onClick={() => {
							setApCb();
							props.onExit();
						}}
						variant='outlined'
						color='primary'
					>
						{t('generic.cancel')}
					</Button>
					<Button
						onClick={() => {
							setSensors({
								variables: {
									sensorids: [props.sensor.sensorid],
									lowerthreshold:
										thresholds[0] !== props.sensor.lowerthreshold && !isNaN(thresholds[0])
											? thresholds[0] && thresholds[0] * (1 / (props.sensor.multiplier || 1))
											: undefined,
									upperthreshold:
										thresholds[1] !== props.sensor.upperthreshold && !isNaN(thresholds[1])
											? thresholds[1] && thresholds[1] * (1 / (props.sensor.multiplier || 1))
											: undefined,
									alarmgraceminutes: agm !== props.sensor.alarmgraceminutes ? agm : undefined,
								},
							});
							if (apCb) apCb();
							setApCb();
							props.onExit();
						}}
						variant='outlined'
						color='primary'
						style={{ margin: '0 0 0 1rem' }}
					>
						{t('generic.save')}
					</Button>
				</div>
			</div>

			<DialogWrapper
				title={t('alarmEditor.editAlarmPeriods')}
				width='30rem'
				dialogProps={{ open: showApPopup, onClose: () => setShowApPopup(false) }}
			>
				<AlarmPeriodEditor
					sensors={[props.sensor]}
					onFinish={cb => {
						setShowApPopup(false);
						setApCb(() => cb);
					}}
				/>
			</DialogWrapper>
		</Dialog>
	);
}

export default connect(getStateVariables(STORE.alarmPeriodSensors), { updateSensors })(AlarmEditor);
