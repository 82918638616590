import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getStateVariables, STORE } from '../../redux/selectors';
import { useTranslation } from 'react-i18next';
import { Cloud, FlashOn, HomeWork, Waves } from '@material-ui/icons';
import { ButtonBack, ButtonContinue, SensorSelect, WizardAlert } from './WizardComponents';

function formatForSelect(sensor) {
	if (!sensor) return undefined;
	return { value: sensor.sensorid, label: sensor.name + ' | ' + sensor.unit };
}

function smartChoices(sensors, unit) {
	return sensors
		.filter(sensor => {
			return sensor.unit?.toLowerCase().includes(unit);
		})
		.map(sensor => formatForSelect(sensor));
}

function UtilitySensors(props) {
	const { property, sensors, options, onBack, onContinue } = props;
	const [utilitySensors, setUtilitySensors] = useState(options);
	const { t } = useTranslation();

	const sensorOptions = sensors.filter(sensor => sensor.locationid === property.locationid);

	const sensorFromId = sensorId => {
		return sensorOptions.find(sensor => sensor.sensorid === sensorId);
	};

	const handleContinue = () => {
		onContinue(utilitySensors);
	};

	const temperatureSensors = smartChoices(sensorOptions, 'c');
	const electricitySensors = smartChoices(sensorOptions, 'w');

	const sensorTypesWithoutSensors = [
		...(temperatureSensors.length ? [] : [t('generic.supplyTemp'), t('generic.outdoorTemp')]),
		// ...(electricitySensors.length ? [] : [t('generic.electricity'), t('constants.districtHeating')]),
	];

	return (
		<>
			<div style={{ display: 'flex', flexDirection: 'column', height: '70vh' }}>
				<h2>{t('utilitySensors.pageTitle')}</h2>
				<hr style={{ width: '100%' }} />
				<SensorSelect
					options={temperatureSensors}
					icon={<Waves />}
					onSelectionChange={sel => setUtilitySensors({ ...utilitySensors, supplyTemp: sensorFromId(sel) })}
					style={{ marginTop: '1rem' }}
					defaultValue={formatForSelect(options.supplyTemp)}
					placeholder={'*' + t('registrationWizard.selectSensor')}
					error={!temperatureSensors.length}
				>
					{t('generic.supplyTemp')}
				</SensorSelect>

				<SensorSelect
					options={temperatureSensors}
					icon={<Cloud />}
					onSelectionChange={sel => setUtilitySensors({ ...utilitySensors, outdoorTemp: sensorFromId(sel) })}
					style={{ marginTop: '1rem' }}
					defaultValue={formatForSelect(options.outdoorTemp)}
					placeholder={'*' + t('registrationWizard.selectSensor')}
					error={!temperatureSensors.length}
				>
					{t('generic.outdoorTemp')}
				</SensorSelect>

				<SensorSelect
					options={electricitySensors}
					icon={<FlashOn />}
					onSelectionChange={sel => setUtilitySensors({ ...utilitySensors, electricity: sensorFromId(sel) })}
					style={{ marginTop: '1rem' }}
					defaultValue={formatForSelect(options.electricity)}
					placeholder={t('registrationWizard.selectSensor')}
					//error={!electricitySensors.length}
				>
					{t('generic.electricity')}
				</SensorSelect>

				<SensorSelect
					options={electricitySensors}
					icon={<HomeWork />}
					onSelectionChange={sel => setUtilitySensors({ ...utilitySensors, districtHeating: sensorFromId(sel) })}
					style={{ marginTop: '1rem' }}
					defaultValue={formatForSelect(options.electricity)}
					placeholder={t('registrationWizard.selectSensor')}
					//error={!electricitySensors.length}
				>
					{t('constants.districtHeating')}
				</SensorSelect>

				{sensorTypesWithoutSensors.length ? (
					<WizardAlert severity='error' style={{ marginTop: '1.5rem' }}>
						{t('utilitySensors.sensorsNotFound', { sensors: sensorTypesWithoutSensors.join(', ') })}
					</WizardAlert>
				) : undefined}
			</div>
			<div position='absolute' style={{ display: 'flex', bottom: '1rem', right: '1rem', justifyContent: 'right' }}>
				<ButtonBack onClick={() => onBack()} />
				<ButtonContinue
					onClick={handleContinue}
					style={{ marginLeft: '1rem' }}
					disabled={utilitySensors.supplyTemp === undefined || utilitySensors.outdoorTemp === undefined}
				/>
			</div>
		</>
	);
}

export default connect(getStateVariables(STORE.sensors))(UtilitySensors);
