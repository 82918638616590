import React, { useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { GridStack } from 'gridstack';
import { Widget } from './Widget';

import 'gridstack/dist/gridstack.min.css';

export const GridInitOptions = {
	float: true,
	maxRow: 16,
};

export const Grid = forwardRef(({ widgets, editMode, deleteWidgetCallback, changeMadeCallback, id = 0 }, ref) => {
	const gridRef = useRef();
	useImperativeHandle(
		ref,
		() => {
			return {
				save(saveContent = true) {
					return gridRef.current.save(saveContent);
				},
				willItFit(x, y, w, h, autoPosition) {
					return gridRef.current.willItFit({ x, y, w, h, autoPosition });
				},
			};
		},
		[]
	);

	useEffect(() => {
		gridRef.current = gridRef.current || GridStack.init(GridInitOptions, `.gridwithid-${id}`);

		gridRef.current.batchUpdate();
		gridRef.current.removeAll(false);
		widgets.forEach(widget => {
			gridRef.current.makeWidget(`#${widget.id}`);
		});
		gridRef.current.batchUpdate(false);

		// eslint-disable-next-line
	}, [widgets]);

	useEffect(() => {
		editMode ? gridRef.current.enable() : gridRef.current.disable();
	}, [editMode]);

	useEffect(() => {
		gridRef.current.on('change', () => {
			changeMadeCallback({
				type: 'change',
			});
		});
		return () => {
			gridRef.current.off('change');
		};
	}, [changeMadeCallback]);

	const deleteWidget = widgetKey => {
		deleteWidgetCallback(widgetKey);
	};

	return (
		<>
			<div className={`grid-stack gridwithid-${id}`} style={{ width: '100%', left: '-0.8%', height: '100%' }}>
				{widgets.map(widget => {
					return (
						<Widget
							key={widget.id}
							options={widget.options}
							id={widget.id}
							deleteWidgetCallback={deleteWidget}
							editMode={editMode}
						>
							{{ ...widget.content }}
						</Widget>
					);
				})}
			</div>
		</>
	);
});
