import React from 'react';
import DeleteWidgetButton from './DeleteWidgetButton';

export function Widget(props) {
	return (
		<div
			className={'grid-stack-item'}
			id={props.id}
			gs-id={props.id}
			gs-w={props.options.w}
			gs-h={props.options.h}
			gs-x={props.options.x}
			gs-y={props.options.y}
			gs-min-w={props.options.minW}
			gs-max-w={props.options.maxW}
			gs-min-h={props.options.minH}
			gs-max-h={props.options.maxH}
			gs-no-resize={props.options.noResize ? 'true' : undefined}
			gs-no-move={props.options.noMove ? 'true' : undefined}
		>
			{props.editMode ? (
				<div style={{ position: 'relative' }}>
					<DeleteWidgetButton deleteWidgetCallback={() => props.deleteWidgetCallback(props.id)} widgetKey={props.id} />
				</div>
			) : null}
			<div
				style={{
					border: '1px solid lightgrey',
					borderRadius: '5px',
					paddingTop: '10px',
					paddingLeft: '20px',
					paddingBottom: '10px',
					paddingRight: '20px',
					overflowY: 'hidden',
				}}
				className='grid-stack-item-content'
			>
				{props.children}
			</div>
		</div>
	);
}
