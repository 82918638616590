import React, { useState, useEffect } from 'react';
import { gql, useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Button, Dialog, IconButton } from '@material-ui/core';
import { Visibility as ShowIcon } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { Backdrop, LinearProgress } from '@material-ui/core';
import Swal from 'sweetalert2';

import { STORE, getStateVariables } from '../redux/selectors';
import { setSensorGroups, updateExternalControlPanels } from '../redux/actionCreators';
import { editObject } from '../utility-functions';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import DialogWrapper from './DialogWrapper';
import ImportedRegisterList from './ImportedRegisterList';
import * as colors from '../colors';

const GET_IMPORTEDREGISTERS = gql`
	query ($filter: ImportedRegisterFilter!) {
		getImportedRegisters(filter: $filter) {
			externalcontrolpanelid
			objectname
			groupname
			parametername
			address
			canread
			canwrite
			read
			write
		}
	}
`;
const SET_EXTERNALCONTROLPANELS = gql`
	mutation ($externalcontrolpanelids: [ID!]!, $locationid: ID, $url: String, $ip: String, $port: Int, $ducid: Int, $label: String) {
		setExternalControlPanels(
			externalcontrolpanelids: $externalcontrolpanelids
			locationid: $locationid
			url: $url
			ip: $ip
			port: $port
			ducid: $ducid
			label: $label
		) {
			externalcontrolpanelid
			locationid
			url
			ip
			port
			ducid
			label
		}
	}
`;
const ADD_EXTERNALCONTROLPANELS = gql`
	mutation ($locationid: ID, $url: String, $ip: String, $port: Int, $ducid: Int, $label: String) {
		addExternalControlPanel(locationid: $locationid, url: $url, ip: $ip, port: $port, ducid: $ducid, label: $label) {
			externalcontrolpanelid
			locationid
			url
			ip
			port
			ducid
			label
		}
	}
`;
const ADD_SENSORGROUP = gql`
	mutation ($name: String!, $locationid: Int!, $externalcontrolpanelid: Int) {
		addSensorGroup(name: $name, locationid: $locationid, externalcontrolpanelid: $externalcontrolpanelid) {
			sensorgroupid
			name
			locationid
			externalcontrolpanelid
		}
	}
`;

const POPUP_TYPES = Object.freeze({ add: 1, edit: 2 });
const DISCRETE_CLICK_ID = 'discrete-click';

/**
 * A table for external control-panels that allows them to be filtered and bulk-edited
 */
function ExternalControlPanelAdministration(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [selectedEcps, setSelectedEcps] = useState([]);
	const [ecpsUnderEdit, setEcpsUnderEdit] = useState([]);
	const [editPopupType, setEditPopupType] = useState();
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [showEcp, setShowEcp] = useState(false);
	const [shownEcp, setShownEcp] = useState();
	const [showRegPopup, setShowRegPopup] = useState(false);
	const [importedRegs, setImportedRegs] = useState([]);
	const [selectedReadRegs, setSelectedReadRegs] = useState([]);
	const [selectedWriteRegs, setSelectedWriteRegs] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const { t } = useTranslation();

	useQuery(GET_IMPORTEDREGISTERS, {
		variables: { filter: { externalcontrolpanelids: props.externalControlPanels.map(ecp => ecp.externalcontrolpanelid) } },
		onCompleted: ({ getImportedRegisters }) => setImportedRegs(getImportedRegisters),
		fetchPolicy: 'no-cache',
	});

	const [setEcps] = useMutation(SET_EXTERNALCONTROLPANELS, {
		onCompleted: ({ setExternalControlPanels }) => props.updateExternalControlPanels(setExternalControlPanels),
	});
	const [addEcp] = useMutation(ADD_EXTERNALCONTROLPANELS, {
		onCompleted: ({ addExternalControlPanel }) => {
			Swal.fire(t('userAdmin.success'), t('externalControlPanelAdmin.ducAdded'), 'success');
			props.updateExternalControlPanels([addExternalControlPanel])
		},
	});

	const [addSensorGroup] = useMutation(ADD_SENSORGROUP, {
		onCompleted: ({ addSensorGroup }) => {
			props.setSensorGroups([...props.sensorGroups, addSensorGroup])
		},
	});

	const filteredEcps = selectedProperties.length
		? props.externalControlPanels.filter(ecp => selectedProperties.some(pro => pro.value === Number(ecp.locationid)))
		: props.externalControlPanels;
	const propertyOptions = [
		...props.properties.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` })),
		{ value: null, label: t('generic.noPropOption') },
	];

	//loading external control panels
	useEffect(() => {
		if (props.externalControlPanels.length) {
			setIsLoading(false);
		} else {
			const timer = setTimeout(() => setIsLoading(false), 4000);
			return () => clearTimeout(timer);

		}
	}, [props.externalControlPanels]);
	// eslint-disable-next-line
	useEffect(() => shownEcp && setShowEcp(true), [shownEcp]);

	useEffect(() => {
		const data = importedRegs.filter(reg => reg.externalcontrolpanelid === ecpsUnderEdit[0]?.externalcontrolpanelid);
		setSelectedReadRegs(data.filter(reg => reg.read).map(reg => reg.address));
		setSelectedWriteRegs(data.filter(reg => reg.write).map(reg => reg.address));
		// eslint-disable-next-line
	}, [showRegPopup, ecpsUnderEdit]);

	return (
		<>
			<div style={{ margin: '1rem 0 0 1rem' }}>
				<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
					<SelectionTable
						localization={{
							title: t('externalControlPanelAdmin.DUCS'),
							nRowsSelected: t('externalControlPanelAdmin.amountChosenDucs') + '{0}',
						}} //TODO: Find a better way to include the amount in the translation
						key={JSON.stringify(filteredEcps)}
						data={filteredEcps
							.map(ecp => ({
								...ecp,
								propertyLabel: propertyOptions.find(pro => pro.value && pro.value === Number(ecp.locationid))?.label || '',
								registerCount: importedRegs.filter(reg => reg.externalcontrolpanelid === ecp.externalcontrolpanelid).length,
							}))
							.sort((a, b) =>
								a.propertyLabel < b.propertyLabel || (a.propertyLabel === b.propertyLabel && a.label < b.label) ? -1 : 1
							)}
						dataId='externalcontrolpanelid'
						onSelectionChange={ecps => setSelectedEcps(ecps)}
						/* 				onRowClick={(ecp, e) => {
											if (e._dispatchInstances.some(dispatch => dispatch.stateNode?.id === DISCRETE_CLICK_ID)) return;
				
											setEcpsUnderEdit([ecp]);
											setEditPopupType(POPUP_TYPES.edit);
											setShowEditPopup(!showEditPopup);
										}} */
						columns={[
							{
								title: t('generic.label'),
								field: 'label',
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.label}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setEcps({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													externalcontrolpanelids: [rowData.externalcontrolpanelid],
													label: e.target.value,
												},
											});
										}}
									/>
								),
							},
							{
								title: t('generic.property'),
								field: 'propertyLabel',
							},
							{
								title: t('externalControlPanelAdmin.IP'),
								field: 'ip',
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.ip}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setEcps({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													externalcontrolpanelids: [rowData.externalcontrolpanelid],
													ip: e.target.value,
												},
											});
										}}
									/>
								),
							},
							{
								title: t('externalControlPanelAdmin.port'),
								field: 'port',
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.port}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setEcps({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													externalcontrolpanelids: [rowData.externalcontrolpanelid],
													port: parseInt(e.target.value),
												},
											});
										}}
									/>
								),
							},
							//BASID
							{
								title: t('externalControlPanelAdmin.ducID'),
								field: 'ducid',
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.ducid}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setEcps({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													externalcontrolpanelids: [rowData.externalcontrolpanelid],
													ducid: parseInt(e.target.value),
												},
											});
										}}
									/>
								),
							},
							{ title: t('externalControlPanelAdmin.register'), field: 'registerCount' },
							{
								title: t('externalControlPanelAdmin.url'),
								field: 'url',
								maxLength: 25,
								render: rowData => (
									<input
										//onClick={() => onSensorClick(rowData)}
										defaultValue={rowData.url}
										style={{ cursor: 'pointer', border: 'none' }}
										contentEditable='true'
										onBlur={e => {
											e.preventDefault();
											setEcps({
												variables: {
													// Cast only if truthy so undefined won't be converted to null, as they are treated differently
													externalcontrolpanelids: [rowData.externalcontrolpanelid],
													url: e.target.value,
												},
											});
										}}
									/>
								),
							},
							{
								title: '',
								align: 'right',
								//sorting: false,
								render: row =>
									row.url && (
										<IconButton onClick={() => setShownEcp(row)} style={{ padding: '0.3rem' }} id={DISCRETE_CLICK_ID}>
											<ShowIcon style={{ width: '1.2rem', height: '1.2rem', color: '#666' }} />
										</IconButton>
									),
							},
						]}
						tableProps={{ maxColumnLength: 60 }}
						pageSizeOptions={[10, 25]}
					/>

					<SelectionSidebar
						buttons={[
							{
								label: t('externalControlPanelAdmin.addDuc'),
								onClick: () => {
									setEditPopupType(POPUP_TYPES.add);
									setShowEditPopup(!showEditPopup);
								},
							},
							{
								label: t('genericAdmin.editSelected'),
								onClick: () => {
									setEcpsUnderEdit(selectedEcps);
									setEditPopupType(POPUP_TYPES.edit);
									setShowEditPopup(!showEditPopup);
								},
								disabled: !selectedEcps.length,
							},
							/* {
								label: t('genericAdmin.archiveSelected'),
								onClick: () => null,
								disabled: !selectedEcps.length,
							}, */
						]}
						filters={propertyOptions}
						updateSelectedFilters={props => setSelectedProperties(props || [])}
						localization={{
							filterTitle: t('externalControlPanelAdmin.filterDucs'),
							filterPlaceholder: t('genericAdmin.selectPropsPrompt'),
						}}
						style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
					/>
				</div>

				<EditPopup
					text={
						editPopupType === POPUP_TYPES.edit
							? {
								title: t('externalControlPanelAdmin.editDuc', { count: ecpsUnderEdit.length }), // TODO: Check that this is correct
								//subtitle: t('externalControlPanelAdmin.newValuesForSelectedDuc', { count: ecpsUnderEdit.length }), // TODO: Check that this is correct
								subtitle:
									ecpsUnderEdit.length === 1
										? t('externalControlPanelAdmin.newValuesForSelectedDuc', { count: ecpsUnderEdit.length })
										: t('externalControlPanelAdmin.newValuesForSelectedDuc_other', { count: ecpsUnderEdit.length }),
							}
							: {
								title: t('externalControlPanelAdmin.addDuc') + '.',
								subtitle: t('externalControlPanelAdmin.enterValuesForNewDuc'),
							}
					}
					fields={
						editPopupType === POPUP_TYPES.edit
							? [
								{
									id: 'label',
									label: t('externalControlPanelAdmin.cadastral'),
									placeholder: ecpsUnderEdit[0].label,
									disabled: ecpsUnderEdit.length > 1,
								},
								{
									id: 'locationid',
									label: t('generic.property'),
									placeholder:
										ecpsUnderEdit.length === 1 && ecpsUnderEdit[0].locationid
											? propertyOptions.find(pro => pro.value === Number(ecpsUnderEdit[0].locationid))?.label
											: '...',
									options: propertyOptions,
								},
								{
									id: 'ip',
									label: t('externalControlPanelAdmin.IP'),
									placeholder: ecpsUnderEdit[0].ip,
									disabled: ecpsUnderEdit.length > 1,
								},
								{
									id: 'port',
									label: t('externalControlPanelAdmin.port'),
									placeholder: ecpsUnderEdit[0].port,
								},
								{
									id: 'ducid',
									label: t('externalControlPanelAdmin.ducID'),
									placeholder: ecpsUnderEdit[0].ducid,
								},
								{
									id: 'url',
									label: t('externalControlPanelAdmin.url'),
									placeholder: ecpsUnderEdit[0].url,
									disabled: ecpsUnderEdit.length > 1,
								},
								{
									id: 'registers',
									label: t('externalControlPanelAdmin.register'),
									disabled: ecpsUnderEdit.length > 1,
									customRender: (() => {
										return (
											<div
												style={{
													margin: '0 0 0 0.15rem',
													gap: '0.8rem',
													fontWeight: '500',
													fontSize: '120%',
													display: 'flex',
													alignItems: 'center',
												}}
											>
												{
													importedRegs.filter(
														reg => reg.externalcontrolpanelid === ecpsUnderEdit[0].externalcontrolpanelid
													).length
												}
												<Button
													style={{ color: colors.primary }}
													variant='outlined'
													size='small'
													onClick={() => setShowRegPopup(true)}
												>
													{t('generic.edit')}
												</Button>
											</div>
										);
									})(),
								},
							]
							: [
								{
									id: 'label',
									label: t('externalControlPanelAdmin.cadastral'),
								},
								{
									id: 'locationid',
									label: t('generic.property'),
									options: propertyOptions,
								},
								{
									id: 'ip',
									label: t('externalControlPanelAdmin.IP'),
								},
								{
									id: 'port',
									label: t('externalControlPanelAdmin.port'),
								},
								{
									id: 'ducid',
									label: t('externalControlPanelAdmin.ducID'),
								},
								{
									id: 'url',
									label: t('externalControlPanelAdmin.url'),
								},
							]
					}
					isOpen={showEditPopup}
					onClose={() => setShowEditPopup(!showEditPopup)}
					onSave={opts => {
						if (editPopupType === POPUP_TYPES.edit) {
							setEcps({
								variables: {
									externalcontrolpanelids: ecpsUnderEdit.map(ecp => ecp.externalcontrolpanelid),
									locationid: opts.locationid,
									url: opts.url,
									ip: opts.ip,
									port: opts.port && Number(opts.port),
									ducid: opts.ducid && Number(opts.ducid),
									label: opts.label,
								},
							});
							Swal.fire(t('userAdmin.success'), t('externalControlPanelAdmin.ducInfo'), 'success');
						} else {
							addEcp({
								variables: {
									locationid: opts.locationid,
									url: opts.url,
									ip: opts.ip,
									port: opts.port && Number(opts.port),
									ducid: opts.ducid && Number(opts.ducid),
									label: opts.label,
								},
							}).then(async (result) => {
								await addSensorGroup({
									variables: {
										name: opts.label,
										locationid: Number(opts.locationid),
										externalcontrolpanelid: Number(result.data.addExternalControlPanel.externalcontrolpanelid),
									},
								});

							});
						}
						setShowEditPopup(!showEditPopup);
					}}
				/>
			</div>

			<Dialog
				open={showEcp}
				onClose={() => setShowEcp(false)}
				onExited={() => setShownEcp()}
				PaperComponent={props => <>{props.children}</>}
			>
				{/* <ECPView ecp={shownEcp} /> */}
			</Dialog>

			<DialogWrapper noPadding hideBg dialogProps={{ open: showRegPopup, onClose: () => setShowRegPopup(false) }}>
				<div style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
					<ImportedRegisterList
						selectedReadRegs={selectedReadRegs}
						setSelectedReadRegs={setSelectedReadRegs}
						selectedWriteRegs={selectedWriteRegs}
						setSelectedWriteRegs={setSelectedWriteRegs}
						type='edit'
						ecpId={ecpsUnderEdit[0]?.externalcontrolpanelid}
						data={importedRegs.filter(reg => reg.externalcontrolpanelid === ecpsUnderEdit[0]?.externalcontrolpanelid)}
						canSave
						onSave={() => {
							setShowRegPopup(false);
							setShowEditPopup(!showEditPopup);
						}}
						onNewData={regs => {
							for (const reg of regs) {
								const oldReg = importedRegs.find(
									r => r.externalcontrolpanelid === reg.externalcontrolpanelid && r.address === reg.address
								);
								if (oldReg) editObject(oldReg, reg);
								else importedRegs.push(reg);
							}
							setImportedRegs([...importedRegs]);
						}}
					/>
				</div>
			</DialogWrapper>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loading')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</>
	);
}

export default connect(getStateVariables(STORE.properties, STORE.externalControlPanels, STORE.sensorGroups), { updateExternalControlPanels, setSensorGroups })(
	ExternalControlPanelAdministration
);
