import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { PAGES } from '../constants';
import { setPage } from '../redux/actionCreators';
import AlarmList from '../components/AlarmList';

function Alarms(props) {
	useEffect(() => {
		props.setPage(PAGES.alarms.id);
		// eslint-disable-next-line
	}, []);

	return <AlarmList />;
}

const AlarmsHoc = connect(null, { setPage })(Alarms);
export { AlarmsHoc as Alarms };
