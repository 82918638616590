import React, { useState, useEffect } from 'react';
import { safeSetState, getDifference } from '../utility-functions';
import MaterialTableWrapper from './MaterialTableWrapper';
import { format as formatDate } from 'date-fns';

const DEFAULTS = Object.freeze({ pageLength: 10 });

function processProps(props) {
	return {
		...props,
		localization: props.localization || {},
		data: props.data || [],
		dataId: props.dataId || 'id',
		preselectedIds: props.preselectedIds || [],
		selectedIds: props.selectedIds,
		disabledIds: props.disabledIds || [],
		columns: props.columns || [],
		pageSizeOptions: props.pageSizeOptions,
		onSelectionChange: props.onSelectionChange || (() => null),
		onSelectionChangeDiff: props.onSelectionChangeDiff || (() => null),
		onRowClick: props.onRowClick || undefined,
		style: props.style || {},
		tableProps: props.tableProps || {},
		clearSelectionsTrigger: props.clearSelectionsTrigger || 0,  // New prop for triggering clear selections
	};
}

/**
 * Displays a list of data that can be selected
 * @param {object} localization : Table-specific language strings, {title, nRowsSelected}
 * @param {array} data : Used to populate the rows of the table
 * @param {string} dataId : Property on data-object containing a unique identifier for the datum
 * @param {array} preselectedIds : Ids of data objects that should be selected on component initialization
 * @param {array} selectedIds : Ids of data objects that should be selected, overriding the default selection
 * @param {array} disabledIds : Ids of data objects that should be disabled
 * @param {array} columns : Passed to material-table
 * @param {number[]} pageSizeOptions : Available options for how many rows can be shown on one page
 * @param {(any[]) => void} onSelectionChange : Called when the set of selected data changes
 * @param {({added: any[], removed: any[]}) => void} onSelectionChangeDiff : Called when the set of selected data changes but only provides the difference
 * @param {function} onRowClick : Called when a row is clicked, parameters: (rowData: Object)
 * @param {boolean} showPagination : Always show Pagination-component, even when it's not needed
 * @param {object} style : Applied to the table
 * @param {object} tableProps : Props passed on to the material-table
 * @param {number} clearSelectionsTrigger : A prop to trigger clearing of selections
 */
function SelectionTable(_props) {
	const props = processProps(_props);

	const [selectedData, setSelectedData] = useState(props.data.filter(datum => props.preselectedIds.includes(datum[props.dataId])));
	const [hiddenSelectedData, setHiddenSelectedData] = useState([]);

	// Hide selected data that has disappeared from props.data and re-select data that has reappeared
	useEffect(() => {
		if (props.selectedIds) return;

		const filteredData = [],
			hiddenData = [];

		function splitArray(arrayToSplit) {
			for (const datum of arrayToSplit) {
				let found = false;
				for (const propDatum of props.data) {
					if (datum[props.dataId] === propDatum[props.dataId]) {
						filteredData.push(datum);
						found = true;
						break;
					}
				}
				if (!found) hiddenData.push(datum);
			}
		}

		splitArray(selectedData); // Remove selected data that do not exist in props.data any longer
		splitArray(hiddenSelectedData); // Add selected data that were previously hidden but have reappeared in props.data

		updateSelectedData(filteredData, hiddenData);
		// eslint-disable-next-line
	}, [props.data, props.dataId]);

	useEffect(() => {
		if (props.selectedIds) setSelectedData(props.data.filter(sel => props.selectedIds.includes(sel[props.dataId])));
	}, [props.data, props.dataId, props.selectedIds]);

	useEffect(() => {
		if (props.clearSelectionsTrigger) {
			clearSelections();
		}
		// eslint-disable-next-line
	}, [props.clearSelectionsTrigger]);

	function updateSelectedData(newData, newHiddenData) {
		const diff = getDifference(selectedData, newData, ele => ele[props.dataId]);
		if (diff.added.length || diff.removed.length) {
			props.onSelectionChange(newData);
			props.onSelectionChangeDiff(diff);
		}
		// Required to circumvent issue where all not-disabled rows are selected and the select-all button is pressed to de-select all but it can't because it tries to select the disabled rows instead
		// It's possible that this might still be called incorrectly when props.preselectedIds is set
		else if (
			props.data.filter(dat => props.disabledIds.includes(dat[props.dataId])).length &&
			newData.length &&
			newData.length === props.data.filter(dat => !props.disabledIds.includes(dat[props.dataId])).length
		) {
			updateSelectedData([]);
			return;
		}

		setSelectedData(newData);
		if (newHiddenData) safeSetState(hiddenSelectedData, newHiddenData, setHiddenSelectedData);
	}

	function clearSelections() {
		updateSelectedData([], selectedData);
		setHiddenSelectedData([]);

		props.onSelectionChange([]);
		props.onSelectionChangeDiff({ added: [], removed: selectedData });
	}

	return (
		<MaterialTableWrapper
			{...props.tableProps}
			title={props.localization.title}
			columns={props.columns}
			data={props.data.map(datum => {
				return {
					...datum,
					timestamp: datum.timestamp ? formatDate(new Date(datum.timestamp), 'yyyy-MM-dd HH:mm') : '',
					tableData: {
						checked: selectedData.some(sel => sel[props.dataId] === datum[props.dataId]),
					},
				};
			})}
			options={{
				selection: true,
				selectionProps: row => ({
					disabled: props.disabledIds.includes(row[props.dataId]),
					style: { left: '1.4rem', padding: '0.26rem' },
				}),
				[props.pageSizeOptions && 'pageSizeOptions']: props.pageSizeOptions,
				...props.tableProps.options,
			}}
			components={{
				[!props.showPagination && props.data.length <= DEFAULTS.pageLength && 'Pagination']: () => null,
				...props.tableProps.components,
			}}
			localization={{
				toolbar: {
					nRowsSelected: props.localization.nRowsSelected || '',
				},
				...props.tableProps.localization,
			}}
			onSelectionChange={rows => {
				updateSelectedData(rows?.filter(row => !props.disabledIds.includes(row[props.dataId])) || []);
			}}
			onRowClick={
				typeof props.onRowClick === 'function'
					? (e, row) => {
							props.onRowClick(row, e);
						}
					: undefined
			}
			style={{ width: '100%', overflow: 'hidden', ...props.style }}
		/>
	);
}

export default SelectionTable;
