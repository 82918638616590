import React from 'react';
import { Slider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import * as colors from '../colors';
import { getFormattedNumber } from '../utility-functions';

/**
 * Material-UI Slider component with standardized functionality and looks
 * @param {object} sliderProps : Passed directly to Slider component
 * @param {boolean} isVertical : Whether the slider should be vertical
 * @param {string} valuePostfix : Placed after the value in the thumb-popup
 * @param {object} style : Applied to wrapping container
 */
function SliderWrapper(props) {
	const sliderMakeStyle = makeStyles({
		thumb: {
			height: 22,
			width: 22,
			[props.isVertical && 'left']: 10,
			[!props.isVertical && 'top']: 10,
			[!props.isVertical && 'marginLeft']: -10,
			backgroundColor: '#fff',
			boxShadow: '0 2px 1px rgba(0,0,0,0.1),0 2px 8px rgba(0,0,0,0.2),0 0 0 1px rgba(0,0,0,0.2)',
			'&:focus,&:hover,&$active': {
				boxShadow: '0 2px 1px rgba(0,0,0,0.1),0 2px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.2)',
				// Reset on touch devices, it doesn't add specificity
				'@media (hover: none)': {
					boxShadow: '0 2px 1px rgba(0,0,0,0.1),0 2px 8px rgba(0,0,0,0.2),0 0 0 1px rgba(0,0,0,0.2)',
				},
			},
		},
		valueLabel: {
			left: '-5px !important',
			'& *': {
				background: colors.primary2,
			},
		},
		track: {
			[props.isVertical && 'width']: '5px !important',
			[!props.isVertical && 'height']: '6px !important',
			background: colors.primary0,
			borderRadius: 99,
		},
		rail: {
			[props.isVertical && 'width']: '6px !important',
			[!props.isVertical && 'height']: '6px !important',
			background: '#bbb',
			borderRadius: 99,
		},
		// Necessary to prevent warnings
		active: {},
	})();

	return (
		<div style={props.style}>
			<Slider
				valueLabelDisplay='auto'
				valueLabelFormat={val => getFormattedNumber(val) + (props.valuePostfix || '')}
				classes={sliderMakeStyle}
				{...props.sliderProps}
			/>
		</div>
	);
}

export default SliderWrapper;
