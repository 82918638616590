import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import DucSelection from './DucSelection';
import ImportedRegisterList from '../components/ImportedRegisterList';
import DucUploadButton from './DucUploadButton';

/**
 * Import functionality for uploading spreadsheet with sensor and register information
 */
function ExternalControlPanelAdministration(props) {
	const [selectedECP, setSelectedECP] = useState();
	const [fileName, setFileName] = useState();
	const [isInvalidFile, setIsInvalidFile] = useState();
	const [formattedSheet, setFormattedSheet] = useState();
	const [selectedReadRegs, setSelectedReadRegs] = useState([]);
	const [selectedWriteRegs, setSelectedWriteRegs] = useState([]);

	const { t } = useTranslation();

	return (
		<div style={{ margin: '1rem' }}>
			<div style={{ display: 'flex' }}>
				<h3 style={{ fontSize: '110%', fontWeight: '500', width: '4.4rem', margin: 'auto 0 auto' }}>{t('importAdmin.DUC')}</h3>
				<DucSelection setSelectedECP={setSelectedECP}></DucSelection>
			</div>
			<div style={{ display: 'flex', marginTop: '0.6rem' }}>
				<h3 style={{ fontSize: '110%', fontWeight: '500', width: '4.4rem', margin: 'auto 0 auto' }}>{t('importAdmin.file')}</h3>
				{isInvalidFile ? (
					<h3 style={{ fontSize: '110%', color: '#c00', margin: 'auto 1rem auto 1px' }}>{t('importAdmin.unkownFilePromt')}</h3>
				) : (
					fileName && <h3 style={{ fontSize: '110%', color: '#000d', margin: 'auto 1rem auto 1px' }}>{fileName}</h3>
				)}
				<DucUploadButton setFileName={setFileName} setFormattedSheet={setFormattedSheet} setIsInvalidFile={setIsInvalidFile}>
					{fileName || isInvalidFile ? t('importAdmin.change') : t('importAdmin.upload')}
				</DucUploadButton>
			</div>

			{formattedSheet && !isInvalidFile && (
				<ImportedRegisterList
					selectedReadRegs={selectedReadRegs}
					setSelectedReadRegs={setSelectedReadRegs}
					selectedWriteRegs={selectedWriteRegs}
					setSelectedWriteRegs={setSelectedWriteRegs}
					type='add'
					ecpId={selectedECP}
					data={formattedSheet}
					canSave={selectedECP}
					onSave={() => {
						setFileName();
						setFormattedSheet();
						props.closeWizard();
						Swal.fire(t('userAdmin.success'), t('importAdmin.registersAdded'), 'success');
					}}
					style={{ marginTop: '1.2rem' }}
				/>
			)}
		</div>
	);
}

export default ExternalControlPanelAdministration;
