import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IconButton, Dialog, DialogTitle, List, ListItem, ListItemText } from '@material-ui/core';
import { gql, useQuery } from '@apollo/client';
import { SettingsOutlined as SettingsIcon } from '@material-ui/icons';
import { useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { editObject } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import COView from './COView';
import { CustomDivider } from './CustomDivider';
import ImportedRegisterListAutomation from './ImportedRegisterListAutomation.js';
import DialogWrapper from './DialogWrapper';

const GET_IMPORTEDREGISTERS = gql`
    query ($filter: ImportedRegisterFilter!) {
        getImportedRegisters(filter: $filter) {
            externalcontrolpanelid
            objectname
            groupname
            parametername
            address
            canread
            canwrite
            read
            write
            automation
        }
    }
`;

const ADD_AI_CONTROL = gql`
mutation ($locationid: ID, $communicationtype: String, $ducid: ID, $name: String, $sensortype: String, $system: ID, $sensorid: String) {
    addAiControl(locationid: $locationid, communicationtype: $communicationtype, ducid: $ducid, name: $name, sensortype: $sensortype, system: $system, sensorid: $sensorid) {
      index
      locationid
	  ducid
      communicationtype
	  name
	  sensortype
	  system
	  sensorid
    }
  }
`;
function Curve(props) {
	const [showEditPopup, setShowEditPopup] = useState(false);
	const [showRegPopup, setShowRegPopup] = useState(false);
	const [importedRegs, setImportedRegs] = useState([]);
	const [selectedReadRegs, setSelectedReadRegs] = useState([]);
	const [selectedWriteRegs, setSelectedWriteRegs] = useState([]);
	const [showEcpSelectionPopup, setShowEcpSelectionPopup] = useState(false); // Added state for ecpId selection
	const [selectedEcpId, setSelectedEcpId] = useState(null); // Added state to store selected ecpId

	const { t } = useTranslation();

	const cos = props.cos
		.filter(co => co.locationid === String(props.currentProperty))
		.sort((a, b) => {
			const aCount = props.coRegisters.filter(cor => cor.coid === a.coid);
			const bCount = props.coRegisters.filter(cor => cor.coid === b.coid);
			return aCount > bCount || (aCount === bCount && a.name < b.name) ? -1 : 1;
		});

	const ecpOptions = [
		...props.externalControlPanels.filter(panel => panel.locationid === props.currentProperty.toString()).map(ecp => ({
			value: ecp.externalcontrolpanelid,
			label: (() => {
				return `${ecp.label || ecp.ip || ecp.url || '-'}`;
			})(),
		})),
	].filter(t => t.label.includes(props.citystreet ?? ''));


	useQuery(GET_IMPORTEDREGISTERS, {
		variables: { filter: { externalcontrolpanelids: props.externalControlPanels.map(ecp => ecp.externalcontrolpanelid) } },
		onCompleted: ({ getImportedRegisters }) => setImportedRegs(getImportedRegisters),
		fetchPolicy: 'no-cache',
	});

	const [addAiControl] = useMutation(ADD_AI_CONTROL, {
		onCompleted: () => console.log('AI control added.'),
		onError: (error) => console.error('Failed to add aicontrol.', error),
	});

	const externalIds = props.externalControlPanels
		.filter(panel => panel.locationid === props.currentProperty.toString())
		.map(panel => panel.externalcontrolpanelid);

	useEffect(() => {
		if (selectedEcpId) {
			const data = importedRegs.filter(reg => reg.externalcontrolpanelid === selectedEcpId);
			setSelectedReadRegs(data.filter(reg => reg.automation).map(reg => reg.address));
		}
	}, [showRegPopup, selectedEcpId]);


	return (
		<div>
			{cos.map((co, coI) => (
				<div style={{ margin: '1.5rem 1.5rem 1.5rem 2rem' }} key={co.coid}>
					{coI > 0 && <CustomDivider marginSideOffset={5} disableEndMargin />}
					<h1 style={{ marginBottom: '0.8rem', fontSize: '135%', display: 'inline-block' }}>{co.name}</h1>
					{coI === 0 && (
						<IconButton style={{ position: 'relative', top: 0, left: '10px' }}>
							<SettingsIcon onClick={() => {
								if (externalIds.length === 1) {
									setShowRegPopup(true);
								} else if (externalIds.length > 1) {
									setShowEcpSelectionPopup(true);
								}
							}} />
						</IconButton>
					)}
					<COView co={co} />
					<DialogWrapper noPadding hideBg dialogProps={{ open: showRegPopup, onClose: () => setShowRegPopup(false) }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', overflowY: 'auto' }}>
							<ImportedRegisterListAutomation
								selectedReadRegs={selectedReadRegs}
								setSelectedReadRegs={setSelectedReadRegs}
								selectedWriteRegs={selectedWriteRegs}
								setSelectedWriteRegs={setSelectedWriteRegs}
								type='edit'
								ecpId={selectedEcpId || externalIds[0]} // Use selectedEcpId if available, otherwise use the first ecpId
								data={importedRegs.filter(reg => reg.externalcontrolpanelid === selectedEcpId)}
								canSave
								onSave={() => {
									setShowRegPopup(false);
									setShowEditPopup(!showEditPopup);
									selectedReadRegs
										.filter(e => !selectedWriteRegs.some(f => f === e))
										.forEach(sen => {
											let readFormated = importedRegs.find(readReg => readReg.address === sen);
											addAiControl({
												variables: {
													locationid: props.currentProperty,
													ducid: selectedEcpId,
													name: readFormated ? readFormated.objectname : null,
													sensortype: readFormated ? readFormated.parametername : null,
												},
											});
										});
								}}
								onNewData={regs => {
									for (const reg of regs) {
										const oldReg = importedRegs.find(
											r => r.externalcontrolpanelid === reg.externalcontrolpanelid && r.address === reg.address
										);
										if (oldReg) editObject(oldReg, reg);
										else importedRegs.push(reg);
									}
									setImportedRegs([...importedRegs]);
								}}
							/>
						</div>
					</DialogWrapper>
				</div>
			))}
			{/* Dialog for selecting ecpId */}
			<Dialog open={showEcpSelectionPopup} onClose={() => setShowEcpSelectionPopup(false)}>
				<DialogTitle>{t('registrationWizard.selectDuc')}</DialogTitle>
				<List>
					{/* {externalIds.map(ecpId => (
						<ListItem button key={ecpId} onClick={() => {
							setSelectedEcpId(ecpId);
							setShowRegPopup(true);
							setShowEcpSelectionPopup(false);
						}}>
							<ListItemText primary={`EcpId: ${ecpId}`} />
						</ListItem>
					))} */}
					{ecpOptions.map(option => (
						<ListItem button key={option.value} onClick={() => {
							setSelectedEcpId(option.value);
							setShowRegPopup(true);
							setShowEcpSelectionPopup(false);
						}}>
							<ListItemText primary={`${option.label}`} />
						</ListItem>
					))}

				</List>
			</Dialog>
		</div>
	);
}

export default connect(getStateVariables(STORE.currentProperty, STORE.cos, STORE.coRegisters, STORE.externalControlPanels, STORE.properties))(Curve);
