import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Slider, Box } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import { MINUTES_PER_DAY, MINUTES_PER_WEEK } from '../../../constants';
import { format as formatDate } from 'date-fns';

// Helper function to convert numerical value to a date
const CHANGE_DELAY = 300;
const MILLISECONDS_PER_MINUTE = 1000 * 60;

const useStyles = makeStyles(theme => ({
	slider: {
		width: 'calc(100% - 300px)',
		margin: 'auto',
		marginLeft: '0.3%',
		top: '88%',
		position: 'relative',
	},
}));

const LargerTooltip = styled(Tooltip)({
	tooltip: {
		fontSize: '9px',
	},
});

const LargerMarksSlider = styled(Slider)({
	mark: {
		backgroundColor: 'red',
		height: 12,
		width: 4,
		marginTop: -5,
	},
	'&:hover .MuiSlider-valueLabel': {
		transform: 'scale(1.5)',
		transition: 'transform 0.1s ease-in-out',
		fontSize: '9px',
		textTransform: 'capitalize',
		textAlign: 'center',
	},
	'& .MuiSlider-mark': {
		height: 9,
		width: 2,
		backgroundColor: 'grey',
		position: 'absolute',
		top: '50%',
		transform: 'translateY(-50%)',
	},
});

function getDateStringFromUnixTimestamp(timestamp) {
	return new Date(timestamp).toISOString();
}

function getDateFromValue(value) {
	if (value) {
		return getDateStringFromUnixTimestamp(value);
	}
	return '';
}

function debounce(mainFunction, delay) {
	let timer;

	return function (...args) {
		clearTimeout(timer);

		timer = setTimeout(() => {
			mainFunction(...args);
		}, delay);
	};
}

const getLongestDataArray = chartData => {
	let longestArray = [];
	chartData.forEach(array => {
		if (array.data.length > longestArray.length) {
			longestArray = array.data;
		}
	});
	return longestArray;
};

const DateSlider = ({ chartData, dataIndex, dataIndexCallback, initialStartEndIndex, timeRange, interval, dataType }) => {
	const classes = useStyles();
	let dataArray = getLongestDataArray(chartData);
	dataArray = dataArray.length ? dataArray : initialStartEndIndex.reduce((acc, curr) => [...acc, { x: curr }], []);
	const [sliderValues, setSliderValues] = useState([0, dataArray.length - 1]);

	useEffect(() => {
		let sliderStart = dataArray.findIndex(value => value.x >= dataIndex[0]);
		let sliderEnd = dataArray.findLastIndex(value => value.x <= dataIndex[1]);
		setSliderValues([sliderStart === -1 ? 0 : sliderStart, sliderEnd === -1 ? dataArray.length - 1 : sliderEnd]);
		// eslint-disable-next-line
	}, [dataIndex]);

	const onChangeCallback = useCallback((start, end) => {
		dataIndexCallback(start, end);
		// eslint-disable-next-line
	}, []);

	const delayedOnChange = useMemo(() => {
		return debounce(onChangeCallback, CHANGE_DELAY);
	}, [onChangeCallback]);

	const handleChange = (event, newValue, activeThumb) => {
		setSliderValues(newValue);
		const startTimestamp = dataArray[newValue[0]].x;
		const endTimestamp = dataArray[newValue[1]].x;
		delayedOnChange([startTimestamp, endTimestamp]);
	};

	const formatTooltipDate = value => {
		if (value === undefined) {
			return '';
		}
		const date = new Date(value);

		if (dataType === 'raw') return formatDate(date, 'MM-dd HH:mm');

		switch (interval.type) {
			case 'hour':
				return formatDate(date, 'MM-dd HH:mm');
			case 'month':
				return formatDate(date, 'yyyy MM');
			case 'year':
				return formatDate(date, 'yyyy');
			default:
				return formatDate(date, 'yyyy MM-dd');
		}
	};

	return (
		<Box sx={{ width: 300 }}>
			<LargerMarksSlider
				data-cy='date-slider'
				getAriaLabel={() => 'Minimum distance'}
				className={classes.slider}
				max={dataArray.length - 1}
				value={sliderValues}
				onChange={handleChange}
				valueLabelFormat={value => formatTooltipDate(dataArray[value]?.x)}
				valueLabelDisplay='auto'
				components={{
					Tooltip: props => (
						<LargerTooltip {...props} arrow placement='top'>
							<span>{formatTooltipDate(dataArray[props.value].x)}</span>
						</LargerTooltip>
					),
				}}
			/>
		</Box>
	);
};

export { CHANGE_DELAY };
export default DateSlider;
