import React, { useState, useEffect } from 'react';
import { Button, Paper, Tabs, Tab, makeStyles } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { gql, useQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';

import SelectionTable from '../../SelectionTable';

const GET_SENSOR_VIEW = gql`
	query ($filter: SensorViewFilter) {
		getSensorView(filter: $filter) {
			sensorid
			name
			locationid
			groupname
		}
	}
`;

const CONTAINER_SHADOW = '0 0.1rem 0.5rem #0005';

const TABS = {
	properties: { label: i18n.t('constants.properties'), value: 'properties' },
	sensors: { label: i18n.t('constants.sensors'), value: 'sensors' },
	selected: { label: i18n.t('generic.selected'), value: 'selected' },
};

export function SensorSelectorTable({
	selectedProperties,
	graphSensors,
	changeSensorsCallback,
	closeCallback,
	maxSelectionCount = undefined,
}) {
	const { t } = useTranslation();
	const [properties, setProperties] = useState([]);
	const [selections, setSelections] = useState(
		graphSensors.map(sen => ({
			locationid: sen.locationid,
			sensorid: sen.sensorid.toString(),
		}))
	);
	const [selectionCounts, setSelectionCounts] = useState({});
	const [selectedTabId, setSelectedTabId] = useState(TABS.sensors.value);

	const { data } = useQuery(GET_SENSOR_VIEW, {
		variables: {
			filter: {
				locationids: selectedProperties.map(({ locationid }) => locationid),
			},
		},
	});

	const propertySensors = data?.getSensorView || [];

	useEffect(() => {
		if (data) {
			const props = [...selectedProperties.map(p => ({ ...p, sensors: [] }))];
			data.getSensorView.forEach(sen => {
				const prop = props.find(p => p.locationid === sen.locationid);
				if (prop) prop.sensors.push(sen);
				else props.push({ ...props, sensors: [sen] });
			});
			setProperties(props);
		}
	}, [data, selectedProperties]);

	useEffect(() => {
		setSelectionCounts({
			[TABS.properties.value]: [...new Set(selections.map(sel => sel.locationid))].length,
			[TABS.sensors.value]: [...new Set(selections.map(sel => sel.sensorid))].length,
			[TABS.selected.value]: [...new Set(selections.map(sel => sel.sensorid))].length,
		});
	}, [selections]);

	const handleSavePopup = () => {
		changeSensorsCallback(
			selections.map(sel => ({
				sensorname: sel.name,
				locationname: properties.find(({ locationid }) => sel.locationid === locationid).street,
				locationid: sel.locationid,
				sensorid: parseInt(sel.sensorid),
				groupname: sel.groupname,
			}))
		);
		closeCallback(true);
	};

	function updateSelection(diff, type) {
		let sel = [...selections];
		let removeFun = () => true;
		let addFun = () => false;

		if (type === TABS.properties.value) {
			removeFun = sel => !diff.removed.map(pro => pro.locationid).includes(sel.locationid);
			addFun = sen => {
				return diff.added.map(pro => pro.locationid).includes(sen.locationid);
			};
		} else {
			removeFun = sel => !diff.removed.map(pro => pro.sensorid).includes(sel.sensorid);
			addFun = sen => diff.added.map(pro => pro.sensorid).includes(sen.sensorid);
		}

		if (diff.removed.length) sel = sel.filter(removeFun);

		if (diff.added.length) {
			sel = sel.concat(
				propertySensors.filter(addFun).map(sen => {
					return {
						name: sen.name,
						sensorid: sen.sensorid,
						locationid: sen.locationid,
						groupname: sen.groupname,
					};
				})
			);
		}

		setSelections(sel);
	}

	const selectedLocations = selections.map(sel => sel.locationid);

	const propTableProps = {
		data: properties.map(pro => ({
			...pro,
			sensorCount: pro.sensors.length,
		})),
		dataId: 'locationid',
		selectedIds: selectedLocations,
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{ title: t('generic.address'), field: 'street' },
			{ title: t('constants.sensors'), field: 'sensorCount' },
		],
	};

	const selectedSensors = selections.map(sel => sel.sensorid);
	const senTableProps = {
		data: propertySensors.map(sen => ({
			...sen,
			...selectedProperties.find(prop => prop.locationid === sen.locationid),
		})),
		dataId: 'sensorid',
		selectedIds: selectedSensors,
		disabledIds:
			maxSelectionCount !== undefined && selections.length >= maxSelectionCount
				? propertySensors.map(({ sensorid }) => sensorid).filter(sensorid => !selectedSensors.includes(sensorid))
				: [],
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{
				title: t('generic.address'),
				field: 'street',
			},
			{
				title: t('constants.sensorGroups'),
				field: 'groupname',
				maxLength: 64,
			},
			{
				title: t('generic.name'),
				field: 'name',
				maxLength: 64,
			},
		],
	};

	const selectedTableProps = {
		data: selections.map(sen => ({
			...sen,
			...selectedProperties.find(prop => prop.locationid === sen.locationid),
			...propertySensors.find(prop => prop.sensorid === sen.sensorid),
		})),
		dataId: 'sensorid',
		selectedIds: selectedSensors,
		disabledIds: [],
		columns: [
			{ title: t('generic.city'), field: 'city', defaultSort: 'asc' },
			{
				title: t('generic.address'),
				field: 'street',
			},
			{
				title: t('constants.sensorGroups'),
				field: 'groupname',
				maxLength: 64,
			},
			{
				title: t('generic.name'),
				field: 'name',
				maxLength: 64,
			},
		],
	};

	return (
		<div style={{ display: 'grid' }}>
			{maxSelectionCount !== undefined ? (
				<MuiAlert style={{ marginBottom: '10px' }} elevation={6} severity='info'>
					{t('charts.maxSensorCount', { count: maxSelectionCount })}
				</MuiAlert>
			) : undefined}
			<div style={{ display: 'flex' }}>
				<SelectionTable
					{...(selectedTabId === TABS.properties.value
						? propTableProps
						: selectedTabId === TABS.sensors.value
							? senTableProps
							: selectedTableProps)}
					localization={{ title: '' }}
					pageSizeOptions={[10, 15, 20]}
					showPagination
					onSelectionChangeDiff={diff => updateSelection(diff, selectedTabId)}
					style={{ maxWidth: '60rem', minWidth: '60rem', minHeight: '29rem', overflow: 'hidden', boxShadow: CONTAINER_SHADOW }}
					tableProps={{
						maxColumnLength: 25,
						toolbarHeight: '48px',
						options: { pageSize: 10 },
					}}
				/>

				<div style={{ marginLeft: '1rem', position: 'relative' }}>
					<Paper style={{ boxShadow: CONTAINER_SHADOW }}>
						<Tabs
							orientation='vertical'
							indicatorColor='primary'
							value={selectedTabId}
							onChange={(e, i) => setSelectedTabId(i)}
							classes={makeStyles({
								indicator: {
									width: '6px',
									height: '38px !important',
									margin: '5px 0 5px 0',
									borderRadius: '0 9px 9px 0',
									right: 'unset',
								},
							})()}
						>
							{Object.values(TABS).map(tab => {
								if (maxSelectionCount !== undefined && tab.value === 'properties') {
									return null;
								}
								return <Tab {...tab} label={`${tab.label} (${selectionCounts[tab.value] || 0})`} key={tab.value} />;
							})}
						</Tabs>
					</Paper>

					<Paper
						style={{
							width: '100%',
							position: 'absolute',
							bottom: '0',
							padding: '0.6rem',
							display: 'grid',
							gap: '0.5rem',
							boxShadow: CONTAINER_SHADOW,
						}}
					>
						<Button onClick={() => closeCallback(false)} style={{ color: '#0008', boxShadow: '0 1px 0.2rem #0004' }}>
							{t('generic.cancel')}
						</Button>
						<Button
							disabled={!selections.length}
							onClick={handleSavePopup}
							color='primary'
							style={{ boxShadow: '0 1px 0.2rem #0004' }}
						>
							{t('generic.save')}
						</Button>
					</Paper>
				</div>
			</div>
		</div>
	);
}

export { GET_SENSOR_VIEW };
