import React, { useState } from 'react';
import { ButtonBack, ButtonSave, CategoryIcon } from './WizardComponents';
import { Cloud, FlashOn, Home, HomeWork, KeyboardArrowDown, KeyboardArrowUp, Router, Waves } from '@material-ui/icons';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function SummaryCard({ title, text, icon, style }) {
	return (
		// <Paper elevation={2} style={{ padding: '0.5rem', margin: '0.5rem 0 0.5rem 0', flexGrow: 1 }}>
		<div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem 0 0.5rem 0', flexGrow: 1, ...style }}>
			<CategoryIcon icon={icon} />
			<div style={{ margin: '0 0 0 0 1rem' }}>
				<Typography variant='h6' style={{ fontSize: '1rem' }}>
					{title}
				</Typography>
				<Typography variant='body1'>{text}</Typography>
			</div>
		</div>
		// </Paper>
	);
}

function Overview({ property, onFinish, onBack, options, ...props }) {
	const { bas, controlObject } = options?.bas || {};
	const { indoorSensors, maxThreshold, minThreshold } = options?.indoorTemperature || {};
	const { supplyTemp, outdoorTemp, electricity } = options?.utilitySensors || {};
	const [finsihedPressed, setFinishedPressed] = useState(false);
	const { t } = useTranslation();

	return (
		<>
			<div style={{ height: '70vh' }}>
				<h2>{t('constants.overview')}</h2>
				<hr style={{ width: '100%' }} />
				<div style={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{property?.street + ', ' + property?.city}</div>
				<div style={{ display: 'flex', padding: '1rem', width: '100%' }}>
					<div style={{ width: '50%' }}>
						<SummaryCard title='DUC' text={(bas?.label || '-') + ' | ' + (controlObject || '-')} icon={<Router />} />
						<SummaryCard
							title={t('indoorTemperatureSelection.indoorTempSensors')}
							text={indoorSensors?.length || '-'}
							icon={<Home />}
							style={{ marginTop: '1rem' }}
						/>
						<SummaryCard
							title={t('indoorTemperatureSelection.maxTemperature')}
							text={maxThreshold || '-'}
							icon={<KeyboardArrowUp />}
							style={{ marginTop: '0.5rem' }}
						/>
						<SummaryCard
							title={t('indoorTemperatureSelection.minTemperature')}
							text={minThreshold || '-'}
							icon={<KeyboardArrowDown />}
							style={{ marginTop: '0.5rem' }}
						/>
					</div>
					<div>
						<SummaryCard title={t('generic.supplyTemp')} text={supplyTemp?.name || '-'} icon={<Waves />} />
						<SummaryCard
							title={t('generic.outdoorTemp')}
							text={outdoorTemp?.name || '-'}
							icon={<Cloud />}
							style={{ marginTop: '0.5rem' }}
						/>
						<SummaryCard
							title={t('generic.electricity')}
							text={electricity?.name || '-'}
							icon={<FlashOn />}
							style={{ marginTop: '0.5rem' }}
						/>
						<SummaryCard
							title={t('constants.districtHeating')}
							text={electricity?.name || '-'}
							icon={<HomeWork />}
							style={{ marginTop: '0.5rem' }}
						/>
					</div>
				</div>
			</div>
			<div style={{ display: 'flex', justifyContent: 'right' }}>
				<ButtonBack onClick={() => onBack()} />
				<ButtonSave
					style={{ marginLeft: '1rem' }}
					onClick={() => {
						setFinishedPressed(true);
						onFinish();
					}}
					disabled={finsihedPressed}
				/>
			</div>
		</>
	);
}

export default Overview;
