import React, { useState } from 'react';

import Dialog from '../components/DialogWrapper';
import ImportAdministration from '../components/ImportAdministration';
import RegistrationWizard from '../components/RegistrationWizard';
import { DialogTitle, DialogActions, DialogContent, DialogContentText, TextField, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import * as colors from '../colors';
import i18n from '../i18n';

const WIZARDS = Object.seal({
	ducImport: { id: 'ducImport', label: i18n.t('wizardAdmin.DUCImport') },
	propertyAndSensors: { id: 'propertyAndSensors', label: i18n.t('wizardAdmin.propertyAndSensors') },
});

const systemPhoto = require('../assets/system-photo.jpg').default;
const propertyPhoto = require('../assets/property-photo.jpg').default;

function WizardAdministration() {
	const [selectedWizard, setSelectedWizard] = useState();
	const [isOpenConfirm, setIsOpenConfirm] = useState(false);

	function setsetSelectedWizard(status) {
		if (status === undefined) {
			setIsOpenConfirm(true);
		} else {
			setSelectedWizard(status);
		}
	}

	function getThumbnail(wizard, imageSrc, imageAlt) {
		return (
			<div
				onClick={() => setsetSelectedWizard(wizard.id)}
				style={{
					width: '13rem',
					height: '9.1rem',
					cursor: 'pointer',
					margin: '0 1.4rem 1.4rem 0',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					textAlign: 'center',
				}}
			>
				<h3 style={{ fontSize: '170%', fontWeight: '600', color: '#fff', textShadow: '0 0 0.5rem #000', zIndex: '1' }}>
					{wizard.label}
				</h3>
				<img
					src={imageSrc}
					alt={imageAlt}
					style={{
						position: 'absolute',
						width: 'inherit',
						height: 'inherit',
						objectFit: 'cover',
						borderRadius: '0.8rem',
						boxShadow: '0 0 6px #aaa',
					}}
				/>
			</div>
		);
	}

	return (
		<>
			<div style={{ display: 'flex', margin: '1rem 0 0 1rem' }}>
				{getThumbnail(WIZARDS.ducImport, systemPhoto, 'ducImport-thumbnail')}
				{getThumbnail(WIZARDS.propertyAndSensors, propertyPhoto, 'propertyAndSensors-thumbnail')}
			</div>

			<Dialog dialogProps={{ open: selectedWizard === WIZARDS.ducImport.id, onClose: () => setsetSelectedWizard() }}>
				<ImportAdministration closeWizard={() => { setIsOpenConfirm(false); setSelectedWizard(undefined); }}/>
			</Dialog>
			<Dialog dialogProps={{ open: selectedWizard === WIZARDS.propertyAndSensors.id, onClose: () => setsetSelectedWizard() }}>
			<RegistrationWizard closeWizard={() => { setIsOpenConfirm(false); setSelectedWizard(undefined); }} />
			</Dialog>

			<Dialog
				dialogProps={{
					open: isOpenConfirm,
					onClose: () => setIsOpenConfirm(false),
					classes: makeStyles({ paper: { minWidth: '18rem', maxWidth: '28rem', overflow: 'visible' } })(),
				}}
			>
				<DialogTitle>{i18n.t('confirmPopup.confirmPrompt')}</DialogTitle>

				<DialogContent style={{ padding: '0 1.5rem', overflow: 'visible' }}>
					<DialogContentText component='div' style={{ marginTop: '-0.5rem' }}>
						{i18n.t('confirmPopup.confirmsubtext')}
					</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button onClick={() => setIsOpenConfirm(false)} style={{color:colors.text}}>
						{i18n.t('generic.cancel')}
					</Button>
					<Button
						onClick={() => {
							setIsOpenConfirm(false);
							setSelectedWizard(undefined);
						}}
						style={{color:colors.text}}
					>
						{i18n.t('generic.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export default WizardAdministration;
