import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, Button } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export function NameTabBox({ isOpen, onClose, onConfirm }) {
	const [newTabName, setNewTabName] = useState('');

	const { t } = useTranslation();

	const handleTabNameChange = event => {
		setNewTabName(event.target.value);
	};

	const createTab = () => {
		newTabName.trim() === '' ? onClose() : onConfirm(newTabName.trim());
		setNewTabName('');
	};

	const closeBox = () => {
		onClose();
		setNewTabName('');
	};

	const handleKeyDown = event => {
		if (event.key === 'Enter') {
			event.preventDefault();
			createTab();
		}
	};

	return (
		<Dialog open={isOpen} onClose={closeBox}>
			<DialogTitle>{t('tabCreation.newTabTitle')}</DialogTitle>
			<div
				style={{
					position: 'absolute',
					top: 8,
					right: 8,
				}}
			>
				<IconButton aria-label='close' onClick={closeBox}>
					<Close />
				</IconButton>
			</div>
			<DialogContent>
				<DialogContentText>{t('tabCreation.nameTab')}</DialogContentText>
				<TextField
					label={t('tabDeletion.deleteConfirmationLabel')}
					value={newTabName}
					onChange={handleTabNameChange}
					fullWidth
					autoFocus
					onKeyDown={handleKeyDown}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={createTab} color='primary'>
					{t('tabCreation.createTabButton')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
