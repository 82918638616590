import React from 'react';

/**
 * Renders a custom divider according to props, while helping keep a standardized look across components
 * @param {Boolean} isVertical : Whether or not the divider should be vertically aligned
 * @param {Number} thicknessOffset : Make the divider thicker or thinner than the default,
 *                                   default: 0, thinner: negative Number, thicker: positive Number
 * @param {Boolean} disableMargin : Remove margin all margins
 * @param {Boolean} disableSideMargin : Remove margins on the side of the line
 * @param {Boolean} disableEndMargin : Remove margins at the ends of the line
 * @param {Number} marginOffset : Add or remove extra space around the divider,
 *                                default: 0, thinner: negative Number, thicker: positive Number
 * @param {Number} marginSideOffset : Add or remove extra space along the long side of the divider,
 *                                    default: 0, thinner: negative Number, thicker: positive Number
 * @param {String} color : Custom color to use instead of the default gray
 * @param {Object} style : Custom CSS-styling to apply
 */
function CustomDivider(props) {
	const isHorizontal = !props.isVertical;

	const thickness = 0.125 + 0.065 * (props.thicknessOffset || 0) + 'rem';

	const marginOffset = props.disableMargin ? 0 : 1.2 + 0.2 * (props.marginOffset || 0);
	const marginSideMultip = props.disableMargin ? 0 : 0.67 + 0.1 * (props.marginSideOffset || 0);

	const color = props.color || '#e8e8e8';

	return (
		<div
			style={{
				...{
					width: isHorizontal ? 'inherit' : thickness,
					height: isHorizontal ? thickness : `calc(100% - ${2 * marginOffset}rem)`,

					margin: isHorizontal
						? `${props.disableSideMargin ? 0 : marginSideMultip * marginOffset}rem ${
								props.disableEndMargin ? 0 : marginOffset
							}rem`
						: `${props.disableEndMargin ? 0 : marginOffset}rem ${
								props.disableSideMargin ? 0 : marginSideMultip * marginOffset
							}rem`,

					background: color,
				},
				...props.style,
			}}
		/>
	);
}

export { CustomDivider };
