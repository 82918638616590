import { createStore } from 'redux';
import rootReducer from './reducers';

const initialState = {
	hasFinishedLoading: false,
	userInfo: {},
	currentPage: '',
	currentTab: '',
	currentProperty: '',
	callbacks: {},
	sensors: [],
	properties: [],
	sensorGroups: [],
	digitalTwins: [],
	digitalTwinTags: [],
	digitalTwinPerspectives: [],
	cos: [],
	coRegisters: [],
	coRegisterOffsets: [],
	actions: [],
	fileRecords: [],
	alarmPeriodSensors: [],
	deviations: [],
	cameras: [],
	externalControlPanels: [],
};

export default createStore(
	rootReducer,
	initialState,
	process.env.NODE_ENV === 'development' ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__() : undefined
);
