import React, { useState } from 'react';
import { gql, useQuery } from '@apollo/client';

const GET_MATTERPORT_THUMBNAIL = gql`
	query ($models: [ID!]!) {
		getMatterportImageUrls(models: $models) {
			model
			url
		}
	}
`;

const PLACEHOLDER_IMG = require('../assets/placeholders/360thumbnail.png').default;

/**
 * Draws a clickable thumbnail for a Matterport model
 * @param {string} model : Id of the Matterport model
 * @param {string} label : Text shown on top of the thumbnail
 * @param {function} onClick : Called when the thumbnail is clicked
 */
function MatterportThumbnail(props) {
	const [imgSrc, setImgSrc] = useState(PLACEHOLDER_IMG);

	useQuery(GET_MATTERPORT_THUMBNAIL, {
		variables: { models: [props.model] },
		onCompleted: res => setImgSrc(res.getMatterportImageUrls?.[0]?.url || PLACEHOLDER_IMG),
	});

	return (
		<div
			onClick={() => props.onClick()}
			style={{
				width: '13rem',
				height: '9.1rem',
				cursor: 'pointer',
				margin: '0 1.4rem 1.4rem 0',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				textAlign: 'center',
			}}
		>
			<h3 style={{ fontSize: '170%', fontWeight: '600', color: '#fff', textShadow: '0 0 0.5rem #000', zIndex: '1' }}>
				{props.label}
			</h3>
			<img
				src={imgSrc}
				onError={() => setImgSrc(PLACEHOLDER_IMG)}
				alt='matterport-thumbnail'
				style={{
					position: 'absolute',
					width: 'inherit',
					borderRadius: '0.8rem',
					boxShadow: '0 0 6px #aaa',
				}}
			/>
		</div>
	);
}

export default MatterportThumbnail;
