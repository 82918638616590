import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Backdrop, LinearProgress } from '@material-ui/core';

import { getStateVariables, STORE } from '../redux/selectors';
import { setSensorGroups } from '../redux/actionCreators';
import { editObject } from '../utility-functions';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import { ConfirmPopup } from './ConfirmPopup';

const SET_SENSORGROUPS = gql`
	mutation ($sensorgroupids: [ID]!, $name: String, $locationid: Int) {
		setSensorGroups(sensorgroupids: $sensorgroupids, name: $name, locationid: $locationid) {
			sensorgroupid
			name
			locationid
		}
	}
`;
const ADD_SENSOR = gql`
	mutation ($name: String, $locationid: Int, $sensorref: String) {
		addSensor(name: $name, locationid: $locationid, sensorref: $sensorref) {
			sensorref
			name
			locationid
		}
	}
`;
const ADD_SENSORGROUP = gql`
	mutation ($name: String!, $locationid: Int!) {
		addSensorGroup(name: $name, locationid: $locationid) {
			sensorgroupid
			name
			locationid
		}
	}
`;
const SET_SENSORS = gql`
  mutation setSensors(
    $sensorids: [ID]!
    $locationid: Int
    $sensorgroupid: Int
  ) {
    setSensors(
      sensorids: $sensorids
      locationid: $locationid
      sensorgroupid: $sensorgroupid
    ) {
      sensorid
      locationid
	  sensorgroupid
    }
  }
`;
const REMOVE_SENSORGROUPS = gql`
	mutation ($sensorgroupids: [ID]) {
		removeSensorGroups(sensorgroupids: $sensorgroupids) {
			sensorgroupid
		}
	}
`;

const POPUP_TYPES = Object.freeze({ editGroup: 1, addGroup: 2 });

/**
 * A table for sensorgroups that allows them to be filtered and bulk-edited
 */
function SensorGroupAdministration(props) {
	const [selectedProperties, setSelectedProperties] = useState([]);
	const [selectedGroups, setSelectedGroups] = useState([]);
	const [groupsUnderEdit, setGroupsUnderEdit] = useState([]);
	const [editPopupType, setEditPopupType] = useState();
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [showConfirmDeletePopup, setShowConfirmDeletePopup] = useState(false);
	const [sensorgroupidsToDelete, setSensorgroupidsToDelete] = useState([]);
	const [isLoading, setIsLoading] = useState(true);

	const [setSensorGroups] = useMutation(SET_SENSORGROUPS, {
		onCompleted: ({ setSensorGroups }) => {
			for (const newGrp of setSensorGroups)
				editObject(
					props.sensorGroups.find(oldGrp => oldGrp.sensorgroupid === newGrp.sensorgroupid),
					newGrp
				);
			props.setSensorGroups([...props.sensorGroups]);
		},
	});
	const [setSensors] = useMutation(SET_SENSORS, {
		onCompleted: () => {
			//RELOAD WINDOW
			window.location.reload();
		}
	});
	const [addSensor] = useMutation(ADD_SENSOR, {
		onCompleted: () => {
			Swal.fire(t('userAdmin.success'), t('sensorGroupAdmin.sensorAdded'), 'success');
		},
	});

	const [addSensorGroup] = useMutation(ADD_SENSORGROUP, {
		onCompleted: ({ addSensorGroup }) => {
			Swal.fire(t('userAdmin.success'), t('sensorGroupAdmin.sensorGroupAdded'), 'success');
			props.setSensorGroups([...props.sensorGroups, addSensorGroup])
		},
	});

	const [removeSensorGroups] = useMutation(REMOVE_SENSORGROUPS, {
		onCompleted: ({ removeSensorGroups }) => {
			Swal.fire(t('userAdmin.success'), t('sensorGroupAdmin.sensorGroupDeleted'), 'success');
			props.setSensorGroups(
				props.sensorGroups.filter(sen => !removeSensorGroups.map(grp => grp.sensorgroupid).includes(String(sen.sensorgroupid)))
			);
		},
	});

	function onDeleteButtonClick() {
		setShowConfirmDeletePopup(true);
		setSensorgroupidsToDelete(selectedGroups.map(grp => grp.sensorgroupid));
	}

	const filteredGroups = (
		selectedProperties.length
			? props.sensorGroups.filter(grp => selectedProperties.some(pro => pro.value === grp.locationid))
			: props.sensorGroups
	).sort((a, b) => (a.locationid < b.locationid || (a.locationid === b.locationid && a.name < b.name) ? -1 : 1));
	const formattedProperties = props.properties
		.map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
		.sort((a, b) => (a.label < b.label ? -1 : 1));
	const { t } = useTranslation();

	// loading sensorGroups
	useEffect(() => {
		if (props.sensorGroups.length > 0) {
			setIsLoading(false);
		} else {
			const timer = setTimeout(() => {
				setIsLoading(false);
			}, 2000);
			return () => clearTimeout(timer);
		}
	}, [props.sensorGroups]);


	const updateSensorsLocation = (opts) => {
		// Extract the sensorgroupids of the selected groups
		const selectedGroupIds = selectedGroups.map(grp => grp.sensorgroupid);
		const selectedGroupIdsAsNumbers = selectedGroupIds.map(Number);
		const filteredSensors = props.sensors.filter(sen => selectedGroupIdsAsNumbers.includes(sen.sensorgroupid));
		// Perform mutation using filtered sensorids
		setSensors({
			variables: {
				sensorids: filteredSensors.map(sen => sen.sensorid),
				locationid: opts.locationid && Number(opts.locationid),
				sensorgroupid: opts.sensorgroupid && Number(opts.sensorgroupid)
			},
		});
	};

	return (
		<>
			<div style={{ margin: '1rem 1rem 1.5rem 1rem', display: 'flex' }}>
				<SelectionTable
					localization={{
						title: t('sensorGroupAdmin.sensorGroups'),
						nRowsSelected: t('sensorGroupAdmin.numberSelectedGroups') + '{0}',
					}}
					key={JSON.stringify(filteredGroups)}
					data={filteredGroups.map(grp => {
						const pro = props.properties.find(pro => pro.locationid === grp.locationid) || {};
						return {
							...grp,
							...pro,
							sensorCount: props.sensors.filter(
								sen => sen.locationid === grp.locationid && sen.sensorgroupid === Number(grp.sensorgroupid) ||
									(sen.sensorgroupid === null && sen.externalcontrolpanelid !== null && sen.externalcontrolpanelid === grp.externalcontrolpanelid)
							).length,
						};
					})}
					dataId='sensorgroupid'
					onSelectionChange={groups => setSelectedGroups(groups)}
					/* 				onRowClick={group => {
										setGroupsUnderEdit([group]);
										setEditPopupType(POPUP_TYPES.editGroup);
										setEditPopupEnabled(!editPopupEnabled);
									}} */
					columns={[
						{
							title: 'Namn',
							field: 'name',
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.name}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setSensorGroups({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												sensorgroupids: [rowData.sensorgroupid],
												name: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{
							title: t('generic.city'),
							field: 'city',
						},
						{
							title: t('generic.address'),
							field: 'street',
						},
						{
							title: t('sensorGroupAdmin.numberOfSensors'),
							field: 'sensorCount',
						},
					]}
					tableProps={{ maxColumnLength: 36 }}
				/>

				<SelectionSidebar
					buttons={[
						/* {
							label: t('genericAdmin.addSensor'),
							onClick: () => {
								setEditPopupType(POPUP_TYPES.addSensor);
								setEditPopupEnabled(!editPopupEnabled);
							},
						}, */
						{
							label: t('sensorGroupAdmin.createNewGroup'),
							onClick: () => {
								setEditPopupType(POPUP_TYPES.addGroup);
								setEditPopupEnabled(!editPopupEnabled);
							},
						},
						{
							label: t('genericAdmin.editSelected'),
							onClick: () => {
								setGroupsUnderEdit(selectedGroups);
								setEditPopupType(POPUP_TYPES.editGroup);
								setEditPopupEnabled(!editPopupEnabled);
							},
							disabled: !selectedGroups.length,
						},
						{
							label: t('genericAdmin.deleteSelected'),
							onClick: () => onDeleteButtonClick(),
							disabled: !selectedGroups.length,
						},
					]}
					filters={formattedProperties}
					updateSelectedFilters={props => setSelectedProperties(props || [])}
					localization={{
						filterTitle: t('sensorGroupAdmin.filterGroups'),
						filterPlaceholder: t('sensorGroupAdmin.selectProperties'),
					}}
					style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
				/>
			</div>

			<EditPopup
				text={
					editPopupType === POPUP_TYPES.editGroup
						? {
							title:
								groupsUnderEdit.length === 1
									? t('sensorGroupAdmin.editGroup') + (groupsUnderEdit[0].name || t('sensorGroupAdmin.withoutName'))
									: t('sensorGroupAdmin.editSelectedGroups', { length: groupsUnderEdit.length }),
							subtitle:
								groupsUnderEdit.length === 1
									? t('sensorGroupAdmin.enterNewValuesForGroup_one')
									: t('sensorGroupAdmin.enterNewValuesForGroup_any'),
							// t('sensorGorupAdmin.enterNewValuesForGroup', { count: groupsUnderEdit.length }),
						}
						:
						editPopupType === POPUP_TYPES.addSensor
							? {
								title: t('sensorGroupAdmin.addSensor'),
								subtitle: t('sensorGroupAdmin.enterValuesForSensor'),
							}
							: {
								title: t('sensorGroupAdmin.createNewGroupDot'),
								subtitle: t('sensorGroupAdmin.selectValuesNewGroup'),
							}
				}
				fields={
					editPopupType === POPUP_TYPES.editGroup
						? [
							{
								id: 'name',
								label: t('generic.name'),
								placeholder: groupsUnderEdit.length === 1 ? groupsUnderEdit[0].name : '...',
							},
							{


								id: 'locationid',
								label: t('generic.property'),
								options: formattedProperties.concat({ value: null, label: t('sensorAdmin.noProperty') }),
								placeholder:
									groupsUnderEdit.length === 1 && groupsUnderEdit[0].city
										? groupsUnderEdit[0].city + ': ' + groupsUnderEdit[0].street
										: '...',
								//onChange: locationid => setEditPopupSelectedPropertyId(locationid),
							},
						]
						:
						editPopupType === POPUP_TYPES.addSensor
							? [
								{
									id: 'sensorref',
									label: t('sensorAdmin.sensorRef'),
									placeholder: '...',
									required: true,
								},
								{
									id: 'name',
									label: t('generic.name'),
									placeholder: '...',
									required: true,
								},
								{
									id: 'locationid',
									label: t('generic.property'),
									placeholder: '...',
									options: formattedProperties,
									required: true,
								},
							]
							:
							[
								{
									id: 'name',
									label: t('generic.name'),
									placeholder: '...',
									required: true,
								},
								{
									id: 'locationid',
									label: t('generic.property'),
									placeholder: '...',
									options: formattedProperties,
									required: true,
								},
								{
									id: 'sensorgroupid',
									label: t('sensorAdmin.group'),
									placeholder: '...',
									required: true,
								},
							]
				}
				isOpen={editPopupEnabled}
				onClose={() => setEditPopupEnabled(!editPopupEnabled)}
				onSave={opts => {
					if (editPopupType === POPUP_TYPES.editGroup) {
						updateSensorsLocation(opts);
						setSensorGroups({
							variables: {
								sensorgroupids: groupsUnderEdit.map(grp => grp.sensorgroupid),
								name: opts.name,
								locationid: opts.locationid && Number(opts.locationid),
								sensorgroupid: opts.sensorgroupid && Number(opts.sensorgroupid),
							},
						});
						Swal.fire(t('userAdmin.success'), t('sensorGroupAdmin.sensorGroupInfo'), 'success');
					} else if (editPopupType === POPUP_TYPES.addSensor) {
						addSensor({
							variables: {
								sensorref: opts.sensorref,
								name: opts.name,
								locationid: Number(opts.locationid),
							},
						});
					}
					else {
						addSensorGroup({
							variables: {
								name: opts.name,
								locationid: Number(opts.locationid),
							},
						});
					}

					setEditPopupEnabled(!editPopupEnabled);
				}}

			/>

			<ConfirmPopup
				enabled={showConfirmDeletePopup}
				title={t('sensorGorupAdmin.confirmDeletion')}
				text={t('sensorGroupAdmin.confirmDeletePromt_any', { count: sensorgroupidsToDelete.length })}
				onFinish={suc => {
					if (suc)
						removeSensorGroups({
							variables: {
								sensorgroupids: sensorgroupidsToDelete,
							},
						});

					setShowConfirmDeletePopup(false);
					setSensorgroupidsToDelete([]);
				}}
			/>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loadingSensorGroups')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</>
	);
}

export default connect(getStateVariables(STORE.sensors, STORE.sensorGroups, STORE.properties, STORE.userInfo), { setSensorGroups })(
	SensorGroupAdministration
);
