import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PAGES } from '../constants';
import { setPage } from '../redux/actionCreators';

function Assignments(props) {
	useEffect(() => {
		props.setPage(PAGES.assignments.id);
	}, []);

	const { t } = useTranslation();
	return <div>{t('assignments.underConstruction')}</div>;
}

const AssignmentsHoc = connect(null, { setPage })(Assignments);
export { AssignmentsHoc as Assignments };
