import React, { useState, useEffect } from 'react';
import { makeStyles, Tabs, Tab } from '@material-ui/core';

import * as colors from '../colors';

/**
 * Draws a row of tabs with predefined style
 * @param {{label: string, id: string|number}[]} tabs : Individual tabs to be drawn
 * @param {string|number} currentTab : Override the natural current tab selection by explicitly controlling state
 * @param {(tabId) => void} onChange : Called when a tab is selected as well as when the component is initialized with the first tab
 */
function TabWrapper(props) {
	const [currentTab, setCurrentTab] = useState(props.tabs[0].id);

	// eslint-disable-next-line
	useEffect(() => props.onChange?.(props.tabs[0].id), []);

	return (
		<Tabs
			value={props.currentTab !== undefined ? props.currentTab : currentTab}
			onChange={(e, val) => {
				setCurrentTab(val);
				if (typeof props.onChange === 'function') props.onChange(val);
			}}
			classes={makeStyles({
				indicator: {
					backgroundColor: colors.bgcolor,
					bottom: '0.2rem',
				},
			})()}
		>
			{props.tabs?.map(tab => (
				<Tab
					label={tab.label}
					value={tab.id}
					disableRipple
					classes={makeStyles({
						root: {
							color: '#888',
							fontWeight: 900,
							minWidth: '6rem',
							textTransform: 'none',
							fontSize: '108%',
						},
						selected: {
							color: colors.bgcolor,
						},
					})()}
					key={tab.id}
				/>
			))}
		</Tabs>
	);
}

export default TabWrapper;
