import React from 'react';
import { connect } from 'react-redux';

import { getStateVariables, STORE } from '../redux/selectors';
import COView from './COView';
import { CustomDivider } from './CustomDivider';

/**
 * Shows a list of all COs registered to the current property
 */
function COTab(props) {
	const cos = props.cos
		.filter(co => co.locationid === String(props.currentProperty))
		.sort((a, b) => {
			const aCount = props.coRegisters.filter(cor => cor.coid === a.coid);
			const bCount = props.coRegisters.filter(cor => cor.coid === b.coid);
			return aCount > bCount || (aCount === bCount && a.name < b.name) ? -1 : 1;
		});

	return cos.map((co, coI) => (
		<div style={{ margin: '1.5rem 1.5rem 1.5rem 2rem' }} key={co.coid}>
			{coI > 0 && <CustomDivider marginSideOffset={5} disableEndMargin />}
			<h1 style={{ marginBottom: '0.8rem', fontSize: '135%' }}>{co.name}</h1>
			<COView co={co} />
		</div>
	));
}

export default connect(getStateVariables(STORE.currentProperty, STORE.cos, STORE.coRegisters))(COTab);
