import { Avatar, Button, Divider, makeStyles, Paper } from '@material-ui/core';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as colors from '../../colors';
import Select from '../SelectWrapper';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
	cancel: {
		color: colors.failure,
		borderColor: colors.failure,
		'&:hover': {
			backgroundColor: colors.failure,
			color: 'white',
			borderColor: colors.failure,
		},
	},
	continue: {
		color: colors.primary,
		borderColor: colors.primary,
		'&:hover': {
			backgroundColor: colors.primary,
			color: 'white',
			borderColor: colors.primary,
		},
	},
	select: {
		border: '1px solid lightgrey',
		borderRadius: '5px',
		padding: '0.5rem',
	},
	selectError: {
		border: '1px solid ' + colors.failure,
		borderRadius: '5px',
		padding: '0.5rem',
	},
}));

function ButtonBack(props) {
	const { t } = useTranslation();
	return (
		<Button color='primary' variant='outlined' {...props}>
			{t('generic.back')}
		</Button>
	);
}

function ButtonContinue(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Button className={classes.continue} color='primary' variant='outlined' {...props}>
			{t('registrationWizard.continue')}
		</Button>
	);
}

function ButtonSave(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Button className={classes.continue} color='primary' variant='outlined' {...props}>
			{t('generic.save')}
		</Button>
	);
}

function ButtonCancel(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<Button className={classes.cancel} color='primary' variant='outlined' {...props}>
			{t('generic.cancel')}
		</Button>
	);
}

function CategoryIcon(props) {
	const { icon, style } = props;
	return (
		<Avatar
			sx={{ width: 10, height: 20 }}
			style={{ backgroundColor: colors.primary, color: 'white', marginRight: '0.5rem', height: '3rem', width: '3rem', ...style }}
		>
			{icon}
		</Avatar>
	);
}

function SensorSelect(props) {
	const { children, icon, options, onSelectionChange, error, style, ...selectProps } = props;
	const classes = useStyles();
	const { t } = useTranslation();

	const handleSensorChange = sel => {
		if (onSelectionChange) {
			onSelectionChange(sel?.value || undefined);
		}
	};

	return (
		<div className={error ? classes.selectError : classes.select} style={{ display: 'flex', alignItems: 'center', ...style }}>
			<div style={{ display: 'flex', alignItems: 'center', width: '20%' }}>
				<Avatar
					sx={{ width: 10, height: 20 }}
					style={{ backgroundColor: colors.primary, color: 'white', marginRight: '0.5rem', height: '3rem', width: '3rem' }}
				>
					{icon}
				</Avatar>
				<div style={{ fontSize: '1rem' }}>{children}</div>
			</div>
			<div style={{ width: '78%' }}>
				<Select
					selectProps={{
						placeholder: '...',
						options: options,
						onChange: handleSensorChange,
						...selectProps,
					}}
					style={{ width: '100%', marginLeft: '1rem' }}
					selectStyle={{
						control: {
							fontSize: '1.2rem',
							boxShadow: '0 0 0 1px ' + error ? colors.failure : colors.primary,
						},
					}}
				/>
			</div>
		</div>
	);
}

function WizardAlert({ severity, children, style }) {
	return (
		<Alert severity={severity} style={{ marginTop: '1rem', fontWeight: 'bold', fontSize: '1rem', alignItems: 'center', ...style }}>
			{children}
		</Alert>
	);
}

export { ButtonBack, ButtonContinue, ButtonSave, ButtonCancel, SensorSelect, CategoryIcon, WizardAlert };
