import { Collapse, IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useEffect } from 'react';

export default function WizardAlert({
	open,
	style,
	severity = 'error',
	duration = 4000,
	variant = 'filled',
	onClose = () => null,
	children,
}) {
	useEffect(() => {
		if (open) {
			const timer = setTimeout(() => {
				onClose();
			}, duration);
			return () => clearTimeout(timer);
		}
		// eslint-disable-next-line
	}, [open]);

	return (
		<>
			{open ? (
				<Alert
					severity={severity}
					variant={variant}
					action={
						<IconButton aria-label='close' color='inherit' size='small' onClick={() => onClose()}>
							<Close fontSize='inherit' />
						</IconButton>
					}
					sx={{ mb: 2 }}
					style={{ zIndex: 1, position: 'absolute', ...style }}
				>
					{children}
				</Alert>
			) : undefined}
		</>
	);
}
