import React from 'react';
import { Link } from 'react-router-dom';

const DEFAULTS = Object.freeze({ label: 'Label', value: '1,234', color: '#aaa' }); // TODO: Translate

/**
 * A small info area with a header, value, and thick underline
 * @param {Object} style : Style to be applied to root div
 * @param {String} label : Header, displayed above value
 * @param {String} value : Value, displayed between header and underline
 * @param {Resoure-link} image : Image to be displayed alongside the other info
 * @param {URL-string} link : Where the object links to when clicked
 * @param {String} color : Affects the thick bottom underline
 */
function OverviewMiniInfo(props) {
	const content = (
		<>
			<div style={{ display: 'flex', position: 'relative' }}>
				<div>
					<h5 style={{ fontSize: '100%', fontWeight: '400' }}>{props.label || DEFAULTS.label}</h5>
					<h3 style={{ fontSize: '120%', fontWeight: '450', margin: '0.2rem 0 0.5rem 0' }}>
						{props.value !== null && props.value !== undefined ? props.value : DEFAULTS.value}
					</h3>
				</div>

				{props.image ? (
					<img
						alt='mini-info'
						src={props.image}
						style={{ width: '2.5rem', position: 'absolute', bottom: '0.5rem', right: '0.5rem' }}
					/>
				) : null}
			</div>

			<div style={{ width: '100%', height: '0.3rem', background: props.color || DEFAULTS.color, borderRadius: '0.15rem' }} />
		</>
	);

	return (
		<div style={props.style}>
			{props.link ? (
				<Link to={props.link} style={{ color: 'inherit', textDecoration: 'inherit' }}>
					{content}
				</Link>
			) : (
				content
			)}
		</div>
	);
}

export { OverviewMiniInfo };
