import React, { useEffect, useState, useReducer } from 'react';
import { useTranslation } from 'react-i18next';

import { useLazyQuery, useQuery, useMutation, gql } from '@apollo/client';
import { connect } from 'react-redux';

import TabHandler from '../components/charts/Tab/TabHandler';
import { TabPanel } from '../components/charts/Tab/TabPanel';

import { CENTER_CONTENT_STYLE, PAGES, MINUTES_PER_DAY } from '../constants';
import { setPage } from '../redux/actionCreators';

import { Backdrop, LinearProgress } from '@material-ui/core';
import { addMinutes, addHours } from 'date-fns';
import { getStartEndDateFromInterval } from '../utility-functions/intervalStartEndDate';

const GET_CHARTS = gql`
	query {
		getChartList
	}
`;

const GET_TABS = gql`
	query ($chartIds: [Int!]!) {
		getTabList(chartIds: $chartIds) {
			id
			chartid
			tabindex
			tabname
			updateinterval
			timerange
			includeinreport
			timestart
			timeend
			properties {
				locationid
				city
				street
			}
		}
	}
`;

const ADD_TAB = gql`
	mutation ($tabData: AddTab!) {
		addTab(tabData: $tabData) {
			id
			chartid
			tabname
			tabindex
			timestart
			timeend
			timerange
			updateinterval
		}
	}
`;

const REMOVE_TAB = gql`
	mutation ($id: Int!) {
		removeTab(id: $id) {
			id
		}
	}
`;

const UPDATE_TAB = gql`
	mutation ($tabData: UpdateTab!) {
		updateTab(tabData: $tabData) {
			id
			chartid
			tabindex
			tabname
			updateinterval
			timerange
			timestart
			timeend
			properties {
				locationid
				city
				street
			}
		}
	}
`;

function tabReducer(tabs, action) {
	let newTabs = tabs;
	switch (action.type) {
		case 'setTabs':
			newTabs = action.tabs;
			break;
		case 'addTab':
			newTabs = [...tabs, action.tab];
			break;
		case 'updateTab':
			const tabClone = [...tabs];
			tabClone[action.index] = { ...tabs[action.index], ...action.options };
			newTabs = tabClone;
			break;
		case 'deleteTab':
			newTabs = tabs.filter((tab, index) => index !== action.index);
			// Filter after id instead? When removing an element, every tab after it wont have to update.
			newTabs = newTabs.map((tab, index) => ({ ...tab, tabIndex: index }));
			break;
		default:
			break;
	}

	if (action.callback) {
		action.callback(newTabs);
	}

	return newTabs;
}

const AUTO_UPDATE_INTERVAL_OFF = 0;

function Charts(props) {
	const [selectedTabIdx, setSelectedTabIdx] = useState(0);
	const [tabs, setTabs] = useReducer(tabReducer, []);

	const { t } = useTranslation();

	const [callGetTabs, { loading: tabsLoading, error: tabsError }] = useLazyQuery(GET_TABS, {
		onCompleted: data => {
			const tabData = data.getTabList.map(tab => {
				const { startDate, endDate } = getStartEndDateFromInterval(tab.timerange);
				return {
					id: tab.id,
					chartId: tab.chartid,
					tabName: tab.tabname,
					tabIndex: tab.tabindex,
					includeInReport: tab.includeinreport,
					updateInterval: tab.updateinterval,
					offset: tab.timerange,
					startDate: tab.timerange == null ? new Date(parseInt(tab.timestart)) : startDate,
					endDate: tab.timerange == null ? new Date(parseInt(tab.timeend)) : endDate,
					properties: tab.properties,
				};
			});
			setTabs({ type: 'setTabs', tabs: tabData });
		},
		fetchPolicy: 'no-cache',
	});

	const { loading: chartLoading, error: chartError } = useQuery(GET_CHARTS, {
		onCompleted: data => {
			callGetTabs({ variables: { chartIds: data.getChartList } });
		},
		fetchPolicy: 'no-cache',
	});

	const [updateTab] = useMutation(UPDATE_TAB);
	const [removeTab] = useMutation(REMOVE_TAB);
	const [addTab] = useMutation(ADD_TAB, {
		onCompleted: ({ addTab }) => {
			setTabs({
				type: 'addTab',
				tab: {
					id: addTab.id,
					chartId: addTab.chartid,
					tabName: addTab.tabname,
					tabIndex: addTab.tabindex,
					includeInReport: addTab.includeinreport,
					updateInterval: AUTO_UPDATE_INTERVAL_OFF,
					backgroundcolor: addTab.backgroundcolor,
					offset: MINUTES_PER_DAY,
					startDate: new Date(parseInt(addTab.timestart)),
					endDate: new Date(parseInt(addTab.timeend)),
					properties: [],
				},
				callback: newTabs => {
					setSelectedTabIdx(newTabs.length - 1);
				},
			});
		},
	});

	useEffect(() => {
		props.setPage(PAGES.charts.id);
		// eslint-disable-next-line
	}, []);

	const handleDeleteTab = index => {
		if (tabs.length <= 1) return;

		removeTab({
			variables: {
				id: tabs[index].id,
			},
		});
		setTabs({
			type: 'deleteTab',
			index: index,
			callback: newTabs => {
				newTabs.slice(index).forEach(tab =>
					updateTab({
						variables: {
							tabData: {
								id: tab.id,
								tabindex: tab.tabIndex,
								tabname: tab.tabName,
								backgroundcolor: null,
								backgroundurl: null,
								updateinterval: tab.updateInterval,
								timerange: tab.offset || null,
								timestart: tab.startDate || null,
								timeend: tab.endDate || null,
								properties: tab.properties.map(property => property.locationid),
							},
						},
					})
				);
			},
		});
	};

	const handleCreateTab = tabName => {
		const now = new Date();
		addTab({
			variables: {
				tabData: {
					tabindex: tabs.length,
					tabname: tabName,
					backgroundcolor: null,
					backgroundurl: null,
					updateinterval: AUTO_UPDATE_INTERVAL_OFF,
					includeinreport: false,
					timerange: MINUTES_PER_DAY,
					timestart: addMinutes(now, -MINUTES_PER_DAY),
					timeend: now,
					properties: [],
				},
			},
		});
	};

	const handleUpdateTab = (index, options) => {
		const tabClone = [...tabs];
		setTabs({
			type: 'updateTab',
			index: index,
			options: options,
		});

		let tab = tabClone[index];
		if (tab === undefined) return;
		tab = { ...tab, ...options };
		updateTab({
			variables: {
				tabData: {
					id: tab.id,
					tabindex: tab.tabIndex,
					tabname: tab.tabName,
					backgroundcolor: null,
					backgroundurl: null,
					updateinterval: tab.updateInterval,
					includeinreport: tab.includeInReport,
					timerange: tab.offset || null,
					timestart: addHours(tab.startDate, 2) || null,
					timeend: addHours(tab.endDate, 2) || null,
					properties: tab.properties.map(property => property.locationid),
				},
			},
		});
	};

	const handleChangeTab = newTab => {
		setSelectedTabIdx(newTab);
	};

	if (chartError || tabsError) {
		return `${t('charts.fetchingError')} ${chartError?.message || tabsError?.message}`;
	}

	return (
		<>
			<div
				style={{
					display: 'flex',
					flexDirection: 'column',
					width: CENTER_CONTENT_STYLE.width,
					height: `calc(100vh - 6rem)`,
				}}
			>
				<Backdrop open={tabsLoading || chartLoading} style={{ zIndex: '100' }}>
					<div
						style={{
							padding: '0.9rem 1rem 1rem',
							fontSize: '112%',
							fontWeight: '300',
							textAlign: 'center',
							background: '#fff',
							borderRadius: '0.3rem',
							boxShadow: '0rem 0.1rem 0.8rem #000c',
						}}
					>
						{t('charts.analysisLoadingMsg')}
						<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
					</div>
				</Backdrop>
				<div
					id='header'
					style={{
						flex: 1,
						//border: '1px solid black', // Toggle line to see div border
						borderRadius: '0.3rem',
						margin: '0 0 5px 0',
						left: '0%',
						position: 'relative',
						width: 'calc(65rem + 10rem)',
					}}
				>
					<TabHandler
						tabs={tabs}
						selectedTab={selectedTabIdx}
						changeTabCallback={handleChangeTab}
						updateTabCallback={handleUpdateTab}
						createTabCallback={handleCreateTab}
						deleteTabCallback={handleDeleteTab}
					/>
				</div>

				<div
					id='gridstack'
					style={{
						flex: 11,
						// border: '1px solid black', // Toggle line to see div border
					}}
				>
					{tabs.map((tab, index) => {
						return (
							<TabPanel
								key={tab.id}
								selectedTabIdx={selectedTabIdx}
								tab={tab}
								tabIndex={index}
								updateTabCallback={handleUpdateTab}
							/>
						);
					})}
				</div>
			</div>
		</>
	);
}
const ChartsHoc = connect(null, { setPage })(Charts);
export { ChartsHoc as Charts };
