import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getStateVariables, STORE } from '../redux/selectors';
import { SensorGraphResults } from './SensorGraphResults';
import GaugeComponent from 'react-gauge-component'
import { gql, useQuery } from '@apollo/client';
import { getFormattedNumber } from '../utility-functions';
import { useTranslation } from 'react-i18next';

const GET_AI_CONTROL = gql`
  query GetAiControls($filter: AiControlFilter) {
    getAiControls(filter: $filter) {
      index
      locationid
      system
      ducid
      name
      sensorid
      communicationtype
      sensortype
      value
      minimum
      maximum
    }
  }
`;

const GET_AILOGG_VIEW = gql`
  query GetAiloggView($AiloggViewFilter: AiloggViewFilter) {
    getAiloggView(filter: $AiloggViewFilter) {
     timestamp
      locationid
      system
      ducid
      forecast
      insidetempcode
      curvechange
      indooraverage
      comment
    }
  }
`;


function Results(props) {
  const [curvechange, setCurvechange] = useState(0);

  const { t } = useTranslation();

  const AiLoggViewQuery = useQuery(GET_AILOGG_VIEW, {
    variables: {
      filter: {
        locationid: [props.currentProperty],
      },
    },
  });
  //set curve change
  useEffect(() => {
    if (AiLoggViewQuery.data?.getAiloggView) {
      const matchingLog = AiLoggViewQuery.data.getAiloggView.find(log => log.locationid === props.currentProperty);
      if (matchingLog) {
        setCurvechange(matchingLog.curvechange);
      }
    }
  }, [AiLoggViewQuery.data?.getAiloggView]);


  const AiControlQuery = useQuery(GET_AI_CONTROL, {
    variables: {
      filter: {
        locationid: [props.currentProperty],
        // Add other filters if needed
      },
    },
  });
  const filteredAiControl = AiControlQuery.data?.getAiControls.filter(control => control.sensortype !== 'indoortemp' && control.sensortype !== 'electricity') || [];

  const location_sensors = filteredAiControl.filter(control => control.locationid === props.currentProperty.toString()).filter(control => control.communicationtype === 'sensor');
  const prematchedSensors = location_sensors.filter(control => control.sensortype !== 'maxtemp' && control.sensortype !== 'mintemp');
  const matchedSensors = prematchedSensors.map(control => props.sensors.find(sensor => sensor.sensorid === control.sensorid)).filter(sensor => sensor);

  const filteredAiControls = AiControlQuery.data?.getAiControls.filter(control => control.sensortype === 'indoortemp' && control.locationid === props.currentProperty.toString()) || [];
  const matchedSensorss = filteredAiControls.map(control => props.sensors.find(sensor => sensor.sensorid === control.sensorid)).filter(sensor => sensor);

  const average = matchedSensorss.length > 0
    ? getFormattedNumber(matchedSensorss.reduce((sum, sensor) => sum + sensor.value, 0) / matchedSensorss.length)
    : undefined;

  const electricitySensor = AiControlQuery.data?.getAiControls.filter(sensor => sensor.sensortype === 'electricity' && sensor.locationid === props.currentProperty.toString()) || [];
  const propertyElectricitySensors = props.sensors.filter(sen => sen.locationid === props.currentProperty);
  const matchedElectricitySensors = propertyElectricitySensors.filter(propertySensor =>
    electricitySensor.some(aiControl => aiControl.sensorid === propertySensor.sensorid)
  );

  const adjustedSensorValue = average > 25 ? average : 25;

  return (
    props.currentProperty === props.currentProperty ? (
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {matchedElectricitySensors.map((sensor) => (
          <div
            key={sensor.sensorid}
            style={{
              flex: '0 0 50%',
              maxWidth: '100%',
              height: '100%'
            }}
          >
            <SensorGraphResults sensorInfo={sensor} showTitle showValue isVisible={true} />


          </div>
        ))}
        <div>
          <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: 'black' }}>
          {t('automations.curveChange')}
          </div>


          <GaugeComponent
            type="radial"
            arc={{
              width: 0.2,
              padding: 0.025,
              cornerRadius: 1,
              gradient: true,
              subArcs: [
                { limit: -2, color: '#ADD8E6', showTick: true },
                { limit: -1, color: '#ADD8E6', showTick: true },
                { limit: 0, color: '#ADD8E6', showTick: true },
                { limit: 1, color: '#ADD8E6', showTick: true },
                { limit: 2, color: '#ADD8E6', showTick: true }
              ]
            }}
            pointer={{
              color: '#345243',
              length: 0.80,
              width: 15,
            }}
            labels={{
              valueLabel: { formatTextValue: value => value + 'ºC' },
              tickLabels: {
                type: 'outer',
                valueConfig: { formatTextValue: value => value + 'ºC', fontSize: 10 },
                ticks: [
                  { value: -2 },
                  { value: -1 },
                  { value: 0 },
                  { value: 1 },
                  { value: 2 }
                ],
              }
            }}
            value={curvechange || 0} // example negative value
            minValue={-2}
            maxValue={2}
            style={{ width: '250px', height: '200px' }}
          />

        </div>
        <div /* style={{ display: 'flex', flexWrap: 'wrap',  backgroundColor: '#505050',  margin: '30px' }} */>
          {/*  <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: 'black' }}> */}
          <div style={{ textAlign: 'center', marginTop: '10px', fontSize: '14px', color: 'black' }}>
          {t('automations.averageIndoorTemperture')}
          </div>
          <GaugeComponent
            type="radial"
            arc={{
              width: 0.2,
              padding: 0.005,
              cornerRadius: 1,
              gradient: true,
              subArcs: [
                {
                  limit: 19,
                  color: '#EA4228',
                  showTick: true,
                  tooltip: {
                    text: 'Too low temperature!'
                  },
                },
                {
                  limit: 20.5,
                  color: '#F5CD19',
                  showTick: true,
                  tooltip: {
                    text: 'Low temperature!'
                  }
                },
                {
                  limit: 22.5,
                  color: '#5BE12C',
                  showTick: true,
                  tooltip: {
                    text: 'Normal temperature!'
                  }
                },
                {
                  limit: 24, color: '#F5CD19', showTick: true,
                  tooltip: {
                    text: 'High temperature!'
                  }
                },
                {
                  color: '#EA4228',
                  tooltip: {
                    text: 'Too high temperature!'
                  }
                }
              ]
            }}
            pointer={{
              color: '#345243',
              length: 0.80,
              width: 15,
              // elastic: true,
            }}
            labels={{
              valueLabel: { formatTextValue: value => value + 'ºC' },
              tickLabels: {
                type: 'outer',
                valueConfig: { formatTextValue: value => value + 'ºC', fontSize: 10 },
                ticks: [
                  { value: 18 },
                  { value: average },
                  { value: 23 }
                ],
              }
            }}
            value={average || 21}
            minValue={17.5}
            maxValue={adjustedSensorValue}
            style={{ height: '200px', width: '260px', justifyContent: 'flex-end' }}
          />
        </div>
        {matchedSensors.map((sensor, index) => (
          <div
            key={sensor.sensorid}
            style={{
              flex: index === 10 ? '0 0 70%' : '0 0 50%',
              maxWidth: index === 10 ? '70%' : '50%',
              height: index === 10 ? 'auto' : '10%'
            }}
          >
            <SensorGraphResults sensorInfo={sensor} showTitle isVisible={true} />

          </div>
        ))}

      </div>
    ) : null

  );

}

export default connect(getStateVariables(STORE.sensors, STORE.currentProperty))(Results);
