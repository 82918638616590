import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import chroma from 'chroma-js';
import { useTranslation } from 'react-i18next';

import { PAGES } from '../constants';
import * as colors from '../colors';
import SmallBarchart from '../components/SmallBarchart';
import { getPointTrend, combineTimeframeData } from '../utility-functions';
import i18n from '../i18n';

const CHART_LENGTH = 5;
const POSITIVE = {
	header: i18n.t('trendsChart.worstEnergyTrend'), //TODO: Are these the right way around?
	colors: [
		chroma(colors.secondaryD).darken(1.6).hex(),
		chroma(colors.secondaryD).darken(1.2).hex(),
		chroma(colors.secondaryD).darken(0.8).hex(),
		chroma(colors.secondaryD).darken(0.4).hex(),
		colors.secondaryD,
	],
};
const NEGATIVE = {
	header: i18n.t('trendsChart.bestEnergyTrend'),
	colors: [
		chroma(colors.secondaryC).darken(1.6).hex(),
		chroma(colors.secondaryC).darken(1.2).hex(),
		chroma(colors.secondaryC).darken(0.8).hex(),
		chroma(colors.secondaryC).darken(0.4).hex(),
		colors.secondaryC,
	],
};

/**
 * Chart with information about which properties' energy consumption are trendin up or down
 * @param {Boolean} negativeTrend : Whether to display negative or positive trending properties
 * @param {Enum/Object} selectedInterval : Info about the time-interval for which to display data
 * @param {Array of Objects} sensors : Sensors to accumulate price-data from, contains data embedded as a key
 * @param {Object} localization : Change various strings, {header, subheader}
 * @param {Object} size : Set the proportions of the graph, {width: String, height: String}
 * @param {Object} style : Passed down to the container of the text and chart
 * @param {Object} chartProps : Additional props that will be passed directly to the chart
 */
function TrendsChart(props) {
	const [chartData, setChartData] = useState([]);

	const routerHistory = useHistory();

	const { t } = useTranslation();

	useEffect(() => {
		// Convert sensor-array into property-array, containing data of all sensors
		const properties = [];
		for (const sen of props.sensors) {
			const pro = properties.find(pro => pro.locationid === sen.locationid);
			if (pro) pro.data.push(sen.data);
			else properties.push({ ...sen, data: [sen.data], combinedData: [], id: `${sen.city}: ${sen.street}` });
		}

		// Combine the aggregated data of sensors of the same property
		for (const pro of properties)
			for (const dat of pro.data)
				combineTimeframeData(pro.combinedData, dat, ['unitVal'], props.selectedInterval.dateFormat, {}, false);

		// Convert combinedData to chart-data format
		const unfilteredChartData = properties.map(pro => ({
			label: pro.id,
			value: getPointTrend(
				// Sorting required by getPointTrend
				pro.combinedData
					.sort((a, b) => (new Date(a.date) < new Date(b.date) ? -1 : 1))
					.map(dat => ({ x: dat.date, y: dat.unitVal || null }))
			),
			locationid: pro.locationid,
		}));

		// Filter pos/neg trends, sort by value, pad chart with empty data
		const filteredChartData = unfilteredChartData
			.filter(dat => (props.negativeTrend ? dat.value < 0 && dat.value > -90 : dat.value > 0 && dat.value < 1000))
			.sort((a, b) => (Math.abs(a.value) < Math.abs(b.value) ? -1 : 1));
		while (filteredChartData.length < CHART_LENGTH)
			filteredChartData.unshift({ label: ' '.repeat(filteredChartData.length), value: Number.MIN_VALUE });

		setChartData(filteredChartData);
		// eslint-disable-next-line
	}, [props.sensors]);

	function onClick(val, isTick = false) {
		// Check for `=== true` required as an object might be unintentionally passed as the second argument
		const locationid = Number((isTick === true ? val.value : val.data.label)?.split(';')[1]);
		if (locationid) routerHistory.push(`/${PAGES.properties.id}/${locationid}`);
	}

	return (
		<SmallBarchart
			header={props.localization?.header || (props.negativeTrend ? NEGATIVE : POSITIVE).header}
			subheader={
				props.localization?.subheader || t('trendsChart.duringLatest', { interval: props.selectedInterval.label.toLowerCase() })
			}
			colors={(props.negativeTrend ? NEGATIVE : POSITIVE).colors}
			// Swap all negative values to positive, to ensure charts are rendered as expected, and cut the length of the array
			data={chartData
				.map(dat => ({ label: `${dat.label};${dat.locationid}`, value: Math.abs(dat.value) }))
				.slice(Math.max(0, chartData.length - CHART_LENGTH))}
			valuePrefix={props.negativeTrend ? '-' : '+'}
			unit='%'
			size={props.size}
			style={props.style}
			chartProps={{ onClick: onClick, ...props.chartProps }}
		/>
	);
}

export default TrendsChart;
