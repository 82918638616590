/**
 * Adds new properties to an object, overwriting existing ones
 * @param {object} object : Object to which `newProperties` are added
 * @param {object} newProperties : Contains the new properties to be added to `object`
 * @returns {object} : `object` with `newProperties` added
 */
function editObject(object, newProperties) {
	if (!object || !newProperties) return;
	for (const [key, val] of Object.entries(newProperties)) object[key] = val;
	return object;
}

export default editObject;
