import React, { useState } from 'react';
import { connect } from 'react-redux';
import { format as formatDate } from 'date-fns';
import { gql, useQuery, useMutation, useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';

import { PAGES, CENTER_CONTENT_STYLE, ACTION_TYPES, UNIT_CLASSIFICATIONS, CO_CLASSIFICATIONS } from '../constants';
import { getFormattedNumber, drawTruncatedStr } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import { setActions, updateActions } from '../redux/actionCreators';
import MaterialTable from './MaterialTableWrapper';
import DialogWrapper from './DialogWrapper';
import EditableText from './EditableText';

const GET_ACTIONS = gql`
	query {
		getActions {
			actionid
			actiontype
			userid
			timestamp
			targetid
			subtargetid
			value
			comment
		}
	}
`;
const GET_KEYCLOAKUSERS = gql`
	query ($filter: KeycloakUserFilter!) {
		getKeycloakUsers(filter: $filter) {
			id
			firstName
			lastName
		}
	}
`;
const SET_ACTIONS = gql`
	mutation ($actionids: [ID!]!, $comment: String) {
		setActions(actionids: $actionids, comment: $comment) {
			actionid
			comment
		}
	}
`;

function ActionList(props) {
	const [showCommentPopup, setShowCommentPopup] = useState(false);
	const [selectedActionId, setSelectedActionId] = useState();

	const { t } = useTranslation();

	const apolloClient = useApolloClient();
	useQuery(GET_ACTIONS, {
		skip: apolloClient.queryManager?.mutationIdCounter > 1, // Counter starts at 1
		onCompleted: ({ getActions }) => {
			if (!getActions) return;
			props.setActions(getActions);
			keycloakUserQuery.refetch({ filter: { ids: [...new Set(getActions.map(dat => dat.userid))] } });
		},
		fetchPolicy: 'no-cache', // Required as otherwise query will not trigger
	});
	const keycloakUserQuery = useQuery(GET_KEYCLOAKUSERS, {
		skip: !props.actions.length,
		variables: { filter: { ids: [...new Set(props.actions.map(dat => dat.userid))] } },
	});
	const [setActions] = useMutation(SET_ACTIONS, {
		onCompleted: ({ setActions }) => props.updateActions(setActions),
	});

	const isGlobal = props.currentPage === PAGES.actions.id;
	const selectedAction = props.actions.find(act => act.actionid === selectedActionId);

	function getPropertyFromAction(action) {
		let locationid;
		if ([ACTION_TYPES.setSensor.id].includes(action.actiontype))
			locationid = props.sensors.find(sen => String(sen.sensorid) === String(action.targetid))?.locationid;
		if ([ACTION_TYPES.setSensorGroup.id, ACTION_TYPES.addSensorGroup.id].includes(action.actiontype))
			locationid = props.sensorGroups.find(grp => String(grp.sensorgroupid) === String(action.targetid))?.locationid;
		if ([ACTION_TYPES.setSensorLocation.id, ACTION_TYPES.addSensorLocation.id].includes(action.actiontype))
			locationid = action.targetid;
		if ([ACTION_TYPES.setDigitalTwin.id, ACTION_TYPES.addDigitalTwin.id].includes(action.actiontype))
			locationid = props.digitalTwins.find(dt => String(dt.digitaltwinid) === String(action.targetid))?.locationid;
		if (
			[ACTION_TYPES.setDigitalTwinTag.id, ACTION_TYPES.addDigitalTwinTag.id, ACTION_TYPES.removeDigitalTwinTag.id].includes(
				action.actiontype
			)
		)
			locationid = props.digitalTwins.find(
				dt =>
					String(dt.digitaltwinid) ===
					String(props.digitalTwinTags.find(dtT => String(dtT.digitaltwintagid) === String(action.targetid))?.digitaltwinid)
			)?.locationid;
		if ([ACTION_TYPES.addDigitalTwinPerspective].includes(action.actiontype))
			locationid = props.digitalTwins.find(
				dt =>
					String(dt.digitaltwinid) ===
					String(
						props.digitalTwinPerspectives.find(dtP => String(dtP.digitaltwinperspectiveid) === String(action.targetid))
							?.digitaltwinid
					)
			)?.locationid;
		if ([ACTION_TYPES.setCo.id, ACTION_TYPES.addCo.id].includes(action.actiontype))
			locationid = props.cos.find(co => String(co.coid) === String(action.targetid))?.locationid;
		if ([ACTION_TYPES.setCoRegister.id].includes(action.actiontype))
			locationid = props.cos.find(
				co => String(co.coid) === String(props.coRegisters.find(coR => String(coR.coregisterid) === String(action.targetid))?.coid)
			)?.locationid;
		if ([ACTION_TYPES.setFileRecord.id, ACTION_TYPES.addFileRecord.id, ACTION_TYPES.removeFileRecord.id].includes(action.actiontype))
			locationid = props.fileRecords.find(rec => String(rec.id) === String(action.targetid))?.locationid;
		if ([ACTION_TYPES.setDeviation.id, ACTION_TYPES.addDeviation.id].includes(action.actiontype))
			locationid = props.deviations.find(dev => String(dev.deviationid) === String(action.targetid))?.locationid;

		return props.properties.find(pro => String(pro.locationid) === String(locationid));
	}

	function getLabelForTargetId(action) {
		if ([ACTION_TYPES.setSensor.id].includes(action.actiontype))
			return props.sensors.find(sen => String(sen.sensorid) === String(action.targetid))?.name;
		if ([ACTION_TYPES.setSensorGroup.id, ACTION_TYPES.addSensorGroup.id].includes(action.actiontype))
			return props.sensorGroups.find(grp => String(grp.sensorgroupid) === String(action.targetid))?.name;
		if ([ACTION_TYPES.setSensorLocation.id, ACTION_TYPES.addSensorLocation.id].includes(action.actiontype))
			return props.properties.find(pro => String(pro.locationid) === String(action.targetid))?.street;
		if ([ACTION_TYPES.setDigitalTwin.id, ACTION_TYPES.addDigitalTwin.id].includes(action.actiontype))
			return props.digitalTwins.find(dt => String(dt.digitaltwinid) === String(action.targetid))?.label;
		if (
			[ACTION_TYPES.setDigitalTwinTag.id, ACTION_TYPES.addDigitalTwinTag.id, ACTION_TYPES.removeDigitalTwinTag.id].includes(
				action.actiontype
			)
		)
			return (
				props.digitalTwinTags.find(dtT => String(dtT.digitaltwintagid) === String(action.targetid))?.label ||
				t('actionList.digitalTwinTagWithoutTitle')
			);
		if ([ACTION_TYPES.addDigitalTwinPerspective].includes(action.actiontype))
			return props.digitalTwins.find(
				dt =>
					String(dt.digitaltwinid) ===
					String(
						props.digitalTwinPerspectives.find(dtP => String(dtP.digitaltwinperspectiveid) === String(action.targetid))
							?.digitaltwinid
					)
			)?.label;
		if ([ACTION_TYPES.setCo.id, ACTION_TYPES.addCo.id].includes(action.actiontype))
			return props.cos.find(co => String(co.coid) === String(action.targetid))?.name;
		if ([ACTION_TYPES.setCoRegister.id].includes(action.actiontype))
			return props.cos.find(
				co => String(co.coid) === String(props.coRegisters.find(coR => String(coR.coregisterid) === String(action.targetid))?.coid)
			)?.name;
		if ([ACTION_TYPES.setFileRecord.id, ACTION_TYPES.addFileRecord.id, ACTION_TYPES.removeFileRecord.id].includes(action.actiontype))
			return props.fileRecords.find(rec => String(rec.id) === String(action.targetid))?.displayname;
		if ([ACTION_TYPES.setDeviation.id, ACTION_TYPES.addDeviation.id].includes(action.actiontype))
			return props.deviations.find(dev => String(dev.deviationid) === String(action.targetid))?.title;
	}

	function getLabelForValue(action) {
		if (action.subtargetid === 'sensorgroupid') return props.sensorGroups.find(grp => String(grp.sensorgroupid) === action.value)?.name;
		if (action.subtargetid === 'locationid') return props.properties.find(pro => String(pro.locationId) === action.value)?.street;
		if (action.subtargetid === 'digitaltwinid') return props.digitalTwins.find(dt => String(dt.digitaltwinid) === action.value)?.label;
		if (action.subtargetid === 'digitaltwintagid')
			return (
				props.digitalTwinTags.find(tag => String(tag.digitaltwintagid) === action.value)?.label ||
				t('actionList.digitalTwinTagWithoutTitle')
			);
		if (action.subtargetid === 'digitaltwinperspectiveid')
			return props.digitalTwins.find(
				dt =>
					String(dt.digitaltwinid) ===
					String(props.digitalTwinPerspectives.find(per => String(per.digitaltwinperspectiveid) === action.value)?.digitaltwinid)
			)?.label;
		if (action.subtargetid === 'coid') return props.cos.find(co => String(co.coid) === action.value)?.name;
		if (action.subtargetid === 'classification') {
			if (action.actiontype === ACTION_TYPES.setSensor.id)
				return Object.values(UNIT_CLASSIFICATIONS).find(val => val.id === action.value)?.label;
			if (action.actiontype === ACTION_TYPES.setCoRegister.id)
				return [...Object.values(CO_CLASSIFICATIONS), ...Object.values(CO_CLASSIFICATIONS.curve.subClasses)].find(
					val => val.value === action.value
				)?.label;
		}
		if (action.subtargetid === 'sensorids')
			return action.value
				.split(',')
				.map(val => props.sensors.find(sen => String(sen.sensorid) === val)?.name)
				.reduce((sum, cur) => (!sum ? cur : `${sum} & ${cur}`), '');
		if (action.subtargetid === 'sensorgroupids')
			return action.value
				.split(',')
				.map(val => props.sensorGroups.find(grp => String(grp.sensorgroupid) === val)?.name)
				.reduce((sum, cur) => (!sum ? cur : `${sum} & ${cur}`), '');
		if (action.subtargetid === 'alarmperiodids') {
			const idCount = (action.value.match(/;/g) || []).length + (action.value ? 1 : 0);
			return idCount ? t('generic.dayOrPeriod', { count: idCount }) : t('generic.removed_other');
		}

		if (action.value === null || action.value === 'null' || action.value === 'undefined' || action.value === '') return '-';
		if (typeof action.value == 'number') return getFormattedNumber(action.value);
		return action.value;
	}

	return (
		<>
			<div style={isGlobal ? {} : { margin: '1rem' }}>
				<MaterialTable
					title={t('actionList.actionTitle')}
					data={props.actions
						.map(act => ({ ...act, ...getPropertyFromAction(act) }))
						.filter(act => act.locationid && (isGlobal || String(act.locationid) === String(props.currentProperty))) // Check locationid to make sure actions of inaccessible properties don't show
						.sort((a, b) => (a.timestamp > b.timestamp ? -1 : 1))
						.map(act => ({
							...act,
							typeLabel: ACTION_TYPES[act.actiontype]?.label || '',
							formattedTimestamp: formatDate(new Date(act.timestamp), 'MMM dd HH:mm'),
							userLabel: (() => {
								const user = keycloakUserQuery.data?.getKeycloakUsers?.find(use => use.id === act.userid);
								return user ? (user.firstName || '') + ' ' + (user.lastName || '') : '';
							})(),
							targetLabel: getLabelForTargetId(act),
							subTargetLabel:
								Object.values(ACTION_TYPES[act.actiontype]?.subTargets || {})?.find(subT => subT.id === act.subtargetid)
									?.label || '',
							formattedValue: getLabelForValue(act),
						}))}
					columns={[
						...(isGlobal ? [{ title: t('generic.city'), field: 'city', maxLength: 8 }] : []),
						...(isGlobal ? [{ title: t('generic.address'), field: 'street' }] : []),
						{ title: t('generic.type'), field: 'typeLabel' },
						{ title: t('generic.user'), field: 'userLabel', maxLength: 8 },
						{ title: t('generic.date'), field: 'formattedTimestamp' },
						{ title: t('generic.object'), field: 'targetLabel' },
						{ title: t('generic.attribute'), field: 'subTargetLabel' },
						{ title: t('generic.value'), field: 'formattedValue' },
						{
							title: t('generic.comment'),
							sorting: false,
							render: row => (
								<div
									onClick={() => {
										setSelectedActionId(row.actionid);
										setShowCommentPopup(true);
									}}
									style={{ cursor: 'pointer' }}
								>
									{drawTruncatedStr(row.comment || '-', 8)}
								</div>
							),
						},
					]}
					options={{
						pageSize: isGlobal ? 16 : 12,
					}}
					style={isGlobal ? { width: CENTER_CONTENT_STYLE.width } : {}}
					maxColumnLength={isGlobal ? 12 : 20}
				/>
			</div>

			<DialogWrapper
				title={t('generic.comment')}
				dialogProps={{ open: showCommentPopup, onClose: () => setShowCommentPopup(false) }}
				width='22rem'
			>
				<EditableText
					text={selectedAction?.comment}
					onSave={newText => setActions({ variables: { actionids: [selectedAction?.actionid], comment: newText } })}
				/>
				
			</DialogWrapper>
		</>
	);
}

export default connect(
	getStateVariables(
		STORE.actions,
		STORE.sensors,
		STORE.sensorGroups,
		STORE.properties,
		STORE.digitalTwins,
		STORE.digitalTwinTags,
		STORE.digitalTwinPerspectives,
		STORE.cos,
		STORE.coRegisters,
		STORE.fileRecords,
		STORE.deviations,
		STORE.currentPage,
		STORE.currentProperty
	),
	{ setActions, updateActions }
)(ActionList);
