import React, { useState } from 'react';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import * as locales from 'react-date-range/dist/locale';

import { MenuItem, Paper, Button, Tooltip, Popper, MenuList, ClickAwayListener, Modal } from '@material-ui/core';

import { CHART_TIME_SELECTION_INTERVALS, MINUTES_PER_DAY, MINUTES_PER_MONTH, MINUTES_PER_WEEK, MINUTES_PER_YEAR } from '../../../constants';
import { DateRangePicker } from 'react-date-range';
import { addDays, addMinutes, format, isToday } from 'date-fns';
import useDefaultStaticRanges from './UseDefaultStaticRanges';
import { useTranslation } from 'react-i18next';
import * as colors from '../../../colors';
import ScheduleIcon from '@material-ui/icons/Schedule';
import { format as formatDate } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';
import { getStartEndDateFromInterval } from '../../../utility-functions/intervalStartEndDate';

/**
 * Draws a time selection component for choosing a time span
 * @param {List} TimeOptions : Which time interval options to use
 * @param {Object} TimeRange : Date time range for the tab
 * @param {Boolean} Calendar : If the user can choose an arbitrary span between two dates
 * @param {Int} MaxSpan : Maximum number of days between two dates if Calendar is active
 * @param {Function} callback : Called when the toggle changes, with the new state of the button
 * @param {Object} style : CSS properties applied to root
 */

const useStyles = makeStyles(() => ({
	largeTooltip: {
		fontSize: '12px',
		padding: '10px',
		marginBottom: '14px',
	},
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '-50px',
	},
}));

function getInitSelectedOption(timeRange) {
	let selected = CHART_TIME_SELECTION_INTERVALS[0].value;
	if (Object.keys(timeRange).length) {
		timeRange.offset !== undefined && timeRange.offset !== null ? (selected = timeRange.offset) : (selected = 'custom');
	}
	return selected;
}

function TimeSelection({ timeRange, calendar, maxSpan, callback, style }) {
	const classes = useStyles();
	const [selectedOption, setSelectedOption] = useState(getInitSelectedOption(timeRange));
	const [openCustom, setOpenCustom] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const [dates, setDates] = useState([
		{
			startDate: timeRange.startDate,
			endDate: timeRange.endDate,
			key: 'selection',
		},
	]);

	const { t, i18n } = useTranslation();

	const CUSTOM_OPTION = { value: 'custom', text: t('timeSelect.custom') };

	const staticRanges = useDefaultStaticRanges(dates);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleCalendarChange = item => {
		setDates([item.selection]);
	};

	const options = [];

	for (const timeOption of CHART_TIME_SELECTION_INTERVALS) {
		options.push(
			<MenuItem key={timeOption.value} value={timeOption.value} onClick={() => handleSave(timeOption.text, timeOption.value)}>
				{timeOption.text}
			</MenuItem>
		);
	}

	if (calendar) {
		options.push(
			<MenuItem key={CUSTOM_OPTION.value} value={CUSTOM_OPTION.value} onClick={() => setOpenCustom(true)}>
				{CUSTOM_OPTION.text}
			</MenuItem>
		);
	}

	const handleCloseList = () => {
		setAnchorEl(null);
	};

	const handleSave = (text, value) => {
		if (selectedOption !== value) {
			setSelectedOption(value);
			const { startDate, endDate } = getStartEndDateFromInterval(value);
			callback({
				startDate: startDate,
				endDate: endDate,
				offset: value,
				selected: text,
			});
		}
		handleCloseList();
	};

	const handleSaveCustom = () => {
		setSelectedOption(CUSTOM_OPTION.value);
		const date = dates[0];
		callback({
			startDate: date.startDate,
			endDate: addDays(date.endDate, 1),
			offset: undefined,
			selected: selectedOption,
		});
		setOpenCustom(false);
	};

	const getOptionFromSelected = () => {
		return CHART_TIME_SELECTION_INTERVALS.find(el => el.value === selectedOption) || CUSTOM_OPTION;
	};

	const getChipDisplayText = () => {
		if (selectedOption === 'custom') {
			const startDate = dates[0].startDate;
			const endDate = dates[0].endDate;
			return `${formatDate(startDate, 'yyyy-MM-dd')} - ${formatDate(endDate, 'yyyy-MM-dd')}`;
		} else {
			return `${getOptionFromSelected().text}`;
		}
	};

	const dayRenderer = day => {
		let underLine = null;
		if (isToday(day)) {
			underLine = (
				<div
					style={{
						background: colors.accent,
						height: '2px',
						width: '18px',
						borderRadius: '2px',
						position: 'absolute',
						bottom: '4px',
						left: '50%',
						transform: 'translate(-50%, 0)',
					}}
				/>
			);
		}
		return (
			<div>
				<span>{format(day, 'd')}</span>
				{underLine}
			</div>
		);
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Tooltip title={t('constants.selectTimeRange')} placement='top' arrow classes={{ tooltip: classes.largeTooltip }}>
				<Button
					startIcon={<ScheduleIcon style={{ marginRight: '-5px', marginTop: '9px' }} />}
					color='inherit'
					onClick={handleClick}
				>
					{getChipDisplayText()}
				</Button>
			</Tooltip>
			{/* style={{ width: anchorEl ?? `${anchorEl?.offsetWidth}px` }} */}
			<Popper open={open} anchorEl={anchorEl}>
				<Paper style={{ minWidth: anchorEl?.offsetWidth ?? `${anchorEl?.offsetWidth}px` }}>
					<ClickAwayListener onClickAway={handleCloseList}>
						<MenuList>{options}</MenuList>
					</ClickAwayListener>
				</Paper>
			</Popper>
			<Modal open={openCustom} className={classes.modal}>
				<div>
					<DateRangePicker
						onChange={item => handleCalendarChange(item)}
						showSelectionPreview={true}
						moveRangeOnFirstSelection={false}
						months={2}
						maxDate={new Date()}
						rangeColors={[colors.primary]}
						dayContentRenderer={dayRenderer}
						color={colors.primary}
						ranges={dates}
						direction='horizontal'
						locale={locales[i18n.language]}
						inputRanges={[]}
						staticRanges={staticRanges}
					/>
					<Paper
						style={{
							justifyContent: 'right',
							bottom: '0',
							padding: '0.6rem',
							display: 'flex',
							gap: '0.5rem',
						}}
					>
						<Button onClick={() => setOpenCustom(false)} style={{ color: '#0008', boxShadow: '0 1px 0.2rem #0004' }}>
							{t('generic.cancel')}
						</Button>
						<Button onClick={handleSaveCustom} color='primary' style={{ boxShadow: '0 1px 0.2rem #0004' }}>
							{t('generic.save')}
						</Button>
					</Paper>
				</div>
			</Modal>
		</>
	);
}

export default TimeSelection;
