import React, { useState, useEffect } from 'react';
import { gql, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import {Backdrop, LinearProgress, TextField   } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { useQuery } from '@apollo/client';

import { STORE, getStateVariables } from '../redux/selectors';
import { updateCameras } from '../redux/actionCreators';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import { COMMUNICATION_SENSOR_TYPES } from '../constants';


const GET_AI_CONTROL = gql`
  query GetAiControls($filter: AiControlFilter) {
    getAiControls(filter: $filter) {
      index
      locationid
      system
      ducid
      name
      sensorid
      communicationtype
      sensortype
      value
      minimum
      maximum
    }
  }
`;

const SET_AI_CONTROL = gql`
    mutation ($indexs: [ID!]!, $name: String, $system: String, $communicationtype: String, $sensortype: String, $minimum: Float, $maximum: Float,$sensorid: String, $locationid:ID) {
        setAiControls(indexs: $indexs, name: $name, system: $system, communicationtype: $communicationtype, sensortype: $sensortype, minimum: $minimum, maximum: $maximum, sensorid: $sensorid, locationid: $locationid) {
            index
            name
            system
            communicationtype
            sensortype
            minimum
            maximum
            sensorid
            locationid
        }
    }
`;

const ADD_AI_CONTROL = gql`
mutation ($locationid: ID, $communicationtype: String, $ducid: ID, $name: String, $sensortype: String, $system: ID, $sensorid: String, $minimum: Float, $maximum: Float) {
    addAiControl(locationid: $locationid, communicationtype: $communicationtype, ducid: $ducid, name: $name, sensortype: $sensortype, system: $system, sensorid: $sensorid, minimum: $minimum, maximum: $maximum) {
      index
      locationid
	  ducid
      communicationtype
	  name
	  sensortype
	  system
	  sensorid
      minimum
      maximum
    }
  }
`;

const POPUP_TYPES = Object.freeze({ add: 1, edit: 2, delete: 3 });

/**
 * A table for Ai-sensors/tags that allows them to be filtered and bulk-edited
 */
function AiControl(props) {

    const [selectedControls, setSelectedControls] = useState([]);
    const [automationsUnderEdit, setAutomationsUnderEdit] = useState([]);
    const [editPopupType, setEditPopupType] = useState();
    const [showEditPopup, setShowEditPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [minTemp, setMinTemp] = useState();
    const [maxTemp, setMaxTemp] = useState();

    const { t } = useTranslation();


    // loading cameras
    useEffect(() => {
        if (props.cameras.length) setIsLoading(false);
    }, [props.cameras]);

    // eslint-disable-next-line
 
    const AiControlQuery = useQuery(GET_AI_CONTROL, {
        variables: {
            filter: {
                locationid: [props.currentProperty],
                // Add other filters if needed
            },
        },
    });

    useEffect(() => {
        AiControlQuery.refetch();
    }, [props.currentProperty]);

    const [setAiControls] = useMutation(SET_AI_CONTROL, {
        onCompleted: () => AiControlQuery.refetch(),
        onError: error => {
            Swal.fire(t('generic.error'), error.message, 'error');
            
        },
    });

    const [addAiControl] = useMutation(ADD_AI_CONTROL, {
        onCompleted: () => console.log('AI control added.'),
        onError: (error) => console.error('Failed to add aicontrol.', error),
    });
    const filteredPropertyAiControlMaxMin = AiControlQuery.data?.getAiControls.filter(control => control.locationid === (props.currentProperty).toString() && (control.sensortype === 'maxtemp' || control.sensortype === 'mintemp')) || [];


    const filteredAiControl = AiControlQuery.data?.getAiControls.filter(control => control.sensortype !== 'maxtemp' && control.sensortype !== 'mintemp') || [];
    const sensorEditOptions = props.sensors
        .filter(sen => sen.locationid === props.currentProperty)
        .map(pro => ({ value: pro.sensorid, label: `${pro.name}` }))
    //.sort((a, b) => (a.label < b.label ? -1 : 1));

    const ecpOptions = props.externalControlPanels
        .filter(ecp => ecp.locationid === props.currentProperty.toString())
        .map(ecp => ({ value: ecp.externalcontrolpanelid, label: ecp.label }));


        const handleMinTempChange = (event) => {
            const newMinTemp = parseFloat(event.target.value);
            setMinTemp(newMinTemp);
            setAiControls({
                variables: {
                    indexs: filteredPropertyAiControlMaxMin.map(control => control.index), // Adjust the indexes as needed
                    minimum: newMinTemp,
                    maximum: maxTemp,
                },
            });
        };
    
        const handleMaxTempChange = (event) => {
            const newMaxTemp = parseFloat(event.target.value);
            setMaxTemp(newMaxTemp);
            setAiControls({
                variables: {
                    indexs: filteredPropertyAiControlMaxMin.map(control => control.index),// Adjust the indexes as needed
                    minimum: minTemp,
                    maximum: newMaxTemp,
                },
            });
        };


    return (
        <>
            <div style={{ margin: '1rem 0 0 1rem' }}>
            <div style={{ display: 'flex', flexWrap: 'wrap',/*  marginLeft: '15cm', */ gap: '20px' }}>
            <div>
					<TextField
						id="outlined-controlled-minimum"
						label={t('generic.minimum')}
						type="number"
						value={minTemp || (filteredPropertyAiControlMaxMin.length > 0 ? filteredPropertyAiControlMaxMin[0].minimum : '')}
						onChange={handleMinTempChange}
						variant='outlined'
						InputLabelProps={{ style: { color: 'black' } }}
					/>
				</div>
				<div>
					<TextField
						id="outlined-controlled-maximum"
						label={t('generic.maximum')}
						type="number"
						value={maxTemp || (filteredPropertyAiControlMaxMin.length > 0 ? filteredPropertyAiControlMaxMin[0].maximum : '')}
						onChange={handleMaxTempChange}
						variant='outlined'
						InputLabelProps={{ style: { color: 'black' } }}
					/>
				</div>
            </div>
                <div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
                    <SelectionTable
                        localization={{ title: t('generic.sensorsAndTags'), nRowsSelected: t('generic.tableSelTitle') }}

                        data={filteredAiControl
                            .filter(ai => ai.locationid === props.currentProperty.toString())
                            .map(ai => ({
                                ...ai,
                                name: ai.name.length > 40 ? ai.name.substring(0, 30) + '...' : ai.name,
                            }))
                        }
                        dataId='index'

                        onSelectionChange={controls => setSelectedControls(controls)}
                        /* onRowClick={(cam, e) => {
                            if (e._dispatchInstances.some(dispatch => dispatch.stateNode?.id === DISCRETE_CLICK_ID)) return;

                            setAutomationsUnderEdit([cam]);
                            setEditPopupType(POPUP_TYPES.edit);
                            setShowEditPopup(!showEditPopup);
                        }} */
                        columns={[
                            {
                                title: t('generic.name'),
                                field: 'name',
                            },
                            /* {
                                title: t('generic.property'),
                                field: 'locationid',
                            }, */
                            {
                                title: t('generic.system'),
                                field: 'system',
                            },
                            {
                                title: t('generic.communicationType'),
                                field: 'communicationtype',
                            },
                            {
                                title: t('generic.sensorType'),
                                field: 'sensortype',
                            },
                           /*  {
                                title: t('generic.minimum'),
                                field: 'minimum',
                            },
                            {
                                title: t('generic.maximum'),
                                field: 'maximum',
                            }, */
                            /*  {
                                 title: t('generic.sensorId'),
                                 field: 'sensorid',
                             }, */
                            /* {
                                title: t('automations.sensor'),
                                field: 'sensorid',
                                render: rowData => props.sensors.find(sensor => sensor.sensorid === rowData.sensorid)?.name
                            } */

                        ]}
                        tableProps={{ maxColumnLength: 60 }}
                    />

                    <SelectionSidebar
                        buttons={[
                            {
                                label: t('automations.addAIcontrol'),
                                onClick: () => {
                                    setEditPopupType(POPUP_TYPES.add);
                                    setShowEditPopup(!showEditPopup);
                                },
                                //   disabled: selectedControls.length > 0,
                            },
                            {
                                label: t('genericAdmin.editSelected'),
                                onClick: () => {
                                    setAutomationsUnderEdit(selectedControls);
                                    setEditPopupType(POPUP_TYPES.edit);
                                    setShowEditPopup(!showEditPopup);
                                },
                                disabled: !selectedControls.length,
                            },
                            {
                                label: t('automations.delete'),
                                onClick: () => {
                                    setAutomationsUnderEdit(selectedControls);
                                    setEditPopupType(POPUP_TYPES.delete);
                                    setShowEditPopup(!showEditPopup);
                                },
                                disabled: !selectedControls.length,
                            },
                        ]}
                    />
                </div>

                <EditPopup
                    text={
                        editPopupType === POPUP_TYPES.edit
                            ? {
                                title:
                                    automationsUnderEdit.length === 1
                                        ? t('automations.sensor_or_tag') + ' ' + (automationsUnderEdit[0].name)
                                        : t('generic.edit') + ' ' + automationsUnderEdit.length + t('automations.selectedSensorsOrTags'),
                                subtitle:
                                    t('genericAdmin.selectValuesFor') +
                                    ' ' +
                                    t('automations.sensor_or_tag', { count: automationsUnderEdit.length }), // Check that this is the correct way to do it.
                            } :
                            editPopupType === POPUP_TYPES.delete
                                ? {
                                    title: t('automations.delete'),
                                    subtitle: t('automations.deleteSelectedSensorsOrTags'),
                                    save: t('generic.confirm'),
                                }
                                : {
                                    title: t('automations.addSensor'),
                                    subtitle: t('automations.sensor_or_tag'),
                                }
                    }
                    fields={
                        editPopupType === POPUP_TYPES.edit
                            ? [
                                /*   {
                                      id: 'locationid',
                                      label: t('cameraAdmin.minimum'),
                                  }, */
                                {
                                    id: 'name',
                                    label: t('automations.name'),
                                    placeholder: automationsUnderEdit[0].name ? automationsUnderEdit[0].name : '...',
                                    disabled: automationsUnderEdit.length > 1 || automationsUnderEdit[0].communicationtype !== 'modbus'
                                },
                                {
                                    id: 'system',
                                    label: t('generic.system'),
                                    placeholder: automationsUnderEdit[0].system ? automationsUnderEdit[0].system : '...',
                                    disabled: automationsUnderEdit.length > 1,
                                },
                                {
                                    id: 'communicationtype',
                                    label: t('generic.communicationType'),
                                    placeholder: automationsUnderEdit.length > 1 ? null : (automationsUnderEdit[0].communicationtype ? automationsUnderEdit[0].communicationtype : '...'),
                                    options: Object.values(COMMUNICATION_SENSOR_TYPES)
                                        .filter(classi => classi.id === 'sensor' || classi.id === 'modbus')
                                        .map(classi => {
                                            return { value: classi.id, label: classi.label };
                                        }),
                                },
                                {
                                    id: 'sensortype',
                                    label: t('generic.sensorType'),
                                    placeholder: automationsUnderEdit.length > 1 ? null : (automationsUnderEdit[0].sensortype ? automationsUnderEdit[0].sensortype : '...'),
                                    options: Object.values(COMMUNICATION_SENSOR_TYPES)
                                        .filter(classi => classi.id !== 'sensor' &&  classi.id !== 'modbus')
                                        .map(classi => {
                                            return { value: classi.id, label: classi.label };
                                        }),
                                    disabled: automationsUnderEdit[0].communicationtype === 'modbus',
                                },
                               /*  {
                                    id: 'minimum',
                                    label: t('generic.minimum'),
                                    placeholder: automationsUnderEdit[0].minimum ? automationsUnderEdit[0].minimum : '...',
                                    disabled: automationsUnderEdit.length > 1,
                                },
                                {
                                    id: 'maximum',
                                    label: t('generic.maximum'),
                                    placeholder: automationsUnderEdit[0].maximum ? automationsUnderEdit[0].maximum : '...',
                                    disabled: automationsUnderEdit.length > 1,
                                }, */
                               /*  {
                                    id: 'sensorid',
                                    label: t('automations.sensor'),
                                    options: sensorEditOptions.concat({ value: null, label: t('sensorAdmin.noProperty') }),
                                    placeholder:
                                        automationsUnderEdit.length === 1 && automationsUnderEdit[0].sensorid
                                            ? props.sensors.find(sensor => sensor.sensorid === automationsUnderEdit[0].sensorid)?.name
                                            : '...',
                                     // placeholder: automationsUnderEdit[0].sensorid ? automationsUnderEdit[0].sensorid : '...',
                                    disabled: automationsUnderEdit.length > 1 || automationsUnderEdit[0].communicationtype === 'modbus',
                                } */


                            ]
                            : editPopupType === POPUP_TYPES.add ?
                                [
                                    {
                                        id: 'sensorid',
                                        label: t('automations.sensor'),
                                        options: sensorEditOptions.concat({ value: null, label: t('sensorAdmin.noProperty') }),
                                        placeholder: '...',
                                    },
                                    /* {
                                        id: 'name',
                                        label: t('automations.name'),
                                        placeholder: '...',
                                    }, */
                                    {
                                        id: 'ducid',
                                        label: t('automations.ducid'),
                                        options: ecpOptions.concat({ value: null, label: t('sensorAdmin.noProperty') }),
                                        placeholder: '...',
                                    },
                                    {
                                        id: 'system',
                                        label: t('generic.system'),
                                        placeholder: '...',
                                    },
                                    {
                                        id: 'communicationtype',
                                        label: t('generic.communicationType'),
                                        options: Object.values(COMMUNICATION_SENSOR_TYPES)
                                            .filter(classi => classi.id === 'sensor' || classi.id === 'modbus')
                                            .map(classi => {
                                                return { value: classi.id, label: classi.label };
                                            }),
                                    },
                                    {
                                        id: 'sensortype',
                                        label: t('generic.sensorType'),
                                        placeholder: '...',
                                        options: Object.values(COMMUNICATION_SENSOR_TYPES).filter(classi => classi.id !== 'sensor' && classi.id !== 'modbus').map(classi => {
                                            return { value: classi.id, label: classi.label };
                                        }),
                                    },
                                    {
                                        id: 'minimum',
                                        label: t('generic.minimum'),
                                        placeholder: '...',
                                    },
                                    {
                                        id: 'maximum',
                                        label: t('generic.maximum'),
                                        placeholder: '...',
                                    },

                                ] : []
                    }
                    isOpen={showEditPopup}
                    onClose={() => setShowEditPopup(!showEditPopup)}
                    onSave={opts => {
                        if (editPopupType === POPUP_TYPES.edit) {
                            setAiControls({
                                variables: {
                                    indexs: automationsUnderEdit.map(cam => cam.index),
                                    name: opts.name,
                                    system: opts.system,
                                    communicationtype: opts.communicationtype,
                                    sensortype: opts.sensortype,
                                    minimum: opts.minimum && Number(opts.minimum),
                                    maximum: opts.maximum && Number(opts.maximum),
                                    sensorid: opts.sensorid,
                                },
                            });
                            Swal.fire(t('userAdmin.success'), t('automations.automationInfo'), 'success');
                        }
                        else if (editPopupType === POPUP_TYPES.delete) {
                            const date = new Date();
                            const timestamp = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();

                            setAiControls({
                                variables: {
                                    indexs: automationsUnderEdit.map(cam => cam.index),
                                    locationid: null,
                                    name: props.currentProperty + '_removed_' + props.userInfo.username + timestamp,
                                },
                            });
                            Swal.fire(t('userAdmin.success'), t('automations.deletedSensorsTags'), 'success');
                        }
                        else {
                            const selectedSensor = props.sensors.find(sen => sen.sensorid === opts.sensorid);
                            addAiControl({
                                variables: {
                                    locationid: props.currentProperty,
                                    communicationtype: opts.communicationtype,
                                    system: opts.system,
                                    ducid: opts.ducid,
                                    name: selectedSensor ? selectedSensor.name : opts.name,
                                    sensortype: opts.sensortype,
                                    sensorid: opts.sensorid,
                                    minimum: opts.minimum && Number(opts.minimum),
                                    maximum: opts.maximum && Number(opts.maximum),
                                },
                            });
                            Swal.fire(t('userAdmin.success'), t('automations.addedAIcontrol'), 'success');
                        }
                        setShowEditPopup(!showEditPopup);
                        AiControlQuery.refetch();
                    }}

                />
            </div>


            <Backdrop open={isLoading} style={{ zIndex: '100' }}>
                <div
                    style={{
                        padding: '0.9rem 1rem 1rem',
                        fontSize: '112%',
                        fontWeight: '300',
                        textAlign: 'center',
                        background: '#fff',
                        borderRadius: '0.3rem',
                        boxShadow: '0rem 0.1rem 0.8rem #000c',
                    }}
                >
                    {t('generic.loadingCameras')}
                    <LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
                </div>
            </Backdrop>
        </>
    );
}

export default connect(getStateVariables(STORE.properties, STORE.cameras, STORE.currentProperty, STORE.sensors, STORE.userInfo, STORE.externalControlPanels), { updateCameras })(AiControl);
