import React from 'react';
import i18n from './i18n';

function IELandingPage() {
	document.body.style.backgroundColor = '#dde8f0';
	return (
		<div
			style={{
				width: '30rem',
				height: '24.5rem',
				background: '#fff',
				borderRadius: '0.3rem',
				boxShadow: '0 0 0.2rem #0005',
				position: 'absolute',
				top: '0',
				bottom: '0',
				left: '0',
				right: '0',
				margin: 'auto',
			}}
		>
			<div
				style={{
					textAlign: 'left',
					margin: '3rem 3rem',
					fontFamily: 'Tahoma, Geneva, "Lucida Sans Unicode", "Lucida Grande", Arial, Helvetica, sans-serif',
				}}
			>
				<h1 style={{ color: '#00bcf2' }}>{i18n.t('IELandingPage.browserNotSupported')}</h1>
				<p style={{ lineHeight: '160%' }}>
					{i18n.t('IELandingPage.hi')} <br />
					{i18n.t('IELandingPage.doesNotSupportPromt')} <br />
					<a style={{ textDecoration: 'none' }} href='https://www.microsoftedgeinsider.com/'>
						<h4
							style={{
								textAlign: 'center',
								color: '#fff',
								background: '#0078d7',
								borderRadius: '0.5rem',
								boxShadow: '0 0 0.2rem #0005',
								padding: '0.5rem',
							}}
						>
							{i18n.t('IELandingPage.pressToDownload')}
						</h4>
					</a>
				</p>
			</div>
		</div>
	);
}

export { IELandingPage };
