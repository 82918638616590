import React, { useState } from 'react';
import { REPORT_INTERVALS } from '../../constants';
import MaterialTable from '../MaterialTableWrapper';
import { Avatar, Button, Chip, CircularProgress, InputAdornment, Paper, TextField, Typography } from '@material-ui/core';
import { Add, Edit, Clear, Description, LocationCity, Layers, SettingsRemote } from '@material-ui/icons';

import { useTranslation } from 'react-i18next';
import * as colors from '../../colors';
import WizardAlert from './WizardAlert';
import i18n from '../../i18n';
import { create } from '@mui/material/styles/createTransitions';

const tableProps = {
	options: {
		search: false,
		toolbar: false,
		headerStyle: {
			backgroundColor: colors.primary0,
			color: '#fff',
			height: '2rem',
			fontWeight: '430',
			fontSize: '98%',
		},
		rowStyle: {
			color: '#444',
			height: '2rem',
			whiteSpace: 'nowrap',
		},
		actionsColumnIndex: -1,
		maxBodyHeight: 'calc(90vh - 26rem)',
		// minBodyHeight: 'calc(90vh - 26rem)',
		pageSize: 5,
	},
	style: { width: '24rem', margin: '0 0 1.3rem 2rem', overflow: 'hidden' },
};

function isNotValidEmail(email) {
	const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	return !regex.test(email);
}

function SummaryCard({ title, text, icon, flexGrow = 1 }) {
	return (
		// <Paper elevation={2} style={{ padding: '0.5rem', margin: '0.5rem 0 0.5rem 0', flexGrow: 1 }}>
		<div style={{ display: 'flex', alignItems: 'center', padding: '0.5rem', margin: '0rem 0 0.5rem 0', flexGrow: flexGrow }}>
			<Avatar style={{ backgroundColor: colors.primary, color: 'white' }}>{icon}</Avatar>
			<div style={{ margin: '0 0 0 1rem' }}>
				<Typography variant='h6' style={{ fontSize: '1rem' }}>
					{title}
				</Typography>
				<Typography variant='body1'>{text}</Typography>
			</div>
		</div>
		// </Paper>
	);
}

const DEFAULT_NAME = i18n.t('generic.reportName');
const DEFAULT_INTERVALS = ['week'];
const NAME_MAX_LENGTH = 50;
const MAX_EMAIL_LENGTH = 254;

export default function AddRecipients({ reportType, loadRecipients, loadReportName, loadReport, handleBack, handleSave }) {
	const [alert, setAlert] = useState({ message: '', severity: 'error', open: false });
	const [createRetries, setCreateRetries] = useState(0);
	const [recipients, setRecipients] = useState(loadRecipients || []);
	const [reportName, setReportName] = useState(loadReportName || DEFAULT_NAME);
	const [intervals, setIntervals] = useState(DEFAULT_INTERVALS);
	const [creatingReport, setCreatingReport] = useState(false);
	const [recipientsInProgress, setRecipientsInProgress] = useState(false);
	const [email, setEmail] = useState('');

	const { t } = useTranslation();

	const buttonText = loadRecipients.length === 0 ? t('reports.createReport') : t('reports.saveReport');

	const setAlertMessage = (message, severity = 'error') => {
		setAlert({ message, severity, open: true });
	};

	const resetAlert = () => {
		setAlert({ ...alert, open: false });
	};

	const handleAddInterval = interval => {
		if (!recipientsInProgress) {
			setRecipientsInProgress(true);
		}
		setIntervals([...intervals, interval]);
	};

	const handleRemoveInterval = interval => {
		if (recipientsInProgress && email !== '' && intervals.length === 1) {
			setRecipientsInProgress(false);
		}
		setIntervals(intervals.filter(i => i !== interval));
	};

	const handleEmailInput = value => {
		if (recipientsInProgress && intervals.length === 0 && value === '') {
			setRecipientsInProgress(false);
		} else if (!recipientsInProgress && value !== '') {
			setRecipientsInProgress(true);
		}
		if (value.length > MAX_EMAIL_LENGTH) {
			return;
		}
		setEmail(value);
	};

	const handleNameInput = newName => {
		if (newName.length > NAME_MAX_LENGTH) {
			return;
		}
		setReportName(newName);
	};

	const handleAddRecipient = () => {
		if (isNotValidEmail(email)) {
			return setAlertMessage(t('reportPopups.wrongEmailFormatError')); // Error wrong email format
		}
		if (intervals.length === 0) {
			return setAlertMessage(t('reportPopups.noIntervalsSelectedError')); // Error no intervals selected
		}

		const newRecipientList = [{ email, intervals: intervals }, ...recipients.filter(recipient => recipient.email !== email)];
		setRecipients(newRecipientList);
		setEmail('');
	};

	const handleSaveSettingsOnBack = () => {
		handleBack({ recipients, reportName });
	};

	const handleSubscribe = () => {
		if (reportName.replace(/\s/g, '') === '') {
			return setAlertMessage(t('reportPopups.noReportNameError')); // Error No report name
		}
		if (recipientsInProgress && recipients.length === 0) {
			setRecipientsInProgress(false);
			setCreateRetries(createRetries + 1);
			return setAlertMessage(t('reportPopups.recipientInProgressError', { addRecipient: t('reportPopups.addRecipient'),buttonName: t('reports.createReport') }), 'warning'); // Error
		}
		if (reportName === DEFAULT_NAME && createRetries < 1) {
			setCreateRetries(createRetries + 1);
			return setAlertMessage(t('reportPopups.defaultReportNameWarning', { buttonName: t('reports.createReport') }), 'warning'); // Error
		}
		setCreatingReport(true);
		handleSave({ recipients, reportName });
	};
	return (
		<>
			<WizardAlert
				open={alert.open}
				severity={alert.severity}
				onClose={resetAlert}
				style={{
					margin: '1rem 4rem 0 1rem',
					width: 'calc(100% - 2rem)',
				}}
				duration={8000}
			>
				{alert.message}
			</WizardAlert>
			<div style={{ margin: '0 1rem 1rem 1rem' }}>
				<div style={{ display: 'flex', margin: '0 1rem 0 0', justifyContent: 'space-between', gap: '1rem' }}>
					<SummaryCard title={t('reportPopups.report')} text={reportType} icon={<Description />} />
					{loadReport?.pages ? (
						<SummaryCard title={t('generic.pages')} text={loadReport.pages.length} icon={<Layers />} />
					) : undefined}
					{loadReport?.properties ? (
						<SummaryCard
							title={t('reportPopups.propertiesTitle')}
							text={loadReport.properties.length || 'All'}
							icon={<LocationCity />}
						/>
					) : undefined}
					{loadReport?.sensors ? (
						<SummaryCard title={t('reportPopups.sensorsTitle')} text={loadReport.sensors.length} icon={<SettingsRemote />} />
					) : undefined}
				</div>
				<div style={{}}>
					<Paper elevation={2} style={{ padding: '0.2rem 0.5rem 0.2rem 0.5rem' }}>
						{/* <p style={{ fontSize: '1.5rem', margin: '0.6rem 0 1rem 0.4rem' }}>{'Lägg till mottagare'}</p> */}
						<TextField
							id='input-with-icon-textfield'
							fullWidth
							value={reportName}
							onChange={event => handleNameInput(event.target.value)}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<Edit />
									</InputAdornment>
								),
							}}
							variant='standard'
							inputProps={{
								style: {
									fontSize: '1.7rem',
									width: '100%',
								},
							}}
							style={{
								margin: '0.5rem 0 0.2rem 0',
							}}
						/>
						<div style={{ display: 'flex', margin: '0.7rem 0 0.5rem 0', width: '100%' }}>
							<TextField
								required
								id='outlined-required'
								variant='outlined'
								label='Email'
								onChange={event => handleEmailInput(event.target.value)}
								value={email}
								InputProps={{
									startAdornment: (
										<InputAdornment position='start' style={{ marginLeft: '0.5rem' }}>
											{'@'}
										</InputAdornment>
									),
									style: {
										width: '41vw',
										height: '2.5rem',
										padding: '0',
										alignContent: 'center',
									},
								}}
								InputLabelProps={{
									style: {
										// Adjust the transform property to move the label up
										// Example adjustment
									},
								}}
							/>
							<div style={{ display: 'flex', margin: '0.2rem 0 0 2vw' }}>
								{Object.keys(REPORT_INTERVALS).map(interval => {
									const selected = intervals.includes(interval);
									const clickFunction = selected ? handleRemoveInterval : handleAddInterval;
									return (
										<Chip
											key={interval}
											variant={selected ? undefined : 'outlined'}
											color={selected ? 'primary' : undefined}
											onClick={() => clickFunction(interval)}
											onDelete={() => clickFunction(interval)}
											deleteIcon={selected ? undefined : <Add />}
											label={REPORT_INTERVALS[interval].label}
											style={{
												marginRight: '0.1rem',
											}}
										/>
									);
								})}
							</div>
							<div style={{ position: 'absolute', right: '2rem', marginTop: '0.1rem' }}>
								<Button
									startIcon={
										<Add
											style={{
												marginBottom: '0.1rem',
											}}
										/>
									}
									variant='outlined'
									color='primary'
									//className={classes.addRecipientButton}
									onClick={() => handleAddRecipient()}
									style={{
										fontWeight: 'bold',
									}}
								>
									{t('reports.saveRecipient')}
								</Button>
							</div>
						</div>
					</Paper>
					<MaterialTable
						columns={[
							{ title: t('generic.email'), field: 'email', maxLength: 48 },
							{ title: t('generic.frequency'), field: 'intervals', maxLength: 32 },
						]}
						data={recipients.map(recipient => ({
							...recipient,
							intervals: recipient.intervals.map(interval => REPORT_INTERVALS[interval].label).join(', '),
						}))}
						actions={[
							{
								icon: Clear,
								tooltip: t('reports.removeRecipient'),
								onClick: (event, rowData) => {
									setRecipients(recipients.filter(recipient => recipient.email !== rowData.email));
								},
							},
						]}
						options={tableProps.options}
						localization={{ header: { actions: '' } }}
						// components={tableProps.components(props.userInfo?.reportRecipients)}
						// style={tableProps.style}
						maxColumnLength={28}
						toolbarHeight='3.3rem'
					/>
				</div>
				<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '1rem' }}>
					<Button variant='outlined' color='primary' onClick={() => handleSaveSettingsOnBack()}>
						{t('generic.back')}
					</Button>
					<Button
						disabled={creatingReport}
						variant={'contained'}
						color='primary'
						style={{ marginLeft: '0.5rem', minWidth: '10rem' }}
						onClick={handleSubscribe}
					>
						{creatingReport ? <CircularProgress size={'1rem'} /> :buttonText}
					</Button>
				</div>
			</div>
		</>
	);
}
