import { differenceInDays, differenceInHours, differenceInMinutes, differenceInSeconds } from 'date-fns';

export function getFormattedTimeDiff(_a, _b) {
	const a = Math.max(new Date(_a), new Date(_b));
	const b = Math.min(new Date(_a), new Date(_b));
	const days = differenceInDays(a, b);
	const hours = differenceInHours(a, b);
	const mins = differenceInMinutes(a, b);
	const secs = differenceInSeconds(a, b);

	return days >= 4
		? `${days}d`
		: days >= 1
			? `${days}d ${hours - days * 24}h`
			: hours >= 12
				? `${hours}h`
				: hours >= 1
					? `${hours}h ${mins - hours * 60}m`
					: mins >= 5
						? `${mins}m`
						: mins >= 1
							? `${mins}m ${secs - mins * 60}s`
							: `${secs}s`;
}
