import React from 'react';

import TooltipWrapper from '../components/TooltipWrapper';

function getTruncatedStr(str, maxLength, leftEnd = false) {
	return str?.length > maxLength
		? (leftEnd ? '...' : '') +
				str?.slice(leftEnd ? str?.length - (maxLength - 2) : 0, leftEnd ? Number.MAX_SAFE_INTEGER : maxLength - 2) +
				(!leftEnd ? '...' : '')
		: str;
}

function drawTruncatedStr(str, maxLength, leftEnd = false) {
	const truncatedStr = getTruncatedStr(str, maxLength, leftEnd);
	return truncatedStr !== str ? <TooltipWrapper text={str}>{<div>{truncatedStr}</div>}</TooltipWrapper> : str;
}

export { getTruncatedStr, drawTruncatedStr };
