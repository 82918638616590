import { addMinutes } from 'date-fns';
import { MINUTES_PER_DAY, MINUTES_PER_MONTH, MINUTES_PER_WEEK, MINUTES_PER_YEAR } from '../constants';

export function getStartEndDateFromInterval(interval) {
	if (interval == null) {
		return { startDate: undefined, endDate: undefined };
	}
	const endDate = new Date();
	if (interval === MINUTES_PER_WEEK || interval === MINUTES_PER_MONTH) {
		endDate.setDate(endDate.getDate() + 1);
	}
	if (interval === MINUTES_PER_YEAR) {
		endDate.setMonth(endDate.getMonth() + 1);
		endDate.setDate(1);
	}
	if (interval === MINUTES_PER_DAY) {
		endDate.setHours(endDate.getHours() + 1, 0, 0, 0);
	} else {
		endDate.setHours(0, 0, 0, 0);
	}

	const startDate = addMinutes(endDate, -interval);
	if (interval === MINUTES_PER_YEAR) {
		startDate.setDate(1);
	}
	return { startDate, endDate };
}
