import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import chroma from 'chroma-js';
import { addDays } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { PAGES } from '../constants';
import * as colors from '../colors';
import SmallBarchart from '../components/SmallBarchart';

const CHART_LENGTH = 5;

/**
 * Chart with information about which properties have triggered the most alarms
 * @param {Enum/Object/Undefined} selectedInterval : Info about the time-interval for which to display alarm-data
 * @param {Object[]} alarms : Info about the existing alarms, [{locationid, latesttimestamp}]
 * @param {Object[]} properties : Info about the available properties, [{locationid, city, street}]
 * @param {Object} size : Set the proportions of the graph, {width: String, height: String}
 * @param {Object} style : Passed down to the container of the text and chart
 * @param {Object} chartProps : Additional props that will be passed directly to the chart
 */
function MostAlarmsChart(props) {
	const [mostAlarmProperties, setMostAlarmsProperties] = useState([]);

	const routerHistory = useHistory();

	const averageAlarmCount = props.properties?.length
		? mostAlarmProperties.reduce((sum, cur) => sum + cur.value, 0) / props.properties.length
		: 0;

	const { t } = useTranslation();

	// Update mostAlarmProperties whenever selectedInterval changes
	useEffect(() => {
		let mostAlarmProperties = [];

		if (props.alarms?.length) {
			const alarmsInTimeframe = props.selectedInterval
				? props.alarms.filter(alarm => addDays(new Date(), -props.selectedInterval.days) <= new Date(alarm.latesttimestamp))
				: props.alarms;

			const idCounts = {};
			for (const alarm of alarmsInTimeframe) {
				const count = idCounts[alarm.locationid];
				idCounts[alarm.locationid] = count ? count + 1 : 1;
			}

			// Create sorted list of location-ids in their alarm-counts, array must be sorted so the smallest entries will be cut
			let keyValues = Object.entries(idCounts);
			keyValues.sort((a, b) => (a[1] < b[1] ? -1 : 1));

			mostAlarmProperties = keyValues
				.map(kv => {
					const property = props.properties?.find(pro => pro.locationid === Number(kv[0]));
					return (
						property && {
							label: `${property.city}: ${property.street}`,
							value: kv[1],
							locationid: property.locationid,
						}
					);
				})
				.filter(val => val);
		}

		while (mostAlarmProperties.length < CHART_LENGTH)
			mostAlarmProperties.unshift({ label: String(' ').repeat(mostAlarmProperties.length), value: Number.MIN_VALUE });

		setMostAlarmsProperties(mostAlarmProperties);
	}, [props.selectedInterval, props.alarms, props.properties]);

	function onClick(val, isTick = false) {
		// Check for `=== true` required as an object might be unintentionally passed as the second argument
		const locationid = Number((isTick === true ? val.value : val.data.label)?.split(';')[1]);
		if (locationid) routerHistory.push(`/${PAGES.properties.id}/${locationid}`);
	}

	return (
		<SmallBarchart
			header={t('mostAlarmsChart.mostAlarms')}
			subheader={t('mostAlarmsChart.onAveragePerProp', { count: Number(averageAlarmCount.toFixed(1)) })}
			colors={[
				chroma(colors.secondaryB).darken(1.6).hex(),
				chroma(colors.secondaryB).darken(1.2).hex(),
				chroma(colors.secondaryB).darken(0.8).hex(),
				chroma(colors.secondaryB).darken(0.4).hex(),
				colors.secondaryB,
			]}
			data={mostAlarmProperties
				.slice(Math.max(0, mostAlarmProperties.length - CHART_LENGTH)) // Limit the length of the chart-data array
				.map(val => ({ ...val, label: `${val.label};${val.locationid}` }))}
			unit={t('mostAlarmsChart.alarm')}
			size={props.size}
			style={props.style}
			chartProps={{ onClick: onClick, ...props.chartProps }}
		/>
	);
}

export default MostAlarmsChart;
