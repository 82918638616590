import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Select from '../components/SelectWrapper';
import { STORE, getStateVariables } from '../redux/selectors';

/**
 * A selection list for sensors
 * @param {Function} setSelectedSensor: a callback function which sets the selected sensor ID
 * @param {Function} onSelectionChange: a callback function which is called when the selection changes
 * @returns {JSX.Element} Sensor selection component
 */
function SensorSelection(props) {
    const { locationid, setSelectedSensor, onSelectionChange } = props;

    const sensorOptions = [
        ...props.sensors.filter(sensor => sensor.locationid === locationid)
            .map(sensor => ({
                value: sensor.sensorid,
                label: sensor.name || '-',
            })),
    ];

    const { t } = useTranslation();

    useEffect(() => {
        if (sensorOptions.length === 0) setSelectedSensor(undefined);
    }, []);

    const handleSensorChange = sel => {
        setSelectedSensor(sel || undefined);
        if (onSelectionChange) {
            onSelectionChange(sel || undefined);
        }
    };

    return (
        <Select
            selectProps={{
                placeholder: t('registrationWizard.selectSensor'),
                options: sensorOptions,
                onChange: handleSensorChange,
            }}
            style={{ width: '20rem' }}
        />
    );
}

export default connect(getStateVariables(STORE.sensors))(SensorSelection);
