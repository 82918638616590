import React from 'react';

import { Button } from '@material-ui/core';

import Select from './SelectWrapper';
import * as colors from '../colors';

/**
 * Sidebar with buttons for editing, archiving, and a multi-select field for choosing filters to display
 * @param {Object[]} buttons : Buttons to be listed above the filter, [{label: String, onClick: Function, disabled: Boolean}, ...]
 * @param {String[]} filters : Listed in the multi-select field
 * @param {Function} updateSelectedFilters : Callback for when the list of filters to filter by changes
 * @param {Object} localization : Use-specific strings, {filterTitle, filterPlaceholder}
 * @param {Object} style : Additional styles to be applied to the root element
 */
function SelectionSidebar(props) {
	const buttons = props.buttons || [],
		filters = props.filters,
		updateSelectedFilters = props.updateSelectedFilters,
		localization = props.localization || {},
		style = props.style;

	const buttonStyle = { width: '100%', margin: '0 0 0.5rem 0', textTransform: 'none', color:colors.primary};

	function onSelectionChange(newFilters, event) {
		// Don't trigger callback when pressing backspace on an empty field ('pop-value' with undefined value)
		if (event.action !== 'pop-value' || event.removedValue) {
			updateSelectedFilters(newFilters || []);
		}
	}

	return (
		<div style={{ ...style }}>
			{buttons.map(but => (
				<Button
					variant='outlined'
					disabled={but.disabled}
					onClick={but.onClick}
					style={buttonStyle}
					key={but.label}
				>
					{but.label}
				</Button>
			))}
			{/* <Button variant='outlined' disabled={!buttonsAreActive} onClick={onEditClick} style={buttonStyle}>
				Redigera valda
			</Button>
			<Button variant='outlined' disabled={!buttonsAreActive} onClick={onArchiveClick} style={buttonStyle}>
				Arkivera valda
			</Button> */}

			<h3 style={{ fontSize: '115%', fontWeight: '300', margin: (buttons.length ? '0.8rem' : '0') + ' 0 0.2rem 0.1rem' }}>
				{localization.filterTitle}
			</h3>
			{filters && (
				<Select
					selectProps={{
						isMulti: true,
						closeMenuOnSelect: false,
						placeholder: localization.filterPlaceholder,
						options: filters,
						onChange: onSelectionChange,
					}}
				/>
			)}
		</div>
	);
}

export default SelectionSidebar;
