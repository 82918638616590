import { UNIT_CLASSIFICATIONS, CLASSIFICATIONS } from '../constants';


function isEnergySensor(sensor) {
	return (
		(
			sensor.includeintotal !== false
			&&
			sensor.locationid !== 794
			&&
			(sensor.accumulateddata === true || (sensor.unit && sensor.unit.toLowerCase().includes('h')) || sensor.includeintotal === true)
			&&
			(sensor.name && !sensor.name.includes('calculated'))
			&&
			(
				[
					UNIT_CLASSIFICATIONS.electricity.id,
					UNIT_CLASSIFICATIONS.districtHeating.id
				].includes(
					sensor.classification
				)
				||
				[
					CLASSIFICATIONS.energy.subcategories.electricity_usage.id,
					CLASSIFICATIONS.energy.subcategories.district_heating_usage.id,
					UNIT_CLASSIFICATIONS.electricity.id,
					UNIT_CLASSIFICATIONS.districtHeating.id
				].includes(
					sensor.subcategory
				)
			)
		)
	);
}

/**
 * Returns a filtered list of energy-sensors
 * @param {object[]} sensors : Sensors to filter
 * @param {sensor => boolean} rule : Returns true if the sensor should be included
 * @returns {object[]} : `sensors` - minus non-energy-sensors
 */
function getEnergySensors(sensors, rule) {
	let filtered_sensors = (
		sensors?.filter(
			sen =>
				(
					isEnergySensor(sen)
					&& (typeof rule !== 'function' || rule(sen))
				)
		) || []
	);
	return filtered_sensors;
}

export { getEnergySensors };
