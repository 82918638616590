import { Button } from '@material-ui/core';
import React from 'react';
import xlsx from 'xlsx';

import * as colors from '../colors';

/**
 * @param {function} setSelectedECP: Callback which sets the selected id of the selected options
 */
function DucUploadButton(props) {
	function selectParseFormatFile() {
		const inputEle = document.createElement('input');
		inputEle.type = 'file';
		inputEle.onchange = e => {
			const file = e.target.files?.[0];
			if (
				!e.target.validity?.valid ||
				(!file?.type.startsWith('application/vnd.ms-excel.sheet') &&
					!file?.type.startsWith('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') &&
					!file?.type.startsWith('text/csv'))
			) {
				props.setIsInvalidFile(true);
				return;
			}
			props.setFileName(file.name);
			props.setIsInvalidFile(false);
			function sanitizeUnit(unit) {
				// Remove special characters like � from unit
				return unit ? unit.replace(/�/g, '') : '';
			}
			//csv file parsing
			if (file?.type.startsWith('text/csv')) {
				const reader = new FileReader();
				reader.readAsText(file);
				reader.onload = e => {
					const csv = e.target.result;
					const rows = csv.split('\n').map(row => row.trim()).filter(row => row);
					const fSheet = [];
					for (let i = 1; i < rows.length; i++) {

						const row = rows[i].split(';').map(cell => cell.trim());
						//const address = parseInt(row[4], 10);
						//if (isNaN(address)) continue; 
						const entry = {
							objectname: row[0],
							groupname: row[1],
							functions: row[2]
								? row[2]
									.replace(/[0, \/]/g, '')
									.split('')
									.map(s => Number(s))
								: [],
							canread: row[3] ? row[3].includes('R') : false,
							canwrite: row[3] ? row[3].includes('W') : false,
							address: parseInt(row[4], 10),
							unit: sanitizeUnit(row[5]) || null,
							multiplier: row[8] ? Number(row[8]) : null,
						};
						fSheet.push(entry);
					}
					props.setFormattedSheet(fSheet);
				}
			}
			//xlsx file parsing
			else {

				const reader = new FileReader();
				reader.readAsArrayBuffer(file);
				reader.onload = e => {
					const doc = xlsx.read(new Uint8Array(reader.result), { type: 'array' });
					const sheet = doc.Sheets[doc.SheetNames?.[doc.SheetNames?.length - 1]];

					const shouldOffset = sheet.A1?.t !== 's';
					const cols = {
						obj: String.fromCharCode('A'.charCodeAt(0) + shouldOffset),
						grp: String.fromCharCode('C'.charCodeAt(0) + shouldOffset),
						par: String.fromCharCode('D'.charCodeAt(0) + shouldOffset),
						fun: String.fromCharCode('E'.charCodeAt(0) + shouldOffset),
						rw: String.fromCharCode('F'.charCodeAt(0) + shouldOffset),
						add: String.fromCharCode('G'.charCodeAt(0) + shouldOffset),
					};

					const fSheet = [];
					function addRegister(rowNum, group, address) {
						function getContainer(column, returnRowI, stopAfterRowI) {
							for (let rowI = rowNum; rowI >= (stopAfterRowI || 0); --rowI) {
								const con = sheet[column + rowI]?.v;
								if (con) return returnRowI ? rowI : con;
							}
							return '';
						}

						const add = address || sheet[cols.add + rowNum].v;
						if (!fSheet.find(row => row.address === add))
							fSheet.push({
								objectname: getContainer(cols.obj),
								groupname: String(group || getContainer(cols.grp, false, getContainer(cols.obj, true))),
								parametername: sheet[cols.par + rowNum].v,
								functions: sheet[cols.fun + rowNum].v
									.replace(/0|,| |\//g, '')
									.split('')
									.map(s => Number(s)),
								canread: sheet[cols.rw + rowNum].v.includes('R'),
								canwrite: sheet[cols.rw + rowNum].v.includes('W'),
								address: add,
							});
					}

					for (const addRow of Object.keys(sheet).filter(key => key[0] === cols.add && /^\d+$/.test(key.slice(1)))) {
						const rowNum = addRow.slice(1);
						if (
							(shouldOffset && !sheet['A' + rowNum].v) ||
							!(cols.par + rowNum in sheet) ||
							!(cols.fun + rowNum in sheet) ||
							!(cols.rw + rowNum in sheet) ||
							!(cols.add + rowNum in sheet)
						)
							continue;
						if (sheet[addRow].t === 'n') addRegister(rowNum);
						else if (sheet[addRow].v?.startsWith('Gr0=')) {
							const v = sheet[addRow].v;
							const firstAdd = Number(v.slice(4, v.indexOf(',')));
							const lastAdd = Number(v.slice(v.lastIndexOf('=') + 1));
							if (!isNaN(firstAdd) && !isNaN(lastAdd))
								for (let add = firstAdd; add <= lastAdd; ++add) addRegister(rowNum, 'Gr' + (add - firstAdd), add);
						}
					}

					props.setFormattedSheet(fSheet);
				};
			}
		};
		inputEle.click();
	}

	return (
		<Button style={{ color: colors.primary }} disabled={props.disabled} variant='outlined' size='small' onClick={selectParseFormatFile}>
			{props.children}
		</Button>
	);
}

export default DucUploadButton;
