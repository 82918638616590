import React, { useState } from 'react';

import { IconButton, SwipeableDrawer } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { isMobile } from 'react-device-detect';

import * as colors from '../colors';
import { Sidebar } from './Sidebar';

const TOPBAR_HEIGHT = '4rem';

function NavigationBarHandler() {
	const [showSidebar, setShowSidebar] = useState(!isMobile);

	if (!isMobile) return <Sidebar />;
	else
		return (
			<div>
				{/* Top-bar */}
				<div
					style={{
						color: '#fff',
						background: colors.bgcolor,
						boxShadow: '0 0 0.35rem #000a',
						position: 'fixed',
						width: '100%',
						height: TOPBAR_HEIGHT,
						left: '0',
						zIndex: '999',
						display: 'grid',
						gridTemplateColumns: '4.5rem auto',
					}}
				>
					<div style={{ gridColumn: '1 / 2', margin: 'auto' }}>
						<IconButton color='inherit' onClick={() => setShowSidebar(!showSidebar)}>
							<MenuIcon />
						</IconButton>
					</div>
					<div style={{ gridColumn: '2 / 3' }}>
						<h2 style={{ margin: '1rem 0', fontWeight: '500' }}>Meliox Connect</h2>
					</div>
				</div>

				{/* Global offset for top-bar */}
				<div style={{ width: '100%', height: TOPBAR_HEIGHT }} />

				{/* Expandable sidebar */}
				<SwipeableDrawer anchor='left' open={showSidebar} onOpen={() => setShowSidebar(true)} onClose={() => setShowSidebar(false)}>
					<Sidebar cssPosition='static' onRedirect={() => setShowSidebar(false)} />
				</SwipeableDrawer>
			</div>
		);
}

export default NavigationBarHandler;
