import React, { useState, useEffect } from 'react';

import { MenuItem, Button, Paper, Tooltip, Popper, ClickAwayListener, MenuList } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import UpdateIcon from '@material-ui/icons/Update';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
	largeTooltip: {
		fontSize: '12px',
		padding: '10px',
		textDecoration: 'uppercase',
		marginBottom: '14px',
	},
}));

/**
 * Draws a time selection component for choosing a time span
 * @param {List} timeOptions : Which time interval options to use
 * @param {Boolean} offOption : If the user can choose to turn auto update off
 * @param {Function} callback : Called when the toggle changes, with the new state of the button
 * @param {Object} style : CSS properties applied to root
 */
function AutoUpdateSelection({ timeOptions, updateInterval, offOption = true, callback }) {
	const classes = useStyles();

	const { t } = useTranslation();
	const OFF_OPTION = { text: t('autoUpdate.off'), interval: 0 };

	const [selectedOption, setSelectedOption] = useState(updateInterval || OFF_OPTION.interval);
	const [anchorEl, setAnchorEl] = useState(null);

	const handleSave = interval => {
		if (selectedOption !== interval) {
			setSelectedOption(interval);
			callback({
				interval: interval,
			});
		}
		handleCloseList();
	};

	const options = [];

	if (offOption) {
		options.push(
			<MenuItem key={OFF_OPTION.interval} value={OFF_OPTION.interval} onClick={() => handleSave(OFF_OPTION.interval)}>
				{OFF_OPTION.text}
			</MenuItem>
		);
	}

	for (const timeOption of timeOptions) {
		options.push(
			<MenuItem key={timeOption.interval} value={timeOption.interval} onClick={() => handleSave(timeOption.interval)}>
				{timeOption.text}
			</MenuItem>
		);
	}

	// useEffect(() => {
	// 	if (callback) {
	// 		triggerCallback();
	// 	}
	// 	// eslint-disable-next-line
	// }, []);

	// const triggerCallback = () => {
	// 	const selected = getOptionFromSelected();
	// 	callback({
	// 		interval: selected.interval,
	// 	});
	// };

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleCloseList = () => {
		setAnchorEl(null);
	};

	const getChipDisplayText = () => {
		return `${getOptionFromSelected().text}`;
	};

	const getOptionFromSelected = () => {
		return timeOptions.find(el => el.interval === selectedOption) || OFF_OPTION;
	};

	const open = Boolean(anchorEl);

	return (
		<>
			<Tooltip title={t('constants.selectUpdateInterval')} placement='top' arrow classes={{ tooltip: classes.largeTooltip }}>
				<Button
					datatype-cy='btn-update'
					startIcon={<UpdateIcon style={{ marginRight: '-5px', marginTop: '9px' }} />}
					color='inherit'
					onClick={handleClick}
				>
					{getChipDisplayText()}
				</Button>
			</Tooltip>

			<Popper open={open} anchorEl={anchorEl}>
				<Paper style={{ minWidth: anchorEl?.offsetWidth ?? `${anchorEl?.offsetWidth}px` }}>
					<ClickAwayListener onClickAway={handleCloseList}>
						<MenuList>{options}</MenuList>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	);
}

export default AutoUpdateSelection;
