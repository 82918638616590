import React, { useState, useEffect } from 'react';
import { Dialog } from '@material-ui/core';
import { connect } from 'react-redux';

import { getStateVariables, STORE } from '../redux/selectors';
import MatterportThumbnail from './MatterportThumbnail';
import ThreeSixtyView from './ThreeSixtyView';

/**
 * Draws a list of property thumbnails which can be clicked for a 360-popup
 * @param {number} locationid : Currently selected property
 */
function ThreeSixtyTab(props) {
	const [selectedDigitalTwin, setSelectedDigitalTwin] = useState();
	const [show360Popup, setShow360Popup] = useState(false);

	useEffect(() => {
		if (selectedDigitalTwin) setShow360Popup(true);
		// eslint-disable-next-line
	}, [selectedDigitalTwin]);

	return (
		<>
			<div style={{ display: 'flex', flexWrap: 'wrap', margin: '0 0 0 1.4rem' }}>
				{props.digitalTwins
					.filter(dt => dt.locationid === props.locationid)
					.map(dt => (
						<MatterportThumbnail
							model={dt.model}
							label={dt.label}
							onClick={() => setSelectedDigitalTwin(dt)}
							key={dt.digitaltwinid}
						/>
					))}
			</div>

			<Dialog
				open={show360Popup}
				onClose={() => setShow360Popup(false)}
				onExited={() => setSelectedDigitalTwin()}
				PaperComponent={props => <>{props.children}</>}
			>
				<ThreeSixtyView digitalTwin={selectedDigitalTwin} />
			</Dialog>
		</>
	);
}

export default connect(getStateVariables(STORE.digitalTwins))(ThreeSixtyTab);
