/**
 * Finds the difference between two arrays, finding both what has been removed and added
 * @param {T[]} oldArr
 * @param {T[]} newArr
 * @param {T => any} map : Mapping function used before comparing elements, when comparing elements directly (===) isn't appropriate, !function = unset
 * @param {boolean} returnUndefined : Whether to return undefined instead of empty arrays when no differences were found
 * @returns {{added: T[], removed: T[]}}
 */
function getDifference(_oldArr, _newArr, _map, returnUndefined = false) {
	const oldArr = _oldArr || [],
		newArr = _newArr || [],
		map = typeof _map === 'function' ? _map : ele => ele;

	const diff = {
		added: newArr.filter(ele => !oldArr.map(map).includes([ele].map(map)[0])),
		removed: oldArr.filter(ele => !newArr.map(map).includes([ele].map(map)[0])),
	};

	return !returnUndefined || diff.added.length || diff.removed.length ? diff : undefined;
}

export { getDifference };
