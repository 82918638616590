/**
 * Returns a number with the given amount of decimals
 * - 0s at the end are removed
 * @param {number | string} number
 * @param {number | string} decimalCount
 */
function getDecimals(number, decimalCount) {
	return Number(Number(number).toFixed(Number(decimalCount)));
}

export default getDecimals;
