import {
	SET_HASFINISHEDLOADING,
	SET_USERINFO,
	SET_PAGE,
	SET_TAB,
	SET_PROPERTY,
	SET_SENSORS,
	SET_PROPERTIES,
	SET_SENSORGROUPS,
	SET_DIGITALTWINS,
	SET_DIGITALTWINTAGS,
	SET_DIGITALTWINPERSPECTIVES,
	SET_COS,
	SET_COREGISTERS,
	SET_COREGISTEROFFSETS,
	SET_ACTIONS,
	SET_FILERECORDS,
	SET_ALARMPERIODSENSORS,
	SET_DEVIATIONS,
	SET_CAMERAS,
	SET_EXTERNALCONTROLPANELS,
	UPDATE_SENSORS,
	UPDATE_SENSORGROUPS,
	UPDATE_PROPERTIES,
	UPDATE_DIGITALTWINS,
	UPDATE_DIGITALTWINTAGS,
	UPDATE_DIGITALTWINPERSPECTIVES,
	UPDATE_COS,
	UPDATE_COREGISTERS,
	UPDATE_COREGISTEROFFSETS,
	UPDATE_ACTIONS,
	UPDATE_USERINFO,
	UPDATE_FILERECORDS,
	UPDATE_ALARMPERIODSENSORS,
	UPDATE_DEVIATIONS,
	UPDATE_CAMERAS,
	UPDATE_EXTERNALCONTROLPANELS,
	ADD_CALLBACK,
	REMOVE_CALLBACK,
} from './actionTypes';

import { PAGES } from '../constants';

export default function rootReducer(state, action) {
	switch (action.type) {
		case SET_HASFINISHEDLOADING:
			return {
				...state,
				hasFinishedLoading: action.hasFinishedLoading,
			};
		case SET_USERINFO:
			return {
				...state,
				userInfo: { ...state.userInfo, ...action.userInfo },
			};
		case SET_PAGE:
			return {
				...state,
				currentPage: action.page,
				currentTab: action.page === PAGES.properties.id ? state.currentTab : '',
				currentProperty: action.page === PAGES.properties.id ? state.currentProperty : '',
			};
		case SET_TAB:
			return {
				...state,
				currentTab: action.tab,
			};
		case SET_PROPERTY:
			return {
				...state,
				currentProperty: action.property,
				currentTab: action.property ? state.currentTab : '',
			};
		case SET_SENSORS: {
			return {
				...state,
				sensors: action.sensors,
			};
		}
		case SET_PROPERTIES: {
			return {
				...state,
				properties: action.properties,
			};
		}
		case SET_SENSORGROUPS: {
			return {
				...state,
				sensorGroups: action.sensorGroups,
			};
		}
		case SET_DIGITALTWINS: {
			return {
				...state,
				digitalTwins: action.digitalTwins,
			};
		}
		case SET_DIGITALTWINTAGS: {
			return {
				...state,
				digitalTwinTags: action.digitalTwinTags,
			};
		}
		case SET_DIGITALTWINPERSPECTIVES: {
			return {
				...state,
				digitalTwinPerspectives: action.digitalTwinPerspectives,
			};
		}
		case SET_COS: {
			return {
				...state,
				cos: action.cos,
			};
		}
		case SET_COREGISTERS: {
			return {
				...state,
				coRegisters: action.coRegisters,
			};
		}
		case SET_COREGISTEROFFSETS: {
			return {
				...state,
				coRegisterOffsets: action.coRegisterOffsets,
			};
		}
		case SET_ACTIONS: {
			return {
				...state,
				actions: action.actions,
			};
		}
		case SET_FILERECORDS: {
			return {
				...state,
				fileRecords: action.fileRecords,
			};
		}
		case SET_ALARMPERIODSENSORS: {
			return {
				...state,
				alarmPeriodSensors: action.alarmPeriodSensors,
			};
		}
		case SET_DEVIATIONS: {
			return {
				...state,
				deviations: action.deviations.map(dev => ({
					...dev,
					datecreated: dev.datecreated && new Date(dev.datecreated),
					dateconfirmed: dev.dateconfirmed && new Date(dev.dateconfirmed),
				})),
			};
		}
		case SET_CAMERAS: {
			return {
				...state,
				cameras: action.cameras,
			};
		}
		case SET_EXTERNALCONTROLPANELS: {
			return {
				...state,
				externalControlPanels: action.externalControlPanels,
			};
		}

		case UPDATE_SENSORS: {
			for (const newSen of action.sensors) {
				const oldSenI = state.sensors.findIndex(sen => String(sen.sensorid) === String(newSen.sensorid));
				if (oldSenI !== -1) state.sensors[oldSenI] = { ...state.sensors[oldSenI], ...newSen };
				else state.sensors.push(newSen);
			}

			return {
				...state,
				sensors: [...state.sensors],
			};
		}
		case UPDATE_SENSORGROUPS: {
			for (const newGrp of action.sensorGroups) {
				const oldGrpI = state.sensorGroups.findIndex(grp => String(grp.sensorgroupid) === String(newGrp.sensorgroupid));
				if (oldGrpI !== -1) state.sensorGroups[oldGrpI] = { ...state.sensorGroups[oldGrpI], ...newGrp };
				else state.sensorGroups.push(newGrp);
			}

			return {
				...state,
				sensorGroups: [...state.sensorGroups],
			};
		}
		case UPDATE_PROPERTIES: {
			for (const newPro of action.properties) {
				const oldProI = state.properties.findIndex(pro => String(pro.locationid) === String(newPro.locationid));
				if (oldProI !== -1) state.properties[oldProI] = { ...state.properties[oldProI], ...newPro };
				else state.properties.push(newPro);
			}

			return {
				...state,
				properties: [...state.properties],
			};
		}
		case UPDATE_DIGITALTWINS: {
			for (const newDT of action.digitalTwins) {
				const oldDTI = state.digitalTwins.findIndex(dt => String(dt.digitaltwinid) === String(newDT.digitaltwinid));
				if (oldDTI !== -1) state.digitalTwins[oldDTI] = { ...state.digitalTwins[oldDTI], ...newDT };
				else state.digitalTwins.push(newDT);
			}

			return {
				...state,
				digitalTwins: [...state.digitalTwins],
			};
		}
		case UPDATE_DIGITALTWINTAGS: {
			for (const newTag of action.digitalTwinTags) {
				const oldTagI = state.digitalTwinTags.findIndex(tag => String(tag.digitaltwintagid) === String(newTag.digitaltwintagid));
				if (oldTagI !== -1) state.digitalTwinTags[oldTagI] = { ...state.digitalTwinTags[oldTagI], ...newTag };
				else state.digitalTwinTags.push(newTag);
			}

			return {
				...state,
				digitalTwinTags: [...state.digitalTwinTags],
			};
		}
		case UPDATE_DIGITALTWINPERSPECTIVES: {
			for (const newPer of action.digitalTwinPerspectives) {
				const oldPerI = state.digitalTwinPerspectives.findIndex(
					per => String(per.digitaltwinperspectiveid) === String(newPer.digitaltwinperspectiveid)
				);
				if (oldPerI !== -1) state.digitalTwinPerspectives[oldPerI] = { ...state.digitalTwinPerspectives[oldPerI], ...newPer };
				else state.digitalTwinPerspectives.push(newPer);
			}

			return {
				...state,
				digitalTwinPerspectives: [...state.digitalTwinPerspectives],
			};
		}
		case UPDATE_COS: {
			for (const newCO of action.cos) {
				const oldCOI = state.cos.findIndex(co => String(co.coid) === String(newCO.coid));
				if (oldCOI !== -1) state.cos[oldCOI] = { ...state.cos[oldCOI], ...newCO };
				else state.cos.push(newCO);
			}

			return {
				...state,
				cos: [...state.cos],
			};
		}
		case UPDATE_COREGISTERS: {
			for (const newCOR of action.coRegisters) {
				const oldCORI = state.coRegisters.findIndex(coR => String(coR.coregisterid) === String(newCOR.coregisterid));
				if (oldCORI !== -1) state.coRegisters[oldCORI] = { ...state.coRegisters[oldCORI], ...newCOR };
				else state.coRegisters.push(newCOR);
			}

			return {
				...state,
				coRegisters: [...state.coRegisters],
			};
		}
		case UPDATE_COREGISTEROFFSETS: {
			for (const newOff of action.coRegisterOffsets) {
				const oldOffI = state.coRegisterOffsets.findIndex(off => String(off.coregisterid) === String(newOff.coregisterid));
				if (oldOffI !== -1) state.coRegisterOffsets[oldOffI] = { ...state.coRegisterOffsets[oldOffI], ...newOff };
				else state.coRegisterOffsets.push(newOff);
			}

			return {
				...state,
				coRegisterOffsets: [...state.coRegisterOffsets],
			};
		}
		case UPDATE_ACTIONS: {
			for (const newAct of action.actions) {
				const oldActI = state.actions.findIndex(act => String(act.actionid) === String(newAct.actionid));
				if (oldActI !== -1) state.actions[oldActI] = { ...state.actions[oldActI], ...newAct };
				else state.actions.push(newAct);
			}

			return {
				...state,
				actions: [...state.actions],
			};
		}
		case UPDATE_ALARMPERIODSENSORS: {
			for (const newAps of action.alarmPeriodSensors) {
				const oldApsI = state.alarmPeriodSensors.findIndex(
					aps => String(aps.alarmperiodsensorid) === String(newAps.alarmperiodsensorid)
				);
				if (oldApsI !== -1) state.alarmPeriodSensors[oldApsI] = { ...state.alarmPeriodSensors[oldApsI], ...newAps };
				else state.alarmPeriodSensors.push(newAps);
			}

			return {
				...state,
				alarmPeriodSensors: [...state.alarmPeriodSensors],
			};
		}
		case UPDATE_USERINFO: {
			return {
				...state,
				userInfo: { ...state.userInfo, ...action.userInfo },
			};
		}
		case UPDATE_FILERECORDS: {
			for (const newFR of action.fileRecords) {
				const oldFRI = state.fileRecords.findIndex(fr => String(fr.id) === String(newFR.id));
				if (oldFRI !== -1) state.fileRecords[oldFRI] = { ...state.fileRecords[oldFRI], ...newFR };
				else state.fileRecords.push(newFR);
			}

			return {
				...state,
				fileRecords: [...state.fileRecords],
			};
		}
		case UPDATE_DEVIATIONS: {
			for (const newDev of action.deviations) {
				const oldDevI = state.deviations.findIndex(dev => String(dev.deviationid) === String(newDev.deviationid));
				if (oldDevI !== -1) state.deviations[oldDevI] = { ...state.deviations[oldDevI], ...newDev };
				else state.deviations.push(newDev);
			}

			return {
				...state,
				deviations: [...state.deviations],
			};
		}
		case UPDATE_CAMERAS: {
			for (const newCam of action.cameras) {
				const oldCamI = state.cameras.findIndex(cam => String(cam.cameraid) === String(newCam.cameraid));
				if (oldCamI !== -1) state.cameras[oldCamI] = { ...state.cameras[oldCamI], ...newCam };
				else state.cameras.push(newCam);
			}

			return {
				...state,
				cameras: [...state.cameras],
			};
		}
		case UPDATE_EXTERNALCONTROLPANELS: {
			for (const newEcp of action.externalControlPanels) {
				const oldEcpI = state.externalControlPanels.findIndex(
					ecp => String(ecp.externalcontrolpanelid) === String(newEcp.externalcontrolpanelid)
				);
				if (oldEcpI !== -1) state.externalControlPanels[oldEcpI] = { ...state.externalControlPanels[oldEcpI], ...newEcp };
				else state.externalControlPanels.push(newEcp);
			}

			return {
				...state,
				externalControlPanels: [...state.externalControlPanels],
			};
		}

		case ADD_CALLBACK: {
			const updatedCallbacks = state.callbacks;

			if (!updatedCallbacks[action.trigger]) updatedCallbacks[action.trigger] = [];

			const preexistingCallback = updatedCallbacks[action.trigger].find(callback => callback.id === action.id);
			if (preexistingCallback) preexistingCallback.callback = action.callback;
			else updatedCallbacks[action.trigger].push({ id: action.id, callback: action.callback });

			return {
				...state,
				callbacks: updatedCallbacks,
			};
		}
		case REMOVE_CALLBACK: {
			const updatedCallbacks = state.callbacks;
			updatedCallbacks[action.trigger] = updatedCallbacks[action.trigger].filter(callback => callback.id !== action.id);

			return {
				...state,
				callbacks: updatedCallbacks,
			};
		}

		default:
			return state;
	}
}
