import React from 'react';

/**
 * Draws a custom, highly customizable, button
 * @param {Component} icon : Drawn inside the button, left of the text
 * @param {String} text : Drawn inside the button, right of the icon
 * @param {Function} onClick : Called when the button is pressed
 * @param {Object} style : Overrides defaults style
 * @param {JSX} children : Elements placed inside the component
 */
function CustomButton(props) {
	const onClick = props.onClick || null;

	const content = (
		<div style={{ display: 'flex' }}>
			{props.icon ? <props.icon style={{ color: '#444', margin: '0 0.6rem 0 -0.6rem' }} /> : null}
			{props.text ? <p style={{ color: '#333', margin: 'auto' }}>{props.text}</p> : null}
		</div>
	);

	return (
		<div
			onClick={onClick}
			style={{
				background: '#fff',
				border: 'solid',
				borderColor: '#ddd',
				borderWidth: '0.09rem',
				borderRadius: '0.5rem',
				boxShadow: '0 0.08rem 0.1rem #0005',
				height: '2.2rem',
				margin: '1rem',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center',
				cursor: 'pointer',
				...props.style,
			}}
			id='CustomButton-button'
		>
			{content}
			{props.children}
		</div>
	);
}

export default CustomButton;
