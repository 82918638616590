import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { setProperty } from '../redux/actionCreators';
import DashboardList from '../components/DashboardList';

function Links(props) {
	const link = window.location.pathname.split('/')[2];

	const { t } = useTranslation();

	useEffect(() => {
		if (link === 'm25ad') props.setProperty(159);
		if (link === 'b4ckv') props.setProperty(213);
		if (link === 'z03gn') props.setProperty(311);
		// eslint-disable-next-line
	}, []);

	if (link === 'm25ad' || link === 'b4ckv' || link === 'z03gn' || link === 'blank') return <DashboardList />;
	else return <div style={{ fontSize: '120%', marginTop: '1rem' }}>{t('links.linkNotFound')}</div>;
}

export default connect(null, { setProperty })(Links);
