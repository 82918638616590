import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { connect } from 'react-redux';
import { STORE, getStateVariables } from '../../redux/selectors';
import { ButtonCancel, ButtonContinue, SensorSelect, WizardAlert } from './WizardComponents';
import { Router, SettingsInputAntenna } from '@material-ui/icons';

const GET_IMPORTEDREGISTERS = gql`
	query ($filter: ImportedRegisterFilter!) {
		getImportedRegisters(filter: $filter) {
			externalcontrolpanelid
			objectname
			groupname
			parametername
			address
			canread
			canwrite
			read
			write
			automation
		}
	}
`;

const OBJECT_FILTER = 'Contoller object';

const CURVE_X_PATTERN = /^X\d+$/;
const CURVE_Y_PATTERN = /^Y\d+$/;

function smartGroups(importedRegisters, basId) {
	if (!basId || !importedRegisters) return [];
	function isCurve(parametername) {
		return CURVE_X_PATTERN.test(parametername) || CURVE_Y_PATTERN.test(parametername);
	}
	const registersByGroups = importedRegisters.filter(
		ecp => ecp.externalcontrolpanelid === basId && ecp.groupname && ecp.objectname === OBJECT_FILTER && isCurve(ecp.parametername)
	);
	return [...new Set(registersByGroups?.map(reg => reg.groupname))].map(group => ({
		value: group,
		label: group,
	}));
}

function matchXYCurves(shortestList, longestlist) {
	const matchingCurves = [];
	shortestList.forEach(curve => {
		const match = longestlist.find(
			c => c.parametername[c.parametername.length - 1] === curve.parametername[curve.parametername.length - 1]
		);
		if (match) {
			matchingCurves.push(curve, match);
		}
	});
	return matchingCurves;
}

function smartCurves(importedRegisters, basId, selectedControlObject) {
	if (!basId || !importedRegisters || !selectedControlObject) return [];
	const allControlObjectsByGroup = importedRegisters.filter(
		ecp => ecp.externalcontrolpanelid === basId && ecp.groupname === selectedControlObject && ecp.objectname === OBJECT_FILTER
	);

	const xCurves = allControlObjectsByGroup.filter(curve => CURVE_X_PATTERN.test(curve.parametername));
	const yCurves = allControlObjectsByGroup.filter(curve => CURVE_Y_PATTERN.test(curve.parametername));

	return xCurves.length > yCurves.length ? matchXYCurves(xCurves, yCurves) : matchXYCurves(yCurves, xCurves);
}

function SelectBas(props) {
	//const { bas, curve } = props.options;
	const { t } = useTranslation();
	const [selectedBas, setSelectedBas] = useState(props.options.bas);
	const [selectedControlObject, setSelectedControlObject] = useState(props.options.controlObject);
	const [selectedCurves, setSelectedCurves] = useState(props.options.curves);

	const propertyECPs = props.externalControlPanels.filter(ecp => Number(ecp.locationid) === props.property.locationid);
	const { data } = useQuery(GET_IMPORTEDREGISTERS, {
		variables: { filter: { externalcontrolpanelids: propertyECPs.map(ecp => ecp.externalcontrolpanelid) } },
	});

	const selectedBasLabel = `${props.property.city}: ${props.property.street} • ${
		selectedBas?.label || selectedBas?.ip || selectedBas?.url || '-'
	}`;

	const ecpOptions = [
		...props.externalControlPanels.map(ecp => ({
			value: ecp.externalcontrolpanelid,
			label: (() => {
				const pro = props.properties.find(pro => pro.locationid === Number(ecp.locationid));
				return `${pro?.city}: ${pro?.street} • ${ecp.label || ecp.ip || ecp.url || '-'}`;
			})(),
		})),
	].filter(t => t.label.includes(props.property?.city + ': ' + props.property?.street ?? ''));

	const groupOptions = useMemo(
		() => smartGroups(data?.getImportedRegisters || [], selectedBas?.externalcontrolpanelid),
		[data, selectedBas]
	);

	const basFromId = externalcontrolpanelid => {
		return propertyECPs.find(ecp => ecp.externalcontrolpanelid === externalcontrolpanelid);
	};

	const handleSelectBas = value => {
		if (value === selectedBas?.externalcontrolpanelid) return;
		setSelectedBas(basFromId(value));
		setSelectedControlObject(undefined);
		setSelectedCurves([]);
	};

	const handleSelectControlObject = value => {
		setSelectedControlObject(value);
		setSelectedCurves(smartCurves(data?.getImportedRegisters || [], selectedBas?.externalcontrolpanelid, value));
	};

	const handleContinue = () => {
		// Do checks
		props.onContinue({ bas: selectedBas, controlObject: selectedControlObject, curves: selectedCurves });
	};

	return (
		<>
			<div style={{ height: '70vh' }}>
				<h2>{t('ducSelection.pageTitle')}</h2>
				<hr />
				<SensorSelect
					options={ecpOptions}
					icon={<Router />}
					onSelectionChange={handleSelectBas}
					defaultValue={
						selectedBas
							? {
									value: selectedBas.externalcontrolpanelid,
									label: selectedBasLabel,
								}
							: undefined
					}
					placeholder={t('ducSelection.selectDUC')}
					style={{ marginTop: '2rem' }}
				>
					{t('generic.bms')}
				</SensorSelect>
				<SensorSelect
					options={groupOptions}
					icon={<SettingsInputAntenna />}
					onSelectionChange={handleSelectControlObject}
					value={selectedControlObject ? { value: selectedControlObject, label: selectedControlObject } : ''}
					isDisabled={!Boolean(selectedBas)}
					placeholder={t('ducSelection.selectControlObject')}
					style={{ marginTop: '2rem' }}
				>
					{t('generic.controlObject')}
				</SensorSelect>

				{selectedCurves.length ? (
					<WizardAlert severity='info'>
						{t('ducSelection.curvesFound', {
							xCurves: selectedCurves.filter(c => CURVE_X_PATTERN.test(c.parametername)).length,
							yCurves: selectedCurves.filter(c => CURVE_Y_PATTERN.test(c.parametername)).length,
						})}
					</WizardAlert>
				) : undefined}

				{!selectedCurves.length && selectedControlObject ? (
					<WizardAlert severity='error'>{t('ducSelection.noCurvesFound')}</WizardAlert>
				) : undefined}
			</div>
			<div style={{ display: 'flex', bottom: '1rem', right: '1rem', justifyContent: 'end' }}>
				<ButtonCancel onClick={props.onCancel} />
				<ButtonContinue disabled={!selectedBas || !selectedCurves.length} style={{ marginLeft: '1rem' }} onClick={handleContinue} />
			</div>
		</>
	);
}

export default connect(getStateVariables(STORE.properties, STORE.externalControlPanels))(SelectBas);
