import { format as formatDate } from 'date-fns';

/**
 * Takes two arrays of objects and combines them based on their date-property and the date-format, allowing two separate datasets covering the same timeframe to be combined
 * @param {Array of Objects} data : newData is added to this array, can be empty
 * @param {Array of Objects} newData : Contains the new values to be added, must contain a date-key
 * @param {Array of Strings} accumulationKeys : Names of the keys in data and newData to combine
 * @param {String} dateFormat : Formatting string to be used with the date-fns format function. Data combinations are done based on the string produced by this formatting
 * @param {Object} extraKeyValues : Contains extra values to be added to the data-objects, these are only added once and don't accumulate
 * @param {Boolean} allowNull : Whether to include or discard any null-values found in newData
 */
function combineTimeframeData(data, newData, accumulationKeys, dateFormat, extraKeyValues = {}, allowNull = true) {
	for (const newDatum of newData || []) {
		if (!allowNull && newDatum.value === null) continue;

		newDatum.formattedDate = formatDate(new Date(newDatum.date), dateFormat);

		let datumExists = false;
		for (const oldDatum of data)
			if (oldDatum.formattedDate === newDatum.formattedDate) {
				for (const key of accumulationKeys)
					if (typeof newDatum[key] === 'number') {
						if (typeof oldDatum[key] !== 'number') oldDatum[key] = 0; // Convert old null value to 0
						oldDatum[key] += newDatum[key];
					}

				datumExists = true;
				break;
			}

		if (!datumExists)
			data.push({
				...newDatum,
				...extraKeyValues,
			});
	}
}
export { combineTimeframeData };
