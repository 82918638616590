import React from 'react';
import { Divider, Paper } from '@material-ui/core';

import { CENTER_CONTENT_STYLE } from '../constants';

/**
 * Generic wrapper with a title, divider, and content below it
 * @param {string} title : Displayed at the top of the view
 * @param {any[]} children : Content to display inside the wrapper
 * @param {object} style : Applied to container wrapping children-content
 */
function SimplePageWrapper(props) {
	return (
		<Paper
			style={{
				width: CENTER_CONTENT_STYLE.width,
				minHeight: CENTER_CONTENT_STYLE.height,
				padding: '0 0.5rem 0.5rem 0.5rem',
			}}
		>
			<h1 style={{ padding: '1.2rem 0 0.8rem 1.2rem', fontSize: '170%', fontWeight: '400' }}>{props.title}</h1>

			<Divider style={{ margin: '0 1rem', height: '0.06rem' }} />

			<div style={props.style}>{props.children}</div>
		</Paper>
	);
}

export default SimplePageWrapper;
