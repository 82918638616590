import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { getStateVariables, STORE } from '../../redux/selectors';
import SelectionTable from '../SelectionTable';
import { useTranslation } from 'react-i18next';
import { Avatar, makeStyles, Slider, styled } from '@material-ui/core';
import { ButtonBack, ButtonContinue, WizardAlert } from './WizardComponents';
import { MeetingRoom, Whatshot } from '@material-ui/icons';
import * as colors from '../../colors';

const MIN_DEGRESS = 15;
const MAX_DEGRESS = 30;

const useStyles = makeStyles(theme => ({
	border: {
		border: '1px solid lightgrey',
		borderRadius: '5px',
	},
	range: {
		fontSize: '1rem',
	},
}));

const TemperatureSlider = styled(Slider)(({ theme }) => ({
	'& .MuiSlider-rail': {
		width: '0.4rem',
	},
	'& .MuiSlider-track': {
		width: '0.4rem',
	},
	'& .MuiSlider-thumb': {
		width: '1rem',
		height: '1rem',
	},
	'&:hover .MuiSlider-valueLabel': {
		transform: 'translate(10%, -10%)',
		transition: 'transform 0.1s ease-in-out',
		textAlign: 'center',
	},
}));

const TEMPERATURE_MARKS = [
	{
		value: MIN_DEGRESS,
		label: `${MIN_DEGRESS}°C`,
	},
	{
		value: MAX_DEGRESS,
		label: `${MAX_DEGRESS}°C`,
	},
];

function getMarks(temperatures) {
	const [min, max] = temperatures;
	const marks = [...TEMPERATURE_MARKS];
	if (min !== MIN_DEGRESS) {
		marks.push({
			value: min,
			label: `${min}°C`,
		});
	}
	if (max !== MAX_DEGRESS && max !== min) {
		marks.push({
			value: max,
			label: `${max}°C`,
		});
	}
	return marks;
}

function toolTipFormat(value) {
	return `${value}`;
}

function IndoorSensors(props) {
	const classes = useStyles();
	const { t } = useTranslation();
	const [selectedIndoorSensors, setSelectedIndoorSensors] = useState(props.options.indoorSensors);
	const [temperatures, setTemperatures] = useState([props.options.minThreshold, props.options.maxThreshold]);

	const sensors = useMemo(
		() => props.sensors.filter(({ locationid }) => locationid === props.property.locationid),
		[props.sensors, props.property]
	);

	const handleTemperatureSlider = (event, newValue) => {
		setTemperatures(newValue);
	};

	const handleContinue = () => {
		props.onContinue({ indoorSensors: selectedIndoorSensors, minThreshold: temperatures[0], maxThreshold: temperatures[1] });
	};

	return (
		<>
			<div style={{ height: '70vh' }}>
				<h2>{t('indoorTemperatureSelection.pageTitle')}</h2>
				<hr style={{ width: '100%' }} />
				<div style={{ display: 'flex', width: '100%' }}>
					<SelectionTable
						localization={{
							title: (
								<div style={{ display: 'flex', justifyContent: 'center' }}>
									<Avatar style={{ backgroundColor: colors.primary, color: 'white', margin: '0.1rem 0.7rem 0 0' }}>
										<MeetingRoom />
									</Avatar>
									<div style={{ marginTop: '0.4rem' }}>{t('registrationWizard.indoorTempSensor')}</div>
								</div>
							),
							nRowsSelected: t('registrationWizard.numberOfSelectedSensors') + '{0}',
						}} //TODO: Find a way to do this better
						data={sensors}
						selectedIds={selectedIndoorSensors.map(({ sensorid }) => sensorid)}
						dataId='sensorid'
						onSelectionChange={sens => setSelectedIndoorSensors(sens)}
						columns={[
							{ title: t('generic.name'), field: 'name', maxLength: 200 },
							{ title: t('generic.group'), field: 'groupname', maxLength: 200 },
						]}
						tableProps={{ maxColumnLength: 28, options: { maxBodyHeight: 'calc(100vh - 29rem)' } }}
						showPagination={true}
					/>

					<div className={classes.border} style={{ display: 'flex', marginLeft: '1rem' }}>
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								marginRight: '1rem',
							}}
						>
							<div style={{ display: 'flex', marginLeft: '0.7rem', alignItems: 'center', width: 'max-content' }}>
								<Avatar style={{ backgroundColor: colors.primary, color: 'white', margin: '0.7rem 0 0.7rem 0' }}>
									<Whatshot />
								</Avatar>
								<div style={{ fontSize: '1.2rem', marginLeft: '0.5rem' }}>
									{t('indoorTemperatureSelection.temperatureRange')}
								</div>
							</div>
							<div style={{ display: 'flex', height: '100%', padding: '1rem', justifyContent: 'center' }}>
								<TemperatureSlider
									valueLabelFormat={toolTipFormat}
									valueLabelDisplay='auto'
									orientation='vertical'
									min={MIN_DEGRESS}
									max={MAX_DEGRESS}
									step={0.5}
									value={temperatures}
									onChange={handleTemperatureSlider}
									marks={getMarks(temperatures)}
									style={{
										WebkitAppearance: 'slider-vertical',
									}}
								/>
							</div>
						</div>
					</div>
				</div>
				<WizardAlert severity='info'>
					{t('indoorTemperatureSelection.temperatureRangeInfo', {
						min: temperatures[0],
						max: temperatures[1],
					})}
				</WizardAlert>
			</div>
			<div style={{ display: 'flex', justifyContent: 'end' }}>
				<ButtonBack onClick={() => props.onBack()} />
				<ButtonContinue disabled={!selectedIndoorSensors.length} style={{ marginLeft: '1rem' }} onClick={handleContinue} />
			</div>
		</>
	);
}

export default connect(getStateVariables(STORE.sensors))(IndoorSensors);
