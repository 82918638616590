import React, { useEffect, useMemo, useState } from 'react';
import MaterialTableWrapper from '../MaterialTableWrapper';
import {
	Add as AddIcon,
	Settings as SettingsIcon,
	Clear as ClearIcon,
	GetAppRounded as DownloadIcon,
	Error as ErrorIcon,
} from '@material-ui/icons';
import TooltipWrapper from '../TooltipWrapper';
import { Button, Dialog, DialogActions, IconButton } from '@material-ui/core';
import * as colors from '../../colors';
import { useTranslation } from 'react-i18next';
import SelectionTable from '../SelectionTable';
import { ConfirmPopup } from '../ConfirmPopup';
import { gql, useQuery } from '@apollo/client';
import { getDifference } from '../../utility-functions';

const DEFAULTS = { maxRecipientRows: 5 };

const tableProps = {
	header: (title, buttonTooltip, buttonIcon, buttonCallback) => (
		<div>
			{/* Copied from native title styles */}
			<h2 style={{ fontSize: '1.25rem', fontWeight: '500', lineHeight: '1.6', letterSpacing: '0.0075em' }}>{title}</h2>
			<TooltipWrapper text={buttonTooltip}>
				<IconButton onClick={buttonCallback} style={{ padding: '0.4rem', position: 'absolute', top: '0.35rem', right: '0.8rem' }}>
					{buttonIcon === AddIcon ? (
						<AddIcon style={{ width: '1.8rem', height: '1.8rem', color: '#555' }} />
					) : (
						<SettingsIcon style={{ width: '1.8rem', height: '1.8rem', padding: '0.2rem', color: '#555' }} />
					)}
				</IconButton>
			</TooltipWrapper>
		</div>
	),
	options: {
		search: false,
		pageSize: DEFAULTS.maxRecipientRows,
		pageSizeOptions: [],
		headerStyle: {
			backgroundColor: colors.primary0,
			color: '#fff',
			height: '2.8rem',
			fontWeight: '430',
			fontSize: '98%',
		},
		rowStyle: {
			color: '#444',
			height: '2rem',
			whiteSpace: 'nowrap',
		},
		actionsColumnIndex: -1,
	},
	components: data => ({
		[(data?.length || 0) <= DEFAULTS.maxRecipientRows ? 'Pagination' : '']: () => null,
	}),
	style: { width: '24rem', margin: '0 0 1.3rem 2rem', overflow: 'hidden' },
};

const GET_PROPERTIES = gql`
	query {
		getSensorLocations {
			locationid
			city
			street
			cadastral
			area
		}
	}
`;

export function PropertySelection(props) {
	const { t } = useTranslation();
	const [selectedProperties, setSelectedProperties] = useState(props.properties || []);
	const [showSensorsPopup, setShowSensorsPopup] = useState(false);
	const [showSensorsConfirmPopup, setShowSensorsConfirmPopup] = useState(false);
	const { data } = useQuery(GET_PROPERTIES);
	const properties = data?.getSensorLocations || [];
	useEffect(() => {
		setSelectedProperties(props.properties || []);
	}, [props.properties]);

	return (
		<>
			<MaterialTableWrapper
				title={tableProps.header(
					t('reports.includedProperties') + ': ' + (selectedProperties.length || t('generic.all')),
					t('reports.changeProperties'),
					SettingsIcon,
					() => {
						setShowSensorsPopup(true);
					}
				)}
				columns={[
					{ title: t('generic.address'), field: 'street' },
					{ title: t('generic.city'), field: 'city' },
				]}
				data={
					selectedProperties
						.map(id => {
							const sensor = properties.find(sen => sen.locationid === id);
							return {
								street: sensor?.street || '',
								city: sensor?.city || '',
							};
						})
						.sort((a, b) => (a.city !== b.city ? (a.city < b.city ? -1 : 1) : a.street < b.street ? -1 : 1)) || []
				}
				options={tableProps.options}
				components={tableProps.components(selectedProperties)}
				style={tableProps.style}
				maxColumnLength={14}
				toolbarHeight='3.3rem'
			/>

			<Dialog
				open={showSensorsPopup}
				onClose={() => setShowSensorsPopup(false)}
				PaperProps={{ style: { width: '50rem', maxWidth: '50rem' } }}
			>
				<SelectionTable
					localization={{
						title: t('reports.selectPropertiesReport') + ': ' + t('generic.all'),
						nRowsSelected: t('reports.numberSelectedProperties') + '{0}',
					}}
					data={properties}
					dataId='locationid'
					preselectedIds={props.properties}
					columns={[
						{ title: t('generic.address'), field: 'street' },
						{ title: t('generic.city'), field: 'city' },
					]}
					pageSizeOptions={[10, 15, 20]}
					onSelectionChange={rows => {
						return setSelectedProperties(rows.map(row => row.locationid));
					}}
					style={{ boxShadow: 'none' }}
					tableProps={{ maxColumnLength: 32 }}
				/>

				<DialogActions>
					<Button
						onClick={() => {
							setSelectedProperties(props.properties || []);
							setShowSensorsPopup(false);
						}}
						style={{ color: colors.primary }}
					>
						{t('generic.cancel')}
					</Button>
					<Button
						disabled={!selectedProperties}
						onClick={() => {
							if (selectedProperties) setShowSensorsConfirmPopup(true);
							else setShowSensorsPopup(false);
						}}
						style={{ color: colors.primary }}
					>
						{t('generic.save')}
					</Button>
				</DialogActions>
			</Dialog>

			<ConfirmPopup
				enabled={showSensorsConfirmPopup}
				title={t('reports.confirmChangeProperties')}
				text={() => {
					const diffs = getDifference(props.properties || [], selectedProperties);
					if (diffs.added.length || diffs.removed.length)
						return (
							<>
								{diffs.added.length ? <b>{diffs.added.length}</b> : ''}
								{diffs.added.length ? t('reports.addedProperties_any') : ''}
								{diffs.added.length && diffs.removed.length ? t('reports.and') : ''}
								{diffs.removed.length ? <b>{diffs.removed.length}</b> : ''}
								{diffs.removed.length ? t('reports.removedProperties_any') : ''}
								{'.'}
							</>
						);
					// TODO: This one seems unstable for the future. Maybe redo this one fully in i18n-next.
					else return t('reports.noChangesFound');
				}}
				onFinish={confirm => {
					setShowSensorsConfirmPopup(false);
					if (confirm) {
						setShowSensorsPopup(false);
						props.handleSave(selectedProperties);
					}
				}}
			/>
		</>
	);
}
