import { CHART_WIDGET_TYPES } from '../../../constants';
import { testData, columns } from './TableWidget';
import i18n from '../../../i18n';

const LineChartDefaultOptions = {
	w: 6,
	h: 4,
};

const LineChartConstraints = {
	minW: 5,
	maxW: 12,
	minH: 3,
	maxH: 6,
};

export const TableDefaultOptions = {
	w: 12,
	h: 7,
};

export const TableConstraints = {
	minW: 10,
	maxW: 14,
	minH: 6,
	maxH: 7,
};

export const WIDGET_DEFAULT_OPTIONS = {
	[CHART_WIDGET_TYPES.LineChart]: {
		element: CHART_WIDGET_TYPES.LineChart,
		props: {
			Title: i18n.t('lineChart.defaultTitle'),
			xLegend: i18n.t('generic.time'),
			yLegend: '',
			showTitle: true,
		},
		options: {
			...LineChartDefaultOptions,
			...LineChartConstraints,
		},
	},
	[CHART_WIDGET_TYPES.BarChart]: {
		element: CHART_WIDGET_TYPES.BarChart,
		props: {
			Title: i18n.t('barChart.defaultTitle'),
			xLegend: i18n.t('generic.time'),
			yLegend: '',
			showTitle: true,
		},
		options: {
			...LineChartDefaultOptions,
			...LineChartConstraints,
		},
	},
	[CHART_WIDGET_TYPES.PieChart]: {
		element: CHART_WIDGET_TYPES.PieChart,
		props: {
			Title: i18n.t('pieChart.defaultTitle'),
			xLegend: i18n.t('generic.time'),
			yLegend: '',
			showTitle: true,
		},
		options: {
			...LineChartDefaultOptions,
			...LineChartConstraints,
		},
	},
	[CHART_WIDGET_TYPES.SpeedoMeter]: {
		element: CHART_WIDGET_TYPES.SpeedoMeter,
		props: {
			Title: i18n.t('speedoMeter.defaultTitle'),
			xLegend: i18n.t('generic.time'),
			yLegend: '',
			showTitle: true,
		},
		options: {
			...LineChartDefaultOptions,
			...LineChartConstraints,
		},
	},
	[CHART_WIDGET_TYPES.Table]: {
		element: CHART_WIDGET_TYPES.Table,
		props: {
			header: i18n.t('constants.tableHeader'),
			rows: testData,
			columns: columns,
			showTitle: true,
			displayName: 'TableWidget',
		},
		options: {
			...TableDefaultOptions,
			...TableConstraints,
		},
	},
};
