import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dialog, DialogContent, DialogActions, DialogTitle, FormControl, MenuList, Select } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { Button, MenuItem, Paper, Popper, ClickAwayListener, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';

const useStylesIntervalButton = makeStyles(theme => ({
	container: {
		display: 'flex',
		flexWrap: 'wrap',
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

export function IntervalButton(props) {
	const [open, setOpen] = useState(false);
	const [intervalValues, setIntervalValues] = useState({
		duration: props.duration,
		type: props.interval,
	});

	const handleSelectInterval = event => {
		const newInterval = {
			...intervalValues,
			type: event.target.value,
		};
		if (props.options[event.target.value] < intervalValues.duration) {
			newInterval.duration = 1;
		}
		setIntervalValues(newInterval);
	};

	const handleSave = () => {
		props.onSelectCallback(intervalValues);
		setOpen(false);
	};

	const classes = useStylesIntervalButton();
	return (
		<>
			<Button color='inherit' onClick={() => setOpen(true)} style={{ border: '1px solid transparent' }} startIcon={props.startIcon}>
				{`${props.text}: ${props.duration} ${props.options[props.interval].text}`}
			</Button>
			<Dialog open={open} onClose={() => setOpen(false)}>
				<DialogTitle>Enter your interval</DialogTitle>
				<DialogContent>
					<form className={classes.container}>
						<FormControl variant='outlined' className={classes.formControl}>
							<Select
								labelId='demo-dialog-select-label'
								id='demo-dialog-select'
								value={intervalValues.duration}
								onChange={event =>
									setIntervalValues({
										...intervalValues,
										duration: event.target.value,
									})
								}
							>
								{[...Array(props.options[intervalValues.type].duration).keys()].map(duration => (
									<MenuItem key={duration} value={duration + 1}>
										{duration + 1}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<FormControl variant='outlined' className={classes.formControl}>
							<Select
								labelId='demo-dialog-select-label'
								id='demo-dialog-select'
								value={intervalValues.type}
								onChange={handleSelectInterval}
							>
								{Object.keys(props.options).map(interval => (
									<MenuItem key={interval} value={interval}>
										{props.options[interval].text}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</form>
				</DialogContent>
				<DialogActions>
					<Button onClick={() => setOpen(false)} color='primary'>
						Cancel
					</Button>
					<Button onClick={handleSave} color='primary'>
						Ok
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
}

export function DropDownButton(props) {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleSelect = value => {
		props.onSelect(value);
		setAnchorEl(null);
	};
	return (
		<>
			<Button
				color='inherit'
				onClick={event => setAnchorEl(event.currentTarget)}
				style={{ border: '1px solid transparent' }}
				startIcon={props.startIcon}
			>
				{props.options[props.value].selectedText}
				<KeyboardArrowDown />
			</Button>
			<Popper open={open} anchorEl={anchorEl} style={{ marginTop: '10px', zIndex: 1500 }}>
				<Paper style={{ minWidth: anchorEl?.offsetWidth ?? `${anchorEl?.offsetWidth}px` }}>
					<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
						<MenuList>
							{Object.keys(props.options).map(value => (
								<MenuItem key={value} value={props.options[value].title} onClick={() => handleSelect(value)}>
									{props.options[value].menuText}
								</MenuItem>
							))}
						</MenuList>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	);
}

const COMPARE_OPTIONS = [
	{ value: 'none', label: 'Off' },
	{ value: 'month', label: 'Previous Month' },
	{ value: 'year', label: 'Previous Year' },
];

export function ComparePreviousButton(props) {
	const [anchorEl, setAnchorEl] = useState(null);

	const open = Boolean(anchorEl);

	return (
		<>
			<Button
				color='inherit'
				onClick={event => setAnchorEl(event.currentTarget)}
				style={{ border: '1px solid transparent' }}
				startIcon={props.startIcon}
			>
				{props.selected}
			</Button>
			<Popper open={open} anchorEl={anchorEl} style={{ marginTop: '10px', zIndex: 1500 }}>
				<Paper style={{ minWidth: anchorEl?.offsetWidth ?? `${anchorEl?.offsetWidth}px`, padding: '20px 20px 20px 20px' }}>
					<ClickAwayListener onClickAway={() => setAnchorEl(null)}>
						<FormControl>
							<FormLabel id='demo-radio-buttons-group-label'>Time period</FormLabel>
							<RadioGroup aria-labelledby='demo-radio-buttons-group-label' defaultValue='none' name='radio-buttons-group'>
								{COMPARE_OPTIONS.map(option => (
									<FormControlLabel value={option.value} control={<Radio />} label={option.label} />
								))}
							</RadioGroup>
						</FormControl>
					</ClickAwayListener>
				</Paper>
			</Popper>
		</>
	);
}
