import React, { useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { STORE, getStateVariables } from '../redux/selectors';
import { updateUserInfo } from '../redux/actionCreators';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import { ConfirmPopup } from './ConfirmPopup';

const SET_SENSOR_SUBSCRIPTIONS = gql`
	mutation ($id: String!, $add: Boolean!, $sensorSubscriptions: [Int!]!) {
		addOrRemoveKeycloakUserAttributes(id: $id, add: $add, sensorSubscriptions: $sensorSubscriptions) {
			sensorSubscriptions
		}
	}
`;

/**
 * A table for sensor-subscriptions that allows them to be viewed and removed
 */
function SensorSubscriptionAdministration(props) {
	const [filteredProperties, setFilteredProperties] = useState([]);
	const [selectedSensors, setSelectedSensors] = useState([]);
	const [showConfirmPopup, setShowConfirmPopup] = useState(false);

	const [setSensorSubscriptions] = useMutation(SET_SENSOR_SUBSCRIPTIONS, {
		onCompleted: ({ addOrRemoveKeycloakUserAttributes }) => props.updateUserInfo(addOrRemoveKeycloakUserAttributes),
	});

	const subSensors = props.sensors.filter(sen => props.userInfo.sensorSubscriptions?.includes(Number(sen.sensorid)));
	const properties = []; // [{value: Number, label: String}, ...]
	for (const sen of subSensors)
		if (!properties.some(pro => pro.value === sen.locationid))
			properties.push({ value: sen.locationid, label: sen.city + ': ' + sen.street });
	properties.sort((a, b) => (a.label < b.label ? -1 : 1));

	const filteredSubSensors = (
		filteredProperties.length ? subSensors.filter(sen => filteredProperties.some(pro => pro.value === sen.locationid)) : subSensors
	)
		.map(sen => {
			return { ...sen, valueNUnit: (sen.value || '') + ' ' + (sen.unit || '') };
		})
		.sort((a, b) => (a.city < b.city || (a.city === b.city && a.street < b.street) ? -1 : 1));

	const { t } = useTranslation();

	return (
		<div>
			<div style={{ margin: '1rem 0 0 1rem' }}>
				<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
					<SelectionTable
						localization={{
							title: t('sensorSubscriptionAdmin.sensorSubscriptions'),
							nRowsSelected: t('sensorSubscriptionAdmin.numberOfSelectedSubscriptions') + '{0}',
						}}
						data={filteredSubSensors}
						dataId='sensorid'
						onSelectionChange={subs => setSelectedSensors(subs)}
						columns={[
							{ title: t('generic.name'), field: 'name' },
							{
								title: t('generic.city'),
								field: 'city',
							},
							{ title: t('generic.address'), field: 'street' },
							{ title: t('sensorSubscriptionAdmin.latestValue'), field: 'valueNUnit' },
							{ title: t('sensorSubscriptionAdmin.latestUpdated'), field: 'timestamp' },
						]}
						tableProps={{ maxColumnLength: 18 }}
					/>

					<SelectionSidebar
						buttons={[
							{
								label: t('sensorSubscriptionAdmin.stopSubscribingSelected'),
								onClick: () => setShowConfirmPopup(true),
								disabled: !selectedSensors.length,
							},
						]}
						filters={properties}
						updateSelectedFilters={properties => setFilteredProperties(properties || [])}
						localization={{
							filterTitle: t('sensorSubscriptionAdmin.filterSubscriptions'),
							filterPlaceholder: t('sensorSubscriptionAdmin.selectPropsDot'),
						}}
						style={{ width: '12rem', margin: '0.05rem 0 0 1rem' }}
					/>
				</div>
			</div>

			<ConfirmPopup
				enabled={showConfirmPopup}
				title={t('sensorSubscriptionAdmin.stopSubscribingPromt', { count: selectedSensors.length })}
				text={selectedSensors.reduce(
					(sum, cur) => (
						<span>
							{sum}
							{cur.name}
							<br />
						</span>
					),
					''
				)}
				confirmLabel={t('generic.confirm')}
				denyLabel={t('generic.cancel')}
				onFinish={success => {
					if (success)
						setSensorSubscriptions({
							variables: {
								id: props.userInfo?.id,
								add: false,
								sensorSubscriptions: selectedSensors.map(sen => Number(sen.sensorid)),
							},
						});
					setShowConfirmPopup(false);
				}}
			/>
		</div>
	);
}

export default connect(getStateVariables(STORE.sensors, STORE.userInfo), { updateUserInfo })(SensorSubscriptionAdministration);
