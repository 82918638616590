import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
//import { getUserInfo } from '../components/Sidebar';

import { PAGES, PROPERTY_TABS } from '../constants';
import { combinePaths } from '../utility-functions';
import { STORE, getStateVariables } from '../redux/selectors';
import { setPage, setProperty, addCallback, removeCallback } from '../redux/actionCreators';
import SimplePageWrapper from '../components/SimplePageWrapper';
import CustomTabs from '../components/CustomTabs';
import PropertyList from '../components/PropertyList';
import Meters from '../components/Meters';
import ThreeSixtyTab from '../components/ThreeSixtyTab';
import ExternalControlPanelList from '../components/ExternalControlPanelList';
import COTab from '../components/COTab';
import OperationCardView from '../components/OperationCardView';
import AlarmList from '../components/AlarmList';
import DeviationList from '../components/DeviationList';
import DocumentsView from '../components/DocumentsView';
import DashboardList from '../components/DashboardList';

/**
 * Properties-page which shows either a tab-view of info about a property, or a list of properties if none have been selected
 */
function Properties(props) {
	const [selectedProperty, setSelectedProperty] = useState();
	const [selectedSensors, setSelectedSensors] = useState([]);

	useEffect(() => {
		props.setPage(PAGES.properties.id);

		props.addCallback('on-sidebar-property-click', 'remove-selected-property', () => setPropertySelection(undefined, false));
		return () => props.removeCallback('on-sidebar-property-click', 'remove-selected-property');
		// eslint-disable-next-line
	}, []);

	// Update the properties whenever the list of properties in Redux change and select property specified in URL
	useEffect(() => {
		// Select location id in URL
		// 0: Empty, 1: Page id, 2: Location id, 3: Property tab
		const urlParts = props.location.pathname.split('/');
		if (urlParts[2] && !selectedProperty)
			setPropertySelection(
				props.properties.find(property => property.locationid === Number(urlParts[2])),
				false
			);

		// Wait until properties have loaded, then change property selection to correspond with the URL
		if (props.properties.length) {
			let locationIdInUrl = props.history.location.pathname.split('/')[2];
			if (!isNaN(locationIdInUrl)) locationIdInUrl = Number(locationIdInUrl);

			const propertyInUrl = props.properties.find(property => property.locationid === locationIdInUrl);

			if (locationIdInUrl !== selectedProperty?.locationid)
				setPropertySelection(propertyInUrl, !propertyInUrl && locationIdInUrl ? true : false);
		}
		// eslint-disable-next-line
	}, [props.properties, props.location]);

	function setPropertySelection(property, changeUrl = true) {
		if (property) {
			const selectedSensors = props.sensors.filter(sen => sen.locationid === property.locationid);

			setSelectedProperty(property);
			setSelectedSensors(selectedSensors);

			props.setProperty(property.locationid);

			if (changeUrl) props.history.push('/' + PAGES.properties.id + '/' + property.locationid);
		} else {
			setSelectedProperty(undefined);
			setSelectedSensors([]);

			props.setProperty('');

			if (changeUrl) props.history.push('/' + PAGES.properties.id);
		}
	}
	/* 	const buildingsCount = props.properties.length;
		console.log('propsfo', props); */
	//useEffect(() => {
	//	props.setPage(PAGES.properties.id);

	//	props.addCallback('on-sidebar-property-click', 'remove-selected-property', () => setPropertySelection(undefined, false));

	//	if (
	//		props.userInfo.propertyAccess !== 'all' /* &&
	//		!PROPERTY_TABS.externalControlPanels.disabled */
	//	) {
	//		props.history.push('/' + PAGES.properties.id);
	//	}

	//	return () => props.removeCallback('on-sidebar-property-click', 'remove-selected-property');

	//}, [props.userInfo.propertyAccess]);
	// If a property is selected
	if (selectedProperty) {
		const urlUpToTab = combinePaths(PAGES.properties.id, props.currentProperty);
		return (
			<SimplePageWrapper title={selectedProperty.street}>
				{/* <CustomTabs
					tabs={Object.values(PROPERTY_TABS).map(tab => ({
						...tab,
						disabled: tab.id === 'externalControlPanels' && (props.userInfo.authorizationAccess !== 'all'),
						disabled: tab.id === 'externalControlPanels' && (props.userInfo.propertyAccess !== 'all'),
					})).concat([{
						id: 'dashboards',
						label: 'Dashboards',
						disabled: false,
					}]).filter(item => item)}
					regexpBeforeTab={`/${PAGES.properties.id}/[0-9]+`}
					squeezeFactor={0.5}
				/> */}
				<CustomTabs
					tabs={Object.values(PROPERTY_TABS)
						.map(tab => ({
							...tab,
							disabled:
								(tab.id === 'externalControlPanels' || tab.id === 'controlObjects') &&
								props.userInfo.authorizationAccess !== 'all' &&
								props.userInfo.authorizationAccess !== 'parents' &&
								props.userInfo.authorizationOptions?.length !== 2,
						}))
						.filter(tab => tab.id !== 'actions')
						.filter(tab => tab.id !== 'curve')
						.filter(tab => tab.id !== 'indoorSensor')
						.filter(tab => tab.id !== 'energyPerformance')
						.filter(tab => tab.id !== 'results')
						.filter(tab => tab.id !== 'aiControl')
						.filter(tab => !((tab.id === 'externalControlPanels' || tab.id === 'controlObjects') && tab.disabled))
						.concat([
							{
								id: 'dashboards',
								label: 'Dashboards',
								disabled: false,
							},
						])}
					regexpBeforeTab={`/${PAGES.properties.id}/[0-9]+`}
					squeezeFactor={0.5}
				/>

				<Route exact path={[urlUpToTab, combinePaths(urlUpToTab, PROPERTY_TABS.meters.id)]} component={Meters} />
				<Route path={combinePaths(urlUpToTab, PROPERTY_TABS.alarmHistory.id)} render={() => <AlarmList isPropertySpecific />} />
				{/* <Route path={combinePaths(urlUpToTab, PROPERTY_TABS.actions.id)} component={ActionList} /> */}
				<Route path={combinePaths(urlUpToTab, PROPERTY_TABS.deviations.id)} component={DeviationList} />
				<Route
					path={combinePaths(urlUpToTab, PROPERTY_TABS.threeSixty.id)}
					render={() => <ThreeSixtyTab locationid={selectedProperty.locationid} />}
				/>
				<Route path={combinePaths(urlUpToTab, PROPERTY_TABS.externalControlPanels.id)} component={ExternalControlPanelList} />
				<Route path={combinePaths(urlUpToTab, PROPERTY_TABS.cos.id)} component={COTab} />
				<Route
					path={combinePaths(urlUpToTab, PROPERTY_TABS.opcard.id)}
					render={() => <OperationCardView street={selectedProperty.street} />}
				/>
				<Route path={combinePaths(urlUpToTab, PROPERTY_TABS.documents.id)} component={DocumentsView} />
				<Route path={combinePaths(urlUpToTab, 'dashboards')} component={DashboardList} />
			</SimplePageWrapper>
		);
	} else {
		return <PropertyList properties={props.properties} onPropertySelect={property => setPropertySelection(property)} />;
	}
}

/* const mapStateToProps = (state) => ({
	sensors: state.sensors,  
	properties: state.properties,
	currentProperty: state.currentProperty,
	userInfo: getUserInfo(state),
});

export default connect(mapStateToProps, {
	setPage,
	setProperty,
	addCallback,
	removeCallback,
})(Properties); */
export default connect(getStateVariables(STORE.sensors, STORE.properties, STORE.currentProperty, STORE.userInfo), {
	setPage,
	setProperty,
	addCallback,
	removeCallback,
})(Properties);
