import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { PAGES } from '../constants';
import { setPage } from '../redux/actionCreators';
import DeviationList from '../components/DeviationList';

function Deviations(props) {
	useEffect(() => {
		props.setPage(PAGES.deviations.id);
		// eslint-disable-next-line
	}, []);

	return <DeviationList />;
}

export default connect(null, { setPage })(Deviations);
