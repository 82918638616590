import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Modal,
	Backdrop,
	Box,
	Card,
	CardMedia,
	CardContent,
	Dialog,
	IconButton,
	Typography,
	CardActionArea,
	Grid,
	Tooltip,
	Button,
} from '@material-ui/core';
import { Close, Add } from '@material-ui/icons';
import { CHART_WIDGET_TYPES } from '../../../constants';
import { makeStyles } from '@material-ui/core/styles';
import { WIDGET_DEFAULT_OPTIONS } from '../Widget/WidgetDefault';
import { SensorSelectorTable } from './SensorSelectorTable';
import i18n from '../../../i18n';

const modalStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		width: '90%',
		paddingTop: '10px',
		borderRadius: '5px',
	},

	closeIcon: {
		marginLeft: 'auto',
	},
	widgetGrid: {
		marginTop: '10px',
		overflowY: 'auto',
		maxHeight: '75vh',
	},
	modalTitle: {
		fontSize: '2rem',
		fontWeight: 'bold',
	},
}));

const toolTipStyle = makeStyles(theme => ({
	largeTooltipCreate: {
		fontSize: '12px',
		padding: '10px',
		textDecoration: 'uppercase',
		marginBottom: '19px',
		marginLeft: '5px',
	},
}));

const widgetList = [
	{
		type: CHART_WIDGET_TYPES.LineChart,
		image: require('../../../assets/dummy-charts/line-chart.png').default,
		title: i18n.t('lineChart.lineChart'),
	},
	{
		type: CHART_WIDGET_TYPES.BarChart,
		image: require('../../../assets/dummy-charts/bar-chart.png').default,
		title: i18n.t('barChart.barChart'),
	},
	{
		type: CHART_WIDGET_TYPES.PieChart,
		image: require('../../../assets/dummy-charts/pie-chart.png').default,
		title: i18n.t('pieChart.pieChart'),
	},
	{
		type: CHART_WIDGET_TYPES.SpeedoMeter,
		image: require('../../../assets/dummy-charts/speedometer-icon.png').default,
		title: i18n.t('speedoMeter.speedoMeter'),
	},
];

function widgetAutoOptions(type, sensors) {
	if (sensors.length === 0) {
		return { ...WIDGET_DEFAULT_OPTIONS[type], sensors: sensors };
	}
	if (sensors.length === 1) {
		return {
			...WIDGET_DEFAULT_OPTIONS[type],
			sensors: sensors,
			props: {
				...WIDGET_DEFAULT_OPTIONS[type].props,
				Title: sensors[0].sensorname,
			},
		};
	}

	const locationid = sensors[0].locationid;
	let sameLocation = undefined;
	if (sensors.every(sensor => sensor.locationid === locationid)) {
		sameLocation = sensors[0].locationname;
	}

	const groupName = sensors[0].groupname;
	let sameGroup = undefined;
	if (groupName !== '' && sensors.every(sensor => sensor.groupname === groupName)) {
		sameGroup = groupName;
	}

	if (sameGroup === null || sameGroup === 'null') {
		sameGroup = undefined;
	}

	let widgetTitle = WIDGET_DEFAULT_OPTIONS[type].props.Title;

	if (sameGroup !== undefined && sameLocation !== undefined) {
		widgetTitle = `${sameLocation} - ${sameGroup}`;
	} else if (sameGroup !== undefined) {
		widgetTitle = `${sameGroup} - ${i18n.t('generic.multipleLocations')}`;
	} else if (sameLocation !== undefined) {
		widgetTitle = `${sameLocation} - ${i18n.t('generic.multipleSensors')}`;
	}

	return {
		...WIDGET_DEFAULT_OPTIONS[type],
		sensors: sensors,
		props: {
			...WIDGET_DEFAULT_OPTIONS[type].props,
			Title: widgetTitle,
		},
	};
}

function WidgetItem({ itemProps, selectWidgetCallback }) {
	return (
		<Card>
			<CardActionArea onClick={() => selectWidgetCallback(itemProps.type)}>
				<CardMedia component='img' image={itemProps.image} />
				<CardContent>
					<Typography variant='h5'>{itemProps.title}</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

function SelectWidgetPopup({ open, handleClose, handleCreate }) {
	const classes = modalStyles();
	const { t } = useTranslation();

	return (
		<Modal
			aria-labelledby='transition-modal-title'
			aria-describedby='transition-modal-description'
			className={classes.modal}
			open={open}
			onClose={() => handleClose(undefined)}
			closeAfterTransition
			BackdropComponent={Backdrop}
			BackdropProps={{
				timeout: 500,
			}}
			data-cy='widget-menu'
		>
			<div id='widget-menu-main' className={classes.paper}>
				<div id='select-modal-header' style={{ display: 'flex' }}>
					<h1 className={classes.modalTitle}>{t('constants.widgets')}</h1>
					<IconButton className={classes.closeIcon} onClick={handleClose}>
						<Close />
					</IconButton>
				</div>
				<Box className={classes.widgetBox}>
					<Grid container spacing={2} className={classes.widgetGrid}>
						{widgetList.map((widget, index) => (
							<Grid key={widget.type} item xs={3}>
								<WidgetItem itemProps={widget} selectWidgetCallback={type => handleCreate(type)} />
							</Grid>
						))}
					</Grid>
				</Box>
			</div>
		</Modal>
	);
}

const CreateWidgetPopup = ({ createWidget, createCallback, cancelCallback, selectedProperties }) => {
	// Some function to get popup from type, for now only sensor table component for all charts

	const createWidgetWithOptions = options => {
		// change this to be dynamic for all options, now tailored only for sensors
		let widgetOptions = undefined;
		if (options.sensors?.length) {
			widgetOptions = widgetAutoOptions(createWidget.type, options.sensors);
		}
		createCallback(widgetOptions);
	};
	return (
		<Dialog open={createWidget.open} maxWidth={false} PaperComponent={props => <>{props.children}</>}>
			<SensorSelectorTable
				isOpen={createWidget.open}
				selectedProperties={selectedProperties}
				graphSensors={[]}
				changeSensorsCallback={sensors => createWidgetWithOptions({ sensors: sensors })}
				closeCallback={cancelCallback}
				maxSelectionCount={createWidget.type === CHART_WIDGET_TYPES.SpeedoMeter ? 1 : undefined}
			/>
		</Dialog>
	);
};

const WidgetMenu = ({ addWidget, editMode, selectedProperties }) => {
	const classesTooltip = toolTipStyle();
	const [open, setOpen] = useState(false);
	const [createWidget, setCreateWidget] = useState({ open: false, type: undefined });
	const { t } = useTranslation();

	const handleCreate = type => {
		setOpen(false);
		setCreateWidget({
			open: true,
			type: type,
		});
	};

	const createCallback = options => {
		// check if actions were carried through
		if (options !== undefined) {
			addWidget(options);
		}
	};

	const closeCreateWidget = () => {
		setCreateWidget({ open: false, type: undefined });
	};

	return (
		<>
			<div
				style={{
					backgroundColor: '#7283bb',
					color: 'white',
					borderRadius: '30px',
					width: '7.7%',
					height: '45px',
					marginLeft: '14px',
					position: 'relative',
					paddingTop: '1px',
					display: editMode ? 'block' : 'none',
				}}
			>
				<Tooltip title={t('constants.createGraph')} placement='top' arrow classes={{ tooltip: classesTooltip.largeTooltipCreate }}>
					<Button
						style={{ padding: '0px', marginLeft: '2px', paddingTop: '1px' }}
						data-cy='create-widget-button'
						startIcon={<Add style={{ marginRight: '-7px', marginLeft: '9px', marginTop: '11px' }} />}
						color='inherit'
						onClick={() => setOpen(true)}
					>
						<p style={{ margin: '0px', marginTop: '1px' }}>{t('toolbar.create')}</p>
					</Button>
				</Tooltip>
				<SelectWidgetPopup open={open} handleClose={() => setOpen(false)} handleCreate={handleCreate} />
			</div>
			<CreateWidgetPopup
				createWidget={createWidget}
				cancelCallback={closeCreateWidget}
				createCallback={createCallback}
				selectedProperties={selectedProperties}
			/>
		</>
	);
};

export default WidgetMenu;
