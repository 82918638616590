import React from 'react';
import Select from 'react-select';
import SelectCreatable from 'react-select/creatable';
import makeAnimated from 'react-select/animated';

import * as colors from '../colors';
/**
 * Wrapper for 'react-select'
 * @param {boolean | undefined} isCreatable : Whether not-listed options can be created
 * @param {object} selectProps : Passed directly to underlying Select component
 * @param {object} style : Applied to wrapping div-element
 */
function SelectWrapper(props) {
	for (const key in props.selectProps || {})
		if (props.selectProps[key] === undefined || props.selectProps[key] === null) delete props.selectProps[key];

	function getSelectProps() {
		return {
			isClearable: true,
			closeMenuOnSelect: true,
			placeholder: '...',
			formatCreateLabel: input => input,
			noOptionsMessage: () => '...',
			components: {
				...makeAnimated(),
				...{ DropdownIndicator: () => null, IndicatorSeparator: () => null },
			},
			styles: {
				control: (styles, state) => ({
					...styles,
					boxShadow: state.isFocused ? '0 0 0 1px ' + colors.primary : null,
					borderRadius: '4px',
					borderColor: state.isFocused ? colors.primary : '#0000003b',
					'&:hover': state.isFocused ? null : { borderColor: '#000' },
					...props.selectStyle?.control,
				}),
				multiValueLabel: styles => {
					return { ...styles, color: '#fff', background: '#4c5f9e' };
				},
				multiValue: styles => {
					return { ...styles, color: '#fff', background: '#465791' };
				},
				multiValueRemove: styles => {
					return { ...styles, color: '#fff', background: '#465791', ':hover': { background: '#5a6eaf' } };
				},
			},
			...props.selectProps,
		};
	}

	return (
		<div style={{ ...props.style }}>
			{props.isCreatable ? <SelectCreatable {...getSelectProps()} /> : <Select {...getSelectProps()} />}
		</div>
	);
}

export default SelectWrapper;
