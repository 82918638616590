import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { getStateVariables, STORE } from '../redux/selectors';
import MeterCard from './MeterCard';
import SensorGroupCard from './SensorGroupCard';

/**
 * Responsible for rendering sensor and sensor-group cards
 */
function Meters(props) {
	const [query, setQuery] = useState({ sensorid: undefined, sensorgroupid: undefined });

	const _propertySensors = props.sensors.filter(sen => sen.locationid === props.currentProperty);
	const sensorGroups = props.sensorGroups
		.filter(grp => grp.locationid === props.currentProperty)
		.map(grp => ({
			...grp,
			sensors: _propertySensors.filter(sen =>
				(sen.sensorgroupid !== null && sen.sensorgroupid === Number(grp.sensorgroupid)) ||
				(sen.sensorgroupid === null && sen.externalcontrolpanelid !== null && sen.externalcontrolpanelid === grp.externalcontrolpanelid))
		}));

	const standaloneSensors = _propertySensors.filter(sen =>
		!sensorGroups.some(grp =>
			grp.sensors.includes(sen)
		)
	);
	useEffect(() => {
		const querySensorId = new URLSearchParams(window.location.search).get('sensor');
		if (querySensorId) {
			const querySensor = props.sensors.find(sen => sen.sensorid === querySensorId);
			if (querySensor) setQuery({ sensorid: querySensor.sensorid, sensorgroupid: String(querySensor.sensorgroupid) });
		}
		// eslint-disable-next-line
	}, [window.location.search]);

	return (
		<div style={{ display: 'flex', flexWrap: 'wrap' }}>
			{sensorGroups
				.sort((a, b) => {
					// Custom hard-coded sorting
					if (a.name === 'Energi') return -1;
					else if (b.name === 'Energi') return 1;
					else if (a.name === 'Undercentral') return -1;
					else if (b.name === 'Undercentral') return 1;
					else if (a.name?.startsWith('Sensor') && !b.name?.startsWith('Sensor')) return -1;
					else if (b.name?.startsWith('Sensor') && !a.name?.startsWith('Sensor')) return 1;
					else return a.name < b.name ? -1 : 1;
				})
				.map(sensorGroup => (
					<SensorGroupCard
						sensorGroup={sensorGroup}
						selectedSensorId={sensorGroup.sensorgroupid === query.sensorgroupid ? query.sensorid : undefined}
						key={'sg-' + sensorGroup.sensorgroupid}
					/>
				))}

			{standaloneSensors
				.sort((a, b) => (a.name < b.name ? -1 : 1))
				.map(sensor => (
					<MeterCard sensor={sensor} isSelected={sensor.sensorid === query.sensorid} key={sensor.sensorid} />
				))}
		</div>
	);
}

export default connect(getStateVariables(STORE.currentProperty, STORE.sensors, STORE.sensorGroups))(Meters);
