import React from 'react';
import * as colors from '../colors';

const COLORS = {
	connectPrimary: colors.bgcolor,
	success: colors.success,
	failure: colors.failure,
};

const HEADER_PROPS = { height: '3rem', color: COLORS.connectPrimary };

/**
 * Draws a header-bar with some text and logos for each page of the report
 * @param {Object} interval : Contains info about the selected time-interval
 */

// interval={INTERVAL}
// 				startDate={START_DATE_STR}
// 				endDate={END_DATE_STR}

// const DATE_VALUES = {
//     startDate: formatDate(new Date(), 'yyyy-MM-dd'),
//     endDate: formatDate(addDays(new Date(), -7), 'yyyy-MM-dd'),
//     interval:
// };

export default function ReportHeader({ customer }) {
	return (
		<div>
			<div
				style={{
					height: HEADER_PROPS.height,
					background: HEADER_PROPS.color,
					// background:
					// 	props.customer?.toLowerCase() === 'swegon'
					// 		? '#f8f8f8'
					// 		: props.customer?.toLowerCase() === 'bastec'
					// 			? '#004b6c'
					// 			: HEADER_PROPS.color,
					display: 'flex',
					//	boxShadow: '0 0 0.35rem #000a',
					// zIndex: '100',
				}}
			>
				{customer === 'bastec' ? (
					<img
						src={require('../assets/BASTEC_logooutline_white.png').default}
						style={{ height: '1.4rem', margin: '0.7rem 0 auto 1.3rem' }}
						alt='Bastec'
					/>
				) : customer === 'sandbackens' ? (
					<img
						src={require('../assets/sandbackens_logo.png').default}
						style={{ height: '1.1rem', margin: '1rem 0 auto 1rem' }}
						alt='Sandbackens'
					/>
				) : customer === 'swegon' ? (
					<img
						src={require('../assets/swegon_logo_color.svg').default}
						style={{ height: '2.2rem', margin: '0.3rem 0 1rem 1rem' }}
						alt='Swegon'
					/>
				) : (
					<img
						src={require('../assets/meliox-logo-white.png').default}
						style={{ height: '1rem', margin: '0.95rem 0 auto 1.2rem' }}
						alt='Meliox'
					/>
				)}
				<div style={{ color: customer?.toLowerCase() === 'swegon' ? 'black' : '#fff', margin: '0.5rem 0 0 1rem' }}>
					<div style={{ fontSize: '0.65rem', fontWeight: '600' }}>{'Weekly Report (week 25)'}</div>
					<div style={{ fontSize: '0.55rem', fontWeight: '400', marginTop: '0.2rem' }}>{'2024-06-17 to 2024-06-23'}</div>
				</div>
			</div>
		</div>
	);
}
