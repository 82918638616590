import React from 'react';
import { connect } from 'react-redux';

import { STORE, getStateVariables } from '../redux/selectors';
import ExternalControlPanel from '../components/ExternalControlPanel';

/**
 * List of external control-panels
 */
function ExternalControlPanelList(props) {
	const ecps = props.externalControlPanels.filter(ecp => String(ecp.locationid) === String(props.currentProperty) && ecp.url);

	return (
		<div>
			{ecps.map(ecp => (
				<ExternalControlPanel ecp={ecp} key={ecp.externalcontrolpanelid} />
			))}
		</div>
	);
}

export default connect(getStateVariables(STORE.currentProperty, STORE.externalControlPanels))(ExternalControlPanelList);
