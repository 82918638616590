import React, { useState } from 'react';
import { IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import { Close, Delete } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

function DeleteWidgetButton({ deleteWidgetCallback, widgetTitle }) {
	const [confirmationOpen, setConfirmationOpen] = useState(false);

	const openDeleteConfirmation = () => {
		setConfirmationOpen(true);
	};

	const closeDeleteConfirmation = () => {
		setConfirmationOpen(false);
	};
	const confirmDelete = () => {
		if (deleteWidgetCallback) {
			deleteWidgetCallback();
		}
		closeDeleteConfirmation();
	};

	const deleteWidget = () => {
		openDeleteConfirmation();
	};

	return (
		<>
			<IconButton
				aria-label='Delete widget'
				onClick={deleteWidget}
				style={{ cursor: 'pointer', zIndex: 2, position: 'absolute', top: 10, right: 50 }}
			>
				<Delete />
			</IconButton>
			<DeleteWidgetConfirmation isOpen={confirmationOpen} onClose={closeDeleteConfirmation} onConfirm={confirmDelete} />
		</>
	);
}

function DeleteWidgetConfirmation({ isOpen, onClose, onConfirm }) {
	const { t } = useTranslation();

	const handleCancel = () => {
		onClose(false);
	};

	const handleConfirm = () => {
		onConfirm();
		onClose(true);
	};

	return (
		<Dialog open={isOpen} onClose={() => onClose(false)} aria-label='delete-widget-confirmation'>
			<DialogTitle>{t('widgetDeletion.deleteConfirmationTitle')}</DialogTitle>
			<div
				style={{
					position: 'absolute',
					top: 8,
					right: 8,
				}}
			>
				<IconButton aria-label='close' onClick={handleCancel}>
					<Close />
				</IconButton>
			</div>
			<DialogContent>
				<DialogContentText>{t('widgetDeletion.deleteConfirmationText')}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleCancel} color='primary'>
					{t('generic.cancel')}
				</Button>
				<Button onClick={handleConfirm} color='secondary' variant='contained' startIcon={<Delete />}>
					{t('generic.remove')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

export default DeleteWidgetButton;
export { DeleteWidgetConfirmation };
