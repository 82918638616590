import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';

import { PAGES, ADMIN_TABS } from '../constants';
import { combinePaths } from '../utility-functions';
import { setPage } from '../redux/actionCreators';
import SimplePageWrapper from '../components/SimplePageWrapper';
import CustomTabs from '../components/CustomTabs';
import UserAdministration from '../components/UserAdministration';
import CreatedUserAdministration from '../components/CreatedUserAdministration';
import SensorSubscriptionAdministration from '../components/SensorSubscriptionAdministration';
import SensorAdministration from '../components/SensorAdministration';
import SensorGroupAdministration from '../components/SensorGroupAdministration';
import PropertyAdministration from '../components/PropertyAdministration';
import ThreeSixtyAdministration from '../components/ThreeSixtyAdministration';
import COAdministration from '../components/COAdministration';
import CameraAdministration from '../components/CameraAdministration';
import ExternalControlPanelAdministration from '../components/ExternalControlPanelAdministration';
import WizardAdministration from '../components/WizardAdministration';

function Administration(props) {
	useEffect(() => {
		props.setPage(PAGES.administration.id);
		// eslint-disable-next-line
	}, []);

	const urlUpToTab = '/' + PAGES.administration.id;

	return (
		<SimplePageWrapper title={PAGES.administration.label}>
			<CustomTabs tabs={Object.values(ADMIN_TABS)} regexpBeforeTab={urlUpToTab} squeezeFactor={4.5} />
			<Switch>
				<Route exact path={[urlUpToTab, combinePaths(urlUpToTab, ADMIN_TABS.personal.id)]} component={UserAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.users.id)} component={CreatedUserAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.sensorSubscriptions.id)} component={SensorSubscriptionAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.sensors.id)} component={SensorAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.sensorGroups.id)} component={SensorGroupAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.properties.id)} component={PropertyAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.threeSixtyModels.id)} component={ThreeSixtyAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.controlObjects.id)} component={COAdministration} />
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.cameras.id)} component={CameraAdministration} />
				<Route
					path={combinePaths(urlUpToTab, ADMIN_TABS.externalControlPanels.id)}
					component={ExternalControlPanelAdministration}
				/>
				<Route path={combinePaths(urlUpToTab, ADMIN_TABS.wizards.id)} component={WizardAdministration} />
			</Switch>
		</SimplePageWrapper>
	);
}

export default connect(null, { setPage })(Administration);
