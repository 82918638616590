import { useState, useMemo, useEffect } from 'react';
import { useQuery, useLazyQuery } from '@apollo/client';
import { QUERY_SENSORDATA_NEW, QUERY_SENSORDATA_NEW_AND_VIEW, QUERY_SENSORDATA_VIEW } from './gqlQueries';
import i18n from '../i18n';

const MILLISECONDS_PER_SECOND = 1000;

/**
 * Draws a time selection component for choosing a time span
 * @param {List} sensorList : List of sensor ids
 * @param {Date} startDate : start date
 * @param {Date} endDate : end date
 * @param {Object} interval : Interval between each datapoint
 * @param {Object} interval
 */

const MILLISECONDS_PER_MINUTE = 1000 * 60;

export function getIntervalInMinutes(timeRange) {
	return timeRange.offset || (timeRange.endDate - timeRange.startDate) / MILLISECONDS_PER_MINUTE;
}

export function useQuerySensorData(sensorList, startDate, endDate, interval, dataType = 'mean') {
	// Every time sensorList or timeRange are changed, this function will refetch data
	const sensorFilter = useMemo(() => {
		return {
			sensorDataFilter: {
				sensorids: sensorList.map(({ sensorid }) => sensorid),
				timestamp_gte: startDate.toISOString(),
				timestamp_lte: endDate.toISOString(),
				timestamp_interval: interval,
				interval_type: dataType,
			},
		};
	}, [sensorList, startDate, endDate, interval, dataType]);

	const { data, loading, error } = useQuery(QUERY_SENSORDATA_NEW, {
		variables: sensorFilter,
	});

	return { data, loading, error };
}

function getSensorDataWidgetFilter(sensors, startDate, endDate, interval, dataType) {
	const sensorids = sensors.map(({ sensorid }) => sensorid);
	const sensorViewFilter = {
		sensorids: sensorids,
	};
	const sensorDataFilter = {
		sensorids: sensorids,
		timestamp_gte: startDate.toISOString(),
		timestamp_lte: endDate.toISOString(),
		timestamp_interval: `${interval.duration} ${interval.type}`,
		interval_type: dataType,
	};
	return {
		sensorDataFilter: sensorDataFilter,
		sensorViewFilter: sensorViewFilter,
	};
}

function prettyPrintStreet(street) {
	const streetNameAndNumber = street.split(' ');
	if (streetNameAndNumber.length > 2 && streetNameAndNumber[0].length <= 6) {
		return streetNameAndNumber[0] + ` ${streetNameAndNumber[1].slice(0, 5)}... ${streetNameAndNumber[streetNameAndNumber.length - 1]}`;
	} else if (streetNameAndNumber.length >= 2) {
		return `${streetNameAndNumber[0].slice(0, 5)}... ${streetNameAndNumber[streetNameAndNumber.length - 1]}`;
	}
	return street;
}

function convertPreviousTimeRange(date, prevPeriod) {
	const correctDate = new Date(date);
	switch (prevPeriod) {
		case 'day':
			correctDate.setDate(correctDate.getDate() + 1);
			break;
		case 'week':
			correctDate.setDate(correctDate.getDate() + 7);
			break;
		case 'month':
			correctDate.setMonth(correctDate.getMonth() + 1);
			break;
		case 'year':
			correctDate.setFullYear(correctDate.getFullYear() + 1);
			break;
		default:
			break;
	}
	return correctDate;
}

function handleWidgetData(sensorData, sensorView, prevPeriod = 'none') {
	const transformedData = [];
	sensorData.forEach(sensor => {
		const sensorOption = sensorView.find(opt => parseInt(opt.sensorid) === sensor.sensorid);

		if (!sensorOption) return;

		const formattedSensorData = {
			id: sensorOption.name,
			unit: sensorOption.unit,
			street: sensorOption.street,
			name: sensorOption.name,
			data: [],
		};

		const numberWithSameName = sensorView.filter(opt => opt.name === formattedSensorData.id).length;
		if (numberWithSameName > 1) {
			const numberOfUniqueStreets = sensorView
				.filter(opt => opt.name === formattedSensorData.name)
				.map(opt => opt.street)
				.filter((value, index, self) => self.indexOf(value) === index).length;

			if (numberOfUniqueStreets > 1) {
				formattedSensorData.id = `${prettyPrintStreet(sensorOption.street)} - ${formattedSensorData.id}`;
			}
			if (numberOfUniqueStreets !== numberWithSameName) {
				let availableIndex = 1;
				while (true) {
					const temporal_id = `${formattedSensorData.id} (${availableIndex})`;
					const sensorWithSameName = transformedData.find(opt => opt.id === temporal_id);
					if (sensorWithSameName === undefined) {
						formattedSensorData.id = `${formattedSensorData.id} (${availableIndex})`;
						break;
					}
					availableIndex++;
				}
			}
		}
		if (prevPeriod !== 'none') {
			formattedSensorData.id = i18n.t('modalSettings.previous') + ' - ' + formattedSensorData.id;
		}

		const dataPoints = sensor.sensordata.map((dataPoint, idx) => ({
			x: convertPreviousTimeRange(dataPoint.x, prevPeriod).getTime(),
			y: dataPoint.y === null ? null : dataPoint.y * (sensorOption.multiplier || 1),
			yLabel: dataPoint.y === null ? null : dataPoint.y * (sensorOption.multiplier || 1),
			unit: sensorOption.unit,
		}));

		formattedSensorData.data = dataPoints.filter(({ y }) => y != null);
		transformedData.push(formattedSensorData);
	});
	return transformedData;
}

function applyUpdatedData(oldData, newData) {
	const updatedData = [...oldData];

	updatedData.forEach(oldSensorData => {
		const dataToAdd = newData.find(s => s.id === oldSensorData.id);
		if (dataToAdd !== undefined) oldSensorData.data.push(...dataToAdd.data);
	});
	return updatedData;
}

export function useQuerySensorDataWidget(
	sensors,
	timeRange,
	interval,
	dataType = 'mean',
	updateInterval = 0,
	skip = 0,
	prevPeriod = 'none'
) {
	const [data, setData] = useState([]);
	const [metaData, setMetaData] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);

	const sensorFilter = useMemo(
		() => getSensorDataWidgetFilter(sensors, timeRange.startDate, timeRange.endDate, interval, dataType),
		[sensors, timeRange, interval, dataType]
	);

	const { loading: sensorLoading, error: sensorError } = useQuery(QUERY_SENSORDATA_NEW_AND_VIEW, {
		variables: sensorFilter,
		skip: skip,
		onCompleted: data => {
			const sensorData = handleWidgetData(data.getSensorDataNew, data.getSensorView, prevPeriod);
			setData(sensorData);
			setMetaData(data.getSensorView);
		},
	});

	const [updateData, { loading: updateLoading, error: updateError }] = useLazyQuery(QUERY_SENSORDATA_NEW, {
		onCompleted: updatedData => {
			const transformedData = handleWidgetData(updatedData.getSensorDataNew, metaData);
			const sensorData = applyUpdatedData(data, transformedData);
			setData(sensorData);
		},
	});

	useEffect(() => {
		setDataLoading(sensorLoading);
	}, [sensorLoading]);

	useEffect(() => {
		if (updateInterval > 0 && timeRange.offset != null && dataType === 'raw') {
			const newIntervalId = setInterval(() => {
				updateData({
					variables: getSensorDataWidgetFilter(sensors, timeRange.endDate, new Date(), interval, dataType),
				});
			}, updateInterval * MILLISECONDS_PER_SECOND);
			return () => {
				clearInterval(newIntervalId);
			};
		}
		// eslint-disable-next-line
	}, [sensors, updateInterval, timeRange, dataType]);
	return { data, metaData, loading: dataLoading, error: sensorError, updateLoading, updateError };
}

export function useQuerySensorDataWidgetPrev(sensors, timeRange, interval, dataType = 'mean', skip = 0, prevPeriod = 'none') {
	const [data, setData] = useState([]);
	const [metaData, setMetaData] = useState([]);
	const [dataLoading, setDataLoading] = useState(true);

	const sensorFilter = useMemo(
		() => getSensorDataWidgetFilter(sensors, timeRange.startDate, timeRange.endDate, interval, dataType),
		[sensors, timeRange, interval, dataType]
	);

	const { loading: sensorLoading, error: sensorError } = useQuery(QUERY_SENSORDATA_NEW_AND_VIEW, {
		variables: sensorFilter,
		skip: skip,
		onCompleted: data => {
			const sensorData = handleWidgetData(data.getSensorDataNew, data.getSensorView);
			setData(sensorData);
			setMetaData(data.getSensorView);
		},
	});

	useEffect(() => {
		setDataLoading(sensorLoading);
	}, [sensorLoading]);

	return { data, metaData, loading: dataLoading, error: sensorError };
}

export function useQuerySensorDataView(sensorList) {
	const { data, loading, error } = useQuery(QUERY_SENSORDATA_VIEW, {
		variables: { sensorViewFilter: { sensorids: sensorList.map(({ sensorid }) => sensorid) } },
	});
	return { data, loading, error };
}
