import React, { useState } from 'react';
import { connect } from 'react-redux';
import { gql, useMutation } from '@apollo/client';
import { IconButton } from '@material-ui/core';
import { SettingsOutlined as SettingsIcon } from '@material-ui/icons';
import { addMinutes } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { CO_CLASSIFICATIONS } from '../constants';
import { getFormattedNumber, getFormattedTimeDiff } from '../utility-functions';
import { getStateVariables, STORE } from '../redux/selectors';
import { setCORegisterOffsets, updateCORegisterOffsets } from '../redux/actionCreators';
import { MultiLineChart } from './NivoCharts';
import EditableValue from './EditableValue';
import MaterialTable from './MaterialTableWrapper';
import EditPopup, { INPUT_TYPE as EditPopupTypes } from './EditPopup';
import i18n from '../i18n';

const SET_COREGISTER_VALUES = gql`
	mutation ($coregisterrefs: [String!]!, $value: String!) {
		setCORegisterValues(coregisterrefs: $coregisterrefs, value: $value) {
			coregisterrefs
			value
		}
	}
`;
const APPLY_COREGISTER_OFFSETS = gql`
	mutation ($coregisterids: [String!]!, $offset: Float!, $hours: Float!) {
		applyCORegisterOffsets(coregisterids: $coregisterids, offset: $offset, hours: $hours) {
			coregisterids
			offset
			hours
		}
	}
`;
const CANCEL_COREGISTER_OFFSETS = gql`
	mutation ($coregisterids: [String!]!) {
		cancelCORegisterOffsets(coregisterids: $coregisterids)
	}
`;

let setCORegisterValues;

/**
 * Adds an extra data point to the left and right of the input data
 * @param {{x: number, y: number}[]} data : To be padded
 * @returns {{x: number, y: number}[]} : Padded `data`
 */
function padData(data) {
	if (data?.length < 2) return [];

	const paddedData = [...data];
	const lastI = data.length - 1;
	paddedData.push({ x: data[lastI].x + (data[lastI].x - data[lastI - 1].x), y: data[lastI].y });
	paddedData.unshift({ x: data[0].x - (data[1].x - data[0].x), y: data[0].y });

	return paddedData;
}

/**
 * Visualizes offset currently applied to CO register and allows it to be modified
 * @param {object[]} registers : Register to show offset for
 * @param {object} style : Applied to root-container
 */
const CORegisterOffset = connect(getStateVariables(STORE.coRegisterOffsets), { setCORegisterOffsets, updateCORegisterOffsets })(props => {
	const [showSettings, setShowSettings] = useState(false);

	const { t } = useTranslation();

	const [applyCORegisterOffsets] = useMutation(APPLY_COREGISTER_OFFSETS, {
		onCompleted: ({ applyCORegisterOffsets }) =>
			props.updateCORegisterOffsets(
				applyCORegisterOffsets.coregisterids.map(id => {
					const register = props.registers.find(reg => reg.coregisterid === id);
					return {
						coregisterid: id,
						offset: applyCORegisterOffsets.offset * register?.multiplier,
						enddate: addMinutes(new Date(), applyCORegisterOffsets.hours * 60),
						originalvalue: register?.latestvalue,
					};
				})
			),
	});
	const [cancelCORegisterOffsets] = useMutation(CANCEL_COREGISTER_OFFSETS, {
		onCompleted: ({ cancelCORegisterOffsets }) =>
			props.setCORegisterOffsets(props.coRegisterOffsets.filter(off => !cancelCORegisterOffsets.includes(off.coregisterid))),
	});

	if (!props.registers?.length) return null;

	const offsets = props.coRegisterOffsets.filter(off => props.registers.some(reg => reg.coregisterid === off.coregisterid));
	const offsetIsValid =
		offsets.length === props.registers.length &&
		offsets.every(off => off.offset === offsets[0].offset && String(off.enddate) === String(offsets[0].enddate));
	const offset = offsets[0]?.offset;

	return (
		<>
			<div style={props.style}>
				<h3 style={{ fontSize: '92%', fontWeight: '300' }}>{t('coView.offset')}</h3>

				<div style={{ display: 'flex' }}>
					<div style={{ fontSize: '114%', marginTop: '0.2rem' }}>
						{!offsets.length ? (
							'-'
						) : offsetIsValid ? (
							<div style={{ display: 'flex' }}>
								{offset >= 0 ? '+' : '-'} {Math.abs(offsets[0].offset)} {props.registers[0].unit}
								<div style={{ margin: '2px 0 0 0.4rem', color: '#000b', fontSize: '80%' }}>
									( {getFormattedTimeDiff(new Date(), offsets[0].enddate)} )
								</div>
							</div>
						) : (
							t('coView.incorrectOffset')
						)}
					</div>

					<IconButton
						onClick={() => setShowSettings(!showSettings)}
						size='small'
						style={{ top: '-1px', left: '0.3rem', width: '1.8rem', height: '1.8rem' }}
					>
						<SettingsIcon style={{ width: '1.25rem', color: '#0003' }} />
					</IconButton>
				</div>
			</div>

			<EditPopup
				text={
					offsets.length
						? {
								title: t('coView.endOffsetTitle'),
								subtitle: t('coView.endOffsetSubtitle'),
								cancel: t('generic.leave'),
								save: t('generic.end'),
							}
						: { title: t('coView.offsetTitle'), subtitle: t('coView.offsetSubtitle') }
				}
				fields={
					offsets.length
						? undefined
						: [
								{
									id: 'offset',
									label: `${t('coView.offset')} ( ${
										props.registers[0]?.unit ||
										(props.registers.length > 1 && t('coView.flowTempAbb_lowercase')) ||
										t('coView.unit_other_lowercase')
									} )`,
									type: EditPopupTypes.Float,
									required: true,
								},
								{ id: 'hours', label: t('generic.hour_other'), type: EditPopupTypes.Float, required: true },
							]
				}
				isOpen={showSettings}
				canSave={offsets.length ? true : undefined}
				onClose={() => setShowSettings(!showSettings)}
				onSave={opts => {
					setShowSettings(!showSettings);
					if (offsets.length) cancelCORegisterOffsets({ variables: { coregisterids: offsets.map(off => off.coregisterid) } });
					else
						applyCORegisterOffsets({
							variables: {
								coregisterids: props.registers.map(off => off.coregisterid),
								offset: Number(opts.offset) / props.registers[0]?.multiplier,
								hours: Number(opts.hours),
							},
						});
				}}
			/>
		</>
	);
});

/**
 * Renders a function-curve and allows it to be adjusted and saved
 * @param {object | undefined} registers : To be rendered
 */
function COCurve(props) {
	if (!props.registers?.length) return null;

	const xRegs = props.registers
		.filter(cor => cor.classification.includes(`;${CO_CLASSIFICATIONS.curve.subClasses.x.value};`))
		.sort((a, b) => (Number(a.classification.split(';')[2]) < Number(b.classification.split(';')[2]) ? -1 : 1));
	const yRegs = props.registers
		.filter(cor => cor.classification.includes(`;${CO_CLASSIFICATIONS.curve.subClasses.y.value};`))
		.sort((a, b) => (Number(a.classification.split(';')[2]) < Number(b.classification.split(';')[2]) ? -1 : 1));
	const combinedVals = xRegs.map((xReg, i) => ({ x: xReg.latestvalue, y: yRegs[i].latestvalue, xReg, yReg: yRegs[i] }));
	if (
		xRegs.length !== yRegs.length ||
		combinedVals.some(
			val =>
				!val.xReg.classification.replace(
					CO_CLASSIFICATIONS.curve.subClasses.x.value,
					CO_CLASSIFICATIONS.curve.subClasses.y.value
				) === val.yReg.classification
		)
	)
		return <div>{i18n.t('coView.improperlyConfiguredRegs')}</div>;

	function renderEditableValue(register) {
		return (
			<EditableValue
				value={getFormattedNumber(register.latestvalue)}
				colors={{ value: '#444', inactiveIcon: '#0003' }}
				editAreaStyle={{ transform: 'scale(0.88)', marginTop: '2px' }}
				checkValidity={val => !isNaN(val) || val === '-'}
				onSave={val =>
					setCORegisterValues({
						variables: { coregisterrefs: [register.coregisterref], value: String(Number(val) / (register.multiplier || 1)) },
					})
				}
			/>
		);
	}

	return (
		<div style={{ display: 'flex' }}>
			<MaterialTable
				data={combinedVals.map((val, i) => ({
					...val,
					index: i + 1,
				}))}
				columns={[
					{ title: i18n.t('coView.point'), field: 'index' },
					{
						title: `X-${i18n.t('coView.setpoint')}`,
						render: row => renderEditableValue(row.xReg),
					},
					{
						title: `Y-${i18n.t('coView.setpoint')}`,
						render: row => renderEditableValue(row.yReg),
					},
				]}
				options={{
					toolbar: false,
					paging: false,
					//sorting: false,
					headerStyle: {
						color: '#000',
						background: '#fff',
						height: '2rem',
						fontWeight: '430',
						fontSize: '90%',
					},
					rowStyle: {
						color: '#444',
						height: '2rem',
						whiteSpace: 'nowrap',
					},
				}}
				style={{ height: 'max-content' }}
			/>

			<div style={{ margin: '1rem 0 0 2rem', width: '29rem', height: '16rem' }}>
				<MultiLineChart
					data={[
						{
							id: 'curve',
							data: padData(combinedVals),
						},
					]}
					maxLegends={0}
					chartProps={{
						axisLeft: {
							tickSize: 0,
							legend: i18n.t('coView.curveYLabel'),
							legendOffset: -36,
							legendPosition: 'middle',
							format: val => getFormattedNumber(val),
						},
						axisBottom: {
							tickSize: 0,
							tickPadding: 0,
							legend: i18n.t('coView.curveXLabel'),
							legendOffset: 26,
							legendPosition: 'middle',
							format: val => getFormattedNumber(val),
						},
					}}
				/>
			</div>

			<CORegisterOffset registers={yRegs} style={{ marginLeft: '-8rem', zIndex: '1' }} />
		</div>
	);
}

/**
 * Renders information about a CO setpoint register
 * @param {object | undefined} register : CORegister of the setpoint
 */
function COSetpoint(props) {
	if (!props.register) return null;

	return (
		<div style={{ display: 'flex', margin: '0 0 0 0.1rem' }}>
			<EditableValue
				label={props.register.name}
				value={getFormattedNumber(props.register.latestvalue)}
				unit={props.register.unit}
				colors={{ value: '#444', inactiveIcon: '#0003' }}
				checkValidity={val => !isNaN(val) || val === '-'}
				onSave={val =>
					setCORegisterValues({
						variables: {
							coregisterrefs: [props.register.coregisterref],
							value: String(Number(val) / (props.register.multiplier || 1)),
						},
					})
				}
			/>
			<CORegisterOffset registers={[props.register]} style={{ marginLeft: '3rem' }} />
		</div>
	);
}

/**
 * Shows info-panels for specific CO types
 * @param {Object} co : For which to show info-panel
 */
function COView(props) {
	[setCORegisterValues] = useMutation(SET_COREGISTER_VALUES, {
		// onCompleted: res => console.log(res),
		onError: console.error,
	});

	const regs = props.coRegisters.filter(cor => cor.coid === props.co?.coid && cor.classification);

	return (
		<>
			<COCurve registers={regs.filter(reg => reg.classification.includes(CO_CLASSIFICATIONS.curve.value + ';'))} />
			<COSetpoint register={regs.find(reg => reg.classification === CO_CLASSIFICATIONS.setpoint.value)} />
		</>
	);
}

export default connect(getStateVariables(STORE.coRegisters))(COView);
