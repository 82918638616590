/**
 * Calls setFunction only if oldValue does not equal newValue.
 * Performs deep comparison on objects.
 * @param {*} oldValue : Current value of variable.
 * @param {*} newValue : Value to give the variable.
 * @param {Function} setFunction : Function used for changing the variable.
 * @returns {Boolean} : Whether or not setFunction was called.
 */
function safeSetState(oldValue, newValue, setFunction) {
	if (typeof setFunction !== 'function') return false;

	if (oldValue === newValue) return false;

	if (typeof oldValue === 'object' && typeof newValue === 'object' && JSON.stringify(oldValue) === JSON.stringify(newValue)) return false;

	// If none of the above checks have been triggered
	setFunction(newValue);
	return true;
}

export default safeSetState;
