/**
 * Checks whether the latest value of a sensor is inside the thresholds
 * @param {object} sensor : Sensor to check, must have 'value', 'lowerthreshold', and 'upperthreshold' keys
 * @returns {boolean} false if the value is ON or OUTSIDE thresholds
 */
function isValueInsideThresholds(sensor) {
	return (
		(sensor.lowerthreshold === null || sensor.value > sensor.lowerthreshold) &&
		(sensor.upperthreshold === null || sensor.value < sensor.upperthreshold)
	);
}

export { isValueInsideThresholds };
