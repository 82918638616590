import React, { useState, useEffect } from 'react';

import { connect } from 'react-redux';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Radio, Button, Dialog, DialogActions, Backdrop, LinearProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { STORE, getStateVariables } from '../redux/selectors';
import SelectionTable from './SelectionTable';
import SelectionSidebar from './SelectionSidebar';
import EditPopup from './EditPopup';
import { PROP_SELECTION_TYPES, AUTHORIZATIONACCESS_IDS, AUTHORIZATION_OPTIONS } from '../constants';
import * as colors from '../colors';

const WEAK_PASSWORD = `weakPassword
passwordRequirements:
- min 12 characters
- at least one uppercase letter 
- at least one lowercase letter 
- at least one number`;

const generatePassword = () => {
	const upperCaseLetters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
	const lowerCaseLetters = 'abcdefghijklmnopqrstuvwxyz';
	const numbers = '0123456789';
	const allCharacters = upperCaseLetters + lowerCaseLetters + numbers;

	let password = '';
	password += upperCaseLetters[Math.floor(Math.random() * upperCaseLetters.length)];
	password += lowerCaseLetters[Math.floor(Math.random() * lowerCaseLetters.length)];
	password += numbers[Math.floor(Math.random() * numbers.length)];

	for (let i = 3; i < 12; i++) {
		password += allCharacters[Math.floor(Math.random() * allCharacters.length)];
	}

	return password.split('').sort(() => 0.5 - Math.random()).join('');
};

const GET_KEYCLOAK_USER_CHILDREN = gql`
	query ($filter: KeycloakUserChildrenFilter!) {
		getKeycloakUserChildren(filter: $filter) {
			id
			parentId
			username
			email
			phonenumber
			firstName
			lastName
			propertyAccess
			propertyIds
			authorizationAccess
			authorizationOptions
			customerName
		}
	}
`;

const GET_KEYCLOAK_USER_WITH_ATTRIBUTE = gql`
	query ($filter: KeycloakUsersAttributeFilter!) {
		getKeycloakUsersWithAttribute(filter: $filter) {
			id
			parentId
			username
			email
			phonenumber
			firstName
			lastName
			propertyAccess
			propertyIds
			authorizationAccess
			authorizationOptions
			customerName
		}
	}
`;
const SET_KEYCLOAK_USERS = gql`
	mutation (
		$ids: [String!]!
		$email: String
		$phonenumber: String
		$firstName: String
		$lastName: String
		$propertyAccess: String
		$propertyIds: [Int!]
		$authorizationAccess: String
		$authorizationOptions: [String!]
		$password: String
		$customerName: String
	) {
		setKeycloakUsers(
			ids: $ids
			email: $email
			phonenumber: $phonenumber
			firstName: $firstName
			lastName: $lastName
			propertyAccess: $propertyAccess
			propertyIds: $propertyIds
			authorizationAccess: $authorizationAccess
			authorizationOptions: $authorizationOptions
			password: $password
			customerName: $customerName
		) {
			id
			username
			email
			phonenumber
			firstName
			lastName
			propertyAccess
			propertyIds
			authorizationAccess
			authorizationOptions
			customerName	
		}
	}
`;
const ADD_KEYCLOAK_USER = gql`
	mutation (
		$parentId: String!
		$username: String!
		$email: String
		$phonenumber: String
		$firstName: String
		$lastName: String
		$propertyAccess: String
		$propertyIds: [Int!]
		$authorizationAccess: String
		$authorizationOptions: [String!]
		$password: String!
		$customerName: String
		$emailSubject: String
		$emailBody: String
	) {
		addKeycloakUser(
			parentId: $parentId
			username: $username
			email: $email
			phonenumber: $phonenumber
			firstName: $firstName
			lastName: $lastName
			propertyAccess: $propertyAccess
			propertyIds: $propertyIds
			authorizationAccess: $authorizationAccess
			authorizationOptions: $authorizationOptions
			password: $password
			customerName: $customerName
			emailSubject: $emailSubject
			emailBody: $emailBody
		) {
			id
		}
	}
`;
const DEL_KEYCLOAK_USER = gql`
	mutation ($id: String!) {
		delKeycloakUser(id: $id)
	}
`;
const GET_PROPERTIES = gql`
	query {
		getSensorLocations {
			locationid
			city
			street
			cadastral
			area
		}
	}
`;

const POPUP_TYPES = Object.freeze({ edit: 1, add: 2, delete: 3 });

function CreatedUserAdministration(props) {
	const [selectedUsers, setSelectedUsers] = useState([]);
	const [usersUnderEdit, setUsersUnderEdit] = useState([]);
	const [editPopupType, setEditPopupType] = useState();
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [selectedPropAlternative, setSelectedPropAlternative] = useState(PROP_SELECTION_TYPES.selected.id);
	const [selectedAuthAlternative, setSelectedAuthAlternative] = useState(AUTHORIZATIONACCESS_IDS.selected);
	const [propPopupEnabled, setPropPopupEnabled] = useState(false);
	const [authPopupEnabled, setAuthPopupEnabled] = useState(false);
	const [selectedPropIds, setSelectedPropIds] = useState([]);
	const [selectedAuthIds, setSelectedAuthIds] = useState([]);
	const [userPopupCanSave, setUserPopupCanSave] = useState();
	const [isLoading, setIsLoading] = useState(true);
	const [errorMessages, setErrorMessages] = useState('');
	const [clearSelectionsTrigger, setClearSelectionsTrigger] = useState(0);
	//New user details
	const [companyName, setCompanyName] = useState('Meliox Connect');
	const [loginLink, setLoginLink] = useState('https://connect.melioxdev.se/');
	const [supportEmail, setSupportEmail] = useState('support@meliox.se');
	const [password, setPassword] = useState('');
	const { t } = useTranslation();

	// Reset the selected properties and selected property alternative when the edit popup closes
	useEffect(() => {
		if (!editPopupEnabled)
			setTimeout(() => {
				setSelectedPropAlternative(PROP_SELECTION_TYPES.selected.id);
				setSelectedAuthAlternative(AUTHORIZATIONACCESS_IDS.selected);
				setSelectedPropIds([]);
				setSelectedAuthIds([]);
				setUserPopupCanSave(undefined);
			}, 200);
	}, [editPopupEnabled]);
	const excludedSuperUsers = ['meliox', 'bastec superuser', 'swegon superuser', 'sandbäckens superuser'];
	const notSuperUser = !excludedSuperUsers.includes(props.userInfo.username);
	/* const childrenQuery = useQuery(notSuperUser ? GET_KEYCLOAK_USER_CHILDREN : GET_KEYCLOAK_USER_WITH_ATTRIBUTE, {
		variables: {
			filter: notSuperUser ? { id: props.userInfo.id } : { customerName: ['meliox', 'bastec', 'swegon', 'sandbackens','Genesta'] },
		},
		skip: notSuperUser && !props.userInfo.id,
	}); */

	const childrenQuery = useQuery(GET_KEYCLOAK_USER_CHILDREN, {
		variables: {
			filter: { id: props.userInfo.id },
		},
		skip: !props.userInfo.id,
	});

	const CustomerQuery = useQuery(GET_KEYCLOAK_USER_WITH_ATTRIBUTE, {
		variables: {
			filter: { customerName: ['meliox', 'bastec', 'swegon', 'sandbackens', 'genesta', 'Meliox', 'Bastec', 'Swegon', 'Sandbackens', 'Genesta'] },
		},
		skip: notSuperUser,
	});


	//loading bar
	useEffect(() => {
		if (props.userInfo.id && !childrenQuery.loading) {
			setIsLoading(false);
		}
	}, [props.userInfo.id, childrenQuery.loading]);

	const [setUsers] = useMutation(SET_KEYCLOAK_USERS, {
		onCompleted: () => {
			childrenQuery.refetch();
			CustomerQuery.refetch();
			triggerClearSelections();
		},
	});

	// Function to trigger clearing of selections
	const triggerClearSelections = () => setClearSelectionsTrigger(prev => prev + 1);

	// generate password
	const handleGeneratePassword = () => {
		const newPassword = generatePassword();
		setPassword(newPassword);
	};
	useEffect(() => {
		setCompanyName(t(`companyName.${props.userInfo.customerName}`) || 'Meliox Connect');
		setLoginLink(t(`loginLink.${props.userInfo.customerName}`) || 'https://connect.melioxdev.se/');
		setSupportEmail(t(`supportEmail.${props.userInfo.customerName}`) || 'support@meliox.se');
	}, [props.userInfo.customerName]);

	const [addUser] = useMutation(ADD_KEYCLOAK_USER, {
		onCompleted: async () => {
			Swal.fire(t('userAdmin.success'), t('userAdmin.createUser'), 'success');
			setEditPopupEnabled(!editPopupEnabled);
			setErrorMessages('');
			childrenQuery.refetch();
			CustomerQuery.refetch();
		},
	});
	const handleChange = async () => {
		try {
			const timeoutPromise = new Promise((_, reject) =>
				setTimeout(() => reject(new Error('User creation timeout')), 1529)
			);

			await Promise.race([addUser(), timeoutPromise]);
		} catch (error) {
			setTimeout(() => {
				setErrorMessages(t('userAdmin.userNameOrEmailAlreadyExists'));
				// Swal.fire('Error', t('userAdmin.errorCreatingUser'), 'error');
			}, 1529);
		}
	};
	const [delUser] = useMutation(DEL_KEYCLOAK_USER, {
		onCompleted: () => {
			Swal.fire(t('userAdmin.success'), t('userAdmin.deleteUser'), 'success');
			childrenQuery.refetch();
		},
	});
	const propertiesQuery = useQuery(GET_PROPERTIES);
	//const excludedUsernames = ['meliox', 'bastec superuser', 'swegon superuser', 'sandbäckens superuser'];
	//const userFilter = !excludedUsernames.includes(props.userInfo.username) ? 'getKeycloakUserChildren' : 'getKeycloakUsersWithAttribute';
	const excludedUsers = ['meliox', 'connect-graphql-server', 'connect-report-deliverer', 'root'];
	const users = (!childrenQuery.loading && childrenQuery.data ? childrenQuery.data.getKeycloakUserChildren : [])
		.concat(!CustomerQuery.loading && CustomerQuery.data ? CustomerQuery.data.getKeycloakUsersWithAttribute : [])
		.filter(user => !excludedUsers.includes(user.username))
		.filter(user => {
			if (props.userInfo.username === 'testing') {
				return user.customerName === 'meliox' && user.parentId !== '7788abaa-66ec-4f53-b80f-37d39b5c622f';
			} else if (props.userInfo.username === 'bastec superuser') {
				return user.customerName === 'bastec' && user.parentId !== '7788abaa-66ec-4f53-b80f-37d39b5c622f';
			} else if (props.userInfo.username === 'swegon superuser') {
				return user.customerName === 'swegon' && user.parentId !== '7788abaa-66ec-4f53-b80f-37d39b5c622f';
			} else if (props.userInfo.username === 'sandbäckens superuser') {
				return user.customerName === 'sandbackens' && user.parentId !== '7788abaa-66ec-4f53-b80f-37d39b5c622f';
			} else if (props.userInfo.username === 'dlind3@gmail.com') {
				return user.customerName === 'Genesta' && user.parentId !== '7788abaa-66ec-4f53-b80f-37d39b5c622f';
			}
			else {
				return !excludedUsers.includes(user.username);
			}
		})
		.filter(user => user.username !== props.userInfo.username)
		.reduce((unique, user) => {
			return unique.some(u => u.username === user.username) ? unique : [...unique, user];
		}, [])
		.map(use => ({
			...use,
			[use.propertyAccess === PROP_SELECTION_TYPES.all.id && 'propertyAccess']: PROP_SELECTION_TYPES.parents.id,
			[use.authorizationAccess === AUTHORIZATIONACCESS_IDS.all && 'authorizationAccess']: AUTHORIZATIONACCESS_IDS.parents,
			propertyCount:
				use.propertyAccess === PROP_SELECTION_TYPES.all.id || use.propertyAccess === PROP_SELECTION_TYPES.parents.id
					? t('generic.all')
					: use.propertyIds
						? use.propertyIds.length
						: 0,
		}));
	/* 	const users = (!childrenQuery.loading && childrenQuery.data ? childrenQuery.data.getKeycloakUserChildren : [])
			.filter(user => user.customerName === 'bastec')
			.map(use => ({
				...use,
				[use.propertyAccess === PROP_SELECTION_TYPES.all.id && 'propertyAccess']: PROP_SELECTION_TYPES.parents.id,
				[use.authorizationAccess === AUTHORIZATIONACCESS_IDS.all && 'authorizationAccess']: AUTHORIZATIONACCESS_IDS.parents,
				propertyCount:
					use.propertyAccess === PROP_SELECTION_TYPES.all.id || use.propertyAccess === PROP_SELECTION_TYPES.parents.id
						? t('generic.all')
						: use.propertyIds
							? use.propertyIds.length
							: 0,
			})); */

	const properties = !propertiesQuery.loading && propertiesQuery.data ? propertiesQuery.data.getSensorLocations : [];
	let tempSelectedPropIds;
	let tempSelectedAuthIds;

	function isStrongPassword(password) {
		return password.length >= 12 && password.match(/[a-z]/) && password.match(/[A-Z]/) && password.match(/[0-9]/);
	}

	function getPropertySelectionField() {
		const radioAlternatives = [
			{
				id: PROP_SELECTION_TYPES.selected.id,
				render: (
					<div>
						{selectedPropIds.length}{' '}
						{(selectedPropIds.length === 1
							? t('createdUserAdmin.selectedProperty_lowercase')
							: t('createdUserAdmin.selectedProperties_lowercase')) + '.'}
						<Button
							onClick={() => setPropPopupEnabled(true)}
							variant='outlined'
							size='small'
							style={{ left: '0.8rem', top: '-0.07rem', padding: '0', color: colors.primary }}
						>
							{t('createdUserAdmin.selectDot')}
						</Button>
					</div>
				),
			},
			{ id: PROP_SELECTION_TYPES.parents.id, render: <div>{t('createdUserAdmin.currentAndFuturePromt')}</div> },
		];

		return (
			<div>
				{radioAlternatives.map(alt => (
					<div key={alt.id} style={{ display: 'flex' }}>
						<Radio
							checked={selectedPropAlternative === alt.id}
							onChange={() => {
								setSelectedPropAlternative(alt.id);
								if (editPopupType === POPUP_TYPES.edit);
							}}
							style={{ padding: '0.3rem', left: '-0.3rem', color: colors.primary }}
						/>
						<div style={{ margin: 'auto 0' }}>{alt.render}</div>
					</div>
				))}
			</div>
		);
	}

	function getAuthorizationSelectionField() {
		//TODO: Unsure how to access this part of the website so i can get context for the translation
		const radioAlternatives = [
			{ id: AUTHORIZATIONACCESS_IDS.read, render: <div>{t('createdUserAdmin.readOnly')}</div> },
			{
				id: AUTHORIZATIONACCESS_IDS.minimum,
				render: <div>{t('createdUserAdmin.ackAndTreshEdit')}</div>,
				disabled:
					props.userInfo.authorizationAccess !== AUTHORIZATIONACCESS_IDS.all &&
					!(
						props.userInfo.authorizationOptions?.includes(AUTHORIZATION_OPTIONS.confirm.id) &&
						props.userInfo.authorizationOptions?.includes(AUTHORIZATION_OPTIONS.editAlarmThreshold.id)
					),
			},
			{
				id: AUTHORIZATIONACCESS_IDS.parents,
				render: <div>{t('createdUserAdmin.admin')}</div>,
				disabled:
					props.userInfo.authorizationAccess !== AUTHORIZATIONACCESS_IDS.all &&
					!props.userInfo.authorizationOptions?.includes(AUTHORIZATION_OPTIONS.delete.id),
			},
			// {
			// 	id: AUTHORIZATIONACCESS_IDS.selected,
			// 	render: (
			// 		<div>
			// 			Andra: {selectedAuthIds.length} {(selectedAuthIds.length === 1 ? 'vald rättighet' : 'valda rättigheter') + '.'}
			// 			<Button
			// 				onClick={() => setAuthPopupEnabled(true)}
			// 				variant='outlined'
			// 				size='small'
			// 				style={{ left: '0.8rem', top: '-0.07rem', padding: '0', color: colors.primary }}
			// 			>
			// 				Välj...
			// 			</Button>
			// 		</div>
			// 	),
			// },
		];



		return (
			<div>
				{radioAlternatives.map(alt => (
					<div key={alt.id} style={{ display: 'flex' }}>
						<Radio
							checked={selectedAuthAlternative === alt.id}
							onChange={() => {
								setSelectedAuthAlternative(alt.id);
								if (editPopupType === POPUP_TYPES.edit);
							}}
							disabled={alt.disabled}
							style={{ padding: '0.3rem', left: '-0.3rem', color: colors.primary }}
						/>
						<div style={{ margin: 'auto 0' }}>{alt.render}</div>
					</div>
				))}
			</div>
		);
	}

	return (
		<div style={{ margin: '1rem 0 0 1rem' }}>
			<div style={{ display: 'flex', margin: '0 1rem 1.5rem 0' }}>
				<SelectionTable
					localization={{ title: t('constants.users'), nRowsSelected: t('createdUserAdmin.amountOfSelectedUsers') + '{0}' }}
					key={JSON.stringify(users)}
					data={users}
					dataId='id'
					onSelectionChange={users => setSelectedUsers(users)}
					/* 				onRowClick={user => {
										setUsersUnderEdit([user]);
										setSelectedPropIds(user.propertyIds || []);
										setSelectedAuthIds(user.authorizationOptions || []);
										setSelectedPropAlternative(
											user.propertyAccess === PROP_SELECTION_TYPES.parents.id
												? PROP_SELECTION_TYPES.parents.id
												: PROP_SELECTION_TYPES.selected.id
										);
										setSelectedAuthAlternative(
											user.authorizationAccess === AUTHORIZATIONACCESS_IDS.parents ||
												user.authorizationOptions?.includes(AUTHORIZATION_OPTIONS.delete.id)
												? AUTHORIZATIONACCESS_IDS.parents
												: !user.authorizationOptions?.length
												? AUTHORIZATIONACCESS_IDS.read
												: user.authorizationOptions?.length === 2 &&
												  user.authorizationOptions?.includes(AUTHORIZATION_OPTIONS.confirm.id) &&
												  user.authorizationOptions?.includes(AUTHORIZATION_OPTIONS.editAlarmThreshold.id)
												? AUTHORIZATIONACCESS_IDS.minimum
												: AUTHORIZATIONACCESS_IDS.selected
										);
										setEditPopupType(POPUP_TYPES.edit);
										setEditPopupEnabled(!editPopupEnabled);
									}} */
					clearSelectionsTrigger={clearSelectionsTrigger}
					columns={[
						{ title: t('generic.userName'), field: 'username', defaultSort: 'asc', maxLength: 24 },
						{
							title: t('generic.email'),
							field: 'email',
							maxLength: 8,
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.email}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setUsers({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												ids: [rowData.id],
												email: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{
							title: t('generic.phoneNumber'),
							field: 'phonenumber',
							maxLength: 8,
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.phonenumber}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setUsers({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												ids: [rowData.id],
												phonenumber: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{
							title: t('generic.firstName'),
							field: 'firstName',
							maxLength: 12,
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.firstName}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setUsers({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												ids: [rowData.id],
												firstName: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{
							title: t('generic.lastName'),
							field: 'lastName',
							maxLength: 12,
							render: rowData => (
								<input
									//onClick={() => onSensorClick(rowData)}
									defaultValue={rowData.lastName}
									style={{ cursor: 'pointer', border: 'none' }}
									contentEditable='true'
									onBlur={e => {
										e.preventDefault();
										setUsers({
											variables: {
												// Cast only if truthy so undefined won't be converted to null, as they are treated differently
												ids: [rowData.id],
												lastName: e.target.value,
											},
										});
									}}
								/>
							),
						},
						{ title: t('constants.properties'), field: 'propertyCount' },
						// display customername only if user is meliox
						{ title: t('generic.customerName'), field: 'customerName', hidden: props.userInfo.username !== 'meliox' },
					]}
					tableProps={{ maxColumnLength: 27 }}
				/>

				<SelectionSidebar
					buttons={[
						{
							label: t('createdUserAdmin.createNewUser'),
							onClick: () => {
								setErrorMessages('');
								setEditPopupType(POPUP_TYPES.add);
								setEditPopupEnabled(!editPopupEnabled);
								//generate password
								handleGeneratePassword();
							},
						},
						{
							label: t('genericAdmin.editSelected'),
							onClick: () => {
								setErrorMessages('');
								setUsersUnderEdit(selectedUsers);
								setEditPopupType(POPUP_TYPES.edit);
								setEditPopupEnabled(!editPopupEnabled);
								if (selectedUsers.length === 1) {
									setSelectedPropIds(selectedUsers[0].propertyIds || []);
									setSelectedAuthIds(selectedUsers[0].authorizationOptions || []);
									setSelectedPropAlternative(
										selectedUsers[0].propertyAccess === PROP_SELECTION_TYPES.parents.id
											? PROP_SELECTION_TYPES.parents.id
											: PROP_SELECTION_TYPES.selected.id
									);
									setSelectedAuthAlternative(
										selectedUsers[0].authorizationAccess === AUTHORIZATIONACCESS_IDS.parents ||
											selectedUsers[0].authorizationOptions?.includes(AUTHORIZATION_OPTIONS.delete.id)
											? AUTHORIZATIONACCESS_IDS.parents
											: !selectedUsers[0].authorizationOptions?.length
												? AUTHORIZATIONACCESS_IDS.read
												: selectedUsers[0].authorizationOptions?.length === 2 &&
													selectedUsers[0].authorizationOptions?.includes(AUTHORIZATION_OPTIONS.confirm.id) &&
													selectedUsers[0].authorizationOptions?.includes(
														AUTHORIZATION_OPTIONS.editAlarmThreshold.id
													)
													? AUTHORIZATIONACCESS_IDS.minimum
													: AUTHORIZATIONACCESS_IDS.selected
									);
								}
							},
							disabled: !selectedUsers.length,
						},
						{
							label: t('genericAdmin.archiveSelected'),
							onClick: () => {
								setErrorMessages('');
								setEditPopupType(POPUP_TYPES.delete);
								setEditPopupEnabled(!editPopupEnabled);
								//selectedUsers.forEach(user => delUser({ variables: { id: user.id } }));
								//setSelectedUsers([]);
							},
							disabled: !selectedUsers.length,
						},

						//{label: t('genericAdmin.expandUser'),
						/* onClick: () => {
							selectedUsers.forEach(user => delUser({ variables: { id: user.id } }));
							setSelectedUsers([]);
						}, */
						//disabled: !selectedUsers.length,},

					]}
					style={{ width: '12.4rem', margin: '0.05rem 0 0 1rem' }}
				/>
			</div>

			<EditPopup
				text={
					editPopupType === POPUP_TYPES.edit
						? {
							title:
								usersUnderEdit.length === 1
									? t('createdUserAdmin.editUser') + ' ' + usersUnderEdit[0].username
									: t('generic.edit') + ' ' + usersUnderEdit.length + ' ' + t('createdUserAdmin.selectedUsers'),
							subtitle:
								t('genericAdmin.selectValuesFor') +
								' ' +
								t('createdUserAdmin.theSelectedUser', { count: usersUnderEdit.length }), // TODO: Check that this is correct because im unsure i did this right
						}
						: editPopupType === POPUP_TYPES.add ?
							{
								title: t('createdUserAdmin.createNewUser'),
								subtitle: (<>{t('createdUserAdmin.provideNewValuesForNewUser')} <br /> <span style={{ color: 'red' }}>{errorMessages}</span> </>)
							}
							: {
								title: t('createdUserAdmin.deleteSelectedUsers'),
								subtitle: t('createdUserAdmin.areYouSureYouWantToDelete') + ' ' + selectedUsers.length + ' ' + t('createdUserAdmin.selectedUsers'),
								save: 'Confirm',
							}
				}
				fields={
					editPopupType === POPUP_TYPES.edit
						? [
							{
								id: 'email',
								label: t('generic.email'),
								placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].email : '...',
								disabled: usersUnderEdit.length > 1,
							},
							{
								id: 'phonenumber',
								label: t('generic.phoneNumber'),
								placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].phonenumber : '...',
								disabled: usersUnderEdit.length > 1,
							},
							{
								id: 'firstName',
								label: t('generic.firstName'),
								placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].firstName : '...',
								disabled: usersUnderEdit.length > 1,
							},
							{
								id: 'lastName',
								label: t('generic.lastName'),
								placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].lastName : '...',
								disabled: usersUnderEdit.length > 1,
							},
							/* {
								id: 'customerName',
								label: t('generic.customerName'),
								placeholder: usersUnderEdit.length === 1 ? usersUnderEdit[0].customerName : '...',
								disabled: props.userInfo.superPower !== 'meliox',
							}, */
							{
								id: 'propertyIds',
								label: t('constants.properties'),
								customRender: getPropertySelectionField(),
							},
							{
								id: 'authorizationOptions',
								label: t('generic.privilige_other'),
								customRender: getAuthorizationSelectionField(),
							},
						]
						: editPopupType === POPUP_TYPES.add ?
							[
								{
									id: 'username',
									label: t('generic.email'),
									placeholder: '...',
									required: true,
								},
								{
									id: 'email',
									label: t('generic.confirmEmail'),
									placeholder: '...',
								},
								/* {
									id: 'password',
									label: t('generic.password'),
									placeholder: '...',
									required: true,
									required_rule_text: WEAK_PASSWORD,
									required_rule: password => !isStrongPassword(password),
								}, */
								{
									id: 'phonenumber',
									label: t('generic.phoneNumber'),
									placeholder: '...',
								},
								{
									id: 'firstName',
									label: t('generic.firstName'),
									placeholder: '...',
								},
								{
									id: 'lastName',
									label: t('generic.lastName'),
									placeholder: '...',
								},
								{
									id: 'customerName',
									label: t('generic.customerName'),
									placeholder: '...',
									disabled: props.userInfo.superPower !== 'meliox',
								},
								{
									id: 'propertyIds',
									label: t('constants.properties'),
									customRender: getPropertySelectionField(),
								},
								{
									id: 'authorizationOptions',
									label: t('generic.privilige_other'),
									customRender: getAuthorizationSelectionField(),
								},
							] : []
				}
				isOpen={editPopupEnabled}
				canSave={userPopupCanSave}
				onClose={() => setEditPopupEnabled(!editPopupEnabled)}
				onSave={(opts) => {
					if (editPopupType === POPUP_TYPES.edit) {
						Swal.fire(t('userAdmin.success'), t('userAdmin.saveInfo'), 'success');
						setUsers({
							variables: {
								ids: usersUnderEdit.map((user) => user.id),
								email: opts.email,
								phonenumber: opts.phonenumber,
								firstName: opts.firstName,
								lastName: opts.lastName,
								//	customerName: props.userInfo.superPower === 'meliox' ? opts.customerName : (props.userInfo.customerName || 'meliox'),
								propertyAccess: selectedPropAlternative === PROP_SELECTION_TYPES.parents.id ? PROP_SELECTION_TYPES.parents.id : '',
								propertyIds: selectedPropAlternative === PROP_SELECTION_TYPES.selected.id ? selectedPropIds : [],
								authorizationAccess: selectedAuthAlternative === AUTHORIZATIONACCESS_IDS.parents ? AUTHORIZATIONACCESS_IDS.parents : '',
								authorizationOptions:
									selectedAuthAlternative === AUTHORIZATIONACCESS_IDS.minimum
										? [AUTHORIZATION_OPTIONS.confirm.id, AUTHORIZATION_OPTIONS.editAlarmThreshold.id]
										: selectedAuthAlternative === AUTHORIZATIONACCESS_IDS.selected
											? selectedAuthIds
											: [],
							},
						}, setEditPopupEnabled(!editPopupEnabled),
						);
					} else if (editPopupType === POPUP_TYPES.add) {
						if (opts.username !== opts.email) {
							setErrorMessages(t('userAdmin.emailAddressNotMatched'));
							return;
						}
						else if (!opts.firstName || !opts.lastName) {
							setErrorMessages(t('userAdmin.firstNameOrLastNameMissing'));
							return;
						}

						else {
							const EmailSubject = t('welcomeMessage.welcomeMessageSubject', {
								companyName,
							});
							const specialCustomer = props.userInfo.customerName.toLowerCase();
							const welcomeMessageKey = (specialCustomer === 'meliox' || specialCustomer === 'bastec')
								? 'welcomeSpecial.welcomeMessageBody'
								: 'welcomeMessage.welcomeMessageBody';
							const welcomeEmailBody = t(welcomeMessageKey, {
								customerName: `${opts.firstName + ' ' + opts.lastName}`,
								companyName,
								username: opts.email,
								password: password,
								loginLink,
								contactEmail: supportEmail //'support@meliox.se' // You can replace this with a dynamic value if needed
							});

							addUser({
								variables: {
									parentId: props.userInfo.id,
									username: opts.username,
									password: password,
									email: opts.email,
									phonenumber: opts.phonenumber,
									firstName: opts.firstName,
									lastName: opts.lastName,
									//customerName: welcomeEmailMessage,
									customerName: props.userInfo.superPower === 'meliox' ? (opts.customerName || 'meliox') : (props.userInfo.customerName || 'meliox'),
									propertyAccess: selectedPropAlternative === PROP_SELECTION_TYPES.parents.id ? PROP_SELECTION_TYPES.parents.id : '',
									propertyIds: selectedPropAlternative === PROP_SELECTION_TYPES.selected.id ? selectedPropIds : [],
									authorizationAccess: selectedAuthAlternative === AUTHORIZATIONACCESS_IDS.parents ? AUTHORIZATIONACCESS_IDS.parents : '',
									authorizationOptions:
										selectedAuthAlternative === AUTHORIZATIONACCESS_IDS.minimum
											? [AUTHORIZATION_OPTIONS.confirm.id, AUTHORIZATION_OPTIONS.editAlarmThreshold.id]
											: selectedAuthAlternative === AUTHORIZATIONACCESS_IDS.selected
												? selectedAuthIds
												: [],
									emailSubject: EmailSubject,
									emailBody: welcomeEmailBody,

								},
							}, handleChange(),
							);
						}
					}

					else {
						selectedUsers.forEach(user => delUser({ variables: { id: user.id } }));
						setSelectedUsers([]);
						setEditPopupEnabled(!editPopupEnabled);
					}
					//setEditPopupEnabled(!editPopupEnabled);
				}}


			/>

			<Dialog open={propPopupEnabled} onClose={() => setPropPopupEnabled(false)} maxWidth={false}>
				<SelectionTable
					localization={{
						title: t('genericAdmin.selectPropsPrompt'),
						nRowsSelected: t('createdUserAdmin.numberSelectedProperties') + ': {0}',
					}} // TODO: Do this properly with {{count}}
					data={properties}
					dataId='locationid'
					preselectedIds={selectedPropIds}
					onSelectionChange={props => (tempSelectedPropIds = props.map(prop => prop.locationid))}
					columns={[
						{ title: t('generic.address'), field: 'street' },
						{
							title: t('generic.city'),
							field: 'city',
						},
						{ title: t('createdUserAdmin.cadastral'), field: 'cadastral' },
						{ title: t('createdUserAdmin.squareMetres'), field: 'area' },
					]}
					style={{ minWidth: '40rem', boxShadow: 'none', overflow: 'auto' }}
					tableProps={{ maxColumnLength: '28' }}
				/>

				<DialogActions>
					<Button
						style={{ colors: colors.primary }}
						onClick={() => setPropPopupEnabled(false)}>
						{t('generic.cancel')}
					</Button>
					<Button
						style={{ colors: colors.primary }}
						onClick={() => {
							if (tempSelectedPropIds) {
								setSelectedPropIds(tempSelectedPropIds);
								setSelectedPropAlternative(PROP_SELECTION_TYPES.selected.id);
								if (editPopupType === POPUP_TYPES.edit);
							}
							setPropPopupEnabled(false);
						}}
					>
						{t('generic.confirm')}
					</Button>
				</DialogActions>
			</Dialog>

			<Dialog open={authPopupEnabled} onClose={() => setAuthPopupEnabled(false)} maxWidth={false}>
				<SelectionTable
					localization={{
						title: t('createdUserAdmin.selectPriviliges'),
						nRowsSelected: t('createdUserAdmin.nSelectedPriviliges') + ': {0}',
					}}
					data={Object.values(AUTHORIZATION_OPTIONS)}
					dataId='id'
					preselectedIds={selectedAuthIds}
					onSelectionChange={auths => (tempSelectedAuthIds = auths.map(auth => auth.id))}
					columns={[
						{ title: t('generic.privilige_one'), field: 'name' },
						{ title: t('generic.description'), field: 'description' },
					]}
					style={{ minWidth: '40rem', boxShadow: 'none' }}
					tableProps={{ maxColumnLength: '80' }}
				/>

				<DialogActions>
					<Button style={{ colors: colors.primary }} onClick={() => setAuthPopupEnabled(false)}>
						{t('generic.cancel')}
					</Button>
					<Button
						style={{ colors: colors.primary }}
						onClick={() => {
							if (tempSelectedAuthIds) {
								setSelectedAuthIds(tempSelectedAuthIds);
								setSelectedAuthAlternative(AUTHORIZATIONACCESS_IDS.selected);
								if (editPopupType === POPUP_TYPES.edit);
							}
							setAuthPopupEnabled(false);
						}}
					>
						{t('generic.confirm')}
					</Button>
				</DialogActions>
			</Dialog>
			<Backdrop open={isLoading} style={{ zIndex: '100' }}>
				<div
					style={{
						padding: '0.9rem 1rem 1rem',
						fontSize: '112%',
						fontWeight: '300',
						textAlign: 'center',
						background: '#fff',
						borderRadius: '0.3rem',
						boxShadow: '0rem 0.1rem 0.8rem #000c',
					}}
				>
					{t('generic.loadingUsers')}
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				</div>
			</Backdrop>
		</div>
	);
}

export default connect(getStateVariables(STORE.userInfo))(CreatedUserAdministration);
