export function normalizeData(data) {
	const newData = [];
	data.forEach(sensor => {
		const yValues = sensor.data.map(s => s.y);
		const [min, max] = [Math.min(...yValues), Math.max(...yValues)];
		newData.push({
			...sensor,
			data: sensor.data.map(dp => ({
				...dp,
				y: max === min ? 0 : (dp.y - min) / (max - min),
			})),
		});
	});
	return newData;
}
