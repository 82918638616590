import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PAGES } from '../constants';
import { setPage } from '../redux/actionCreators';

function Issues(props) {
	useEffect(() => {
		props.setPage(PAGES.issues.id);
		// eslint-disable-next-line
	}, []);

	const { t } = useTranslation();

	return <div>{t('issues.underConstruction')}</div>;
}

const IssuesHoc = connect(null, { setPage })(Issues);
export { IssuesHoc as Issues };
