import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from '@material-ui/core/styles';

import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, IconButton, DialogContentText } from '@material-ui/core';
import * as colors from '../../../colors';
import EditIcon from '@material-ui/icons/Edit';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Close } from '@material-ui/icons';

function Alert(props) {
	return <MuiAlert elevation={6} variant='filled' {...props} />;
}

const useStyles = makeStyles(theme => ({
	box: {
		position: 'absolute',
		top: '22%',
		right: '6%',
		zIndex: 100,
	},
	root: {
		width: '100%',
		position: 'relative',
		zIndex: 100,
	},
	titlecss: {
		zIndex: 2,
		width: '100%',
		fontSize: '20px',
		fontWeight: 'bold',
		lineHeight: '29px',
		textTransform: 'none',
		borderBottom: '2px solid grey',
		borderRadius: '0px',
		height: '60px',
		paddingTop: '10px',
		color: colors.secondaryG,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		fontFamily:
			'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	},
}));

export default function ChangeTitle({ title, changeTitleCallback }) {
	const classes = useStyles();

	const [open, setOpen] = useState(false);
	const [openSnack, setOpenSnack] = useState(false);

	const { t } = useTranslation();

	const handleClose = () => {
		setOpen(false);
	};

	const handleCloseSnack = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpenSnack(false);
	};

	const handleWidgetTitleChange = inputTitle => {
		changeTitleCallback(inputTitle);
		setOpenSnack(true);
		setOpen(false);
	};

	return (
		<>
			<Button
				className={classes.titlecss}
				id='transition-modal-title'
				data-cy='modal-settings-line-graph-title'
				onClick={() => setOpen(true)}
			>
				{title}
				<EditIcon style={{ width: '40px' }} />
			</Button>
			<ChangeTitleDialog open={open} title={title} handleClose={handleClose} handleSave={handleWidgetTitleChange} />
			<div className={classes.root}>
				<Snackbar open={openSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
					<Alert onClose={handleCloseSnack} severity='success'>
						{t('widgetPopoutMenu.changeNameSuccess')}
					</Alert>
				</Snackbar>
			</div>
		</>
	);
}
export function ChangeTitleDialog({ open, title, handleClose, handleSave }) {
	const [inputTitle, setInputTitle] = useState(title);
	const { t } = useTranslation();
	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>{t('widgetPopoutMenu.changeTitle')}</DialogTitle>
			<div
				style={{
					position: 'absolute',
					top: 8,
					right: 8,
				}}
			>
				<IconButton aria-label='close' onClick={handleClose}>
					<Close />
				</IconButton>
			</div>
			<DialogContent>
				<DialogContentText>
					{t('widgetPopoutMenu.inputNewGraphTitleText')}
					<b>{` ${title}`}</b>
					{'.'}
				</DialogContentText>
				<TextField
					label={t('widgetPopoutMenu.currentTitle')}
					value={inputTitle}
					onChange={event => setInputTitle(event.target.value)}
					fullWidth
					autoFocus
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					{t('generic.cancel')}
				</Button>
				<Button onClick={() => handleSave(inputTitle)} color='primary' variant='contained'>
					{t('generic.save')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
