import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { RadioGroup, FormControlLabel, Radio, Input, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PAGES } from '../constants';
import { STORE, getStateVariables } from '../redux/selectors';
import { setPage, setProperty, addCallback, removeCallback } from '../redux/actionCreators';
import { getDecimals } from '../utility-functions';
import SliderWrapper from './SliderWrapper';
import SelectWrapper from './SelectWrapper';
const RADIO_COMPONENT = <Radio color='primary' style={{ padding: '7px' }} />;

function EnergyPerformance(props) {
    const [effect, setEffect] = useState(80);
    const [type, setType] = useState(0);
    const [lowerThreshold, setLowerThreshold] = useState(20.5);
    const [upperThreshold, setUpperThreshold] = useState(23.5);
    const [selectedProperties, setSelectedProperties] = useState([]);

    const { t } = useTranslation();

    const [selectedProperty, setSelectedProperty] = useState();

    useEffect(() => {
        props.setPage(PAGES.properties.id);

        props.addCallback('on-sidebar-property-click', 'remove-selected-property', () => setPropertySelection(undefined, false));
        return () => props.removeCallback('on-sidebar-property-click', 'remove-selected-property');
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        props.setPage(PAGES.automation.id);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const urlParts = props.location.pathname.split('/');
        if (urlParts[2] && !selectedProperty)
            setPropertySelection(
                props.properties.find(property => property.locationid === Number(urlParts[2])),
                false
            );

        if (props.properties.length) {
            let locationIdInUrl = props.history.location.pathname.split('/')[2];
            if (!isNaN(locationIdInUrl)) locationIdInUrl = Number(locationIdInUrl);

            const propertyInUrl = props.properties.find(property => property.locationid === locationIdInUrl);

            if (locationIdInUrl !== selectedProperty?.locationid)
                setPropertySelection(propertyInUrl, !propertyInUrl && locationIdInUrl ? true : false);
        }
        // eslint-disable-next-line
    }, [props.properties, props.location]);

    function setPropertySelection(property, changeUrl = true) {
        if (property) {
            setSelectedProperty(property);
            props.setProperty(property.locationid);
            if (changeUrl) props.history.push(`/${PAGES.properties.id}/${property.locationid}`);
        } else {
            setSelectedProperty(undefined);
            props.setProperty('');
            if (changeUrl) props.history.push(`/${PAGES.properties.id}`);
        }
    }

    useEffect(() => {
        setSelectedProperties(
            props.properties
                .filter(pro => pro.city === 'Karlskrona')
                .map(pro => ({ value: pro.locationid, label: `${pro.city}: ${pro.street}` }))
                .sort((a, b) => (a.label < b.label ? -1 : 1))
        );
        // eslint-disable-next-line
    }, [props.properties]);

    function randomize() {
        setEffect(50 + Math.floor(Math.random() * 44));
        setType(Math.floor(Math.random() * 3));
        setLowerThreshold(19 + getDecimals(Math.random() * 2, 1));
        setUpperThreshold(22 + getDecimals(Math.random() * 2, 1));
    }

        return (
           

                <div style={{ display: 'grid', gridTemplateColumns: '50% 30%' }}>
                    <div>
                        <h2 style={{ fontWeight: '500', fontSize: '125%' }}>{t('automation.effect')}</h2>
                        <SliderWrapper
                            sliderProps={{ value: effect, onChange: (e, val) => setEffect(val) }}
                            valuePostfix='%'
                            style={{ width: '16rem', marginLeft: '0.1rem' }}
                        />

                        <h2 style={{ fontWeight: '500', fontSize: '125%', margin: '1rem 0 0.2rem 0' }}>{t('automation.optimizationType')}</h2>
                        <RadioGroup value={type} onChange={e => setType(Number(e.target.value))} style={{ marginLeft: '0.2rem' }}>
                            <FormControlLabel value={0} control={RADIO_COMPONENT} label={t('automation.minimizeConsumption')} />
                            <FormControlLabel value={1} control={RADIO_COMPONENT} label={t('automation.minimizePeaks')} />
                            <FormControlLabel value={2} control={RADIO_COMPONENT} label={t('automation.optimizeWater')} />
                        </RadioGroup>

                        <h2 style={{ fontWeight: '500', fontSize: '125%', margin: '1.2rem 0 0.2rem 0' }}>
                            {t('automation.insideTempThresholds')}
                        </h2>
                        <div style={{ display: 'flex', fontSize: '112%', margin: '0.5rem 0 0 0.1rem', alignItems: 'center' }}>
                            <div style={{ width: '7rem' }}>{t('automation.lowerThresh')}</div>
                            <Input
                                color='primary'
                                value={lowerThreshold}
                                onChange={e => setLowerThreshold(e.target.value)}
                                endAdornment={<InputAdornment position='end'>C</InputAdornment>}
                                style={{ width: '3.5rem' }}
                            />
                        </div>
                        <div style={{ display: 'flex', fontSize: '112%', margin: '0.5rem 0 0 0.1rem', alignItems: 'center' }}>
                            <div style={{ width: '7rem' }}>{t('automation.upperThresh')}</div>
                            <Input
                                color='primary'
                                value={upperThreshold}
                                onChange={e => setUpperThreshold(e.target.value)}
                                endAdornment={<InputAdornment position='end'>C</InputAdornment>}
                                style={{ width: '3.5rem' }}
                            />
                        </div>
                    </div>

                    <div>
                        <h2 style={{ fontWeight: '500', fontSize: '125%', marginBottom: '0.4rem' }}>{t('automation.selectedProps')}</h2>
                        <SelectWrapper
                            selectProps={{
                                isMulti: true,
                                closeMenuOnSelect: false,
                                placeholder: t('genericAdmin.selectPropsPrompt'),
                                options: selectedProperties,
                                value: selectedProperties,
                                onChange: pros => {
                                    setSelectedProperties(pros || []);
                                    if (pros?.length && !selectedProperties.length) randomize();
                                    else if (!pros?.length) {
                                        setEffect(0);
                                        setType(-1);
                                        setLowerThreshold('');
                                        setUpperThreshold('');
                                    }
                                },
                            }}
                        />
                    </div>
                </div>

        );
    } 


export default connect(getStateVariables(STORE.sensors, STORE.properties, STORE.currentProperty, STORE.userInfo), {
    setPage,
    setProperty,
    addCallback,
    removeCallback,
})(EnergyPerformance);
