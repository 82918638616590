import React, { useEffect, useState } from 'react';
import { Button, ThemeProvider, Dialog, Tooltip, Badge } from '@material-ui/core';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import { makeStyles, withStyles } from '@material-ui/core/styles';

import { t } from 'i18next';

import { createMuiTheme } from '@material-ui/core/styles';
import { PropertySelectorTable } from './PropertySelectorTable';

const StyledBadge = withStyles(() => ({
	badge: {
		right: 30,
		top: 10,
	},
}))(Badge);

const styles = makeStyles(() => ({
	btn: {
		backgroundColor: 'inherit',
		fontSize: '12px',
		color: 'white',
		fontFamily:
			'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	},
	largeTooltip: {
		textDecoration: 'uppercase',
		fontSize: '12px',
		padding: '10px',
		marginBottom: '8px',
	},
}));

function PropertySelection({ onDataSelectionCallback, selectedProperties, widgets }) {
	const [openPropertyDialog, setOpenPropertyDialog] = useState(false);

	const classes = styles();

	const theme = createMuiTheme({
		typography: {
			button: {
				textTransform: 'none',
			},
		},
	});

	useEffect(() => {
		if (!selectedProperties.length) {
			setOpenPropertyDialog(true);
		}
	}, []);

	return (
		<>
			<Dialog open={openPropertyDialog} maxWidth={false} PaperComponent={props => <>{props.children}</>}>
				<PropertySelectorTable
					selectedProperties={selectedProperties}
					onDataSelectionCallback={onDataSelectionCallback}
					closeCallback={() => setOpenPropertyDialog(false)}
					widgets={widgets}
				/>
			</Dialog>
			<div style={{ display: 'flex', alignItems: 'center', fontSize: '160%', fontWeight: '400' }}>
				<ThemeProvider theme={theme}>
					<Tooltip title={t('constants.selectProperties')} placement='top' arrow classes={{ tooltip: classes.largeTooltip }}>
						<Button
							onClick={() => setOpenPropertyDialog(true)}
							style={{ display: 'flex', alignItems: 'center', fontWeight: '400', fontSize: '12px' }}
							className={classes.btn}
						>
							<StyledBadge
								badgeContent={selectedProperties.length}
								color='primary'
								style={{ position: 'absolute', left: '50px', top: '2px' }}
							></StyledBadge>
							<LocationCityIcon style={{ marginRight: '7px', marginTop: '4px', position: 'relative' }} />
							<p
								style={{
									marginLeft: '-5px',
									fontWeight: '500',
									lineHeight: '25px',
									fontSize: '14px',
									textTransform: 'uppercase',
									fontFamily:
										'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
								}}
							>
								{t('constants.properties')}
							</p>
						</Button>
					</Tooltip>
				</ThemeProvider>
			</div>
		</>
	);
}

export default PropertySelection;
