import React from 'react';

import { HorizontalBarChart } from '../components/NivoCharts';

/**
 * Small barchart with a header, subheader, and a chart
 * @param {String} header : Drawn above the chart
 * @param {String} subheader : Drawn below the header
 * @param {Array} colors : For the chart
 * @param {Array} data : For the chart
 * @param {String} valuePrefix : Displayed in front of the value in the tooltip
 * @param {String} unit : Unit in which the data is represented
 * @param {Object} size : Set the proportions of the graph, {width: String, height: String}
 * @param {Object} style : Passed down to the container of the text and chart
 * @param {Object} chartProps : Additional props that will be passed directly to the chart
 */
function SmallBarchart(props) {
	return (
		<div style={{ width: '100%', ...(props.style || {}) }}>
			<div style={{ margin: '0 0 0.5rem 0.7rem' }}>
				<h3 style={{ margin: '0', fontWeight: '400', fontSize: '130%' }}>{props.header}</h3>
				<h4 style={{ margin: '0.2rem 0 0 0', fontWeight: '400' }}>{props.subheader}</h4>
			</div>

			<div style={{ width: props.size?.width || '16rem', height: props.size?.height || '8rem', margin: '0.8rem 0 0 0' }}>
				<HorizontalBarChart
					colors={props.colors}
					data={props.data}
					valuePrefix={props.valuePrefix}
					unit={props.unit}
					chartProps={props.chartProps}
				/>
			</div>
		</div>
	);
}

export default SmallBarchart;
