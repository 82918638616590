import React from 'react';
import { CHART_AUTO_UPDATE_INTERVALS } from '../../../constants';
import { AppBar, Button, Toolbar, Switch, Tooltip, Badge } from '@material-ui/core';
import { Create, Add, Fullscreen, Description } from '@material-ui/icons';
import { Trans, useTranslation } from 'react-i18next';
import Divider from '@material-ui/core/Divider';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropertySelection from './PropertySelection';
import WidgetMenu from '../Widget/WidgetMenu';

import Grid from '@material-ui/core/Grid';

import TimeSelection from './TimeSelection';
import AutoUpdateSelection from './AutoUpdateSelection';
import { Alert } from '@material-ui/lab';

const StyledBadge = withStyles(theme => ({
	badge: {
		right: 15,
		top: 10,
	},
}))(Badge);

const tooltipStyles = makeStyles(theme => ({
	largeTooltip: {
		fontSize: '12px',
		padding: '10px',
		textDecoration: 'uppercase',
	},
	largeTooltipCreate: {
		fontSize: '12px',
		padding: '10px',
		textDecoration: 'uppercase',
		marginBottom: '19px',
		marginLeft: '5px',
	},
	largeTooltipEdit: {
		fontSize: '12px',
		padding: '10px',
		textDecoration: 'uppercase',
		marginBottom: '10px',
	},
	largeTooltipNorm: {
		fontSize: '12px',
		padding: '10px',
		textDecoration: 'uppercase',
		marginBottom: '15px',
	},
	largeTooltipRep: {
		fontSize: '12px',
		padding: '10px',
		textDecoration: 'uppercase',
		marginBottom: '16px',
	},
}));

const rootStyles = makeStyles(theme => ({
	grid: {
		width: '100%',
		height: 'fit-content',
		border: `1px solid ${theme.palette.divider}`,
		maxWidth: '100%',

		color: 'white',
		'& svg': {
			margin: theme.spacing(1.5),
		},
		'& hr': {
			margin: `12px 2px 12px ${theme.spacing(2)}px`,
			backgroundColor: 'white',
		},
	},
	rightDivider: {
		orientation: 'vertical',
		height: '62%',
		left: '90.1%',
		width: '1.2px',
		bottom: '0.5px',

		color: 'white',

		'& svg': {
			margin: theme.spacing(1.5),
		},
		'& hr': {
			margin: `12px 2px 12px ${theme.spacing(2)}px`,
			backgroundColor: 'white',
			left: '40%',
		},
	},
	leftDivider: {
		orientation: 'vertical',
		height: '62%',
		left: '85.7%',
		width: '1.2px',
		bottom: '0.5px',

		color: 'white',
	},
}));

const ChartToolbar = ({
	addWidget,
	widgets,
	timeSelectCallback,
	autoUpdateInterval,
	autoUpdateCallback,
	toggleEditMode,
	editMode,
	currentDatetimeRange,
	toggleFullscreen,
	toggleIncludeInReportCallback,
	includeInReport,
	toggleNormalizeCallback,
	onDataSelectionCallback,
	selectedProperties,
	normalize,
	changesMade,
}) => {
	const classesTooltip = tooltipStyles();
	const classesBtn = rootStyles();

	const { t } = useTranslation();

	const toggleIncludeReport = () => {
		toggleIncludeInReportCallback();
	};

	const toggleNormalize = () => {
		toggleNormalizeCallback();
	};

	const toggleViewMode = () => {
		toggleEditMode();
	};

	return (
		<>
			<AppBar
				position='static'
				style={{
					marginBottom: '10px',
					borderRadius: '5px',
					width: '115%',
					marginLeft: '0%',
					border: '1px gray solid',
					display: 'block',
				}}
			>
				<Toolbar style={{ width: '100%', height: 'fit-content', paddingLeft: '0px', paddingRight: '0px' }}>
					<Grid container alignItems='center' className={classesBtn.grid}>
						<WidgetMenu addWidget={addWidget} editMode={editMode} selectedProperties={selectedProperties} />

						<Divider
							orientation='vertical'
							flexItem
							style={{
								display: editMode ? 'block' : 'none',
							}}
						/>

						<PropertySelection
							onDataSelectionCallback={onDataSelectionCallback}
							selectedProperties={selectedProperties}
							widgets={widgets}
						/>

						<Divider orientation='vertical' flexItem />

						<TimeSelection
							data-cy='timeselect-button'
							style={{ with: '210px' }}
							calendar={true}
							timeRange={currentDatetimeRange}
							callback={timeSelectCallback}
						/>

						<Divider orientation='vertical' flexItem />

						<AutoUpdateSelection
							data-cy='auto-update-section'
							timeOptions={CHART_AUTO_UPDATE_INTERVALS}
							callback={autoUpdateCallback}
							updateInterval={autoUpdateInterval}
						/>

						<Divider orientation='vertical' flexItem />

						<Tooltip
							title={t('constants.normalizeData')}
							placement='top'
							arrow
							classes={{ tooltip: classesTooltip.largeTooltipNorm }}
						>
							<Button
								data-cy='normalize-button'
								color='inherit'
								onClick={toggleNormalize}
								style={{ border: '1px solid transparent', marginRight: '-18px', paddingLeft: '15px' }}
							>
								<Trans i18nKey='toolbar.normalize' />
								<Switch color='default' checked={normalize} />
							</Button>
						</Tooltip>

						<Divider orientation='vertical' flexItem />

						<Tooltip
							title={t('constants.includeInReport')}
							placement='top'
							arrow
							classes={{ tooltip: classesTooltip.largeTooltipRep }}
						>
							<span>
								<Button
									data-cy='includein-report-button'
									color='inherit'
									onClick={toggleIncludeReport}
									style={{ padding: '0px', marginLeft: '5px', border: '1px solid transparent', marginRight: '-10px' }}
								>
									<Description style={{ marginRight: '-5px', marginLeft: '10px' }} />
									<Switch color='default' checked={includeInReport} />
								</Button>
							</span>
						</Tooltip>

						{/*<Divider orientation='vertical' flexItem />*/}

						<Divider orientation='vertical' flexItem absolute className={classesBtn.leftDivider} />

						<Tooltip title={t('constants.fullscreen')} placement='top' arrow classes={{ tooltip: classesTooltip.largeTooltip }}>
							<Button
								data-cy='toggle-fullscreen-button'
								startIcon={<Fullscreen style={{ marginRight: '-4px', marginTop: '12px', fontSize: '30px' }} />}
								color='inherit'
								onClick={toggleFullscreen}
								style={{
									border: '1px solid transparent',
									marginRight: '-5px',
									marginLeft: '86.4%',
									position: 'absolute',
								}}
							/>
						</Tooltip>

						<Divider orientation='vertical' flexItem absolute className={classesBtn.rightDivider} />

						<Tooltip
							title={t('constants.editMode')}
							placement='top'
							arrow
							classes={{ tooltip: classesTooltip.largeTooltipEdit }}
						>
							<Button
								data-cy='edit-button'
								color='inherit'
								onClick={toggleViewMode}
								style={{
									left: '91.5%',
									border: '1px solid transparent',
									marginRight: '-10px',
									paddingRight: '0px',
									position: 'absolute',
								}}
							>
								<StyledBadge badgeContent={'!'} invisible={!changesMade.hasChanged} color='secondary'>
									<Create style={{ marginRight: '-5px', marginLeft: '5px' }} />
								</StyledBadge>
								<Switch data-cy='edit-switch' color={changesMade.hasChanged ? 'secondary' : 'default'} checked={editMode} />
							</Button>
						</Tooltip>
					</Grid>
				</Toolbar>
			</AppBar>
			<div style={{ width: '115%' }}>
				{changesMade.hasChanged && changesMade.triedToExit ? (
					<Alert icon={<Create fontSize='inherit' />} severity='error'>
						{t('chartsToolbar.triedToExitAlert')}
					</Alert>
				) : changesMade.hasChanged ? (
					<Alert icon={<Create fontSize='inherit' />} severity='info'>
						{t('chartsToolbar.changesMadeAlert')}
					</Alert>
				) : undefined}
			</div>
		</>
	);
};

export default ChartToolbar;
