import React, { useState } from 'react';
import {
	Tab,
	Button,
	Box,
	Tabs,
	Input,
	IconButton,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';

import { Edit as EditIcon } from '@material-ui/icons';
import { NameTabBox } from './NameTabBox';
import { useTranslation } from 'react-i18next';
import { disabledFg } from '../../../colors';

function TabHandler({ selectedTab, tabs, createTabCallback, deleteTabCallback, changeTabCallback, updateTabCallback }) {
	const [newTab, setNewTab] = useState(false);

	// const [addTab] = useMutation(ADD_TAB, {
	// 	onCompleted: ({ addTab }) => {
	// 		setTabData([
	// 			...tabData,
	// 			{
	// 				id: addTab.id,
	// 				chartId: addTab.chartid,
	// 				tabName: addTab.tabname,
	// 				tabIndex: addTab.tabindex,
	// 				updateInterval: updateInterval,
	// 				backgroundcolor: addTab.backgroundcolor,
	// 				offset: timeSelection.offset || null,
	// 				startDate: timeSelection.startDate || null,
	// 				endDate: timeSelection.endDate || null,
	// 				properties: [],
	// 			},
	// 		]);
	// 		const newIndex = addTab.tabindex;
	// 		setNewTab(false);
	// 		handleTabChange(null, newIndex);
	// 	},
	// });

	// const deleteTab = index => {
	// 	if (tabData.length > 1) {
	// 		removeTab({
	// 			variables: {
	// 				id: tabData[index].id,
	// 			},
	// 		});
	// 		let newTabData = [...tabData];
	// 		newTabData.splice(index, 1);
	// 		newTabData = newTabData.map((tab, idx) => ({ ...tab, tabIndex: idx }));
	// 		setTabData(newTabData, newData => {
	// 			newData.forEach(data => updateTabWithIndexWrapper(newData, data.tabIndex));
	// 			if (selectedTab === index) {
	// 				setCurrentTabDataIndex(currentTabDataIndex - 1);
	// 				handleChangeTabCallback(null, currentTabDataIndex - 1);
	// 			}
	// 		});
	// 	}
	// };

	const createTab = tabName => {
		createTabCallback(tabName);
		setNewTab(false);
	};

	const handleTabChange = (event, newValue) => {
		if (selectedTab === newValue) return;
		changeTabCallback(newValue);
	};

	return (
		<>
			<NameTabBox isOpen={newTab} onClose={() => setNewTab(false)} onConfirm={tabName => createTab(tabName)}></NameTabBox>
			<Box style={{ borderBottom: '1px solid gray', width: '100%' }}>
				<Tabs style={{ width: '100%' }} value={selectedTab} onChange={handleTabChange} variant='scrollable' scrollButtons='auto'>
					{tabs.map((tab, index) => (
						<Tab
							style={{
								textTransform: 'none',
								backgroundColor: tab.tabIndex === selectedTab ? 'white' : disabledFg,
								borderRadius: '5px 5px 0px 0px',
								borderLeft: tab.tabIndex === selectedTab ? '1px solid lightgrey' : '1px solid #636262',
								borderTop: tab.tabIndex === selectedTab ? '1px solid lightgrey' : '1px solid #636262',
								borderRight: tab.tabIndex === selectedTab ? '1px solid lightgrey' : '1px solid #636262',
								marginRight: 7,
								minWidth: 'fit-content',
								minHeight: '45px',
								marginTop: 7,
							}}
							key={tab.id}
							id={`tab-${tab.id}`}
							label={
								<div>
									{tab.tabIndex === selectedTab ? (
										<CurrentTab
											tabs={tabs}
											tabName={tab.tabName}
											tabIndex={index}
											updateTabCallback={updateTabCallback}
											deleteTabCallback={deleteTabCallback}
											setNewTabIndex={value => changeTabCallback(value)}
										/>
									) : (
										tab.tabName
									)}
								</div>
							}
						/>
					))}
					<AddTabButton
						// component='span'
						onClick={() => setNewTab(true)}
						aria-label='add-tab-button'
					>
						<AddIcon fontSize='small' />
					</AddTabButton>
				</Tabs>
			</Box>
		</>
	);
}

function CurrentTab({ tabs, tabName, tabIndex, updateTabCallback, deleteTabCallback, setNewTabIndex }) {
	const [editMode, setEditMode] = useState(false);
	const [newTabName, setNewTabName] = useState(tabName);
	const [deleteOpen, setDeleteOpen] = useState(false);

	const handleDeleteTab = () => {
		deleteTabCallback(tabIndex);
		setNewTabIndex(0);
		setDeleteOpen(false);
	};

	const toggleEditMode = () => {
		if (editMode) {
			updateTabCallback(tabIndex, { tabName: newTabName });
		}
		setEditMode(!editMode);
	};

	const handleKeyDown = (event, tabIndex) => {
		if (event.key === 'Enter') {
			if (editMode) {
				toggleEditMode(tabIndex);
			}
		}
	};
	return (
		<>
			<DeleteTabConfirmation isOpen={deleteOpen} tabName={tabName} onClose={() => setDeleteOpen(false)} onConfirm={handleDeleteTab} />
			{editMode ? (
				<Input
					size='small'
					margin='none'
					style={{
						paddingRight: 0,
						fontWeight: 500,
						marginLeft: '-23px',
					}}
					value={newTabName}
					fullWidth={true}
					onChange={event => {
						setNewTabName(event.target.value);
					}}
					onKeyDown={handleKeyDown}
					variant='filled'
				/>
			) : (
				<div style={{ marginRight: 20 }}>{tabName}</div>
			)}
			{tabs.length > 1 ? (
				<Button
					component='span'
					onClick={() => setDeleteOpen(true)}
					id='deleteTab'
					style={{ position: 'absolute', top: 5, right: 5, padding: 0, minWidth: 'unset' }}
				>
					<CloseIcon fontSize='small' style={{ fontSize: 16, color: '#B42018' }} />
				</Button>
			) : null}
			<Button
				component='span'
				onClick={() => toggleEditMode()}
				id='editTabName'
				style={{ position: 'absolute', bottom: 5, right: 5, padding: 0, minWidth: 'unset' }}
			>
				<EditIcon fontSize='small' style={{ fontSize: 16, color: disabledFg }} />
			</Button>
		</>
	);
}

function DeleteTabConfirmation({ isOpen, tabName, onClose, onConfirm }) {
	const { t } = useTranslation();

	return (
		<Dialog open={isOpen} onClose={onClose}>
			<DialogTitle>{t('tabDeletion.deleteConfirmationTitle')}</DialogTitle>
			<div
				style={{
					position: 'absolute',
					top: 8,
					right: 8,
				}}
			>
				<IconButton aria-label='close' onClick={onClose}>
					<CloseIcon />
				</IconButton>
			</div>
			<DialogContent>
				<DialogContentText>
					{t('tabDeletion.deleteConfirmationText')}
					<b>{` ${tabName}`}</b>
					{'?'}
				</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color='primary'>
					{t('tabDeletion.deleteConfirmationCancel')}
				</Button>
				<Button onClick={onConfirm} color='secondary' variant='contained'>
					{t('tabDeletion.deleteConfirmationDelete')}
				</Button>
			</DialogActions>
		</Dialog>
	);
}

const AddTabButton = ({ onClick, children }) => {
	return (
		<Button
			style={{
				backgroundColor: disabledFg,
				color: 'white',
				borderRadius: '50%',
				width: '20px',
				height: '20px',
				minWidth: 'unset',
				marginTop: '20px',
				marginLeft: '10px',
			}}
			aria-label='add-tab-button'
			onClick={onClick}
			children={children}
		/>
	);
};

export { DeleteTabConfirmation };
export default TabHandler;
