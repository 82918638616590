import React, { useState } from 'react';
import { connect } from 'react-redux';
import {
	Stepper,
	Step,
	StepButton,
	Button,
	Radio,
	RadioGroup,
	FormControlLabel,
	TextField,
	IconButton,
	LinearProgress,
} from '@material-ui/core';
import { Add as AddIcon, Close as CloseIcon } from '@material-ui/icons';
import { gql, useQuery, useMutation } from '@apollo/client';
import { Map, Marker, TileLayer } from 'react-leaflet';
import Leaflet from 'leaflet';
import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';

import { PROP_SELECTION_TYPES } from '../constants';
import * as colors from '../colors';
import { STORE, getStateVariables } from '../redux/selectors';
import { updateProperties, updateExternalControlPanels } from '../redux/actionCreators';
import { createDeepCopy } from '../utility-functions';
import Select from '../components/SelectWrapper';
import SelectionTable from './SelectionTable';
import EditPopup from './EditPopup';
import TooltipWrapper from './TooltipWrapper';
import DucSelection from './DucSelection';
import DucUploadButton from './DucUploadButton';
import ImportedRegisterList from './ImportedRegisterList';
import i18n from '../i18n';

const STEPS = {
	property: { id: 'property', label: i18n.t('generic.property') },
	duc: { id: 'duc', label: i18n.t('generic.bms') },
	import: { id: 'import', label: i18n.t('registrationWizard.import') },
	sensors: { id: 'sensors', label: i18n.t('constants.sensors') },
	sensorGroups: { id: 'sensorGroups', label: i18n.t('registrationWizard.sensorGroups') },
	summary: { id: 'summary', label: i18n.t('constants.overview') },
};

const RADIO_COMPONENT = <Radio color='primary' style={{ padding: '7px' }} />;
const INPUT_TYPE = Object.freeze({ Int: 1, Float: 2 });

//ADDs the new property;
const ADD_PROPERTY = gql`
	mutation ($street: String!, $city: String!, $cadastral: String, $area: Int, $longitude: Float, $latitude: Float) {
		addSensorLocation(street: $street, city: $city, cadastral: $cadastral, area: $area, longitude: $longitude, latitude: $latitude) {
			locationid
			city
			street
			cadastral
			area
			longitude
			latitude
		}
	}
`;

//ADDs then new DUC
const ADD_EXTERNALCONTROLPANELS = gql`
	mutation ($locationid: ID, $url: String, $ip: String, $port: Int, $ducid: Int, $label: String) {
		addExternalControlPanel(locationid: $locationid, url: $url, ip: $ip, port: $port, ducid: $ducid, label: $label) {
			externalcontrolpanelid
			locationid
			url
			ip
			port
			ducid
			label
		}
	}
`;

//Adds the RegisterList
const ADD_IMPORTEDREGISTERS = gql`
	mutation ($externalcontrolpanelid: ID!, $registers: [ImportedRegisterReg!]!) {
		addImportedRegisters(externalcontrolpanelid: $externalcontrolpanelid, registers: $registers) {
			address
		}
	}
`;
const ADD_SENSOR = gql`
	mutation ($name: String, $locationid: Int, $sensorref: String, $externalcontrolpanelid: Int) {
		addSensor(name: $name, locationid: $locationid, sensorref: $sensorref, externalcontrolpanelid: $externalcontrolpanelid) {
			sensorref
			name
			locationid
			externalcontrolpanelid
		}
	}
`;

const ADD_SENSORGROUP = gql`
	mutation ($name: String!, $locationid: Int!, $externalcontrolpanelid: Int) {
		addSensorGroup(name: $name, locationid: $locationid, externalcontrolpanelid: $externalcontrolpanelid) {
			sensorgroupid
			name
			locationid
			externalcontrolpanelid
		}
	}
`;

const GET_SENSORS = gql`
	query ($filter: SensorFilter) {
		getSensors(filter: $filter) {
			sensorid
			sensorref
		}
	}
`;

const SET_SENSORS = gql`
	mutation ($sensorids: [ID]!, $locationid: Int, $sensorgroupid: Int) {
		setSensors(sensorids: $sensorids, locationid: $locationid, sensorgroupid: $sensorgroupid) {
			sensorid
			locationid
		}
	}
`;

// Checks if the input is of the correct type and returns true (error) if it is not
function checkInputError(type, input) {
	if (type === INPUT_TYPE.Int) {
		if (isNaN(input) || String(input || '').includes('.')) return true;
	} else if (type === INPUT_TYPE.Float) {
		if (isNaN(input)) return true;
	}
	return false;
}

function RegistrationWizard(props) {
	const [curStepI, setCurStepI] = useState(0);
	const [completedStepIds, setCompletedStepIds] = useState([]);

	let STEPSArray = Object.values(STEPS);
	if (props.userInfo?.propertyAccess !== PROP_SELECTION_TYPES.all.id)
		STEPSArray = STEPSArray.filter(t => !(t.id == 'sensors' || t.id == 'sensorGroups'));

	const { t } = useTranslation();

	const currentStep = STEPSArray[curStepI];
	const onLastStep = curStepI === STEPSArray.length - 1;

	const [propRadioSel, setPropRadioSel] = useState(0);
	const [selectedCurPropertyId, setSelectedCurPropertyId] = useState();
	const [selectedNewProperty, setSelectedNewProperty] = useState({});
	const [addProperty] = useMutation(ADD_PROPERTY);
	const [addSensor] = useMutation(ADD_SENSOR, {
		onError: () => null,
	});

	const [ducRadioSel, setDucRadioSel] = useState(0);
	const [selectedECP, setSelectedECP] = useState();
	const [selectedNewECP, setSelectedNewECP] = useState({});
	const filteredEcps = props.externalControlPanels;
	const newDucData = [
		{
			id: 'label',
			label: t('generic.label'),
		},
		{
			id: 'locationid',
			label: t('generic.property'),
			preselected: true,
		},
		{
			id: 'ip',
			label: t('registrationWizard.IP'),
		},
		{
			id: 'port',
			label: t('registrationWizard.port'),
		},
		{
			id: 'ducid',
			label: t('registrationWizard.ducID'),
		},
		{
			id: 'url',
			label: t('registrationWizard.url'),
		},
	];
	const [addEcp] = useMutation(ADD_EXTERNALCONTROLPANELS);

	const [selectedReadRegs, setSelectedReadRegs] = useState([]);
	const [selectedWriteRegs, setSelectedWriteRegs] = useState([]);
	const [addImportedRegisters] = useMutation(ADD_IMPORTEDREGISTERS, {
		onError: () => console.error('Failed to add registers. Likely because some or all of them already exist in the database.'),
	});

	const [fileName, setFileName] = useState();
	const [isInvalidFile, setIsInvalidFile] = useState();
	const [formattedSheet, setFormattedSheet] = useState();

	const [unregSensors, setUnregSensors] = useState([]);
	const [selectedSens, setSelectedSens] = useState([]);
	const [setSensors] = useMutation(SET_SENSORS);

	const [showAddGroupPopup, setShowAddGroupPopup] = useState(false);
	const [newGroups, setNewGroups] = useState([]);
	const [selectedGrpSens, setSelectedGrpSens] = useState([]);
	const [addSensorGroup] = useMutation(ADD_SENSORGROUP);

	useQuery(GET_SENSORS, {
		variables: { filter: { locationids: [null] } },
		skip: props.userInfo?.propertyAccess !== PROP_SELECTION_TYPES.all.id || unregSensors.length,
		onCompleted: ({ getSensors }) => setUnregSensors(createDeepCopy(getSensors)),
	});

	const cities = []; // [{value: Number, label: String}, ...]
	for (const prop of props.properties)
		if (!cities.some(city => city.value === prop.city)) cities.push({ value: prop.city, label: prop.city });
	cities.sort((a, b) => (a.label < b.label ? -1 : 1));

	function ExtractCityStreet() {
		let property = { city: t('registrationWizard.noCitySelected'), street: t('registrationWizard.noAddressSelected') };
		if (propRadioSel === 0 && selectedCurPropertyId) {
			property = props.properties.find(pro => pro.locationid === selectedCurPropertyId);
		} else {
			property.city = selectedNewProperty?.city ? selectedNewProperty.city : t('registrationWizard.noCitySelected');
			property.street = selectedNewProperty?.street ? selectedNewProperty.street : t('registrationWizard.noAddressSelected');
		}
		return `${property?.city}: ${property?.street}`;
	}

	function UpdateMark(e) {
		setSelectedNewProperty({
			...selectedNewProperty,
			['longitude']: e.latlng.lng,
			['latitude']: e.latlng.lat,
		});
	}

	function ValidateStep() {
		let disableButton = false;
		switch (curStepI) {
			case 0:
				if (
					(propRadioSel === 0 && !selectedCurPropertyId) ||
					(propRadioSel === 1 && !(selectedNewProperty.city && selectedNewProperty.street))
				)
					disableButton = true;
				break;
			case 1:
				if ((ducRadioSel === 0 && !selectedECP) || (ducRadioSel === 1 && !selectedNewECP.label)) disableButton = true;
				break;
			default:
				break;
		}
		return disableButton;
	}
	function ValidateImport() {
		let ipportducid = [ducRadioSel === 0 ? filteredEcps.find(ecp => ecp.externalcontrolpanelid === selectedECP) || {} : selectedNewECP]
			.map(ecp => [ecp.ip, ecp.port, ecp.ducid])[0]
			.some(item => !item);
		return ipportducid;
	}

	async function SaveChanges() {
		let locationId = selectedCurPropertyId;
		let externalcontrolpanelId = selectedECP;
		if (propRadioSel === 1) {
			const { data } = await addProperty({
				variables: {
					street: selectedNewProperty?.street,
					city: selectedNewProperty?.city,
					cadastral: selectedNewProperty?.cadastral,
					area: selectedNewProperty?.area && Number(selectedNewProperty?.area),
					longitude: selectedNewProperty?.longitude && Number(selectedNewProperty?.longitude),
					latitude: selectedNewProperty?.latitude && Number(selectedNewProperty?.latitude),
				},
			});
			props.updateProperties([data.addSensorLocation]);
			setSelectedCurPropertyId(data.addSensorLocation.locationid);
			locationId = data.addSensorLocation.locationid;
		}
		if (ducRadioSel === 1 && locationId) {
			const { data } = await addEcp({
				variables: {
					locationid: locationId,
					url: selectedNewECP?.url,
					ip: selectedNewECP?.ip,
					port: selectedNewECP?.port && Number(selectedNewECP?.port),
					ducid: selectedNewECP?.ducid && Number(selectedNewECP?.ducid),
					label: selectedNewECP?.label,
				},
			});
			props.updateExternalControlPanels([data.addExternalControlPanel]);
			setSelectedECP(data.addExternalControlPanel.externalcontrolpanelid);
			externalcontrolpanelId = data.addExternalControlPanel.externalcontrolpanelid;
			// Add sensor group for the new ECP
			await addSensorGroup({
				variables: {
					name: selectedNewECP.label,
					locationid: Number(locationId),
					externalcontrolpanelid: Number(data.addExternalControlPanel.externalcontrolpanelid),
				},
			});
		}
		if (selectedReadRegs.length > 0 || selectedWriteRegs.length > 0) {
			await addImportedRegisters({
				variables: {
					externalcontrolpanelid: externalcontrolpanelId,
					registers: formattedSheet.map(she => ({
						objectname: she.objectname,
						groupname: she.groupname,
						parametername: she.parametername,
						address: she.address,
						functions: she.functions,
						canread: she.canread,
						canwrite: she.canwrite,
						read: selectedReadRegs.includes(she.address),
						write: selectedWriteRegs.includes(she.address),
						automation: false,
					})),
				},
			}).then(result => {
				if (result.data) {
					for (const she of formattedSheet) {
						if (selectedReadRegs.includes(she.address)) {
							const sensorRef = `ducid | ${externalcontrolpanelId} | ${she.address}`;
							try {
								addSensor({
									variables: {
										name: she.groupname,
										locationid: locationId,
										sensorref: sensorRef,
										externalcontrolpanelid: Number(externalcontrolpanelId),
									},
								});
							} catch (error) {
								console.error('Error adding sensor:', error);
							}
						}
					}
				}
			}).catch(() => {
				// Do nothing
			});

		}

		if (selectedSens.length > 0) {
			await setSensors({
				variables: {
					sensorids: selectedSens.map(t => t.sensorid),
					locationid: locationId,
				},
			});
		}

		if (newGroups.length > 0 && selectedSens.length > 0) {
			for (const newGroup of newGroups) {
				const { data } = await addSensorGroup({
					variables: {
						name: newGroup.name,
						locationid: locationId,
						externalcontrolpanelid: Number(externalcontrolpanelId),
					},
				});
				await setSensors({
					variables: {
						sensorids: newGroup.sensors.map(t => t.sensorid),
						locationid: locationId,
						sensorgroupid: Number(data.addSensorGroup.sensorgroupid),
					},
				});
			}
		}
		if (locationId) props.callbacks['on-update-importedregisters'].forEach(cb => cb.callback(locationId));

		props.closeWizard();
		Swal.fire(t('userAdmin.success'), t('registrationWizard.success'), 'success');
	}

	function renderProperty() {
		return (
			<>
				<RadioGroup
					value={propRadioSel}
					onChange={e => {
						setPropRadioSel(Number(e.target.value));
						setDucRadioSel(Number(e.target.value));
					}}
					style={{ marginLeft: '0.2rem' }}
				>
					<FormControlLabel value={0} control={RADIO_COMPONENT} label={t('registrationWizard.existingProperty')} />
					<div
						style={{
							width: '20rem',
							margin: '.3rem 0 1.5rem 1.7rem',
							...(propRadioSel !== 0 ? { opacity: '.4', pointerEvents: 'none' } : {}),
						}}
					>
						<Select
							selectProps={{
								placeholder: t('registrationWizard.selectProperty'),
								options: props.properties.map(pro => ({
									value: pro.locationid,
									label: `${pro.city}: ${pro.street}`,
								})),
								onChange: sel => setSelectedCurPropertyId(sel?.value || undefined),
							}}
						/>
					</div>

					<FormControlLabel value={1} control={RADIO_COMPONENT} label={t('registrationWizard.newProperty')} />
					<div
						style={{
							display: 'flex',
							...(propRadioSel !== 1 ? { opacity: '.4', pointerEvents: 'none' } : {}),
						}}
					>
						<div
							style={{
								width: '20rem',
								margin: '.3rem 0 0 1.7rem',
							}}
						>
							{[
								{
									id: 'street',
									label: t('generic.address'),
								},
								{
									id: 'city',
									label: t('generic.city'),
									options: cities,
									creatable: true,
								},
								{
									id: 'cadastral',
									label: t('registrationWizard.cadastral'),
								},
								{
									id: 'area',
									label: t('registrationWizard.squareMetres'),
									type: INPUT_TYPE.Int,
								},
								{
									id: 'longitude',
									label: t('registrationWizard.longitude'),
									type: INPUT_TYPE.Float,
								},
								{
									id: 'latitude',
									label: t('registrationWizard.latitude'),
									type: INPUT_TYPE.Float,
								},
							].map(
								fie =>
									!fie.disabled && (
										<div key={fie.id} style={{ margin: '0 0 0.5rem 0' }}>
											<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem' }}>{fie.label}</div>
											{fie.options ? (
												<Select
													isCreatable={fie.creatable}
													selectProps={{
														placeholder: '...',
														options: fie.options,
														onChange: selection =>
															setSelectedNewProperty({
																...selectedNewProperty,
																[fie.id]: selection ? selection.value : undefined,
															}),
														maxMenuHeight: 204,
													}}
												/>
											) : (
												<TextField
													type={fie.elementType || 'text'}
													margin='dense'
													variant='outlined'
													placeholder='...'
													error={
														fie.type && selectedNewProperty[fie.id]
															? checkInputError(fie.type, selectedNewProperty[fie.id])
															: false
													}
													fullWidth
													value={selectedNewProperty[fie.id] || ''}
													onChange={e => {
														if (fie.type === INPUT_TYPE.Float) {
															if (/^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$/.test(e.target.value)) {
																setSelectedNewProperty({
																	...selectedNewProperty,
																	[fie.id]: e.target.value,
																});
															}
														} else {
															setSelectedNewProperty({
																...selectedNewProperty,
																[fie.id]: e.target.value,
															});
														}
													}}
													inputProps={{ style: { fontSize: '86%' } }}
													style={{ margin: '0' }}
												/>
											)}
										</div>
									)
							)}
						</div>
						<Map
							center={
								selectedNewProperty.longitude && selectedNewProperty.latitude
									? [selectedNewProperty.latitude, selectedNewProperty.longitude]
									: [0, 0]
							}
							onClick={UpdateMark}
							zoom={selectedNewProperty.longitude && selectedNewProperty.latitude ? 12 : 1}
							style={{
								height: '23.8rem',
								width: '21.6rem',
								margin: '1.7rem 0 0 1.6rem',
								borderRadius: '0.25rem',
							}}
						>
							<TileLayer
								attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
								url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
							/>
							{selectedNewProperty.longitude && selectedNewProperty.latitude && (
								<Marker
									position={[selectedNewProperty.latitude, selectedNewProperty.longitude]}
									icon={
										new Leaflet.icon({
											iconUrl: require('../assets/house-green.png').default,
											iconSize: [24, 24],
											iconAnchor: [12, 12],
										})
									}
								/>
							)}
						</Map>
					</div>
				</RadioGroup>
			</>
		);
	}
	function renderDuc() {
		return (
			<>
				<RadioGroup value={ducRadioSel} onChange={e => setDucRadioSel(Number(e.target.value))} style={{ marginLeft: '0.2rem' }}>
					{propRadioSel === 0 ? (
						<>
							<FormControlLabel value={0} control={RADIO_COMPONENT} label={t('registrationWizard.existingDUC')} />
							<div
								style={{
									width: '20rem',
									margin: '.3rem 0 1.5rem 1.7rem',
									...(ducRadioSel !== 0 ? { opacity: '.4', pointerEvents: 'none' } : {}),
								}}
							>
								<DucSelection citystreet={ExtractCityStreet()} setSelectedECP={setSelectedECP}></DucSelection>
							</div>
							<FormControlLabel value={1} control={RADIO_COMPONENT} label='Ny DUC' />{' '}
						</>
					) : (
						<p style={{ fontSize: '1rem' }}>Ny DUC</p>
					)}
					<div
						style={{
							width: '20rem',
							margin: '.3rem 0 1.5rem 1.7rem',
							...(ducRadioSel !== 1 && propRadioSel !== 1 ? { opacity: '.4', pointerEvents: 'none' } : {}),
						}}
					>
						{newDucData.map(ducData => (
							<div key={ducData.id} style={{ margin: '0 0 0.5rem 0' }}>
								<div style={{ color: '#002', margin: '0 0 0.2rem 0.1rem' }}>{ducData.label}</div>
								<TextField
									type={'text'}
									margin='dense'
									variant='outlined'
									placeholder={String('...')} // String() required to prevent errors
									value={ducData.preselected && ExtractCityStreet()}
									fullWidth
									onChange={selection =>
										setSelectedNewECP({
											...selectedNewECP,
											[ducData.id]: selection ? selection.target.value : undefined,
										})
									}
									rows={2}
									rowsMax={15}
									inputProps={{ style: { fontSize: '86%' } }}
									style={{ margin: '0' }}
								/>
							</div>
						))}
					</div>
				</RadioGroup>
			</>
		);
	}
	function renderImport() {
		return (
			<>
				{ValidateImport() && (
					<div
						style={{
							margin: '0.6rem 0',
							padding: '0.3rem 0.7rem',
							width: 'fit-content',
							background: '#e03444',
							borderRadius: '5px',
							color: 'white',
							fontSize: '110%',
						}}
					>
						{t('registrationWizard.importDucPromt')}
					</div>
				)}
				<div style={{ display: 'flex', marginTop: '0.6rem' }}>
					<h3 style={{ fontSize: '110%', fontWeight: '500', width: '4.4rem', margin: 'auto 0 auto' }}>Fil:</h3>
					{isInvalidFile ? (
						<h3 style={{ fontSize: '110%', color: '#c00', margin: 'auto 1rem auto 1px' }}>
							Okänd filtyp, kunde inte laddas upp.
						</h3>
					) : (
						fileName && <h3 style={{ fontSize: '110%', color: '#000d', margin: 'auto 1rem auto 1px' }}>{fileName}</h3>
					)}
					<DucUploadButton
						setFileName={setFileName}
						setFormattedSheet={setFormattedSheet}
						setIsInvalidFile={setIsInvalidFile}
						disabled={ValidateImport()}
					>
						{fileName || isInvalidFile ? t('registrationWizard.change') : t('registrationWizard.upload')}
					</DucUploadButton>
				</div>
				{fileName && !formattedSheet && (
					<LinearProgress style={{ width: '18rem', height: '0.6rem', marginTop: '0.5rem', borderRadius: '0.2rem' }} />
				)}
				{formattedSheet && !isInvalidFile && (
					<ImportedRegisterList
						selectedReadRegs={selectedReadRegs}
						setSelectedReadRegs={setSelectedReadRegs}
						selectedWriteRegs={selectedWriteRegs}
						setSelectedWriteRegs={setSelectedWriteRegs}
						disableButton={true}
						type='add'
						ecpId={selectedECP}
						data={formattedSheet}
						canSave={selectedECP}
						onSave={() => {
							setFileName();
							setFormattedSheet();

						}}
						style={{ marginTop: '1.2rem' }}
					/>
				)}
			</>
		);
	}

	function renderSensor() {
		return (
			<SelectionTable
				localization={{
					title: t('registrationWizard.nonRegisteredSensors'),
					nRowsSelected: t('registrationWizard.numberOfSelectedSensors') + '{0}',
				}} //TODO: Find a way to do this better
				data={[...props.sensors.filter(f => f.locationid == null), ...unregSensors]}
				dataId='sensorid'
				onSelectionChange={sens => setSelectedSens(sens)}
				columns={[{ title: t('generic.id'), field: 'sensorref', maxLength: 200 }]}
				pageSizeOptions={[]}
				tableProps={{ maxColumnLength: 28 }}
			/>
		);
	}
	function renderSensorGroup() {
		return (
			<>
				{newGroups.map(grp => (
					<div key={grp.name} style={{ display: 'flex' }}>
						<div
							style={{
								display: 'flex',
								width: 'fit-content',
								minWidth: '20rem',
								fontSize: '120%',
								fontWeight: '400',
								margin: '0 0 .5rem 0',
								padding: '.3rem .6rem',
								background: `${colors.primary}20`,
								border: `.1rem solid ${colors.primary}40`,
								borderRadius: '.2rem',
							}}
						>
							<div>{grp.name}</div>
							<div style={{ margin: 'auto 0 auto auto' }}>
								{t('registrationWizard.sensorPromt')}
								{grp.sensors.length}
							</div>
						</div>
						<TooltipWrapper text={t('registrationWizard.deleteSensorGroup')}>
							<IconButton
								style={{ width: '2rem', height: '2rem', margin: '.1rem 0 0 .5rem' }}
								onClick={() => setNewGroups(newGroups.filter(_grp => _grp.name !== grp.name))}
							>
								<CloseIcon />
							</IconButton>
						</TooltipWrapper>
					</div>
				))}

				<Button
					variant='outlined'
					onClick={() => setShowAddGroupPopup(!showAddGroupPopup)}
					style={{ display: 'flex', marginTop: '0.2rem', color: colors.primary }}
				>
					<AddIcon style={{ width: '1rem', height: '1rem' }} />
					{t('registrationWizard.addSensorGroup')}
				</Button>

				<EditPopup
					isOpen={showAddGroupPopup}
					text={{ title: t('registrationWizard.addSensorGroup') }}
					fields={[
						{ id: 'name', label: t('generic.name') },
						{
							customRender: (
								<SelectionTable
									localization={{
										title: t('constants.sensors'),
										nRowsSelected: '{0}' + t('registrationWizard.selected'),
									}} //TODO: Find a better way to do this (and make it dynamic)
									data={selectedSens}
									dataId='sensorid'
									onSelectionChange={sens => setSelectedGrpSens(sens)}
									columns={[{ title: t('generic.id'), field: 'sensorref', maxLength: 200 }]}
									pageSizeOptions={[]}
									tableProps={{ maxColumnLength: 28, reducePadding: true, toolbarHeight: '3rem' }}
									style={{ marginTop: '1rem' }}
									disabledIds={newGroups.flatMap(newgroup => newgroup.sensors.map(sensor => sensor.sensorid))}
								/>
							),
						},
					]}
					onClose={() => setShowAddGroupPopup(!showAddGroupPopup)}
					onSave={vals => {
						setShowAddGroupPopup(!showAddGroupPopup);
						setNewGroups([...newGroups, { name: vals.name, sensors: selectedGrpSens }]);
						setSelectedGrpSens([]);
					}}
					dialogStyle={{ maxWidth: '40rem' }}
				/>
			</>
		);
	}

	function renderSummary() {
		return (
			<>
				<div id='Fastighetbefintligny'>
					<div style={{ fontWeight: '800', marginBottom: '.2rem' }}>
						{t('generic.property')} ({propRadioSel === 0 ? t('registrationWizard.existing') : t('registrationWizard.new')})
					</div>
					<div style={{ fontWeight: '400', display: 'flex' }}>
						<div>
							{[
								t('generic.address'),
								t('generic.city'),
								t('generic.label'),
								t('registrationWizard.squareMetres'),
								t('registrationWizard.longitude'),
								t('registrationWizard.latitude'),
							].map(v => (
								<div>{v}</div>
							))}
						</div>
						<div style={{ marginLeft: '1rem' }}>
							{[
								propRadioSel === 0
									? props.properties.find(pro => pro.locationid === selectedCurPropertyId) || {}
									: selectedNewProperty,
							]
								.map(pro => [pro.street, pro.city, pro.cadastral, pro.area, pro.longitude, pro.latitude])
								.flat()
								.map(v => (
									<div>{v || ''}</div>
								))}
						</div>
					</div>
				</div>
				<div id='DUCbefintligNy' style={{ margin: '1rem 0 .2rem 0' }}>
					<div style={{ fontWeight: '800', marginBottom: '.2rem' }}>
						{t('generic.bms')} ({ducRadioSel === 0 ? t('registrationWizard.existing') : t('registrationWizard.new')})
					</div>
					<div style={{ fontWeight: '400', display: 'flex' }}>
						<div>
							{newDucData.map(v => (
								<div>{v.label}</div>
							))}
						</div>
						<div style={{ marginLeft: '1rem' }}>
							{[
								ducRadioSel === 0
									? filteredEcps.find(ecp => ecp.externalcontrolpanelid === selectedECP) || {}
									: selectedNewECP,
							]
								.map(ecp => [ecp.label, ecp.ip, ecp.port, ecp.ducid, ecp.url])
								.flat()
								.map(v => (
									<div>{v || ''}</div>
								))}
						</div>
					</div>
				</div>
				{selectedSens.length > 0 && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>{t('registrationWizard.freestandingSensors')}</div>
				)}

				{selectedSens
					.filter(
						sen => !newGroups.flatMap(newgroup => newgroup.sensors.map(sensor => sensor.sensorid)).some(t => t == sen.sensorid)
					)
					.map(sen => (
						<div style={{ fontWeight: '400' }}>{sen.name || sen.sensorref || '-'}</div>
					))}

				{newGroups.length > 0 && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>{t('registrationWizard.sensorGroups')}</div>
				)}
				{newGroups.map(newGroup => (
					<>
						<div style={{ fontWeight: '400' }}>{newGroup.name}</div>
						{newGroup.sensors.map(sensor => (
							<div style={{ marginLeft: '1rem', fontWeight: '400' }}>{`	${sensor.sensorref}`}</div>
						))}
					</>
				))}
				{(selectedReadRegs.length > 0 || selectedWriteRegs.length > 0) && (
					<div style={{ fontWeight: '800', margin: '1rem 0 .2rem 0' }}>{t('registrationWizard.ducSensors')}</div>
				)}

				<div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, max-content)', columnGap: '1rem', fontWeight: '400' }}>
					{selectedReadRegs
						.filter(e => selectedWriteRegs.some(f => f === e))
						.map(sen => {
							let readFormated = formattedSheet.find(readReg => readReg.address === sen);
							return (
								<>
									<div>{readFormated.objectname}</div>
									<div>{readFormated.parametername}</div>
									<div>{t('registrationWizard.readWrite')}</div>
								</>
							);
						})}

					{selectedReadRegs
						.filter(e => !selectedWriteRegs.some(f => f === e))
						.map(sen => {
							let readFormated = formattedSheet.find(readReg => readReg.address === sen);
							return (
								<>
									<div>{readFormated.objectname}</div>
									<div>{readFormated.parametername}</div>
									<div>{t('registrationWizard.read')}</div>
								</>
							);
						})}
					{selectedWriteRegs
						.filter(e => !selectedReadRegs.some(f => f === e))
						.map(sen => {
							let writeFormated = formattedSheet.find(writeReg => writeReg.address === sen);
							return (
								<>
									<div>{writeFormated.objectname}</div>
									<div>{writeFormated.parametername}</div>
									<div>{t('registrationWizard.write')}</div>
								</>
							);
						})}
				</div>
			</>
		);
	}

	function renderWindowSwitch(stepId) {
		let functioncall = () => <></>;
		switch (stepId) {
			case STEPS.property.id:
				functioncall = renderProperty;
				break;
			case STEPS.duc.id:
				functioncall = renderDuc;
				break;
			case STEPS.import.id:
				functioncall = renderImport;
				break;
			case STEPS.sensors.id:
				functioncall = renderSensor;
				break;
			case STEPS.sensorGroups.id:
				functioncall = renderSensorGroup;
				break;
			case STEPS.summary.id:
				functioncall = renderSummary;
				break;

			default:
				functioncall = () => <></>;
				break;
		}

		return functioncall();
	}
	return (
		<>
			<Stepper nonLinear activeStep={curStepI} style={{ margin: '0 -1rem .4rem' }}>
				{STEPSArray.map((step, i) => (
					<Step key={step.id} completed={completedStepIds.includes(step.id)}>
						<StepButton
							style={{ [curStepI < i && 'pointerEvents']: 'none', color: colors.primary }}
							onClick={() => setCurStepI(i)}
						>
							{step.label}
						</StepButton>
					</Step>
				))}
			</Stepper>

			<div style={{ margin: '0 1rem' }}>{renderWindowSwitch(currentStep.id)}</div>

			<div style={{ display: 'flex', margin: '1.5rem .9rem 1rem auto' }}>
				<Button
					color='primary'
					variant='outlined'
					disabled={curStepI === 0}
					onClick={() => setCurStepI(curStepI - 1)}
					style={{ margin: '0 1rem 0 0' }}
				>
					{t('generic.back')}
				</Button>
				<Button
					color='primary'
					disabled={ValidateStep()}
					variant='outlined'
					onClick={() => (!onLastStep ? setCurStepI(curStepI + 1) : SaveChanges())}
				>
					{!onLastStep ? t('registrationWizard.continue') : t('generic.save')}
				</Button>
			</div>
		</>
	);
}

export default connect(getStateVariables(STORE.callbacks, STORE.properties, STORE.sensors, STORE.userInfo, STORE.externalControlPanels), {
	updateExternalControlPanels,
	updateProperties,
})(RegistrationWizard);
