export const STORE = Object.freeze({
	hasFinishedLoading: 'hasFinishedLoading',
	sensors: 'sensors',
	properties: 'properties',
	sensorGroups: 'sensorGroups',
	digitalTwins: 'digitalTwins',
	digitalTwinTags: 'digitalTwinTags',
	digitalTwinPerspectives: 'digitalTwinPerspectives',
	cos: 'cos',
	coRegisters: 'coRegisters',
	coRegisterOffsets: 'coRegisterOffsets',
	actions: 'actions',
	deviations: 'deviations',
	userInfo: 'userInfo',
	fileRecords: 'fileRecords',
	callbacks: 'callbacks',
	currentPage: 'currentPage',
	currentTab: 'currentTab',
	currentProperty: 'currentProperty',
	alarmPeriodSensors: 'alarmPeriodSensors',
	cameras: 'cameras',
	externalControlPanels: 'externalControlPanels',
});

export function getStateVariables(...requestVariables) {
	return state => {
		const returnVariables = {};
		for (const vari of requestVariables) returnVariables[STORE[vari]] = state[STORE[vari]];
		return returnVariables;
	};
}
