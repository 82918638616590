import React, { useState, useRef, useEffect } from 'react';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
	IconButton,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
} from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, Add as AddIcon, Close as CloseIcon, Build as BuildIcon } from '@material-ui/icons';
import { gql, useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Fullscreen as FullscreenIcon } from '@material-ui/icons';
import { STORE, getStateVariables } from '../redux/selectors';
import { FILE_CLASSIFICATIONS } from '../constants';
import CustomButton from './CustomButton';
import EditPopup from './EditPopup';
import * as colors from '../colors';

const GET_FILES = gql`
	query ($filter: FileRecordFilter!) {
		getFileRecords(filter: $filter) {
			id
			locationid
			name
			displayname
			classification
		}
	}
`;

const SET_FILES = gql`
	mutation ($ids: [ID]!, $displayname: String) {
		setFileRecords(ids: $ids, displayname: $displayname) {
			id
		}
	}
`;

const ADD_FILE = gql`
	mutation ($file: Upload!, $locationid: Int!, $displayname: String!, $uploaderid: String!, $classification: String!) {
		addFile(file: $file, locationid: $locationid, displayname: $displayname, uploaderid: $uploaderid, classification: $classification) {
			id
		}
	}
`;

const REMOVE_FILES = gql`
	mutation ($ids: [ID]!) {
		removeFiles(ids: $ids) {
			id
		}
	}
`;

const NO_REDIRECT_ID = 'no-redirect';

function OperationCardView(props) {
	const [opcards, setOpcards] = useState([]);
	const [expandedOpcardIds, setExpandedOpcardIds] = useState([]);
	const [opcardUnderEdit, setOpcardUnderEdit] = useState();
	const [addPopupEnabled, setAddPopupEnabled] = useState(false);
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [newOpcardName, setNewOpcardName] = useState('');
	const [confirmDelete, setConfirmDelete] = useState(false);
	const isMounted = useRef(true);

	const filesQuery = useQuery(GET_FILES, { variables: { filter: { locationids: [props.currentProperty] } } });
	const [addFile] = useMutation(ADD_FILE, { onCompleted: () => isMounted.current && filesQuery.refetch() });
	const [setFiles] = useMutation(SET_FILES, { onCompleted: () => isMounted.current && filesQuery.refetch() });
	const [removeFiles] = useMutation(REMOVE_FILES, { onCompleted: () => isMounted.current && filesQuery.refetch() });

	const { t } = useTranslation();

	useEffect(() => {
		return () => (isMounted.current = false);
	}, []);

	useEffect(() => {
		if (!filesQuery.loading && filesQuery.data)
			setOpcards(filesQuery.data.getFileRecords.filter(file => file.classification === FILE_CLASSIFICATIONS.opcard.id));
		// eslint-disable-next-line
	}, [filesQuery.data]);

	function addOpcard() {
		const inputElement = document.createElement('input');
		inputElement.type = 'file';
		inputElement.accept = '.pdf, application/pdf';
		inputElement.onchange = event => {
			if(event.target.files[0].type !== 'application/pdf') {
				alert(t('documentView.onlyPDF'));
			} else if(event.target.files[0].size > 10000000) {
				alert(t('documentView.fileTooLarge'));
			} else {
				setOpcardUnderEdit(event.target.files[0]);
				setAddPopupEnabled(!addPopupEnabled);
			}/* // The following code is for uploading any file type
			if (event.target.validity.valid && event.target.files.length) {
				setOpcardUnderEdit(event.target.files[0]);
				setAddPopupEnabled(!addPopupEnabled);
			} */
		};
		inputElement.click();
	}

	function editDocument(doc) {
		setOpcardUnderEdit(doc);
		setNewOpcardName(doc ? doc.displayname : '');
		setConfirmDelete(false);
		setEditPopupEnabled(doc ? true : false);
	}

	function renameDocument(doc, newName) {
		if (newName && doc.displayname !== newName) setFiles({ variables: { ids: [doc.id], displayname: newName } });
		editDocument();
	}

	function deleteDocument(doc) {
		if (doc && confirmDelete) {
			removeFiles({ variables: { ids: [doc.id] } });
			editDocument();
		} else if (opcardUnderEdit) {
			setConfirmDelete(true);
		}
	}
	const handleAccordionChange = (event, isExpanded, id) => {
		const noRedirectFlag =
			event._dispatchInstances.length &&
			event._dispatchInstances.some((dispatch) => {
				return dispatch.stateNode && dispatch.stateNode.id === NO_REDIRECT_ID;
			});

		if (!noRedirectFlag) {
			setExpandedOpcardIds(
				isExpanded
					? expandedOpcardIds.concat(id)
					: expandedOpcardIds.filter((currentId) => currentId !== id)
			);
		}
	};
	function openFullScreen(url) {
		const newWindow = window.open(url, '_blank');
		if (newWindow) {
			newWindow.document.title = 'Full Screen';
			newWindow.document.body.style.margin = '0';
		}
	}



	return (
		<div style={{ margin: '0 1rem 1rem 1rem' }}>
			{/* div required around Accordions to make last Accordion draw with rounded corners */}
			{/* <div>
				{opcards.map(op => (
					<Accordion
						expanded={Boolean(expandedOpcardIds.find(id => id === op.id))} // If undefined is returned the component will not be controlled
						onChange={(event, isExpanded) => {
							const noRedirectFlag =
								event._dispatchInstances.length &&
								event._dispatchInstances.some(dispatch => {
									return dispatch.stateNode && dispatch.stateNode.id === NO_REDIRECT_ID;
								});

							if (!noRedirectFlag)
								setExpandedOpcardIds(
									isExpanded ? expandedOpcardIds.concat(op.id) : expandedOpcardIds.filter(id => id !== op.id)
								);
						}}
						elevation={2}
						key={op.id}
					>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: '115%' }}>
							{op.displayname}
							<IconButton
								onClick={() => editDocument(op)}
								style={{ margin: '0 0.5rem 0 auto', padding: '0.3rem', color: '#0007' }}
								id={NO_REDIRECT_ID}
							>
								<BuildIcon style={{ width: '1rem', height: '1rem' }} />
							</IconButton>
						</AccordionSummary>
						<AccordionDetails style={{ paddingTop: '0' }}>
							<img
								alt={op.name}
								style={{ maxHeight: '28rem', margin: '0 auto' }}
								src={process.env.REACT_APP_FILES_SERVER_URL + '/' + op.locationid + '/' + op.name}
							/>
						</AccordionDetails>
					</Accordion>
				))}
			</div> */}
			<div>
				{opcards.map((op) =>
					op.name.toLowerCase().endsWith('.pdf') ? (
						<Accordion
							expanded={Boolean(expandedOpcardIds.find((id) => id === op.id))}
							onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, op.id)}
							key={op.id}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: '115%', display: 'flex', justifyContent: 'space-between' }}>
								<span>{op.displayname}</span>
								<div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
									{Boolean(expandedOpcardIds.find((id) => id === op.id)) && (
										<IconButton onClick={() => openFullScreen(`${process.env.REACT_APP_FILES_SERVER_URL}/${op.locationid}/${op.name}`)}
											style={{ padding: '0.3rem', color: '#0007' }}
											id={NO_REDIRECT_ID} >
											<FullscreenIcon style={{ width: '1rem', height: '1rem' }} />
										</IconButton>)}
									<IconButton
										onClick={(e) => {
											e.preventDefault();
											editDocument(op);
										}}
										style={{ padding: '0.3rem', color: '#0007' }}
										id={NO_REDIRECT_ID}
									>
										<BuildIcon style={{ width: '1rem', height: '1rem' }} />
									</IconButton>
								</div>

							</AccordionSummary>
							<AccordionDetails style={{ paddingTop: '0' }}>
								<iframe
									title={op.displayname}
									src={`${process.env.REACT_APP_FILES_SERVER_URL}/${op.locationid}/${op.name}`}
									width="100%"
									height="500px"
								></iframe>
							</AccordionDetails>
						</Accordion>
					) : (
						<Accordion
							expanded={Boolean(expandedOpcardIds.find((id) => id === op.id))}
							onChange={(event, isExpanded) => handleAccordionChange(event, isExpanded, op.id)}
							key={op.id}
						>
							<AccordionSummary expandIcon={<ExpandMoreIcon />} style={{ fontSize: '115%', display: 'flex', justifyContent: 'space-between' }}>
								<span>{op.displayname}</span>
								<div style={{ display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
									<IconButton
										onClick={() => editDocument(op)}
										style={{ padding: '0.3rem', color: '#0007' }}
										id={NO_REDIRECT_ID}
									>
										<BuildIcon style={{ width: '1rem', height: '1rem' }} />
									</IconButton>
								</div>
							</AccordionSummary>
							<AccordionDetails style={{ paddingTop: '0' }}>
								<img
									alt={op.name}
									style={{ maxHeight: '28rem', margin: '0 auto' }}
									src={`${process.env.REACT_APP_FILES_SERVER_URL}/${op.locationid}/${op.name}`}
								/>
							</AccordionDetails>
						</Accordion>
					)
				)}
			</div>
			<CustomButton
				icon={AddIcon}
				text={t('operationCardView.uploadOperationCard')}
				onClick={addOpcard}
				style={{ margin: '1rem 0' }}
			/>

			{/* Popup for specifying name of the opcard to upload */}
			<EditPopup
				text={{
					title: t('operationCardView.uploadOperationCard'),
					subtitle: t('operationCardView.nameOperationCard'),
					save: t('operationCardView.upload'),
				}}
				fields={[
					{
						id: 'displayname',
						label: t('generic.name'),
						default: (opcardUnderEdit || { name: '' }).name.replace(/\..*$/, ''),
					},
				]}
				isOpen={addPopupEnabled}
				onClose={() => setAddPopupEnabled(!addPopupEnabled)}
				onSave={opts => {
					addFile({
						variables: {
							file: opcardUnderEdit,
							locationid: props.currentProperty,
							displayname: opts.displayname,
							uploaderid: props.userInfo.id,
							classification: FILE_CLASSIFICATIONS.opcard.id,
						},
					});
					setAddPopupEnabled(!addPopupEnabled);
				}}
			/>

			{/* Popup dialog for editing or removing opcards */}
			<Dialog open={editPopupEnabled} onClose={() => editDocument()}>
				{/* Close/X button */}
				<IconButton
					onClick={() => editDocument()}
					style={{ position: 'absolute', right: '0', margin: '0.3rem', padding: '0.4rem' }}
				>
					<CloseIcon style={{ width: '1.15rem', height: '1.15rem' }} />
				</IconButton>

				<DialogTitle>{t('operationCardView.editOperationCard')}</DialogTitle>

				<DialogContent style={{ paddingTop: '0' }}>
					<TextField
						label={t('generic.name')}
						value={newOpcardName}
						onChange={event => setNewOpcardName(event.target.value)}
						fullWidth
					/>
				</DialogContent>

				<DialogActions style={{ padding: '1rem' }}>
					<Button
						onClick={() => renameDocument(opcardUnderEdit, newOpcardName)}
						disabled={confirmDelete}
						style={{ textTransform: 'none', color:colors.text}}
						autoFocus
					>
						{t('operationCardView.saveSettings')}
					</Button>
					<Button disabled={confirmDelete} onClick={() => deleteDocument()} color='secondary' style={{ textTransform: 'none' }}>
						{t('operationCardView.removeOperationCard')}
					</Button>
				</DialogActions>

				{confirmDelete && (
					<Button
						onClick={() => deleteDocument(opcardUnderEdit)}
						color='secondary'
						style={{
							color: '#fff',
							background: '#f00',
							borderRadius: '0',
							height: '2.7rem',
							fontSize: '110%',
							textTransform: 'none',
						}}
					>
						{t('operationCardView.confirmRemoval')}
					</Button>
				)}
			</Dialog>
		</div>
	);
}

export default connect(getStateVariables(STORE.currentProperty, STORE.userInfo))(OperationCardView);
