import React, { useState, useRef, useEffect } from 'react';

import { Add } from '@material-ui/icons';
import { gql, useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { STORE, getStateVariables } from '../redux/selectors';
import { FILE_CLASSIFICATIONS } from '../constants';
import DocumentList from './DocumentList';
import CustomButton from './CustomButton';
import EditPopup from './EditPopup';

const GET_FILES = gql`
	query ($filter: FileRecordFilter!) {
		getFileRecords(filter: $filter) {
			id
			locationid
			name
			displayname
			dateuploaded
			uploaderid
			classification
		}
	}
`;

const SET_FILES = gql`
	mutation ($ids: [ID]!, $displayname: String) {
		setFileRecords(ids: $ids, displayname: $displayname) {
			id
		}
	}
`;

const ADD_FILE = gql`
	mutation ($file: Upload!, $locationid: Int!, $displayname: String!, $uploaderid: String!, $classification: String!) {
		addFile(file: $file, locationid: $locationid, displayname: $displayname, uploaderid: $uploaderid, classification: $classification) {
			id
		}
	}
`;

const REMOVE_FILES = gql`
	mutation ($ids: [ID]!) {
		removeFiles(ids: $ids) {
			id
		}
	}
`;

/**
 * A list of documents with the ability to upload further documents
 */
function DocumentsView(props) {
	const [documents, setDocuments] = useState([]);
	const [editPopupEnabled, setEditPopupEnabled] = useState(false);
	const [documentUnderEdit, setDocumentUnderEdit] = useState();
	const isMounted = useRef(true);

	const filesQuery = useQuery(GET_FILES, {
		variables: { filter: { locationids: [props.currentProperty] } },
	});

	const [addFile] = useMutation(ADD_FILE, { onCompleted: () => isMounted.current && filesQuery.refetch() });
	const [setFiles] = useMutation(SET_FILES, { onCompleted: () => isMounted.current && filesQuery.refetch() });
	const [removeFiles] = useMutation(REMOVE_FILES, { onCompleted: () => isMounted.current && filesQuery.refetch() });

	const { t } = useTranslation();

	useEffect(() => {
		return () => (isMounted.current = false);
	}, []);

	useEffect(() => {
		if (!filesQuery.loading && filesQuery.data)
			setDocuments(filesQuery.data.getFileRecords.filter(file => file.classification !== FILE_CLASSIFICATIONS.opcard.id)); // Filter operationcards as they are displayed in a separate tab
		// eslint-disable-next-line
	}, [filesQuery.data]);

	function renameDocument(doc, newName) {
		setFiles({ variables: { ids: [doc.id], displayname: newName } });
	}

	function deleteDocument(doc) {
		removeFiles({ variables: { ids: [doc.id] } });
	}

	function selectDocument() {
		const inputElement = document.createElement('input');
		inputElement.type = 'file';
		inputElement.accept = 'application/pdf';
		inputElement.onchange = event => {
			if (event.target.files[0].type !== 'application/pdf') {
				alert(t('documentView.onlyPDF'));
			} else if (event.target.files[0].size > 10000000) {
				alert(t('documentView.fileTooLarge'));
			} else {
				setDocumentUnderEdit(event.target.files[0]);
				setEditPopupEnabled(!editPopupEnabled);
			}
		};
		inputElement.click();
	}

	return (
		<div>
			<DocumentList documents={documents} onRename={renameDocument} onDelete={deleteDocument} />
			<CustomButton icon={Add} text={t('documentView.uploadDocument')} onClick={selectDocument} />

			<EditPopup
				text={{
					title: t('documentView.uploadDocument'),
					subtitle: t('documentView.nameDocument'),
					save: t('documentView.upload'),
				}}
				fields={[
					{
						id: 'displayname',
						label: t('generic.name'),
						default: (documentUnderEdit || { name: '' }).name.replace(/\..*$/, ''),
					},
					{
						id: 'classification',
						label: t('generic.class'),
						options: Object.values(FILE_CLASSIFICATIONS).map(classi => {
							return { value: classi.id, label: classi.label };
						}),
						placeholder: FILE_CLASSIFICATIONS.other.label,
					},
				]}
				isOpen={editPopupEnabled}
				onClose={() => setEditPopupEnabled(!editPopupEnabled)}
				onSave={opts => {
					addFile({
						variables: {
							file: documentUnderEdit,
							locationid: props.currentProperty,
							displayname: opts.displayname,
							uploaderid: props.userInfo.id,
							classification: opts.classification || FILE_CLASSIFICATIONS.other.id,
						},
					});
					setEditPopupEnabled(!editPopupEnabled);
				}}
			/>
		</div>
	);
}

export default connect(getStateVariables(STORE.currentProperty, STORE.userInfo))(DocumentsView);
