import { getDecimals } from '../utility-functions';
import i18n from '../i18n';

function getFormattedNumber(inNumber, showPositiveSign = false, showSuffix = true) {
	const number = Number(inNumber);
	const absNumber = Math.abs(number);
	let r = number;

	if      (absNumber >= 10000000000) r = getDecimals(number / 1000000000, 1) + (showSuffix ? i18n.t('nivoCharts.giga') : '');
	else if (absNumber >= 1000000000) r = getDecimals(number / 1000000, 0) + (showSuffix ? i18n.t('nivoCharts.mega') : '');
	else if (absNumber >= 100000000) r = getDecimals(number / 1000000, 1) + (showSuffix ? i18n.t('nivoCharts.mega') : '');
	else if (absNumber >= 1000000) r = getDecimals(number / 1000, 0) + (showSuffix ? i18n.t('nivoCharts.kilo') : '');
	else if (absNumber >= 10000) r = getDecimals(number / 1000, 1) + (showSuffix ? i18n.t('nivoCharts.kilo') : '');
	else if (absNumber >= 100) r = getDecimals(number, 0);
	else if (absNumber >= 1) r = getDecimals(number, 1);
	else if (absNumber >= 0.01) r = getDecimals(number, 2);
	else if (absNumber >= 0.001) r = getDecimals(number, 3);
	else if (absNumber >= 0.0001) r = getDecimals(number, 4);
	else if (absNumber >= 0.00001) r = getDecimals(number, 5);
	else if (absNumber >= 0.000001) r = getDecimals(number, 6);

	return (showPositiveSign && r > 0 ? '+' : '') + r;
}

export default getFormattedNumber;
