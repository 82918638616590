import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Select from '../components/SelectWrapper';
import { STORE, getStateVariables } from '../redux/selectors';
/**
 * A selectionList for which ExternalControlPanelList to pick (DUC)
 * @param {Function} setSelectedECP: a callback function witch sets the selected id of the selected Options.
 * @returns
 */
function DucSelection(props) {
	const ecpOptions = [
		...props.externalControlPanels.map(ecp => ({
			value: ecp.externalcontrolpanelid,
			label: (() => {
				const pro = props.properties.find(pro => pro.locationid === Number(ecp.locationid));
				return `${pro?.city}: ${pro?.street} • ${ecp.label || ecp.ip || ecp.url || '-'}`;
			})(),
		})),
	].filter(t => t.label.includes(props.citystreet ?? ''));

	const { t } = useTranslation();

	useEffect(() => {
		if (ecpOptions.length === 0) props.setSelectedECP(undefined);
	}, []);
	return (
		<Select
			selectProps={{
				placeholder: t('ducSelection.selectDUC'),
				options: ecpOptions,
				onChange: sel => props.setSelectedECP(sel?.value || undefined),
				...props.selectProps,
			}}
			style={{ width: '20rem ', ...props.style }}
			selectStyle={props.selectStyle}
		/>
	);
}

export default connect(getStateVariables(STORE.properties, STORE.externalControlPanels))(DucSelection);
