import React from 'react';
import { gql, useMutation } from '@apollo/client';
import { Button, Checkbox } from '@material-ui/core';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getStateVariables, STORE } from '../redux/selectors';
import Table from './MaterialTableWrapper';
import * as colors from '../colors';

const SET_IMPORTEDREGISTERS = gql`
	mutation ($externalcontrolpanelids: [ID!]!, $registers: [ImportedRegisterMutable!]!) {
		setImportedRegisters(externalcontrolpanelids: $externalcontrolpanelids, registers: $registers) {
			externalcontrolpanelid
			address
			read
			write
			automation
		}
	}
`;
const ADD_AI_CONTROL = gql`
  mutation($locationid: ID) {
	addAiControl(locationid: $locationid) {
      locationid
    }
  }
`;
/**
 * A table of imported registers with options for editing/adding/viewing.
 * @param {'add' | 'edit'} type : Adjusts the functionality/purpose of the component
 * @param {number} ecpId : Id of ECP to which registers belong
 * @param {object[]} data : Imported registers
 * @param {boolean} canSave : Override save-button state
 * @param {() => void} onSave : Called when the save-button is pressed
 * @param {({}) => void} onNewData : Called with new data when it arrives
 * @param {CSS} style : Applied to surrounding container
 */
function ImportedRegisterListAutomation(_props) {
	const props = {
		..._props,
		data: _props.data || [],
		onSave: typeof _props.onSave === 'function' ? _props.onSave : () => null,
		onNewData: typeof _props.onNewData === 'function' ? _props.onNewData : () => null,
		disableButton: _props.disableButton ?? false,
	};
	const [setImportedRegisters] = useMutation(SET_IMPORTEDREGISTERS, {
		onCompleted: ({ setImportedRegisters }) => props.onNewData(setImportedRegisters),
		//update: updateSensors,
		onError: (error) => console.error('Failed to set registers.', error),
	});

	useMutation(ADD_AI_CONTROL, {
		onCompleted: () => console.log('AI control added.'),
		onError: (error) => console.error('Failed to add aicontrol.',error),
	});

	const { t } = useTranslation();

	return (
		<div style={props.style}>
			<Table
				title={`${props.type === 'add' ? t('importedRegisterList.registerToImport') : t('importedRegisterList.editRegister')}${props.selectedReadRegs.length || props.selectedWriteRegs.length
					? ` (${props.selectedReadRegs.length + props.selectedWriteRegs.length} valda)`
					: ''
					}`}
				columns={[
					{ title: t('generic.object'), field: 'objectname' },
					{ title: t('importedRegisterList.group'), field: 'groupname' },
					{ title: t('importedRegisterList.parameter'), field: 'parametername' },
				]}
				actions={[
					row => ({ icon: () => <div />, disabled: true }), // Only for left-padding
					row => {
						const isDisabled = !row.canread;
						const isChecked = props.selectedReadRegs.includes(row.address);
						return {
							icon: () => (
								<Checkbox
									disabled={isDisabled}
									checked={isChecked}
									onChange={() =>
										isChecked
											? props.setSelectedReadRegs(props.selectedReadRegs.filter(add => add !== row.address))
											: props.setSelectedReadRegs([...props.selectedReadRegs, row.address])
									}
									style={{ padding: '0', color: colors.primary}}
								/>
							),
							disabled: isDisabled,
						};
					},
					/* row => {
						const isDisabled = !row.canwrite;
						const isChecked = props.selectedWriteRegs.includes(row.address);
						return {
							icon: () => (
								<Checkbox
									disabled={isDisabled}
									checked={isChecked}
									onChange={() =>
										isChecked
											? props.setSelectedWriteRegs(props.selectedWriteRegs.filter(add => add !== row.address))
											: props.setSelectedWriteRegs([...props.selectedWriteRegs, row.address])
									}
									style={{ padding: '0', color: colors.primary }}
								/>
							),
							disabled: isDisabled,
						};
					}, */
				]}
				data={props.data.sort((a, b) => (a.address < b.address ? -1 : 1))}
				maxColumnLength={30}
				localization={{ header: { actions: t('importedRegisterList.readWrite') } }}
			/>
			{!props.disableButton && (
				<div style={{ marginTop: '1rem', textAlign: 'right' }}>
					<Button
						variant='outlined'
						disabled={!props.canSave}
						onClick={() => {
							/* setImportedRegisters({
									variables: {
										externalcontrolpanelids: [props.ecpId],
										registers: props.data.map(reg => ({
											address: reg.address,
											read: reg.read,
											write: reg.write,
											automation: props.selectedReadRegs.includes(reg.address),
										})),
									},
								}); */
								setImportedRegisters({
									variables: {
									  externalcontrolpanelids: [props.ecpId],
									  registers: props.data.map(reg => ({
										address: reg.address,
										read: reg.read,
										write: reg.write,
										automation: props.selectedReadRegs.includes(reg.address),
									  })),
									},
								  })/* .then(result => {
									if (result.data) {
									  props.data.forEach(async (reg) => {
										if(reg.automation){
										  const locationId = Number(
											props.externalControlPanels.find(ecp => ecp.externalcontrolpanelid === props.ecpId)?.locationid
										  );
										  if (reg.automation){
											await addAiControl({
											  variables: {
												locationid: locationId,
												//system: reg.address,
												///name: reg.groupname,
											//	ducid: props.ecpId,
												//sensortype: reg.parametername,
											  },
											});
										  }
										}
									  });
									}
								  }); */
							props.onSave();
							const locationid = Number(
								props.externalControlPanels.find(ecp => ecp.externalcontrolpanelid === props.ecpId)?.locationid
							);
							if (!isNaN(locationid)) props.callbacks['on-update-importedregisters'].forEach(cb => cb.callback(locationid));
						}}
						style={{ background: '#fff', color: colors.primary }}
					>
						{props.type === 'add' ? t('importedRegisterList.importRegister') : t('importedRegisterList.saveChanges')}
					</Button>
				</div>
			)}
		</div>
	);
}

export default connect(getStateVariables(STORE.externalControlPanels, STORE.callbacks, STORE.sensorGroups, STORE.sensors, STORE.userInfo))(ImportedRegisterListAutomation);
