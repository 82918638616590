import {
	SET_HASFINISHEDLOADING,
	SET_USERINFO,
	SET_PAGE,
	SET_TAB,
	SET_PROPERTY,
	SET_SENSORS,
	SET_PROPERTIES,
	SET_SENSORGROUPS,
	SET_DIGITALTWINS,
	SET_DIGITALTWINTAGS,
	SET_DIGITALTWINPERSPECTIVES,
	SET_COS,
	SET_COREGISTERS,
	SET_COREGISTEROFFSETS,
	SET_ACTIONS,
	SET_FILERECORDS,
	SET_ALARMPERIODSENSORS,
	SET_DEVIATIONS,
	SET_CAMERAS,
	SET_EXTERNALCONTROLPANELS,
	UPDATE_SENSORS,
	UPDATE_SENSORGROUPS,
	UPDATE_PROPERTIES,
	UPDATE_DIGITALTWINS,
	UPDATE_DIGITALTWINTAGS,
	UPDATE_DIGITALTWINPERSPECTIVES,
	UPDATE_COS,
	UPDATE_COREGISTERS,
	UPDATE_COREGISTEROFFSETS,
	UPDATE_ACTIONS,
	UPDATE_USERINFO,
	UPDATE_FILERECORDS,
	UPDATE_ALARMPERIODSENSORS,
	UPDATE_DEVIATIONS,
	UPDATE_CAMERAS,
	UPDATE_EXTERNALCONTROLPANELS,
	ADD_CALLBACK,
	REMOVE_CALLBACK,
} from './actionTypes';

export function setHasFinishedLoading(hasFinishedLoading) {
	return {
		type: SET_HASFINISHEDLOADING,
		hasFinishedLoading: hasFinishedLoading,
	};
}

export function setUserInfo(userInfo) {
	return {
		type: SET_USERINFO,
		userInfo: userInfo,
	};
}

export function setPage(newPage) {
	return {
		type: SET_PAGE,
		page: newPage,
	};
}

export function setTab(newTab) {
	return {
		type: SET_TAB,
		tab: newTab,
	};
}

export function setProperty(newProperty) {
	return {
		type: SET_PROPERTY,
		property: newProperty,
	};
}

export function setSensors(sensors) {
	return {
		type: SET_SENSORS,
		sensors: sensors,
	};
}

export function setProperties(properties) {
	return {
		type: SET_PROPERTIES,
		properties: properties,
	};
}

export function setSensorGroups(sensorGroups) {
	return {
		type: SET_SENSORGROUPS,
		sensorGroups: sensorGroups,
	};
}

export function setDigitalTwins(digitalTwins) {
	return {
		type: SET_DIGITALTWINS,
		digitalTwins: digitalTwins,
	};
}

export function setDigitalTwinTags(digitalTwinTags) {
	return {
		type: SET_DIGITALTWINTAGS,
		digitalTwinTags: digitalTwinTags,
	};
}

export function setDigitalTwinPerspectives(digitalTwinPerspectives) {
	return {
		type: SET_DIGITALTWINPERSPECTIVES,
		digitalTwinPerspectives: digitalTwinPerspectives,
	};
}

export function setCOs(cos) {
	return {
		type: SET_COS,
		cos: cos,
	};
}

export function setCORegisters(coRegisters) {
	return {
		type: SET_COREGISTERS,
		coRegisters: coRegisters,
	};
}

export function setCORegisterOffsets(coRegisterOffsets) {
	return {
		type: SET_COREGISTEROFFSETS,
		coRegisterOffsets: coRegisterOffsets,
	};
}

export function setActions(actions) {
	return {
		type: SET_ACTIONS,
		actions: actions,
	};
}

export function setFileRecords(fileRecords) {
	return {
		type: SET_FILERECORDS,
		fileRecords: fileRecords,
	};
}

export function setAlarmPeriodSensors(alarmPeriodSensors) {
	return {
		type: SET_ALARMPERIODSENSORS,
		alarmPeriodSensors: alarmPeriodSensors,
	};
}

export function setDeviations(deviations) {
	return {
		type: SET_DEVIATIONS,
		deviations: deviations,
	};
}

export function setCameras(cameras) {
	return {
		type: SET_CAMERAS,
		cameras: cameras,
	};
}

export function setExternalControlPanels(externalControlPanels) {
	return {
		type: SET_EXTERNALCONTROLPANELS,
		externalControlPanels: externalControlPanels,
	};
}

export function updateSensors(sensors) {
	return {
		type: UPDATE_SENSORS,
		sensors,
	};
}

export function updateSensorGroups(sensorGroups) {
	return {
		type: UPDATE_SENSORGROUPS,
		sensorGroups,
	};
}

export function updateProperties(properties) {
	return {
		type: UPDATE_PROPERTIES,
		properties,
	};
}

export function updateDigitalTwins(digitalTwins) {
	return {
		type: UPDATE_DIGITALTWINS,
		digitalTwins,
	};
}

export function updateDigitalTwinTags(digitalTwinTags) {
	return {
		type: UPDATE_DIGITALTWINTAGS,
		digitalTwinTags,
	};
}

export function updateDigitalTwinPerspectives(digitalTwinPerspectives) {
	return {
		type: UPDATE_DIGITALTWINPERSPECTIVES,
		digitalTwinPerspectives,
	};
}

export function updateCOs(cos) {
	return {
		type: UPDATE_COS,
		cos,
	};
}

export function updateCORegisters(coRegisters) {
	return {
		type: UPDATE_COREGISTERS,
		coRegisters,
	};
}

export function updateCORegisterOffsets(coRegisterOffsets) {
	return {
		type: UPDATE_COREGISTEROFFSETS,
		coRegisterOffsets,
	};
}

export function updateActions(actions) {
	return {
		type: UPDATE_ACTIONS,
		actions,
	};
}

export function updateUserInfo(userInfo) {
	return {
		type: UPDATE_USERINFO,
		userInfo,
	};
}

export function updateFileRecords(fileRecords) {
	return {
		type: UPDATE_FILERECORDS,
		fileRecords,
	};
}

export function updateAlarmPeriodSensors(alarmPeriodSensors) {
	return {
		type: UPDATE_ALARMPERIODSENSORS,
		alarmPeriodSensors,
	};
}

export function updateDeviations(deviations) {
	return {
		type: UPDATE_DEVIATIONS,
		deviations,
	};
}

export function updateCameras(cameras) {
	return {
		type: UPDATE_CAMERAS,
		cameras,
	};
}

export function updateExternalControlPanels(externalControlPanels) {
	return {
		type: UPDATE_EXTERNALCONTROLPANELS,
		externalControlPanels,
	};
}

/**
 * Store a new, or overwrite an existing callback, in the Redux store
 * @param {String} trigger : Id of the trigger condition of the callback
 * @param {String} id : Id of the callback itself
 * @param {Function} callback : Function to be called on trigger
 */
export function addCallback(trigger, id, callback) {
	return {
		type: ADD_CALLBACK,
		trigger: trigger,
		id: id,
		callback: callback,
	};
}

/**
 * Remove a callback from the Redux store
 * @param {String} trigger : Id of the trigger condition of the callback
 * @param {String} id : Id of the callback to remove
 */
export function removeCallback(trigger, id) {
	return {
		type: REMOVE_CALLBACK,
		trigger: trigger,
		id: id,
	};
}
