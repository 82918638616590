import React, { useCallback, useMemo, useState } from 'react';

import SensorHandler from './SensorHandler';
import BarChart from './BarChart';
import LineChart from './LineChart';
import TableWidget from './TableWidget';
import PieChart from './PieChart';
import SpeedoMeter from './SpeedoMeter';
import * as colors from '../../../colors';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	IconButton,
	LinearProgress,
	TextField,
	makeStyles,
} from '@material-ui/core';
import { CHART_WIDGET_TYPES, MINUTES_PER_DAY, MINUTES_PER_MONTH, MINUTES_PER_WEEK, MINUTES_PER_YEAR } from '../../../constants';
import { getIntervalInMinutes, useQuerySensorDataWidget } from '../../../utility-functions/dataFetcherHooks';
import { normalizeData } from './dataFilters';
import AnalyseMenu, { LineGraphSettings } from '../Modal/DataAnalyseMenu';
import { useTranslation } from 'react-i18next';
import { Create, Close } from '@material-ui/icons';
import { styled } from '@material-ui/styles';
import { ChangeTitleDialog } from '../Modal/ChangeTitle';

const MILLISECONDS_PER_MINUTE = 1000 * 60;

function timeRangeToInterval(timeRange) {
	const intervalInMinutes = getIntervalInMinutes(timeRange);

	if (intervalInMinutes < MINUTES_PER_WEEK) {
		return { type: 'hour', duration: 1 };
	}
	if (intervalInMinutes < MINUTES_PER_MONTH * 4) {
		return { type: 'day', duration: 1 };
	}
	if (intervalInMinutes < MINUTES_PER_YEAR * 3) {
		return { type: 'month', duration: 1 };
	}
	return { type: 'year', duration: 1 };
}

export function CreateLineChart({
	widgetKey,
	id,
	sensors,
	props,
	options,
	changeSensorsCallback,
	properties,
	currentDatetimeRange,
	currentAutoUpdateInterval,
	updateChildTitle,
	normalize,
	editMode,
	dataLoadedCallback,
	hidePopoutIcon = false,
	animation = true,
	viewMode = 'overview',
}) {
	const interval = timeRangeToInterval(currentDatetimeRange);
	const rangeInMinutes = getIntervalInMinutes(currentDatetimeRange);
	const dataType = rangeInMinutes <= MINUTES_PER_WEEK ? 'raw' : 'mean';

	const { data, loading } = useQuerySensorDataWidget(sensors, currentDatetimeRange, interval, dataType);

	const transformData = useMemo(() => {
		if (normalize) return normalizeData(data);
		return data;
	}, [data, normalize]);

	const dataIsEmpty = data.reduce((acc, curr) => (acc += curr.data.length), 0) === 0;

	return (
		<>
			{editMode ? (
				<SensorHandler
					changeSensorsCallback={sensors => changeSensorsCallback(sensors, widgetKey)}
					selectedProperties={properties}
					graphSensors={sensors}
				/>
			) : null}
			{data.length ? (
				<LineChart
					data={transformData}
					{...props}
					normalized={normalize}
					viewMode={viewMode}
					animation={animation}
					dataLoadedCallback={dataLoadedCallback}
					width={options.w}
					dataType={dataType}
				/>
			) : undefined}
			{loading ? <LinearProgress style={{ position: 'absolute', top: '95%', width: '90%', zIndex: 1 }} /> : undefined}
			{!dataIsEmpty ? (
				<AnalyseMenu hidePopoutIcon={hidePopoutIcon}>
					<LineGraphSettings
						sensorData={data}
						title={props.Title}
						timeRange={currentDatetimeRange}
						updateInterval={currentAutoUpdateInterval}
						interval={interval}
						sensors={sensors}
						normalize={normalize}
						widgetType={CHART_WIDGET_TYPES.LineChart}
						prefferedDataType={dataType}
						updateTitle={title => updateChildTitle(title, id)}
					/>
				</AnalyseMenu>
			) : undefined}
		</>
	);
}

function timeRangeToIntervalBarChart(timeRange) {
	const intervalInMinutes = getIntervalInMinutes(timeRange);

	if (intervalInMinutes <= MINUTES_PER_DAY * 2) {
		return { type: 'hour', duration: 4 };
	}
	if (intervalInMinutes <= MINUTES_PER_WEEK * 2) {
		return { type: 'day', duration: 1 };
	}
	if (intervalInMinutes <= MINUTES_PER_MONTH * 2) {
		return { type: 'week', duration: 1 };
	}
	if (intervalInMinutes <= MINUTES_PER_YEAR) {
		return { type: 'month', duration: 1 };
	}
	if (intervalInMinutes < MINUTES_PER_YEAR * 3) {
		return { type: 'month', duration: 4 };
	}
	return { type: 'year', duration: 1 };
}

export function CreateBarChart({
	widgetKey,
	id,
	sensors,
	props,
	options,
	changeSensorsCallback,
	properties,
	currentDatetimeRange,
	currentAutoUpdateInterval,
	updateChildTitle,
	normalize,
	editMode,
	dataLoadedCallback,
	hidePopoutIcon = false,
	animation = true,
	viewMode = 'overview',
}) {
	const prefferedDataType = 'sum';
	const interval = timeRangeToIntervalBarChart(currentDatetimeRange);
	const { data, loading } = useQuerySensorDataWidget(sensors, currentDatetimeRange, interval, prefferedDataType);
	const transformData = useMemo(() => {
		if (normalize) return normalizeData(data);
		return data;
	}, [data, normalize]);

	const dataIsEmpty = data.reduce((acc, curr) => (acc += curr.data.length), 0) === 0;

	return (
		<>
			{editMode ? (
				<SensorHandler
					changeSensorsCallback={sensors => changeSensorsCallback(sensors, widgetKey)}
					selectedProperties={properties}
					graphSensors={sensors}
				/>
			) : null}
			{data.length ? (
				<BarChart
					data={transformData}
					{...props}
					normalized={normalize}
					timeRange={currentDatetimeRange}
					animation={animation}
					width={options.w}
					dataLoadedCallback={dataLoadedCallback}
					viewMode={viewMode}
				/>
			) : undefined}
			{loading ? <LinearProgress style={{ position: 'absolute', width: '90%', top: '95%', zIndex: 1 }} /> : undefined}
			{!dataIsEmpty ? (
				<AnalyseMenu hidePopoutIcon={hidePopoutIcon}>
					<LineGraphSettings
						sensorData={data}
						title={props.Title}
						timeRange={currentDatetimeRange}
						updateInterval={currentAutoUpdateInterval}
						interval={interval}
						sensors={sensors}
						normalize={normalize}
						widgetType={CHART_WIDGET_TYPES.BarChart}
						prefferedDataType={prefferedDataType}
						updateTitle={title => updateChildTitle(title, id)}
					/>
				</AnalyseMenu>
			) : undefined}
		</>
	);
}

export function CreatePieChart({
	widgetKey,
	id,
	sensors,
	props,
	options,
	changeSensorsCallback,
	properties,
	currentDatetimeRange,
	currentAutoUpdateInterval,
	updateChildTitle,
	normalize,
	editMode,
	dataLoadedCallback,
	hidePopoutIcon = false,
	animation = true,
	viewMode = 'overview',
}) {
	const prefferedDataType = 'sum';
	const interval = timeRangeToInterval(currentDatetimeRange);
	const { data, loading } = useQuerySensorDataWidget(sensors, currentDatetimeRange, interval, prefferedDataType);

	const dataIsEmpty = data.reduce((acc, curr) => (acc += curr.data.length), 0) === 0;
	const { t } = useTranslation();
	const transformData = useMemo(() => {
		if (normalize) return normalizeData(data);
		return data;
	}, [data, normalize]);

	return (
		<>
			{editMode ? (
				<SensorHandler
					changeSensorsCallback={sensors => changeSensorsCallback(sensors, widgetKey)}
					selectedProperties={properties}
					graphSensors={sensors}
				/>
			) : null}
			{data.length ? (
				<PieChart
					data={transformData}
					{...props}
					animation={animation}
					dataLoadedCallback={dataLoadedCallback}
					dataType={prefferedDataType}
					viewMode={viewMode}
					width={options.w}
				/>
			) : undefined}
			{loading ? <LinearProgress style={{ position: 'absolute', width: '90%', top: '95%', zIndex: 1 }} /> : undefined}
			{!dataIsEmpty ? (
				<AnalyseMenu hidePopoutIcon={hidePopoutIcon}>
					<LineGraphSettings
						sensorData={data}
						title={props.Title}
						timeRange={currentDatetimeRange}
						updateInterval={currentAutoUpdateInterval}
						interval={interval}
						sensors={sensors}
						normalize={normalize}
						widgetType={CHART_WIDGET_TYPES.PieChart}
						prefferedDataType={prefferedDataType}
						updateTitle={title => updateChildTitle(title, id)}
					/>
				</AnalyseMenu>
			) : undefined}
		</>
	);
}

export function CreateSpeedoMeter({
	widgetKey,
	id,
	sensors,
	props,
	options,
	changeSensorsCallback,
	properties,
	currentDatetimeRange,
	currentAutoUpdateInterval,
	updateChildTitle,
	normalize,
	editMode,
	dataLoadedCallback,
	viewMode = 'overview',
}) {
	const rangeInMinutes = getIntervalInMinutes(currentDatetimeRange);
	const interval = timeRangeToInterval(currentDatetimeRange);
	const dataType = rangeInMinutes <= MINUTES_PER_WEEK ? 'raw' : 'mean';
	const { data, metaData, loading } = useQuerySensorDataWidget(sensors, currentDatetimeRange, interval, dataType);
	const transformData = useMemo(() => {
		if (normalize) return normalizeData(data);
		return data;
	}, [data, normalize]);

	return (
		<>
			{editMode ? (
				<SensorHandler
					changeSensorsCallback={sensors => changeSensorsCallback(sensors, widgetKey)}
					selectedProperties={properties}
					graphSensors={sensors}
					maxSelectionCount={1}
				/>
			) : null}
			{transformData && transformData.length > 0 && metaData && metaData.length > 0 ? (
				<SpeedoMeter data={transformData} viewMode={viewMode} sensorMetaData={metaData} dataLoadedCallback={dataLoadedCallback} />
			) : undefined}

			{loading ? <LinearProgress style={{ position: 'absolute', width: '90%', top: '95%', zIndex: 1 }} /> : undefined}
		</>
	);
}

export function CreateTable({
	widgetKey,
	sensors,
	props,
	changeSensorsCallback,
	properties,
	currentDatetimeRange,
	currentAutoUpdateInterval,
}) {
	return (
		<>
			<TableWidget {...props} />
		</>
	);
}

const useStyles = makeStyles(theme => ({
	title: {
		fontSize: '20px',
		fontWeight: '500',
		whiteSpace: 'nowrap',
		marginTop: '-7px',
		marginLeft: '-12px',
		textTransform: 'none',
		lineHeight: '29px',
		color: colors.secondaryG,
		fontFamily:
			'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
	},
}));

function WidgetLayout(props) {
	const [open, setOpen] = useState(false);
	const classes = useStyles();

	const handleWidgetTitleChange = inputTitle => {
		setOpen(false);
		props.updateChildTitle(inputTitle, props.id);
	};

	const openTitleEdit = () => {
		if (props.editMode) setOpen(true);
	};
	return (
		<>
			<Button
				style={{ zIndex: 1, ...(!props.editMode ? { backgroundColor: 'transparent', pointerEvents: 'none' } : {}) }}
				startIcon={props.editMode ? <Create style={{ marginRight: '-7px' }} /> : undefined}
				className={classes.title}
				onClick={openTitleEdit}
			>
				{props.props.Title}
			</Button>
			{props.children}
			<ChangeTitleDialog
				open={open}
				title={props.props.Title}
				handleClose={() => setOpen(false)}
				handleSave={handleWidgetTitleChange}
			/>
		</>
	);
}

export function createWidget(element, props) {
	let widget = <></>;
	switch (element) {
		case CHART_WIDGET_TYPES.LineChart:
			widget = <CreateLineChart {...props} />;
			break;
		case CHART_WIDGET_TYPES.BarChart:
			widget = <CreateBarChart {...props} />;
			break;
		case CHART_WIDGET_TYPES.PieChart:
			widget = <CreatePieChart {...props} />;
			break;
		case CHART_WIDGET_TYPES.SpeedoMeter:
			widget = <CreateSpeedoMeter {...props} />;
			break;
		default:
			return <></>;
	}
	return <WidgetLayout {...props}>{widget}</WidgetLayout>;
}
