import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import * as colors from '../../../colors';

export const TableWidgetDefaultOptions = {
	w: 12,
	h: 7,
	noResize: false,
	noMove: false,
};

export const TableWidgetConstraints = {
	minW: 10,
	maxW: 14,
	minH: 6,
	maxH: 7,
};

//Data format columns
export const columns = [
	{ id: 'col1', label: 'Name' },
	{ id: 'col2', label: 'ISO\u00a0Code' },
	{
		id: 'col3',
		label: 'Population',
	},
	{
		id: 'col4',
		label: 'Size\u00a0(km\u00b2)',
	},
];

//Data format rows
export const testData = [
	{
		row: [{ col1: 'India', col2: 'IN', col3: 1324171354, col4: 3287263 }],
	},
	{
		row: [{ col1: 'China', col2: 'CN', col3: 1403500365, col4: 3287263 }],
	},
	{
		row: [{ col1: 'USA', col2: 'US', col3: 331002651, col4: 9372610 }],
	},
	{
		row: [{ col1: 'Indonesia', col2: 'ID', col3: 273523615, col4: 1904569 }],
	},
	{
		row: [{ col1: 'Pakistan', col2: 'PK', col3: 220892340, col4: 881913 }],
	},
	{
		row: [{ col1: 'Brazil', col2: 'BR', col3: 212559417, col4: 8515767 }],
	},
	{
		row: [{ col1: 'Nigeria', col2: 'NG', col3: 206139587, col4: 923768 }],
	},
	{
		row: [{ col1: 'Bangladesh', col2: 'BD', col3: 164689383, col4: 148460 }],
	},
	{
		row: [{ col1: 'Russia', col2: 'RU', col3: 145912025, col4: 17125242 }],
	},
	{
		row: [{ col1: 'Mexico', col2: 'MX', col3: 128932753, col4: 1964375 }],
	},
	{
		row: [{ col1: 'Japan', col2: 'JP', col3: 126476461, col4: 377975 }],
	},
	{
		row: [{ col1: 'Ethiopia', col2: 'ET', col3: 114963588, col4: 1104300 }],
	},
	{
		row: [{ col1: 'Egypt', col2: 'EG', col3: 102334404, col4: 1002450 }],
	},
	{
		row: [{ col1: 'Philippines', col2: 'PH', col3: 109581078, col4: 342353 }],
	},
];

const useStyles = makeStyles({
	root: {
		width: '100%',
		height: '100%',
	},
	container: {
		maxHeight: 440,
	},
	head: {
		height: '60px',
		backgroundColor: 'black',
	},
	rows: {
		height: '60px',
	},
	footer: {
		marginLeft: '810px',
		marginTop: '-130px',
	},
	header: {
		marginLeft: '105px',
		paddingTop: '20px',
		height: '95px',
		fontSize: '28px',
		fontFamily:
			'Roboto, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica Neue, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol"',
		color: colors.secondaryG,
		fontWeight: 'bold',
		textDecoration: 'underline',
	},
});

export default function TableWidget(props) {
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const rows = [];
	props.rows.forEach(data => {
		const row = {};

		for (const key in data.row[0]) {
			if (data.row[0].hasOwnProperty(key)) {
				row[key] = data.row[0][key];
			}
		}

		rows.push(row);
	});

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = event => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	return (
		<Paper className={classes.root}>
			<h1 className={classes.header} aria-labelledby={'stickytitle'}>
				{props.showTitle ? props.header : null}
			</h1>
			<TableContainer className={classes.container}>
				<Table stickyHeader aria-label='stickytable'>
					<TableHead>
						<TableRow hover={true} className={classes.head}>
							{props.columns.map(column => (
								<TableCell key={column.id} align={'center'} style={{ minWidth: 200, backgroundColor: 'lightgray' }}>
									{column.label}
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(row => {
							return (
								<TableRow className={classes.rows} hover={true} role='checkbox' tabIndex={-1} key={row.code}>
									{props.columns.map(column => {
										const value = row[column.id];
										return (
											<TableCell key={column.id} align={'center'}>
												{typeof value === 'number' ? value.toLocaleString('sv-SE') : value}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</Table>
			</TableContainer>
			<TablePagination
				rowsPerPageOptions={[10, 25, 50]}
				component='div'
				count={rows.length}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={handleChangePage}
				onChangePage={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
				onChangeRowsPerPage={handleChangeRowsPerPage}
				nextIconButtonProps={{ page }}
			/>
		</Paper>
	);
}
