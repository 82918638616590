import React, { useState } from 'react';
import { SensorSelectorTable } from './SensorSelectorTable';
import { Button, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

function SensorHandler({ selectedProperties, changeSensorsCallback, graphSensors, maxSelectionCount = undefined }) {
	const { t } = useTranslation();
	const [openPopup, setOpenPopup] = useState(false);

	return (
		<>
			<Button
				style={{ position: 'absolute', top: 50, right: 5, zIndex: 1 }}
				variant='contained'
				color='primary'
				onClick={() => setOpenPopup(true)}
			>
				{t('constants.sensors')}
			</Button>
			<Dialog open={openPopup} maxWidth={false} PaperComponent={props => <>{props.children}</>}>
				<SensorSelectorTable
					isOpen={openPopup}
					selectedProperties={selectedProperties}
					graphSensors={graphSensors}
					changeSensorsCallback={changeSensorsCallback}
					closeCallback={didSave => setOpenPopup(false)}
					maxSelectionCount={maxSelectionCount}
				/>
			</Dialog>
		</>
	);
}

export default SensorHandler;
