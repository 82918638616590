import React, { useState, useEffect, useRef, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import IconButton from '@material-ui/core/IconButton';
import { LinearProgress, Switch, Divider } from '@material-ui/core';
import ZoomOutMapOutlinedIcon from '@material-ui/icons/ZoomOutMapOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { CompareArrows, ShowChart } from '@material-ui/icons';
import { getIntervalInMinutes, useQuerySensorDataWidget } from '../../../utility-functions/dataFetcherHooks';
import { AppBar, Button, Toolbar } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LineChart from '../Widget/LineChart';
import BarChart from '../Widget/BarChart';
import PieChart from '../Widget/PieChart';
import DateSlider from './DateSlider';
import ChangeTitle from './ChangeTitle';
import { useTranslation } from 'react-i18next';
import { normalizeData } from '../Widget/dataFilters';
import { ComparePreviousButton, DropDownButton, IntervalButton } from './ToolbarButtons';
import { CHART_WIDGET_TYPES, MINUTES_PER_DAY, MINUTES_PER_MONTH, MINUTES_PER_WEEK, MINUTES_PER_YEAR } from '../../../constants';
import i18n from '../../../i18n';

const useStyles = makeStyles(theme => ({
	modal: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		marginTop: '-50px',
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(1, 2, 1),
		width: '90%',
		height: '85%',
		paddingTop: '5px',
	},
	btnpop: {
		position: 'absolute',
		top: 0,
		right: 40,
		zIndex: 100,
	},
	btninside: {
		'& > *': {
			margin: theme.spacing(1),
		},
		position: 'absolute',
		top: '8%',
		right: '4.8%',
		zIndex: 100,
	},
}));

const AnalyseMenu = props => {
	const classes = useStyles();
	const [open, setOpen] = useState(false);
	const isMounted = useRef(true);

	useEffect(() => {
		return () => {
			isMounted.current = false;
		};
	}, []);

	const handleOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	if (!isMounted.current) {
		return null;
	}
	return (
		<div>
			{props.hidePopoutIcon ? undefined : (
				<div className={classes.btnpop} data-cy='modal-pop-up'>
					<IconButton style={{ position: 'absolute', top: 0, left: -10 }} onClick={handleOpen} aria-label='open'>
						<ZoomOutMapOutlinedIcon />
					</IconButton>
				</div>
			)}
			<Modal
				aria-labelledby='transition-modal-title'
				aria-describedby='transition-modal-description'
				className={classes.modal}
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
				}}
				data-cy='modal-settings-line-graph'
			>
				<Fade in={open}>
					<div id='main' className={classes.paper}>
						<div className={classes.btninside}>
							<IconButton onClick={handleClose} aria-label='delete'>
								<CloseIcon />
							</IconButton>
						</div>
						{props.children}
					</div>
				</Fade>
			</Modal>
		</div>
	);
};

const filterData = (chartData, startEndIndex) => {
	const newData = chartData.reduce((filtered, value) => {
		return [
			...filtered,
			{
				...value,
				data: value.data.slice(
					value.data.findIndex(datapoints => datapoints.x >= startEndIndex[0]),
					value.data.findLastIndex(datapoints => {
						return datapoints.x <= startEndIndex[1];
					}) + 1
				),
			},
		];
	}, []);
	return newData;
};

function getInitialStartEndIndex(timerange) {
	const roundTimestamp = (ts, floor = true) => {
		const date = new Date(ts);
		date.setHours(0, 0, 0, 0);
		if (!floor) {
			date.setDate(date.getDate() + 1);
			date.setMilliseconds(-1);
		}
		return date.getTime();
	};
	if (timerange.offset === undefined || timerange.offset === null) {
		return [roundTimestamp(new Date(timerange.startDate).getTime()), roundTimestamp(new Date(timerange.endDate).getTime(), false)];
	}
	const nowUnix = new Date().getTime();
	return [roundTimestamp(nowUnix - timerange.offset * 60 * 1000), roundTimestamp(nowUnix, false)];
}

const DATATYPEOPTIONS = {
	mean: { selectedText: i18n.t('constants.mean'), menuText: i18n.t('constants.mean') },
	sum: { selectedText: i18n.t('constants.sum'), menuText: i18n.t('constants.sum') },
};

const COMPAREOPTIONS = {
	none: { menuText: 'Off', selectedText: 'compare' },
	month: { menuText: 'Previous Month', selectedText: 'prev. month' },
	year: { menuText: 'Previous Year', selectedText: 'prev. year' },
};

const INTERVAL_OPTIONS = {
	hour: { text: i18n.t('generic.hour'), duration: 12 },
	day: { text: i18n.t('generic.day'), duration: 7 },
	week: { text: i18n.t('generic.week'), duration: 4 },
	month: { text: i18n.t('generic.month'), duration: 12 },
	year: { text: i18n.t('generic.year'), duration: 5 },
};

function getIntervalOptions(timeRange) {
	const interval = getIntervalInMinutes(timeRange);
	let options = {};

	if (MINUTES_PER_MONTH > interval) {
		options = { hour: { duration: 12, text: i18n.t('generic.hour') } };
	}
	if (MINUTES_PER_MONTH * 6 > interval && interval > MINUTES_PER_DAY) {
		options = { day: { duration: Math.min(Math.floor(interval / MINUTES_PER_DAY), 7), text: i18n.t('generic.day') }, ...options };
	}
	if (MINUTES_PER_YEAR * 2 > interval && interval > MINUTES_PER_WEEK) {
		options = { week: { duration: Math.min(Math.floor(interval / MINUTES_PER_WEEK), 4), text: i18n.t('generic.week') }, ...options };
	}
	if (MINUTES_PER_YEAR * 6 > interval && interval > MINUTES_PER_MONTH) {
		options = {
			month: { duration: Math.min(Math.floor(interval / MINUTES_PER_MONTH), 12), text: i18n.t('generic.month') },
			...options,
		};
	}
	if (interval > MINUTES_PER_YEAR) {
		options = { ...options, year: { duration: Math.min(Math.floor(interval / MINUTES_PER_YEAR), 5), text: i18n.t('generic.year') } };
	}

	return options;
}

function getCompareOptions(timeRange) {
	const defaultOption = { none: { menuText: i18n.t('autoUpdate.off'), selectedText: i18n.t('sensorGraph.compare') } };
	switch (timeRange.offset) {
		case MINUTES_PER_DAY:
			return {
				...defaultOption,
				day: { menuText: i18n.t('staticTimeRange.yesterday'), selectedText: i18n.t('staticTimeRange.yesterday') },
			};
		case MINUTES_PER_WEEK:
			return {
				...defaultOption,
				week: {
					menuText: i18n.t('modalSettings.previousWeek'),
					selectedText: i18n.t('modalSettings.previous') + i18n.t('generic.week'),
				},
			};
		case MINUTES_PER_MONTH:
			return {
				...defaultOption,
				month: {
					menuText: i18n.t('modalSettings.previousMonth'),
					selectedText: i18n.t('modalSettings.previous') + i18n.t('generic.month'),
				},
				year: {
					menuText: i18n.t('modalSettings.sameMonthPreviousYear'),
					selectedText: i18n.t('modalSettings.previous') + i18n.t('generic.year'),
				},
			};
		case MINUTES_PER_YEAR:
			return {
				...defaultOption,
				year: {
					menuText: i18n.t('modalSettings.previousYear'),
					selectedText: i18n.t('modalSettings.previous') + i18n.t('generic.year'),
				},
			};
		default:
			return { ...defaultOption };
	}
}

const UPDATEINTERVAL_OFF = 0;

function previousTimeRange(timeRange, compareTo) {
	const prevStartDate = new Date(timeRange.startDate);
	const prevEndDate = new Date(timeRange.endDate);
	const offset = timeRange.offset;

	switch (compareTo) {
		case 'day':
			prevStartDate.setDate(prevStartDate.getDate() - 1);
			prevEndDate.setDate(prevEndDate.getDate() - 1);
			break;
		case 'week':
			prevStartDate.setDate(prevStartDate.getDate() - 7);
			prevEndDate.setDate(prevEndDate.getDate() - 7);
			break;
		case 'month':
			prevStartDate.setMonth(prevStartDate.getMonth() - 1);
			prevEndDate.setMonth(prevEndDate.getMonth() - 1);
			break;
		case 'year':
			prevStartDate.setFullYear(prevStartDate.getFullYear() - 1);
			prevEndDate.setFullYear(prevEndDate.getFullYear() - 1);
			break;
		default:
			break;
	}

	return {
		startDate: prevStartDate,
		endDate: prevEndDate,
		offset: offset,
	};
}

export function LineGraphSettings(props) {
	const [normalize, setNormalize] = useState(props.normalize);
	const [startEndIndex, setStartEndIndex] = useState(getInitialStartEndIndex(props.timeRange));
	const [data, setData] = useState(props.sensorData);
	const [dataType, setDataType] = useState(props.prefferedDataType || 'mean');
	const [interval, setInterval] = useState(props.interval);
	const [compareTo, setCompareTo] = useState('none');
	const { t } = useTranslation();
	const prevTimeRange = useMemo(() => previousTimeRange(props.timeRange, compareTo), [props.timeRange, compareTo]);

	const { data: queryData, loading } = useQuerySensorDataWidget(props.sensors, props.timeRange, interval, dataType);
	const { data: compareData, loading: compareLoading } = useQuerySensorDataWidget(
		props.sensors,
		prevTimeRange,
		interval,
		dataType,
		UPDATEINTERVAL_OFF,
		compareTo === 'none',
		compareTo
	);

	useEffect(() => {
		if (queryData.length) {
			setData(queryData);
			setStartEndIndex([...startEndIndex]);
		}
	}, [queryData]);

	const transformedData = useMemo(() => {
		let transformedData = data;
		if (compareTo !== 'none') {
			transformedData = [...data, ...compareData];
		}
		if (normalize) {
			transformedData = normalizeData(transformedData);
		}
		return transformedData;
	}, [data, normalize, compareData, compareTo]);

	let dataTypeOptions = DATATYPEOPTIONS;
	if (getIntervalInMinutes(props.timeRange) <= MINUTES_PER_WEEK && props.widgetType === CHART_WIDGET_TYPES.LineChart)
		dataTypeOptions = { ...DATATYPEOPTIONS, raw: { selectedText: t('constants.raw'), menuText: t('constants.raw'), value: 'raw' } };

	return (
		<>
			<div style={{ display: 'flex', width: '100%' }}>
				<div style={{ width: '27%', height: 'fit-content' }}>
					<ChangeTitle title={props.title} changeTitleCallback={title => props.updateTitle(title)} />
				</div>
				<div style={{ marginLeft: '15px', width: 'calc(73% - 55px)' }}>
					<AppBar
						position='relative'
						style={{
							borderRadius: '5px',
							width: '100%',
							border: '1px gray solid',
							display: 'block',
						}}
					>
						<Toolbar style={{ width: '100%', height: 'fit-content' }}>
							<Grid container alignItems='center'>
								<DropDownButton
									value={dataType}
									onSelect={type => setDataType(type)}
									options={dataTypeOptions}
									startIcon={<ShowChart style={{ marginBottom: '3px' }} />}
								/>

								<Divider
									orientation='vertical'
									flexItem
									style={{ backgroundColor: 'white', marginLeft: '5px', marginRight: '5px' }}
								/>

								{dataType === 'raw' || props.widgetType === CHART_WIDGET_TYPES.PieChart ? undefined : (
									<>
										<IntervalButton
											text={t('constants.interval')}
											duration={interval.duration}
											interval={interval.type}
											options={getIntervalOptions(props.timeRange)}
											onSelectCallback={selected => setInterval(selected)}
										/>
										<Divider
											orientation='vertical'
											flexItem
											style={{ backgroundColor: 'white', marginLeft: '5px', marginRight: '5px' }}
										/>
									</>
								)}

								<Button
									data-cy='normalize-button'
									color='inherit'
									onClick={() => setNormalize(!normalize)}
									style={{ border: '1px solid transparent' }}
								>
									{t('constants.normalizeData')}
									<Switch color='default' checked={normalize} />
								</Button>
								<Divider
									orientation='vertical'
									flexItem
									style={{ backgroundColor: 'white', marginLeft: '5px', marginRight: '5px' }}
								/>
								{props.timeRange.offset === undefined ? undefined : (
									<DropDownButton
										value={compareTo}
										onSelect={type => setCompareTo(type)}
										options={getCompareOptions(props.timeRange)}
										startIcon={<CompareArrows style={{ marginBottom: '3px' }} />}
									/>
								)}
							</Grid>
						</Toolbar>
					</AppBar>
				</div>
			</div>
			<div style={{ width: '100%', height: '90%', zIndex: 0, marginTop: '4px' }}>
				{loading || compareLoading ? (
					<LinearProgress style={{ position: 'absolute', width: '65.6%', zIndex: 1, marginTop: '30px' }} />
				) : undefined}
				{props.widgetType === CHART_WIDGET_TYPES.BarChart ? (
					<BarChart data={filterData(transformedData, startEndIndex)} timeRange={props.timeRange} viewMode={'popout'} />
				) : props.widgetType === CHART_WIDGET_TYPES.PieChart ? (
					<PieChart data={filterData(transformedData, startEndIndex)} dataType={dataType} viewMode={'popout'} />
				) : (
					<LineChart
						data={filterData(transformedData, startEndIndex)}
						{...props}
						showTitle={false}
						timeRange={props.timeRange}
						viewMode={'popout'}
						interval={interval}
						dataType={dataType}
					/>
				)}

				<DateSlider
					chartData={data}
					dataIndex={startEndIndex}
					dataIndexCallback={index => {
						setStartEndIndex(index);
					}}
					initialStartEndIndex={getInitialStartEndIndex(props.timeRange)}
					timeRange={props.timeRange}
					interval={interval}
					dataType={dataType}
				/>
			</div>
		</>
	);
}

export default AnalyseMenu;
