import { addDays, addMonths, endOfDay, endOfMonth, endOfWeek, isSameDay, startOfDay, startOfMonth, startOfWeek } from 'date-fns';

import { useTranslation } from 'react-i18next';

const useDefaultStaticRanges = selectedRange => {
	const { t } = useTranslation();

	const selectableDateRangesLimits = {
		startOfWeek: startOfWeek(new Date(), { weekStartsOn: 1 }),
		endOfWeek: endOfWeek(new Date(), { weekStartsOn: 1 }),
		startOfLastWeek: startOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
		endOfLastWeek: endOfWeek(addDays(new Date(), -7), { weekStartsOn: 1 }),
		startOfToday: startOfDay(new Date()),
		endOfToday: endOfDay(new Date()),
		startOfYesterday: startOfDay(addDays(new Date(), -1)),
		endOfYesterday: endOfDay(addDays(new Date(), -1)),
		startOfMonth: startOfMonth(new Date()),
		endOfMonth: endOfMonth(new Date()),
		startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
		endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
	};

	const selectableRangesWithoutIsSelected = [
		{
			label: t('staticTimeRange.today'),
			range: () => ({
				startDate: selectableDateRangesLimits.startOfToday,
				endDate: selectableDateRangesLimits.endOfToday,
			}),
		},
		{
			label: t('staticTimeRange.yesterday'),
			range: () => ({
				startDate: selectableDateRangesLimits.startOfYesterday,
				endDate: selectableDateRangesLimits.endOfYesterday,
			}),
		},

		{
			label: t('staticTimeRange.thisWeek'),
			range: () => ({
				startDate: selectableDateRangesLimits.startOfWeek,
				endDate: selectableDateRangesLimits.endOfWeek,
			}),
		},
		{
			label: t('staticTimeRange.lastWeek'),
			range: () => ({
				startDate: selectableDateRangesLimits.startOfLastWeek,
				endDate: selectableDateRangesLimits.endOfLastWeek,
			}),
		},
		{
			label: t('staticTimeRange.thisMonth'),
			range: () => ({
				startDate: selectableDateRangesLimits.startOfMonth,
				endDate: selectableDateRangesLimits.endOfMonth,
			}),
		},
		{
			label: t('staticTimeRange.lastMonth'),
			range: () => ({
				startDate: selectableDateRangesLimits.startOfLastMonth,
				endDate: selectableDateRangesLimits.endOfLastMonth,
			}),
		},
	];

	const isSelected = staticRange =>
		selectedRange.startDate &&
		staticRange?.range()?.startDate &&
		selectedRange.endDate &&
		staticRange?.range()?.endDate &&
		isSameDay(selectedRange.startDate, staticRange.range().startDate) &&
		isSameDay(selectedRange.endDate, staticRange.range().endDate);

	const addIsSelectedToSelectableRanges = ranges => {
		return ranges.map(range => ({
			isSelected: () => isSelected(range),
			...range,
		}));
	};

	return addIsSelectedToSelectableRanges(selectableRangesWithoutIsSelected);
};

export default useDefaultStaticRanges;
