import React from 'react';

import IconButton from '@material-ui/core/IconButton';

/**
 * Draws a button for toggling a state on/off
 * @param {Boolean} isOn : Whether or not the toggle is enabled
 * @param {Element} onIconElement : For signaling on-state, should come with pre-set styles (width, height, color)
 * @param {Element} offIconElement : For signaling off-state, should come with pre-set styles (width, height, color)
 * @param {Function} callback : Called when the toggle changes, with the new state of the button
 * @param {Object} style : CSS properties applied to root
 */
function ToggleButton(props) {
	function onClick() {
		props.callback(!props.isOn);
	}

	return (
		<IconButton onClick={onClick} style={props.style}>
			{props.isOn ? props.onIconElement : props.offIconElement}
		</IconButton>
	);
}

export default ToggleButton;
