import React from 'react';
import { Tooltip, Zoom } from '@material-ui/core';

/**
 * Standardized Tooltip with predefined settings
 * @param {components} children : Passed to the Tooltip
 * @param {object} tooltipProps : Additional props to apply or override on the Tooltip
 * @param {string | HTML} text : Displayed when the object is hovered or touched
 */
function TooltipWrapper(props) {
	return (
		<Tooltip arrow TransitionComponent={Zoom} enterTouchDelay={0} leaveTouchDelay={6000} title={props.text} {...props.tooltipProps}>
			{props.children}
		</Tooltip>
	);
}

export default TooltipWrapper;
