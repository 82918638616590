import { format as formatDate } from 'date-fns';

/**
 * Returns ISO formatted date-string. If no parameter is specified then current local time is used
 * @param {Date / undefined} date : Date to convert to string
 * @returns {String} : ISO formatted date with precision up to seconds
 */
function getLocalDateString(date = new Date()) {
	// '~' is used instead of 'T' because 'T' has special formatting functionality
	// T must be used for compatibility with Safari
	return formatDate(date, 'yyyy-MM-dd~HH:mm:ss').replace('~', 'T').substring(0, 19);
}

export { getLocalDateString };
