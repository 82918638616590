import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { PAGES } from '../constants';
import { setPage } from '../redux/actionCreators';
import ActionList from '../components/ActionList';

function Actions(props) {
	useEffect(() => {
		props.setPage(PAGES.actions.id);
		// eslint-disable-next-line
	}, []);

	return <ActionList />;
}

export default connect(null, { setPage })(Actions);
